import React, { Component } from "react";
import { injectIntl, IntlShape, defineMessages } from "react-intl";
import { AdminHeader } from "../../components";
import ProfileCustomization from "../../containers/Profile/ProfileCustomization";

interface IProps {
  intl: IntlShape;
}

const messages = defineMessages({
  title: {
    id: "MyAccount.customization",
    defaultMessage: "Customization",
  },
});

class WrappedCustomization extends Component<IProps> {
  render() {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;

    return (
      <div>
        <AdminHeader title={formatMessage(messages.title)}>
          <ProfileCustomization />
        </AdminHeader>
      </div>
    );
  }
}

const Customization = injectIntl(WrappedCustomization);

Customization.displayName = "Customization";

export default Customization;
