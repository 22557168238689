import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProfileCustomizationForm from "../ProfileCustomizationForm";
// import { getCompanyWhitelabelData, setCompanyCustomizeData } from "actions";
import {
  APIResponseSuccess,
  APIResponseFail,
} from "@intent-ai/mandal-npm-lib-v2/dist/actions";

import { defineMessages, injectIntl, IntlShape } from "react-intl";
import { Dispatch, IStore, select } from "../../../store";

const messages = defineMessages({
  apiResponesCustomizeTitle: {
    id: "MyAccount.changeCustomizeSuccessMessage",
    defaultMessage: "Customize Settings",
  },
  apiResponseCustomizeSuccessMessages: {
    id: "MyAccount.changeSuccessDescription",
    defaultMessage: "All changes have been successfully saved",
  },
  apiResponseCustomizeFailMessages: {
    id: "MyAccount.changeFailMessage",
    defaultMessage: "Failed to save changes",
  },
});

interface IProps {
  APIResponseSuccess: Function;
  APIResponseFail: Function;
  getCompanyWhitelabelData: Function;
  setCompanyCustomizeData: Function;
  whiteLabelId: string;
  intl: IntlShape;
  initialValues: any;
}

class WrappedMyAccountCustomizePage extends Component<IProps> {
  componentDidMount() {
    const {
      props: { getCompanyWhitelabelData, whiteLabelId, initialValues },
    } = this;
    if (whiteLabelId && !initialValues) {
      getCompanyWhitelabelData({ id: whiteLabelId });
    }
  }

  handleFormSubmit = (e: any) => {
    const {
      props: { setCompanyCustomizeData, whiteLabelId },
    } = this;
    const { savePostFail: fail, savePostSuccess: success } = this;

    setCompanyCustomizeData({
      data: {
        whitelabel_settings: { ...e },
        id: whiteLabelId,
      },
      fail,
      success,
    });
  };

  savePostFail = () => {
    const {
      props: { intl, APIResponseFail },
    } = this;
    APIResponseFail(
      intl.formatMessage(messages.apiResponesCustomizeTitle),
      intl.formatMessage(messages.apiResponseCustomizeFailMessages)
    );
  };

  savePostSuccess = () => {
    const {
      props: { intl, APIResponseSuccess },
    } = this;
    APIResponseSuccess(
      intl.formatMessage(messages.apiResponesCustomizeTitle),
      intl.formatMessage(messages.apiResponseCustomizeSuccessMessages),
      () => window.location.reload()
    );
  };

  render() {
    const {
      props: { initialValues },
      handleFormSubmit,
    } = this;
    if (!initialValues) {
      return null;
    }
    return (
      <div>
        <ProfileCustomizationForm
          initialValues={initialValues.whitelabel_settings}
          onSubmit={handleFormSubmit}
          companyName={initialValues.name}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  whiteLabelId: select.authentication.selectWhiteLabelId(state),
  initialValues: select.admin.whitelabelDataSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCompanyWhitelabelData: dispatch.admin.getWhitelabelCustomizationData,
  setCompanyCustomizeData: dispatch.admin.setWhitelabelCustomizationData,
  APIResponseFail: bindActionCreators(APIResponseFail, dispatch),
  APIResponseSuccess: bindActionCreators(APIResponseSuccess, dispatch),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(WrappedMyAccountCustomizePage)
);
