import React, { Component } from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { MainContainer } from "./containers";
import { APIResponseContainer } from "@intent-ai/mandal-npm-lib-v2";
import { Routes } from "./Routes";
import packageJson from "../package.json";
import localeData from "./i18n/data.json";
import { Dispatch, IRootState, select } from "./store";
import "rc-dropdown/assets/index.css";
import "./reset.scss";
import "./App.css";
import { TopProgressBarProvider } from "./providers/TopProgressBarProvider";
import { ClientInfoContext } from "./providers/ClientInfoProvider";
import { getClient } from "./utils";
import NotificationProvider from "./modules/global/components/NotificationProvider";
import { createTheme, ThemeProvider } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { IWhitelabelSettings } from "./types";
import { AUTH_TOKEN_EXPIRY_DATE } from "./_constants";

console.log("Current version: " + packageJson.version);
console.log("intent.ai");


const defaultTheme = {
  typography: {
    fontFamily: [
      "Source Sans Pro",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif"
    ].join(','),
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          textAlign: 'center' as 'center',
        },
      }
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          disableScrollLock: true,
          sx: {
            maxHeight: 350,
          },
        },
      },
      styleOverrides: {
        select: {
          '& ~ fieldset': {
            borderColor: '#dfe7f0',
          },
        },
        root: {
          height: 40,
          backgroundColor: '#fff',
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small' as const,
      }
    },
    MuiPopover: {
      defaultProps: {
        disableScrollLock: true,
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#dfe7f1',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#ccc',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          background: '#ccc',
          '& > span': {
            background: '#8e8e8e',
          },
        },
      },
    },
  },
  spacing: 4,
};

interface IProps {
  currentUserLocale: string;
  whiteLabelSettings?: IWhitelabelSettings;
  logout: Function;
}
class App extends Component<IProps> {
  getLocaleMessages(language: string) {
    if (!language) {
      return localeData.en;
    }
    const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
    const tempData: any = localeData;
    return (
      tempData[languageWithoutRegionCode] || tempData[language] || localeData.en
    );
  }

  getWhiteLabelTheme(whiteLabelSettings?: IWhitelabelSettings) {
    if (whiteLabelSettings) {
      return {
        palette: {
          primary: {
            main: `#${whiteLabelSettings.primary_color}`,
          },
          secondary: {
            main: `#${whiteLabelSettings.secondary_color}`,
          },
          warning: {
            main: `#${whiteLabelSettings.warning_color}`,
          },
          error: {
            main: `#${whiteLabelSettings.gauge_color}`,
          }
        }
      }
    }
  }

  componentDidMount() {
    const newDate = new Date();
    const date = new Date(localStorage.getItem(AUTH_TOKEN_EXPIRY_DATE) || '');
    date.setHours(date.getHours() - 1);
    if (newDate > date) {
      this.props.logout();
    }
  }

  render() {
    const { currentUserLocale, whiteLabelSettings } = this.props;
    const messages = this.getLocaleMessages(currentUserLocale);

    const clientInfo = getClient();

    const whiteLabelTheme = this.getWhiteLabelTheme(whiteLabelSettings);

    const theme = createTheme(deepmerge(defaultTheme, whiteLabelTheme || {}));

    // const messages = localeData.ru;
    return (
      <ThemeProvider theme={theme}>
        <IntlProvider locale={"en"} messages={messages} textComponent="span">
          <TopProgressBarProvider />
          <div className="resize-warning">
            <p className="resize-warning-message">
              For having best user experience, please use desktop.
            </p>
          </div>
          <APIResponseContainer>
            <MainContainer>
              <ClientInfoContext.Provider value={{
                client: clientInfo,
              }}>
                <Routes />
              </ClientInfoContext.Provider>
            </MainContainer>
          </APIResponseContainer>
          <NotificationProvider />
        </IntlProvider>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return ({
    currentUserLocale: select.authentication.currentUserLocaleSelector(state),
    whiteLabelSettings: state.admin?.whitelabel?.whitelabel_settings,
  });
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: dispatch.authentication.logout,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
