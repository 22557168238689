import React, { Component } from "react";

import { APIResponseSuccess } from "@intent-ai/mandal-npm-lib-v2/dist/actions";

import { AdminDrawer, Modal, Sidebar } from "../../containers";
import { adminMessages, billingMessages, profileMessages } from "../../messages";
import { WithModal } from "../../providers";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dispatch, IRootState, select } from "../../store";
import { IModalProps, ISpace, IUser, IWhitelabelSettings } from "../../types";
import { TOS_STATUSES, userRoles } from "../../_constants";
import "./style.scss";
import clsx from "clsx";
import { Button } from "@mui/material";
import { getSpaceImage, Hamburger } from "../../modules";


const styles = {
  logoutBtn: {
    backgroundColor: '#84EBFD',
    boxShadow: 'none',
    borderRadius: 50,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#84EBFD',
      opacity: 0.7
    }
  }
}

interface IProps {
  title: string | JSX.Element;
  iconName: string;
  className?: string;
  children: JSX.Element[] | JSX.Element;
  isProfilePage?: boolean;
  logout: Function;
  currentBalance: number;
  currentUserRole: number;
  currentUserSpaceName: string;
  currentUserSpaceImage: string;
  header?: JSX.Element[] | JSX.Element;
  currentUser: IUser;
  APIResponseSuccess: Function;
  intl: IntlShape;
  isOwnerAccount: boolean;
  currentUserSpace: ISpace;
  settings: IWhitelabelSettings;
}

interface IState {
  adminPanelIsOpen: boolean;
  active: boolean;
  navBarActiveClass?: string;
}

class AuthTitleWrapper extends Component<IProps & IModalProps, IState> {
  state = {
    adminPanelIsOpen: false,
    active: false,
    navBarActiveClass: "",
  };

  componentDidMount() {
    const {
      props: { currentUserRole },
      handleKeyUp,
    } = this;
    if (currentUserRole === userRoles.WL_OWNER) {
      document.addEventListener("keyup", handleKeyUp);
    }
  }
  toggleMenu = () => {
    this.setState(
      {
        active: !this.state.active,
      },
      () => {
        if (this.state.active) {
          document.body.style.position = "fixed";
          this.setState({
            navBarActiveClass: "is-active",
          });
        } else {
          document.body.style.position = "static";

          this.setState({
            navBarActiveClass: "",
          });
        }
      }
    );
  };
  handleKeyUp = (event: any) => {
    const {
      state: { adminPanelIsOpen },
    } = this;

    if (
      !adminPanelIsOpen &&
      event.keyCode === 191 &&
      event.target.tagName !== "INPUT"
    ) {
      this.openAdminPanel();
    }
  };

  openAdminPanel = () => {
    if (this.state.navBarActiveClass) {
      this.toggleMenu();
    }
    this.setState(({ adminPanelIsOpen }) => ({
      adminPanelIsOpen: !adminPanelIsOpen,
    }));
  };

  closeAdminPanel = () => {
    this.setState(() => ({
      adminPanelIsOpen: false,
    }));
  };

  openRefillModal = () => {
    const {
      props: {
        intl: { formatMessage },
        openModal,
        modalTypes,
        currentUser,
        APIResponseSuccess,
      },
    } = this;


    const { tos_status_type_key } = currentUser;

    if (
      tos_status_type_key !== null &&
      tos_status_type_key === TOS_STATUSES.PENDING
    ) {
      APIResponseSuccess(
        formatMessage(billingMessages.success),
        formatMessage(profileMessages.legalEntityInfoPending)
      );
      return;
    }
    openModal({
      modalType: modalTypes.refillBalanceModal,
      modalData: {},
    });
  };

  render() {
    const {
      props: {
        intl: { formatMessage },
        children,
        title,
        isProfilePage,
        logout,
        className = "",
        modalType,
        toggle,
        isOpen,
        closeModal,
        modalTypes,
        modalData,
        openModal,
        currentUserSpaceName,
        currentUserRole,
        currentUserSpaceImage,
        header,
        currentUserSpace,
        currentUser,
        settings,
      },
      state: { adminPanelIsOpen, navBarActiveClass },
      openAdminPanel,
      closeAdminPanel,
      toggleMenu,
    } = this;
    const isMyAccount = currentUserSpace.userId === currentUser.id;

    return (
      <>
        <Sidebar settings={settings} isActive={navBarActiveClass} />
        {adminPanelIsOpen && (
          <AdminDrawer
            open={adminPanelIsOpen}
            closeAdminPanel={closeAdminPanel}
          />
        )}
        <div className="auth-title-wrapper-container">
          <div
            className={clsx("auth-title", {
              user: currentUserRole === userRoles.USER_OWNER,
            })}
          >
            {[userRoles.WL_OWNER, userRoles.WL_MODERATOR].includes(
                currentUserRole
              ) ? (
                <div
                  className={clsx("auth-title-admin", {
                    open: adminPanelIsOpen,
                  })}
                  onClick={openAdminPanel}
                >
                  <div className="auth-title-admin-account-image">
                    <img
                      src={getSpaceImage(currentUserSpaceImage)}
                      alt="space"
                    />
                  </div>
                  <div className="auth-title-admin-text">
                    <p className="auth-title-admin-account">
                      <FormattedMessage
                        id="Admin.currentAccount"
                        defaultMessage="Current Account"
                      />{" "}
                      {isMyAccount &&
                        `(${formatMessage(adminMessages.myAccount)})`}
                    </p>
                    <p className="auth-title-admin-account-name">
                      {currentUserSpaceName}{" "}
                    </p>
                  </div>
                  <div className="auth-title-admin-arrow">
                    <div
                      className={clsx("dropdown-arrow", {
                        open: adminPanelIsOpen,
                      })}
                    />
                  </div>
                </div>
              ) : null
            }
          <div className="auth-title-meta">
              <span
                className={clsx("auth-title-text", {
                  user: currentUserRole === userRoles.USER_OWNER,
                })}
              >
                {title}
              </span>
          </div>
          <div className="auth-title-billing">
            {isProfilePage ? (
              <div className="auth-title-logout">
                <Button
                  className={'mai-button'}
                  onClick={() => logout()}
                  sx={styles.logoutBtn}
                  variant={'contained'}
                >{formatMessage(adminMessages.logOut)}</Button>
              </div>
              ) : null
            }
              <div className="menu-icon">
                <Hamburger
                  sidebarToggle={toggleMenu}
                  sidebarOpen={!!navBarActiveClass}
                  icon="icon1"
                />
              </div>
            </div>
          </div>
        </div>
        {header && <div className="auth-header">{header}</div>}
        <div className={`auth-children ${className}`}>{children}</div>
        {isOpen && (
          <Modal
            modalData={modalData}
            modalType={modalType}
            toggle={toggle}
            isOpen={isOpen}
            openModal={openModal}
            closeModal={closeModal}
            modalTypes={modalTypes}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  currentBalance: select.space.currentBalanceSelector(state),
  currentUserSpaceName: select.authentication.selectCurrentUserSpaceName(state),
  currentUserRole: select.authentication.selectCurrentUserRole(state),
  settings: select.admin.whitelabelSettingsSelector(state),
  currentUserSpaceImage:
    select.authentication.selectCurrentUserSpaceImage(state),
  currentUser: select.authentication.selectCurrentUser(state),
  isOwnerAccount: select.authentication.isOwnerAccountSelector(state),
  currentUserSpace: select.authentication.selectCurrentUserSpace(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: dispatch.authentication.logout,
  APIResponseSuccess: bindActionCreators(APIResponseSuccess, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WithModal(AuthTitleWrapper)));
