/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 8/30/2023
 * All Rights Reserved.
 */
import { Box, Button, debounce, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { AudienceService } from "../../../audience/services";
import { Website } from "../../../audience/types/audience";
import {
  abbreviateNumber,
  CrossIcon,
  IntAutocomplete,
  IntFieldController,
  PendingIcon,
  ProfileIcon,
} from "../../../global";
import { useFormContext, useWatch } from "react-hook-form";
import iconPlaceholder from '../../../../assets/images/ic_placeholder.png';
import { style } from './style';
import { useTranslation } from "react-i18next";
import { CampaignService } from "../../services";
import { StatusCode } from "../../../global/types";
import { useParams } from "react-router-dom";

export type Props = {
  name: string;
  placeholder: string;
}

function SearchSites({ name, placeholder, }: Props) {
  const [value, setValue] = useState('');

  const { control, setValue: setFieldValue } = useFormContext();

  const { t } = useTranslation();

  const [websiteList, setWebsiteList] = useState<{ isLoading: boolean; data: Array<Website>; }>({
    isLoading: false,
    data: [],
  });

  const [resultFound, setResultFound] = useState(true);

  const { id } = useParams<{ id?: string; }>();

  const apps = useWatch({
    name,
    control,
  });

  const searchWebsites = useCallback(debounce((searchTerm: string) => { // eslint-disable-line
    setWebsiteList({
      isLoading: true,
      data: [],
    });
    setResultFound(true);
    AudienceService.getAudienceResource(searchTerm)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          const resources = data.filter((app: Website) => app.users_min && app.users_max)
            .map((app: Website) => ({ ...app, value: app.id }));

          if (!resources.length) {
            setResultFound(false);
          }
          setWebsiteList({
            isLoading: false,
            data: resources,
          });
        }
      });
  }, 300), []);

  useEffect(() => {
    if (value) {
      searchWebsites(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps


  function removeWebsiteFromList(id: string) {
    setFieldValue(name, apps.filter((app: Website) => app.id !== id));
  }

  async function addWebsiteToList() {
    const response = await CampaignService.addResource({
      name: value,
      id,
    });
    if (response.type?.code == StatusCode.OK && response.data) { // eslint-disable-line
      setFieldValue(name, [...apps, { name: response.data.name, id: response.data.id  }]);
      setValue('');
    }
  }

  function isAppDefined(app: Website) {
    return app.category_id || app.users_max || app.users_min || app.description || app.icon;
  }

  // function hasUserInfo(app: Website) {
  //   return !!(app.users_max || app.users_min);
  // }

  return (
    <>
      {!!apps.length && (
        <Box sx={style.appList}>
          {apps.map((app: Website) => (
            <Box key={app.id || app.name} sx={[style.app, style.appInList]}>
              {isAppDefined(app) ? (
                <Box
                  component='img'
                  src={app.icon || iconPlaceholder}
                  alt={app.description}
                  sx={style.image}
                />
              ) : (
                <Box sx={style.pendingIcon}>
                  <PendingIcon />
                </Box>
              )}
              <Box sx={style.appInfo}>
                <Typography sx={style.appTitle}>{app.name}</Typography>
                <Typography sx={style.appTitle}>
                  {isAppDefined(app) ? (
                    `${abbreviateNumber(app.users_min || 0)} - ${abbreviateNumber(app.users_max || 0)}`
                  ) : t('campaign.targeting.pending')}
                </Typography>
              </Box>
              <CrossIcon style={style.crossIcon} onClick={() => removeWebsiteFromList(app.id)}/>
            </Box>
          ))}
        </Box>
      )}
      <IntFieldController
        control={control}
        name={name}
        Component={IntAutocomplete}
        renderTags={() => null}
        placeholder={placeholder}
        onInputChange={(e, newValue, reason) => {
          if (reason == 'input') { // eslint-disable-line
            setValue(newValue);
          }}
        }
        options={websiteList.data}
        inputValue={value}
        getOptionLabel={(option) => option.name}
        multiple
        clearIcon={null}
        disableCloseOnSelect
        renderOption={(props, option) => (
          <li {...props}>
            <Box sx={style.label}>
              <Box
                component='img'
                src={option.icon || iconPlaceholder}
                alt={option.description}
                sx={style.image}
              />
              <Typography sx={style.optionName}>{option.name}</Typography>
            </Box>
            <Box sx={style.numbers}>
              <ProfileIcon />
              <Typography>
                {abbreviateNumber(option.users_min || 0)} - {abbreviateNumber(option.users_max || 0)}
              </Typography>
            </Box>
          </li>
        )}
        sx={style.autocomplete}
        disableClearable
        noOptionsText={(!resultFound && value) ? (
          <Box sx={style.noOptionBox}>
            <Typography sx={style.noItemFound}>
              {t('campaign.targeting.weDontHaveInfo')}
              <Typography sx={style.itemText} component='span'>"{value}"</Typography>
              {t('campaign.targeting.weCanProvide')}
            </Typography>
            <Button onClick={addWebsiteToList}>{t('campaign.targeting.requestResearch')}</Button>
          </Box>
        ) : t('campaign.targeting.searchForOptions')}
        />
    </>
  )
}

export default SearchSites;