import { FetchUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { POST } from "../_constants";
import { IBaseFilters } from "../types";

const { createRequest } = FetchUtils;

const EMPTY_OBJECT = {};

export const getTransactionsRequest = (filters: IBaseFilters) =>
  createRequest(`/accountant/transactions/list`, POST, EMPTY_OBJECT, filters);

export const getTransactionsByIdRequest = (id: string) =>
  createRequest(`/accountant/transactions/${id}`);
