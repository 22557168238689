import {
  Tabs,
  TabsConsumer,
  DashboardStats,
  MaiRow,
  Switcher,
  Loading,
  AverageStats,
} from "@intent-ai/mandal-npm-lib-v2";
import { reportMessages } from "../../../messages";
import React, { Component } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { Field } from "redux-form";
import { IReportData, IReportFilter, IReportTotals } from "../../../types";

interface IProps {
  reportData: IReportData[];
  reportTotals: IReportTotals;
  intl: IntlShape;
  reportDataIsLoading: boolean;
  state: IReportFilter;
  formattedDictGranularityType: any;
}

class InVideoReportsContainer extends Component<IProps> {
  render() {
    const {
      props: {
        intl: { formatMessage },
        formattedDictGranularityType,
        reportTotals,
        reportDataIsLoading,
        reportData,
      },
    } = this;

    const {
      clicks,
      impressions,
      spent,
      prev_clicks,
      prev_impressions,
      prev_spent,
      campaign_cpm,
      reach,
      prev_reach,
      views = 0,
      watch_time = 0,
    } = reportTotals;

    const ctr =
      clicks !== 0 && impressions !== 0 ? (clicks / impressions) * 100 : 0;
    const prevCtr =
      prev_clicks !== 0 && prev_impressions !== 0
        ? (prev_clicks / prev_impressions) * 100
        : 0;
    const cpc = spent !== 0 && clicks !== 0 ? spent / clicks : 0;
    const prevCpc =
      prev_spent !== 0 && prev_clicks !== 0 ? prev_spent / prev_clicks : 0;
    const view_rate =
      views !== 0 && impressions !== 0 ? views / impressions : 0;
    const avgCPV =
      spent !== 0 && clicks !== 0 && views !== 0 ? spent / (views + clicks) : 0;
    const avgViewDuration =
      watch_time !== 0 && impressions !== 0 ? watch_time / impressions : 0;

    return (
      <div>
        <Tabs>
          <TabsConsumer>
            {({ activeKey }: { activeKey: string }) => {
              return (
                <Tabs.TabPaneFillContainer>
                  <Tabs.TabPane
                    fill
                    tab={
                      <DashboardStats
                        data={[
                          {
                            label: formatMessage(reportMessages.spent),
                            value: spent,
                            showMoneySymbol: true,
                            prevValue: prev_spent,
                            tooltip: formatMessage(reportMessages.spentTooltip),
                          },
                          {
                            label: formatMessage(reportMessages.cpm),
                            value: campaign_cpm,
                            showMoneySymbol: true,
                            tooltip: formatMessage(reportMessages.cpmTooltip),
                          },
                        ]}
                        loading={reportDataIsLoading}
                        active={activeKey === "1"}
                      />
                    }
                    tabKey="1"
                  />
                  <Tabs.TabPane
                    fill
                    tab={
                      <DashboardStats
                        data={[
                          {
                            label: formatMessage(reportMessages.views),
                            value: views,
                            tooltip: formatMessage(reportMessages.viewsTooltip),
                          },
                          {
                            label: formatMessage(reportMessages.viewRate),
                            value: view_rate,
                            tooltip: formatMessage(
                              reportMessages.viewRateTooltip
                            ),
                            children: {
                              label: formatMessage(reportMessages.avgCPV),
                              valueDecimalLength: 2,
                              value: avgCPV,
                              tooltip: formatMessage(
                                reportMessages.avgCPVTooltip
                              ),
                            },
                          },
                        ]}
                        loading={reportDataIsLoading}
                        active={activeKey === "4"}
                      />
                    }
                    tabKey="4"
                  />
                  <Tabs.TabPane
                    fill
                    tab={
                      <DashboardStats
                        data={[
                          {
                            label: formatMessage(reportMessages.reach),
                            value: reach,
                            prevValue: prev_reach,
                            tooltip: formatMessage(reportMessages.reachTooltip),
                          },
                        ]}
                        loading={reportDataIsLoading}
                        active={activeKey === "5"}
                      />
                    }
                    tabKey="5"
                  />
                  <Tabs.TabPane
                    fill
                    tab={
                      <DashboardStats
                        data={[
                          {
                            value: watch_time,
                            tooltip: formatMessage(
                              reportMessages.watchTimeTooltip
                            ),
                            label: formatMessage(reportMessages.watchTime),
                          },
                          {
                            value: avgViewDuration,
                            tooltip: formatMessage(
                              reportMessages.avgViewDurationTooltip
                            ),
                            label: formatMessage(
                              reportMessages.avgViewDuration
                            ),
                          },
                        ]}
                        active={activeKey === "3"}
                        loading={reportDataIsLoading}
                      />
                    }
                    tabKey="3"
                  />
                  <Tabs.TabPane
                    fill
                    tab={
                      <DashboardStats
                        data={[
                          {
                            label: formatMessage(reportMessages.clicks),
                            value: clicks,
                            showPercentageSymbol: true,
                            valueDecimalLength: 2,
                            prevValue: prevCtr,
                            tooltip: formatMessage(
                              reportMessages.inVideoClicksTooltip
                            ),
                          },
                          {
                            value: ctr,
                            label: formatMessage(reportMessages.ctr),
                            tooltip: formatMessage(reportMessages.ctrTooltip),
                            showPercentageSymbol: true,
                            children: {
                              value: cpc,
                              label: formatMessage(reportMessages.cpc),
                              prevValue: prevCpc,
                              valueDecimalLength: 2,
                              tooltip: formatMessage(reportMessages.cpcTooltip),
                            },
                          },
                        ]}
                        loading={reportDataIsLoading}
                        active={activeKey === "2"}
                      />
                    }
                    tabKey="2"
                  />
                </Tabs.TabPaneFillContainer>
              );
            }}
          </TabsConsumer>
          <div className="report-container-graph-stats">
            <MaiRow mp0 className="report-container-graph-stats-granularity">
              <MaiRow.Col size={4}>
                <Field
                  name="granularity_type_key"
                  component={Switcher}
                  switchType="inline"
                  options={formattedDictGranularityType}
                  label="View by:"
                  withLabel
                  topLabel
                  mp0
                />
              </MaiRow.Col>
            </MaiRow>
            {reportDataIsLoading ?  <Loading /> : (
              <>
                <Tabs.Container tabKey="1">
                  <AverageStats
                    name={formatMessage(reportMessages.spent)}
                    color="#3A77EB"
                    data={reportData}
                    dataKey="spent"
                    tooltip={formatMessage(reportMessages.spendGraphTooltip)}
                    barChart
                    versusDataKey="saved_spent"
                    versusName={formatMessage(reportMessages.savedAmount)}
                    versusColor="#dfe7f0"
                  />
                </Tabs.Container>
                <Tabs.Container tabKey="2">
                  <AverageStats
                    color="#3A77EB"
                    data={reportData}
                    dataKey="clicks"
                    tooltip={formatMessage(reportMessages.clicksTooltip)}
                    tooltipMetrics={[
                      {
                        value: "clicks",
                      },
                    ]}
                  />
                </Tabs.Container>
                <Tabs.Container tabKey="3">
                  <AverageStats
                    name={formatMessage(reportMessages.watchTime)}
                    color="#3A77EB"
                    data={reportData}
                    dataKey="watch_time"
                    barChart
                    versusDataKey="pause_close"
                    versusName={formatMessage(reportMessages.pauseClose)}
                    versusColor="#dfe7f0"
                    tooltipMetrics={[
                      {
                        value: "watch_time",
                      },
                    ]}
                    tooltip={formatMessage(reportMessages.watchTimeTooltip)}
                  />
                </Tabs.Container>
                <Tabs.Container tabKey="4">
                  <AverageStats
                    color="#3A77EB"
                    data={reportData}
                    dataKey="views"
                    tooltip={formatMessage(
                      reportMessages.impressionGraphTooltip
                    )}
                    tooltipMetrics={[
                      {
                        value: "views",
                      },
                    ]}
                  />
                </Tabs.Container>
                <Tabs.Container tabKey="5">
                  <AverageStats
                    color="#3A77EB"
                    data={reportData}
                    dataKey="reach"
                    tooltip={formatMessage(reportMessages.reachGraphTooltip)}
                    tooltipMetrics={[
                      {
                        value: "reach",
                      },
                    ]}
                  />
                </Tabs.Container>
              </>
            )}
          </div>
        </Tabs>
      </div>
    );
  }
}

export default injectIntl(InVideoReportsContainer);
