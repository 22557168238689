import React from "react";
import {
  defineMessages,
  injectIntl,
  IntlShape,
  FormattedMessage,
} from "react-intl";
import { Button, Flex, NotificationApi } from "@intent-ai/mandal-npm-lib-v2";
import { fail_icon, moderation_icon } from "../../../assets";
import {
  IN_MODERATION,
  REJECTED,
  PAUSED_DUE_TO_SPACE_BALANCE_SPENT,
  SPACE_BALANCE_EMPTY,
  userRoles,
} from "../../../_constants";
import { ICampaign, IModalProps } from "../../../types";
import { connect } from "react-redux";
import { select, Dispatch, IStore } from "../../../store";
import "./styles.scss";
import { WithModal } from "../../../providers";
import { Modal } from "../../../containers";
import { campaignMessages } from "../../../messages";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { routerPaths } from "../../../config/routes/paths";

const showingStatus = [
  IN_MODERATION,
  REJECTED,
  PAUSED_DUE_TO_SPACE_BALANCE_SPENT,
  SPACE_BALANCE_EMPTY,
];

const messages = defineMessages({
  [`status_${IN_MODERATION}`]: {
    id: "CampaignForm.statusModeration",
    defaultMessage: "Waiting For Approval",
  },
  [`status_${SPACE_BALANCE_EMPTY}`]: {
    id: "Campaign.balanceEmpty",
    defaultMessage: "Insufficient balance",
  },
  [`status_${REJECTED}`]: {
    id: "CampaignForm.statusRejected",
    defaultMessage: "Campaign Rejected",
  },
  [`status_${PAUSED_DUE_TO_SPACE_BALANCE_SPENT}`]: {
    id: "CampaignForm.statusSuspended",
    defaultMessage: "Campaign suspended due to insuffficient balance",
  },
  [`status_description_${SPACE_BALANCE_EMPTY}`]: {
    id: "Campaign.balanceEmptyDescription",
    defaultMessage:
      "You have insufficient funds on your account, please refill the balance to run your campaigns.",
  },
  [`status_description_${IN_MODERATION}`]: {
    id: "CampaignForm.statusModerationDescription",
    defaultMessage: "Please wait until our moderators verify this campaign.",
  },
  [`status_description_${PAUSED_DUE_TO_SPACE_BALANCE_SPENT}`]: {
    id: "CampaignForm.statusSuspendedDescription",
    defaultMessage:
      "You have insufficient funds on your account, please refill the balance to run your campaigns.",
  },
});

const iconTypes: { [key: string]: string } = {
  2: fail_icon,
  99: fail_icon,
  1: moderation_icon,
  44: moderation_icon,
};

interface IProps extends IModalProps {
  intl: IntlShape;
  campaign: ICampaign;
  currentUserRoleId: number;
  approveCampaign: Function;
  rejectCampaign: Function;
}

function WrappedCampaignStatusIndicator({
  campaign,
  intl: { formatMessage },
  currentUserRoleId,
  rejectCampaign,
  modalData,
  modalType,
  toggle,
  isOpen,
  openModal,
  closeModal,
  modalTypes,
  history: { push },
}: IProps & RouteComponentProps) {
  if (
    !campaign ||
    !campaign.status_key ||
    !showingStatus.includes(campaign.status_key)
  ) {
    return null;
  }

  const handleAproveCamapign = () => {
    openModal({
      modalType: modalTypes.approveCampaignModal,
      modalData: {
        campaignName: campaign.name,
        campaignId: campaign.id,
      },
    });
  };

  const handleRejectCamapign = () => {
    openModal({
      modalType: modalTypes.commentModal,
      modalData: {
        campaignName: campaign.name,
        campaignId: campaign.id,
        rejectAction: handleRejectCampaignAction,
      },
    });
  };

  const handleRejectCampaignAction = (comment: string) => {
    rejectCampaign({
      data: {
        campaign_id: campaign.id,
        moderation_start_at: new Date(),
        comment,
      },
      fail: () =>
        NotificationApi.error(formatMessage(campaignMessages.globalError)),
      success: () => {
        NotificationApi.success(
          formatMessage(campaignMessages.campaignRejected)
        );
        push(routerPaths.Campaigns);
      },
    });
  };

  const {
    moderator_comment: campaignStatusMessage,
    status_key: campaignStatus,
  } = campaign;

  return (
    <>
      <div className=" campaign-status-indicator">
        <div className={`campaign-container status-${campaignStatus}`}>
          <div>
            <img
              className="campaign-reject-icon"
              src={campaignStatus ? iconTypes[campaignStatus] : ""}
              alt="Campaign Suspended"
            />
          </div>
          <div className="campaign-reject-info">
            { currentUserRoleId === userRoles.WL_OWNER &&
                campaignStatus === IN_MODERATION ? (
              <div className="campaing-in-moderation-admin">
                <h3>
                  <FormattedMessage
                    id="CampaignForm.statusModerationAdmin"
                    defaultMessage="This Campaign is waiting to be approved. Check the following components before approving:"
                  />
                </h3>
                <h4>
                  <FormattedMessage
                    id="CampaignForm.visuals"
                    defaultMessage="Visuals"
                  />
                </h4>
                <ul>
                  <li>
                    <FormattedMessage
                      id="CampaignForm.visualsAdminList1"
                      defaultMessage="Are nor Offensive, Hate Speech, Racism, Vulgar/Racy"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="CampaignForm.visualsAdminList2"
                      defaultMessage="Don’t contain obvious grammatical issues"
                    />
                  </li>
                </ul>
                <h4>
                  <FormattedMessage
                    id="CampaignForm.clickURL"
                    defaultMessage="Click URL"
                  />
                </h4>
                <ul>
                  <li>
                    <FormattedMessage
                      id="CampaignForm.clickURLAdminList1"
                      defaultMessage="Is working?"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="CampaignForm.clickURLAdminList2"
                      defaultMessage="Directs to the company/roduct/event/person page, which is advertised"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="CampaignForm.clickURLAdminList3"
                      defaultMessage="If attribution is ON, the click link and attribution links are the same"
                    />
                  </li>
                </ul>
                <Flex>
                  <Button
                    i18nId="ButtonLabel.approve"
                    onClick={handleAproveCamapign}
                  />
                  <Button
                    onClick={handleRejectCamapign}
                    type="border"
                    color="red"
                    i18nId="ButtonLabel.reject"
                  />
                </Flex>
              </div>
              ) : (
                <>
                  <h3>{formatMessage(messages[`status_${campaignStatus}`])}</h3>
                  <p className="campaign-reject-description">
                    {campaignStatus === 2
                    ? campaignStatusMessage
                    : formatMessage(
                    messages[`status_description_${campaignStatus}`]
                    )}
                  </p>
                </>
              )
            }
          </div>
        </div>
      </div>
      {isOpen && (
        <Modal
          modalData={modalData}
          modalType={modalType}
          toggle={toggle}
          isOpen={isOpen}
          openModal={openModal}
          closeModal={closeModal}
          modalTypes={modalTypes}
        />
      )}
    </>
  );
}

const mapStateToProps = (state: IStore) => ({
  currentUserRoleId: select.authentication.selectCurrentUserRoleId(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  approveCampaign: dispatch.admin.approveCampaign,
  rejectCampaign: dispatch.admin.rejectCampaign,
});

export const CampaignStatusIndicator = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WithModal(withRouter(WrappedCampaignStatusIndicator))));
