import React from "react";
import "./style.scss";
import { AppsAndWebsitesPreviewList } from "../../../../../components";

interface IProps {
  list?: any;
  currentData: any;
  type: string;
  formChange: any;
  handleCampaignGaugeFieldsChange: Function;
}

class AppsAndWebsitesPreview extends React.Component<IProps> {
  render() {
    const {
      props: { currentData, type, handleCampaignGaugeFieldsChange },
    } = this;
    if (!currentData?.length) return null;
    return (
      <div className="apps-and-websites-preview">
        <ul className="apps-and-websites-preview-list">
          {currentData?.map((e: any) => {
            const handleRemove = () => {
              const {
                props: { formChange },
              } = this;

              const filteredItem = currentData.filter(
                (el: any) => el.name !== e.name
              );
              formChange(
                "CAMPAIGN_FORM",
                `targeting.apps.${type}`,
                filteredItem
              );
              handleCampaignGaugeFieldsChange();
            };
            return (
              <li>
                <AppsAndWebsitesPreviewList
                  items={e}
                  withCloseIcon
                  deleteItem={handleRemove}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default AppsAndWebsitesPreview;
