import React, { Component } from "react";
import { creditCardTypes } from "../../_constants";
import { injectIntl, IntlShape, FormattedMessage } from "react-intl";
import "./PaymentCard.scss";
import { TextWithTooltip } from "@intent-ai/mandal-npm-lib-v2";

interface IProps {
  intl: IntlShape;
  cardNumber: string;
  typeKey: number;
  expMonth: string;
  expYear: string;
  paymentId: string;
  spaceId: string | null;
  isDefault: boolean;
  removeSpacePaymentMethod: Function;
  setSpaceDefaultPayment: Function;
  clientName: string;
  state?: "deleting" | "submitting" | "idle";
}

class PaymentCard extends Component<IProps> {
  render() {
    const {
      props: {
        typeKey,
        cardNumber,
        expMonth,
        expYear,
        removeSpacePaymentMethod,
        paymentId,
        isDefault,
        setSpaceDefaultPayment,
        clientName,
        state,
      },
    } = this;
    return (
      <div
        className="card-wrapper"
        style={{ filter: isDefault ? "grayscale(7%)" : "" }}
      >
        <div>
          <TextWithTooltip
            className="card-title"
            text={clientName}
            tooltip={clientName}
          />
          <div className="card-number">**** {cardNumber}</div>
          <div className="card-exp-date">
            {expMonth}/{expYear.slice(2)}
          </div>
        </div>
        <div className="image-buttons-wrapper">
          <div className="credit-card-type">
            <img
              src={
                typeKey
                  ? creditCardTypes[typeKey].image
                  : creditCardTypes[14].image
              }
              className="type-image"
              alt="Credit card type"
            />
          </div>

          <div>
            {isDefault ? (
              <div className="default-text">
                <FormattedMessage
                  id="AddPaymentMethods.defaultCard"
                  defaultMessage="DEFAULT"
                />
              </div>
            ) : (
              <div
                className="default-text set-default"
                style={state === "submitting" ? ({
                  color: "hsl(100, 100%, 100%, 0.4)",
                }) : undefined}
                onClick={() =>
                  setSpaceDefaultPayment({
                    method_id: paymentId,
                    make_default: true,
                  })
                }
              >
                <FormattedMessage
                  id="AddPaymentMethods.setDefaultCard"
                  defaultMessage="SET DEFAULT"
                />
              </div>
            )}
            <div
              className="remove-text"
              style={state === "deleting" ? ({
                color: "hsl(100, 100%, 100%, 0.4)",
              }) : undefined}
              onClick={() =>
                removeSpacePaymentMethod({
                  id: paymentId,
                  cardNumber,
                })
              }
            >
              <FormattedMessage
                id="AddPaymentMethods.removeCard"
                defaultMessage="REMOVE"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(PaymentCard);
