import { createModel } from "@rematch/core";
import {
  FetchUtils,
  ObjectUtils,
} from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import {
  IDictionaryField,
  IReportFilter,
  IReportsCampaignList,
  IReportData,
  IReportTotals,
  IReportPlatformsData,
  IReportPublisherData,
  IReportBannersData,
} from "../types";
import {
  getReportsCampaignRequest,
  getReportPlatformsRequest,
  getReportPublishersRequest,
  getReportBannersRequest,
} from "../api";
import { select, IRootState } from "../store";
import { deviceTypes, adTypes } from "../_constants";
import { ReportService } from "../modules/report/services";
import { weekAgo } from "../modules/global/utils/date-utils";

const { makeRequest } = FetchUtils;
const { checkObjectIsEmpty } = ObjectUtils;

export const reportFilterInitialValues: IReportFilter = {
  date: {
    from_date: weekAgo(),
    to_date: new Date(),
    dateLabel: "All Time",
    datePresetValue: {
      from_date: weekAgo(),
      to_date: new Date(),
    },
    key: "7_DAYS",
  },
  granularity_type_key: "2",
  publisher: "",
  device_type_key: 1,
  visual_size_key: 888,

  ad_type_key: 1,
  top_publishers_highest_first: 1,
  mode: "c",
};

export function getReportFilterInitialValues(dateRange: {from_date?: Date; to_date?: Date}): IReportFilter {
  return {
    date: {
      from_date: dateRange.from_date || new Date(),
      to_date: dateRange.to_date || new Date(),
      dateLabel: "ALL_TIME",
      datePresetValue: {
        from_date: dateRange.from_date || new Date(),
        to_date: dateRange.to_date || new Date(),
        ...dateRange
      },
      key: "ALL_TIME",
    },
    granularity_type_key: "2",
    publisher: "",
    device_type_key: 1,
    visual_size_key: 888,
  
    ad_type_key: 1,
    top_publishers_highest_first: 1,
    mode: "c",
    ...dateRange
  };
}

export interface ReportsState {
  reportId: string;
  data: IReportData[];
  campaingList: IDictionaryField[];
  totals: IReportTotals;
  campaignListIsLoading: boolean;
  reportIsLoading: boolean;
  reportTotalsLoading: boolean;
  platformData: IReportPlatformsData;
  publisherData: IReportPublisherData;
  bannersData: IReportBannersData;
  reportPlatformDataIsLoading: boolean;
  reportPublisherDataIsLoading: boolean;
  reportBannersIsLoading: boolean;
}

const initialState: ReportsState = {
  reportId: "",
  data: [],
  totals: {
    cpm: 0,
    prev_bids: 0,
    prev_spent: 0,
    spent: 0,
    prev_impressions: 0,
    bids: 0,
    prev_clicks: 0,
    clicks: 0,
    impressions: 0,
    prev_cpm: 0,
    campaign_cpm: 0,
    prev_win_rate: 0,
    win_rate: 0,
    reach: 0,
    prev_reach: 0,
  },
  campaingList: [],
  campaignListIsLoading: true,
  reportIsLoading: true,
  reportPlatformDataIsLoading: true,
  reportPublisherDataIsLoading: true,
  reportBannersIsLoading: true,
  reportTotalsLoading: true,
  platformData: {
    clicks: {
      os: [],
      platforms: [],
      total: 0,
    },
    impressions: {
      os: [],
      platforms: [],
      total: 0,
    },
  },
  publisherData: {
    clicks: [],
    impressions: [],
  },
  bannersData: {
    clicks: {
      values: [],
      total: 0,
    },
    impressions: {
      values: [],
      total: 0,
    },
  },
};

type Report =  { impressions: number; bids: number; clicks: number; spent: number; cpc: number; ctr: number; };

export const reports = createModel({
  name: "reports",
  state: initialState,
  reducers: {
    setReportData: (
      state,
      data: IReportData[] | undefined,
      totals: IReportTotals
    ): ReportsState => data ? ({
      ...state,
      data,
      totals,
    }) : ({ ...state, totals }),
    setReportId: (state, reportId: string): ReportsState => ({
      ...state,
      reportId,
    }),
    setReportLoading: (state, reportIsLoading: boolean): ReportsState => ({
      ...state,
      reportIsLoading,
    }),
    setReportTotalsLoading: (state, reportTotalsLoading: boolean): ReportsState => ({
      ...state,
      reportTotalsLoading,
    }),
    setReportPlatformDataIsLoading: (
      state,
      reportPlatformDataIsLoading: boolean
    ): ReportsState => ({
      ...state,
      reportPlatformDataIsLoading,
    }),
    setReportPublisherDataIsLoading: (
      state,
      reportPublisherDataIsLoading: boolean
    ): ReportsState => ({
      ...state,
      reportPublisherDataIsLoading,
    }),
    setReportBannersIsLoading: (
      state,
      reportBannersIsLoading: boolean
    ): ReportsState => ({
      ...state,
      reportBannersIsLoading,
    }),

    setReportPlatformData: (
      state,
      platformData: IReportPlatformsData
    ): ReportsState => ({
      ...state,
      platformData,
    }),
    setReportPublisherData: (
      state,
      publisherData: IReportPublisherData
    ): ReportsState => ({
      ...state,
      publisherData,
    }),
    setReportBannersData: (
      state,
      bannersData: IReportBannersData
    ): ReportsState => ({
      ...state,
      bannersData,
    }),
    setCampaignList: (
      state,
      campaingList: IDictionaryField[]
    ): ReportsState => ({
      ...state,
      campaingList,
      campaignListIsLoading: false,
    }),
    nullifyReports: (): ReportsState => initialState,
  },
  selectors: (slice, createSelector, hasProps): any => ({
    reportsCampaignListSelector() {
      return (rootState: any) => rootState.reports.campaingList;
    },
    reportsCampaignIsLoadingSelector() {
      return (rootState: any) => rootState.reports.campaignListIsLoading;
    },
    reportIsLoadingSelector() {
      return (rootState: any) => rootState.loading.effects.reports.getReport;
    },
    reportDataSelector() {
      return (rootState: any) => rootState.reports.data;
    },
    reportTotalsSelector() {
      return (rootState: any) => rootState.reports.totals;
    },
    reportCampaignIdSelector() {
      return (rootState: any) => rootState.reports.reportId;
    },
    reportDataIsLoadingSelector() {
      return (rootState: any) => rootState.reports.reportIsLoading;
    },
    reportTotalsIsLoadingSelector() {
      return (rootState: any) => rootState.reports.reportTotalsLoading;
    },
    reportPlatformDataIsLoadingSelector() {
      return (rootState: IRootState) =>
        rootState.reports.reportPlatformDataIsLoading;
    },
    reportPublisherDataIsLoadingSelector() {
      return (rootState: IRootState) =>
        rootState.reports.reportPublisherDataIsLoading;
    },
    reportBannersIsLoadingSelector() {
      return (rootState: IRootState) =>
        rootState.reports.reportBannersIsLoading;
    },
    reportPlatformDataSelector() {
      return (rootState: any) => rootState.reports.platformData;
    },
    reportPublisherDataSelector() {
      return (rootState: any) => rootState.reports.publisherData;
    },
    reportBannersDataSelector() {
      return (rootState: any) => rootState.reports.bannersData;
    },
  }),
  effects: (dispatch) => ({
    async getReport(filters: IReportFilter) {
      dispatch.reports.setReportLoading(true);
      dispatch.reports.setReportTotalsLoading(true);
      let totals = {};
      const MILLISECONDS_IN_DAYS = 86400000;
      let fromDate = new Date(filters.from_date || '');
      const toDate = new Date(filters.to_date || '');
      const campaignStart = new Date(filters.campaign_start_at || '');
      if (fromDate.getTime() < campaignStart.getTime()) {
        fromDate = campaignStart;
      }
      const daysBetweenDates = Math.floor((
        toDate.getTime() - fromDate.getTime()
      ) / MILLISECONDS_IN_DAYS);
      let date = new Date(fromDate);
      const data: Array<Report> = Array(daysBetweenDates).fill({
        "cpm": 0,
        "reach": 0,
        "spent": 0,
        "bids": 0,
        "clicks": 0,
        "impressions": 0,
        "cpc": 0,
        "ctr": 0,
      }).map(item => {
        date.setDate(date.getDate() + 1);
        return ({
          ...item,
          name: date.toISOString(),
        });
      });
      ReportService.getReportTotals(filters).then(response => {
        totals = response.data;
        dispatch.reports.setReportData(undefined, totals);
        dispatch.reports.setReportTotalsLoading(false);
      });
      let index = 0;
      function getReportDashboard(filters: any[]) {
        const seperatedFilters = [
          filters.shift(),
          filters.shift(),
          filters.shift(),
          filters.shift(),
        ].filter(Boolean);
        Promise.all(ReportService.getReportDashboard(seperatedFilters as Array<IReportFilter>))
          .then(response => {
            response.forEach(response => {
              response.data.forEach((item: Report) => {
                const ctr =
                  item.clicks !== 0 && item.impressions !== 0
                    ? (item.clicks / item.impressions) * 100
                    : 0;
                const cpc =
                  item.spent !== 0 && item.clicks !== 0
                    ? item.spent / item.clicks
                    : 0;
                data[index++] = {
                  ...item,
                  cpc,
                  ctr,
                };
              })
            });
            dispatch.reports.setReportData(data, totals);
            dispatch.reports.setReportLoading(false);
            if (filters.length) {
              getReportDashboard(filters);
            }
          });
      }
      getReportDashboard(ReportService.separateReportRequestsWeekly({
        ...filters,
        from_date: fromDate,
      }));
    },
    async getReportPlatformCampaign(filters: IReportFilter) {
      dispatch.reports.setReportPlatformDataIsLoading(true);

      await makeRequest(getReportPlatformsRequest(filters), {
        success: (data: any) => {
          // TODO: fix formatting function

          var formattedPlatformData: any = {
            clicks: {
              platforms: [],
              os: [],
            },
            impressions: {
              platforms: [],
              os: [],
            },
          };
          formattedPlatformData.clicks.platforms.push({
            name: "Mobile",
            value: data.clicks.mobile.total,
            isMobile: true,
            type: deviceTypes.mobile,
          });
          formattedPlatformData.clicks.platforms.push({
            name: "Desktop",
            value: data.clicks.desktop.total,
            isDesktop: true,
            type: deviceTypes.desktop,
          });

          formattedPlatformData.clicks.total =
            data.clicks.desktop.total + data.clicks.mobile.total;

          formattedPlatformData.clicks.os = Object.entries(
            data.clicks.desktop.values
          ).map(([item, item2]) => {
            if (item.toLowerCase().includes("mac")) {
              return {
                name: item,
                value: item2,
                isDesktop: true,
                type: deviceTypes.mac,
              };
            }

            if (item.toLowerCase().includes("windows")) {
              return {
                name: item,
                value: item2,
                isDesktop: true,
                type: deviceTypes.windows,
              };
            }

            return {
              name: item,
              value: item2,
              isDesktop: true,
              type: deviceTypes.all,
            };
          });

          formattedPlatformData.clicks.os.push(
            ...Object.entries(data.clicks.mobile.values).map(
              ([item, item2]) => {
                if (item.toLowerCase().includes("ios")) {
                  return {
                    name: item,
                    value: item2,
                    isMobile: true,
                    type: deviceTypes.ios,
                  };
                }

                if (item.toLowerCase().includes("android")) {
                  return {
                    name: item,
                    value: item2,
                    isMobile: true,
                    type: deviceTypes.android,
                  };
                }

                return {
                  name: item,
                  value: item2,
                  isMobile: true,
                };
              }
            )
          );

          formattedPlatformData.clicks.os.push({
            value: data.clicks.mobile.others,
            name: "Mobile Others",
            isMobile: true,
            type: deviceTypes.otherMobile,
          });

          formattedPlatformData.clicks.os.push({
            value: data.clicks.desktop.others,
            name: "Desktop Others",
            isDesktop: true,
            type: deviceTypes.otherDesktop,
          });

          formattedPlatformData.impressions.platforms.push({
            name: "Mobile",
            value: data.impressions.mobile.total,
            isMobile: true,
            type: deviceTypes.mobile,
          });
          formattedPlatformData.impressions.platforms.push({
            name: "Desktop",
            value: data.impressions.desktop.total,
            isDesktop: true,
            type: deviceTypes.desktop,
          });

          formattedPlatformData.impressions.total =
            data.impressions.desktop.total + data.impressions.mobile.total;

          formattedPlatformData.impressions.os = Object.entries(
            data.impressions.desktop.values
          ).map(([item, item2]) => {
            if (item.toLowerCase().includes("mac")) {
              return {
                name: item,
                value: item2,
                isDesktop: true,
                type: deviceTypes.mac,
              };
            }

            if (item.toLowerCase().includes("windows")) {
              return {
                name: item,
                value: item2,
                isDesktop: true,
                type: deviceTypes.windows,
              };
            }

            return {
              name: item,
              value: item2,
              isDesktop: true,
              type: deviceTypes.all,
            };
          });

          formattedPlatformData.impressions.os.push(
            ...Object.entries(data.impressions.mobile.values).map(
              ([item, item2]) => {
                if (item.toLowerCase().includes("ios")) {
                  return {
                    name: item,
                    value: item2,
                    isMobile: true,
                    type: deviceTypes.ios,
                  };
                }

                if (item.toLowerCase().includes("android")) {
                  return {
                    name: item,
                    value: item2,
                    isMobile: true,
                    type: deviceTypes.android,
                  };
                }

                return {
                  name: item,
                  value: item2,
                  isMobile: true,
                };
              }
            )
          );

          formattedPlatformData.impressions.os.push({
            value: data.impressions.mobile.others,
            name: "Mobile Others",
            isMobile: true,
            type: deviceTypes.otherMobile,
          });

          formattedPlatformData.impressions.os.push({
            value: data.impressions.desktop.others,
            name: "Desktop Others",
            isDesktop: true,
            type: deviceTypes.otherDesktop,
          });

          formattedPlatformData.impressions.os.reverse();
          formattedPlatformData.clicks.os.reverse();
          dispatch.reports.setReportPlatformData(formattedPlatformData);
          dispatch.reports.setReportPlatformDataIsLoading(false);
        },
      });
    },
    async getReportPublishers(filters: IReportFilter) {
      dispatch.reports.setReportPublisherDataIsLoading(true);

      await makeRequest(getReportPublishersRequest(filters), {
        success: (data: any) => {
          var x = {
            impressions: data.impressions.publishers.map(
              ({ name, value }: any) => ({ name, value, clickable: true })
            ),
            clicks: data.clicks.publishers.map(({ name, value }: any) => ({
              name,
              value,
              clickable: true,
            })),
          };
          x.impressions.push({
            value: data.impressions.others,
            name: "Others",
            clickable: false,
          });
          x.clicks.push({
            value: data.clicks.others,
            name: "Others",
            clickable: false,
          });
          dispatch.reports.setReportPublisherData(x);
          dispatch.reports.setReportPublisherDataIsLoading(false);
        },
      });
    },
    async getReportBanner(filters: IReportFilter) {
      dispatch.reports.setReportBannersIsLoading(true);
      await makeRequest(getReportBannersRequest(filters), {
        success: (data: any) => {
          var formattedPlatformData = {
            impressions: {
              values: Object.entries(data.impressions.values).map(
                ([name, value]) => ({
                  name: name.replace("_", " × "),
                  value,
                })
              ),
              total: data.impressions.total,
              ad_types: Object.entries(data.impressions.ad_types).map(
                ([name, value]) => ({
                  name,
                  value,
                  type: adTypes[name],
                })
              ),
            },
            clicks: {
              values: Object.entries(data.clicks.values).map(
                ([name, value]) => ({
                  name: name.replace("_", " × "),
                  value,
                })
              ),
              total: data.clicks.total,
              ad_types: Object.entries(data.clicks.ad_types).map(
                ([name, value]) => ({
                  name,
                  value,
                  type: adTypes[name],
                })
              ),
            },
          };
          dispatch.reports.setReportBannersData(formattedPlatformData);
          dispatch.reports.setReportBannersIsLoading(false);
        },
      });
    },

    async changeReportId(id: string) {
      dispatch.reports.setReportId(id);
    },
    async getReportsCampaign({ success, fail } = {}, store) {
      const spaceId = select.authentication.selectCurrentUserSpaceId(store);
      await makeRequest(getReportsCampaignRequest(spaceId), {
        success: (data: IReportsCampaignList) => {
          if (checkObjectIsEmpty(data)) {
            const campaignList = Object.entries(data).map(([id, name]) => ({
              value: id,
              title: name,
            }));
            dispatch.reports.setReportId(campaignList[0].value);
            dispatch.reports.setCampaignList(campaignList);
            if (success) {
              success(campaignList);
            }
          } else {
            dispatch.reports.setCampaignList([]);
          }
        },
        fail,
      });
    },
  }),
});
