import {
  AddIcon, downloadBlob,
  IntDateRangePicker,
  IntFieldController,
} from "../../../global";
import { Box, Button, MenuItem, Modal, Paper, Select, TextField, Typography } from "@mui/material";
import { Control, SetFieldValue, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { STATUS_LIST_CAMPAIGNS_FILTER } from "../../utils";
import { style } from './style';
import { lazy, Suspense, useEffect } from "react";
import { CampaignService } from "../../services";
import { NotificationService } from "../../../global/services";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";

const CampaignObjective = lazy(() => import('../CampaignObjective'));

type Props = {
  getCampaigns: Function;
  control: Control<any>;
  setValue: SetFieldValue<any>;
  userId: string;
  modalOpen: boolean;
  handleModalOpen: () => void;
  handleModalClose: () => void;
}

function CampaignListFilter({
  getCampaigns,
  control,
  setValue,
  userId,
  modalOpen,
  handleModalOpen,
  handleModalClose
}: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.list.filter' });

  const fields = useWatch({
    control,
  });
  useEffect(() => {
    getCampaigns({
      order_field: "",
      order_asc: false,
      from_date: fields.date?.from,
      to_date: fields.date?.to,
      name: fields.name,
      status_filter: fields.status_filter || [],
      // status_filter: (fields.status_filter || []).map(({ value }: { value: string; }) => value),
      space_id: userId,
      fromFiler: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fields.name,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fields.status_filter,
    fields.date?.from,
    fields.date?.to,
    getCampaigns,
    userId
  ]);

  async function exportToCSV() {
    const blob = await CampaignService.exportToCSV(userId, {
      statusFilter: (fields.status_filter || []).map(({ value }: { value: string; }) => value),
      fromDate: fields.date.from,
      toDate: fields.date.to,
    });
    downloadBlob(blob, 'campaigns.csv');
    NotificationService.handleSuccess(t('exportSuccess'));
  }

  // return <Box sx={style.filter}>
  //   <IntFieldController
  //     control={control}
  //     name='name'
  //     placeholder={t<string>('campaignName')}
  //     sx={style.inputField}
  //   />
  //   <IntFieldController
  //     control={control}
  //     name='status_filter'
  //     multiple
  //     Component={IntAutocomplete}
  //     options={STATUS_LIST_CAMPAIGNS_FILTER}
  //     sx={style.statusFilter}
  //     placeholder={t<string>('statusFilter')}
  //   />
  //   <Typography>{t('showMetricsFor')}</Typography>
  //   <Box sx={style.dateRange}>
  //     <IntFieldController control={control} name='date' Component={IntDateRangePicker} />
  //   </Box>
  //   <Typography sx={style.exportCSV} onClick={exportToCSV}>
  //     {t('exportCSV')}
  //   </Typography>
  //   <Button
  //     variant='contained'
  //     startIcon={<AddIcon sx={style.addIcon}/>}
  //     sx={style.add}
  //     onClick={handleModalOpen}
  //   >
  //     {t('createNew')}
  //   </Button>
  //   <Modal open={modalOpen} onClose={handleModalClose}>
  //     <Paper sx={style.paper}>
  //       <Suspense fallback={null}>
  //         <CampaignObjective handleModalClose={handleModalClose} />
  //       </Suspense>
  //     </Paper>
  //   </Modal>
  // </Box>;

  return  <Box className={'report-list-filter top-radius'}>
    <SearchIcon fontSize={'medium'}/>
    <TextField
      className={'report-list-filter-search-input'}
      variant="standard"
      value={fields.name}
      onChange={(e) => setValue('name', e.target.value)}
      placeholder={t<string>('campaignPlaceholder')}
      InputProps={{
        disableUnderline: true,
      }}/>
    <Button
      variant='contained'
      startIcon={<AddIcon fontSize={'large'} sx={style.addIcon}/>}
      sx={style.add}
      onClick={handleModalOpen}
    >
    </Button>
    <Modal open={modalOpen} onClose={handleModalClose}>
      <Paper sx={style.paper}>
        <Suspense fallback={null}>
          <CampaignObjective handleModalClose={handleModalClose} />
        </Suspense>
      </Paper>
    </Modal>
    <Typography  className={'report-list-filter-csv'} sx={{...style.exportCSV, marginBottom: '12px',
      textAlign: 'end'}} onClick={exportToCSV}>
      {t('exportCSV')}
    </Typography>
    <Select
      sx={{ minWidth: 30, backgroundColor: 'transparent', marginRight: '30px',  float: 'right'}}
      variant="standard"
      displayEmpty
      IconComponent={ExpandMoreIcon}
      disableUnderline
      value={fields.status_filter}
      placeholder={t<string>('statusFilter')}
      onChange={(e) => {
        setValue('status_filter', e.target.value === "" ? [] : [e.target.value])
      }}
    >
      <MenuItem value={""}>All</MenuItem>
      {
        STATUS_LIST_CAMPAIGNS_FILTER.map(({label, value}: any) => (
          <MenuItem key={value} value={value}>{label}</MenuItem>
        ))
      }
    </Select>
    <Typography className={'report-list-filter-dimensions-label'}>
      {t('statusFilter')}
    </Typography>

    <Box sx={style.dateRange}>
      <IntFieldController control={control} name='date' Component={IntDateRangePicker} />
    </Box>
    <Typography className={'campaign-list-filter-date-label'}>{t('showMetricsFor')}</Typography>
  </Box>
}

CampaignListFilter.displayName = 'CampaignListFilter';

export default CampaignListFilter;