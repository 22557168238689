export type Account = {
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  type: AccountType;
  source: AccountSource;
  whitelabelId: WhiteLabel;
  niche: string;
  position: string;
  other: string;
  rewarded_ads_cpm_rate: number;
}

enum AccountType {
  FREELANCER = 'freelancer',
  AGENCY = 'agency',
  BRAND = 'brand',
}

export enum AccountSource {
  LINKEDIN_AD = 'linkedin_ad',
  LINKEDIN_PERSONAL_NETWORK= 'linkedin_personal_network',
  COLD_EMAIL= 'cold_email',
  GOOGLE_ADS= 'google_ads',
  INTENT_ADS= 'intent_ads',
  REFERRED= 'referred',
  OTHER= 'other',
}

enum WhiteLabel {
  ARMENIA= 'am',
  NIGERIA= 'ng',
  TUNISIA= 'tn',
}
