import { IStore } from "../../../../store";

export const CURRENCIES: Record<string, string> = {
  'NGN': '₦',
  'USD': '$',
  undefined: '$',
  null: '$',
};

function getCurrentAccountCurrency(store: IStore) {
  const storedCurrency: string | null = sessionStorage.getItem('currentAccountCurrency');
  const currency: string = !storedCurrency ? store.authentication?.user?.currency : storedCurrency;
  return CURRENCIES[currency];
}

function getWhitelabelCurrency(store: IStore) {
  return CURRENCIES[store.authentication?.user?.currency] || '₦';
}

function getWhiteLabelCompanyLogo(store: IStore) {
  return store.admin.whitelabel?.whitelabel_settings?.company_logo;
}

function getTermsOfServiceSelector(store: IStore) {
  return store.admin.whitelabel?.whitelabel_settings?.term_of_service_url;
}

function getCurrentSpaceIdSelector(store: IStore) {
  return store.authentication?.space?.id;
}
function getWhiteLabelTimezoneOffset(store: IStore) {
  return store.admin.whitelabel?.timezone_offset_seconds && store.admin.whitelabel?.timezone_offset_seconds / 60;
}

export {
  getWhitelabelCurrency,
  getWhiteLabelCompanyLogo,
  getTermsOfServiceSelector,
  getCurrentSpaceIdSelector,
  getCurrentAccountCurrency,
  getWhiteLabelTimezoneOffset,
}