import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, IntlShape, defineMessages } from "react-intl";
import { AdminHeader, ModerationUserForm } from "../../components";
import { match } from "react-router-dom";
import { IRootState, Dispatch, select } from "../../store";
import { IUser, IModalProps } from "../../types";
import { Button, Flex } from "@intent-ai/mandal-npm-lib-v2";
import { History } from "history";
import { bindActionCreators } from "redux";
import {
  APIResponseFail,
  APIResponseSuccess,
} from "@intent-ai/mandal-npm-lib-v2/dist/actions";
import { adminMessages } from "../../messages";
import { WithModal } from "../../providers";
import { Modal } from "../../containers";
import { getFormValues } from "redux-form";
import { MODERATION_USER_FORM } from "../../_constants";
import { routerPaths } from "../../config/routes/paths";

interface IMatchParams {
  id: string;
}

interface IProps extends IModalProps {
  isModerationRegistrationApproved: boolean;
  intl: IntlShape;
  match: match<IMatchParams>;
  moderationUser: IUser;
  getModerationRegistration: Function;
  setModerationLegalUsersApprove: Function;
  setModerationLegalUsersReject: Function;
  history: History;
  APIResponseFail: Function;
  APIResponseSuccess: Function;
  updateUserLegalEntity: Function;
  currentData: IUser;
}

const messages = defineMessages({
  title: {
    id: "Admin.approveAccount",
    defaultMessage: "Approve Account",
  },
});

class WrappedLegalEntityUser extends Component<IProps> {
  componentDidMount() {
    const {
      props: {
        match: {
          params: { id },
        },
        getModerationRegistration,
      },
    } = this;
    getModerationRegistration({ id });
  }

  handleUpdateUserData = () => {
    const {
      props: {
        updateUserLegalEntity,
        moderationUser,
        history: { push },
        APIResponseSuccess,
        intl: { formatMessage },
        currentData,
      },
    } = this;

    updateUserLegalEntity({
      data: {
        user_id: moderationUser.id,
        moderation_start_at: new Date(),
        ...currentData,
      },
      success: () => {
        push(routerPaths.Moderation);
        APIResponseSuccess(
          formatMessage(adminMessages.verified),
          formatMessage(adminMessages.accountLegalEntityVerified)
        );
      },
    });
  };

  render() {
    const {
      props: {
        intl: { formatMessage },
        moderationUser,
        modalData,
        modalType,
        toggle,
        isOpen,
        openModal,
        closeModal,
        modalTypes,
      },
      handleUpdateUserData,
    } = this;
    return (
      <>
        <AdminHeader title={formatMessage(messages.title)}>
          <div>
            <ModerationUserForm
              initialValues={moderationUser}
              user={moderationUser}
              isLegalEntityForm
            />
            <Flex justifyContent="center">
              <Button
                i18nId="ButtonLabel.saveChanges"
                style={{ width: "30%" }}
                type="border"
                onClick={handleUpdateUserData}
              />
            </Flex>
          </div>
        </AdminHeader>
        {isOpen && (
          <Modal
            modalData={modalData}
            modalType={modalType}
            toggle={toggle}
            isOpen={isOpen}
            openModal={openModal}
            closeModal={closeModal}
            modalTypes={modalTypes}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  moderationUser: select.admin.moderationUserSelector(state),
  isModerationRegistrationApproved: select.admin.isModerationRegistrationApprovedSelector(
    state
  ),
  currentData: getFormValues(MODERATION_USER_FORM)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  APIResponseFail: bindActionCreators(APIResponseFail, dispatch),
  APIResponseSuccess: bindActionCreators(APIResponseSuccess, dispatch),
  getModerationRegistration: dispatch.admin.getModerationRegistration,
  setModerationLegalUsersApprove: dispatch.admin.setModerationLegalUsersApprove,
  setModerationLegalUsersReject: dispatch.admin.setModerationLegalUsersReject,
  updateUserLegalEntity: dispatch.authentication.updateUserLegalEntity,
});

const LegalEntityUser = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WithModal(WrappedLegalEntityUser)));

LegalEntityUser.displayName = "LegalEntityUser";

export default LegalEntityUser;
