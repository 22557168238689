import { Box, Divider, Typography } from "@mui/material";
import { style } from './style';
import { useTranslation } from "react-i18next";
import SignupForm from "../SignupForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getSignupFormValidation, SIGNUP_FORM_DEFAULT_VALUES } from "../../utils";
import { SignupFormDTO } from "../../types";
import { AuthService } from "../../services";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { NEW_ROUTES } from "../../../global";

function Signup() {
  const { t } = useTranslation('translation', { keyPrefix: 'auth.signup'});

  const [registered, setRegistered] = useState(false);

  const {
    control,
    formState: {
      isValid,
      isSubmitting
    },
    handleSubmit
  } = useForm({
    resolver: yupResolver(getSignupFormValidation()),
    defaultValues: SIGNUP_FORM_DEFAULT_VALUES,
    mode: 'onChange',
  });

  const history = useHistory();

  async function onSubmit(data: SignupFormDTO) {
    const response = await AuthService.registerAccount(data);
    if (response.data) {
      setRegistered(true);
    }
  }

  function onLoginClick() {
    history.replace(NEW_ROUTES.auth.login.fullPath);
  }

  return (
    <Box sx={style.view}>
      <Box sx={style.form}>
        {registered ? (
          <Typography sx={style.success}>{t('successfullyRegistered')}</Typography>
        ) : (
          <>
            <Typography sx={style.formTitle}>{t("createNew")}</Typography>
            <Typography sx={style.adCampaignsText}>{t("adCampaigns")}</Typography>
            <Divider />
            <SignupForm control={control} disabled={!isValid || isSubmitting} onSubmit={handleSubmit(onSubmit)} />
          </>
        )}
      </Box>
      <Typography sx={style.login} onClick={onLoginClick}>{t('login')}</Typography>
    </Box>
  )
}

Signup.displayName = 'Signup';

export default Signup;
