import { mixed, object, string } from "yup";
import { t } from "i18next";

function getCreateAudienceValidationSchema() {
  return object().shape({
    name: string().required(t<string>('errorMessages.required')),
    file: mixed<File>()
      .test(
        "required",
        t<string>('errorMessages.required'),
        (file?: File) => !!file,
      ),
  });
}

export {
  getCreateAudienceValidationSchema,
}