import { userRoles } from "../../../../_constants";
import { useTranslation } from "react-i18next";
import { AuthTitleWrapper } from "../../../../components";
import { useSelector } from "react-redux";
import { PersonalInfoForm, ProfileLegalForm } from "../index";
import ProfileSecurityForm from "../ProfileSecurityForm";
import { Box } from '@mui/material';
import { style } from './style';
import ProfileOtherForm from "../ProfileOtherForm";
import { IRootState } from "../../../../store";
import { UserRole, UserType } from "../../types";
import ProfileNotifications from "../ProfileNotifications";
import { getUserSpaceSelector } from "../../../store";

function Profile() {
  const { t } = useTranslation();

  const user = useSelector((store: IRootState) => store.authentication.user);

  const userAccount = useSelector(getUserSpaceSelector);

  const isLegalPerson = user.role_key != UserRole.WHITELABEL_ACCOUNTANT // eslint-disable-line
  && user.user_type_key == UserType.LEGAL; // eslint-disable-line

  return (
    <AuthTitleWrapper
      title={t(`navigation.profile.${user.role_key == userRoles.WL_OWNER ? 'whiteLabel' : 'user'}`)} // eslint-disable-line
      iconName="settings"
      isProfilePage
    >
      <Box sx={style.formWrapper}>
        <PersonalInfoForm user={user} />
      </Box>
      {isLegalPerson && (
        <Box sx={style.formWrapper}>
          <ProfileLegalForm user={user}/>
        </Box>
      )}
      <Box sx={style.formWrapper}>
        <ProfileSecurityForm />
      </Box>
      {isLegalPerson && (
        <Box sx={style.formWrapper}>
          <ProfileNotifications userAccount={userAccount}/>
        </Box>
      )}
      <ProfileOtherForm user={user}/>
    </AuthTitleWrapper>
  );
}

Profile.displayName = 'Profile';

export default Profile;
