import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactNode, useEffect, useState } from "react";
import { CampaignService } from "../../services";
import { CampaignFormDTO } from "../../types";
import { Box, Divider, Typography } from "@mui/material";
import { style } from "./style";
import { IntFieldController } from "../../../global";
import IntAutocomplete from "../../../global/components/IntForm/IntAutocomplete";

type Props = {
  footer: ReactNode;
}

function GeographyForm({ footer }: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'campaign.geography',
  });

  const [cities, setCities] = useState<ISelectOption[]>([]);

  const {
    control,
  } = useFormContext<CampaignFormDTO>();

  function getCitiesListByCountry() {
    CampaignService
      .getCitiesListByCountry()
      .then(response => {
        if(response.type.key === 'ok'){
          const {data} = response;
          setCities(data.map(({ id, name }: any) => ({
            value: id,
            label: name,
          })));
        }
      });
  }
  
  useEffect(() => {
    getCitiesListByCountry();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return  (
    <Box sx={style.geography}>
      <Typography sx={style.title}>
        {t('title')}
      </Typography>
      <Box>
        <Divider />
        <Box sx={style.geographyInputs}>

          {!!cities.length ? (
            <Box sx={[style.field, style.languageField]}>
              <Typography sx={style.fieldLabel}>{t('cities')}</Typography>
              <IntFieldController
                control={control}
                name='targeting.cities'
                Component={IntAutocomplete}
                sx={[style.field, style.selectField]}
                options={cities || []}
                placeholder={t<string>('selectCities')}
                multiple
                extractValue
              />
            </Box>
            ) : null
          }
        </Box>
      </Box>
      {footer}
    </Box>
  )
}

GeographyForm.displayName = 'GeographyForm';

export default GeographyForm;