import React, { Component } from "react";
import { injectIntl, IntlShape, FormattedMessage } from "react-intl";
import { AuthTitleWrapper } from "../../components";
import { connect } from "react-redux";
import { Dispatch, select, IRootState } from "../../store";
import {
  MaiRow,
  MaiFilters,
  ContentWrapper,
  Flex,
  NotificationApi,
  MaiFilterField,
  MaiFiltersDate,
} from "@intent-ai/mandal-npm-lib-v2";
import {
  APIResponseSuccess,
  APIResponseFail,
  APIResponseNullify,
} from "@intent-ai/mandal-npm-lib-v2/dist/actions";
import {
  IRoutePaths,
  IModalProps,
  PaymentHistory,
  IPaymetnFinilaze,
  IUser,
} from "../../types";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RefillBalanceHistory, AddPaymentMethods, Modal } from "../../containers";
import { WithModal } from "../../providers";
import { billingMessages, profileMessages } from "../../messages";
import "./style.scss";
import { bindActionCreators } from "redux";
import { TOS_STATUSES, USER_TYPES } from "../../_constants";
import clsx from "clsx";
import { CURRENCIES } from "../../modules/store";
import { Button } from "@mui/material";
import { numberToStringWithCommas } from "../../modules";


interface IProps extends IModalProps {
  isPaymentHistoryLoaded: boolean;
  spaceId: string;
  intl: IntlShape;
  getRefillBalanceHistory: Function;
  savePaymentCard: Function;
  routePaths: IRoutePaths;
  APIResponseFail: Function;
  APIResponseNullify: Function;
  nullifySpacePayments: Function;
  APIResponseSuccess: Function;
  defaultPaymentId: string;
  makeBindingPayment: Function;
  getSpacePaymentsMethods: Function;
  setCompanySpaceRefill: Function;
  getCurrentSpaceBalance: Function;
  currentBalance: number;
  paymentHistoryData: PaymentHistory;
  children?: any;
  currentUser: IUser;
  isOwnerAccount: boolean;
  termsOfServiceLink: string;
  getInvoiceData: Function;
}

interface IState {
  page: number;
}

class WrappedBilling extends Component<IProps & RouteComponentProps, IState> {
  state = {
    page: 1,
    refill: true,
  };

  componentDidMount() {
    const {
      props: {
        location,
        setCompanySpaceRefill,
      },
      paymentRefillFail: postFail,
      paymentRefillSuccess: postSuccess,
    } = this;
    const query = new URLSearchParams(location.search);
    const paymentID = query.get("paymentID");
    if (paymentID) {
      const data = {
        payment_id: paymentID,
      };
      setCompanySpaceRefill({ data, fail: postFail, success: postSuccess });
    }
  }
  componentWillUnmount() {
    const {
      props: { nullifySpacePayments },
    } = this;
    nullifySpacePayments();
  }

  paymentRefillFail = (needReplace = true) => {
    const {
      props: {
        history: { replace },
        intl: { formatMessage },
        APIResponseFail,
      },
    } = this;

    APIResponseFail(
      formatMessage(billingMessages.paymentFailure),
      formatMessage(billingMessages.paymentBackendFail)
    );
    if (needReplace) {
      replace("/payments/history");
    }
  };

  paymentRefillSuccess = (data: IPaymetnFinilaze) => {
    const {
      props: {
        history: { replace },
        intl: { formatMessage },
        APIResponseSuccess,
        savePaymentCard,
        spaceId,
        currentBalance,
        getCurrentSpaceBalance,
        getSpacePaymentsMethods,
        getRefillBalanceHistory,
        APIResponseNullify,
      },
      paymentRefillFail,
    } = this;

    if (data.payment_status_key === 2) {
      APIResponseSuccess(
        formatMessage(billingMessages.successful),
        formatMessage(billingMessages.successfulDescription, {
          amount: data.amount ? numberToStringWithCommas(data.amount) : 0,
          totalAmount: data.amount
            ? numberToStringWithCommas(currentBalance + data.amount)
            : 0,
        }),
        null,
        <Button
          onClick={() => {
            APIResponseNullify();
            savePaymentCard({
              data: {
                remember_card: true,
                space_id: spaceId,
                payment_method_id: data.payment_method_id,
              },
              success: (isOkay: boolean) => {
                if (isOkay) {
                  getSpacePaymentsMethods({
                    data: {
                      space_id: spaceId,
                    },
                  });

                  NotificationApi.success(
                    formatMessage(billingMessages.successfulCardAdded)
                  );
                } else {
                  paymentRefillFail(false);
                }
              },
            });
          }}
          variant="outlined"
          sx={{ width: "50%", marginBottom: "10px", borderRadius: 50 }}
        >{formatMessage(billingMessages.saveLinkedCards)}</Button>
      );
    } else if (data.payment_status_key === 20) {
      APIResponseSuccess(
        formatMessage(billingMessages.successfulCardAdded),
        formatMessage(billingMessages.successfulCardAddedDescription)
      );
    }

    getSpacePaymentsMethods({
      data: {
        space_id: spaceId,
      },
    });
    getCurrentSpaceBalance({ id: spaceId });
    getRefillBalanceHistory({ space_id: spaceId });
    replace("/payments/history");
  };

  openRefillModal = () => {
    const {
      props: {
        intl: { formatMessage },
        openModal,
        modalTypes,
        currentBalance,
        currentUser,
        APIResponseSuccess,
      },
      refillBalance,
    } = this;

    const { tos_status_type_key } = currentUser;

    if (
      tos_status_type_key !== null &&
      tos_status_type_key === TOS_STATUSES.PENDING
    ) {
      APIResponseSuccess(
        formatMessage(billingMessages.success),
        formatMessage(profileMessages.legalEntityInfoPending)
      );
      return;
    }

    openModal({
      modalType: modalTypes.refillBalanceModal,
      modalData: {
        refillBalance,
        currentBalance,
      },
    });
  };

  refillBalance = () => {
    const {
      props: { spaceId, makeBindingPayment, defaultPaymentId },
    } = this;
    const data = {
      payment_method_id: defaultPaymentId,
      amount: 10,
      space_id: spaceId,
    };
    makeBindingPayment(data);
  };

  render() {
    const {
      props: {
        intl: { formatMessage },
        modalType,
        toggle,
        isOpen,
        closeModal,
        modalTypes,
        modalData,
        getRefillBalanceHistory,
        isPaymentHistoryLoaded,
        paymentHistoryData,
        openModal,
        currentBalance,
        // isOwnerAccount,
        currentUser,
        termsOfServiceLink,
        getInvoiceData,
      },
      //openRefillModal,
    } = this;

    const noFunds = currentBalance < 1000;
    const lowBalance = currentBalance < 500;
    const getCurrentUserCurrency = () => {
      const storedCurrency: string | null = sessionStorage.getItem('currentAccountCurrency');
      const currency: string = !storedCurrency ? currentUser.currency : storedCurrency;
      return CURRENCIES[currency];
    };

    return (
      <AuthTitleWrapper
        iconName="billing"
        title={formatMessage(billingMessages.title)}
      >
        <>
          <div className="billing-screen">
            <div className="payment-methods-and-funds">
              <ContentWrapper>
                <Flex
                  justifyContent="space-between"
                  customClassName="aviable-funds-info"
                  alignItems="center"
                >
                  <div>
                    <p className="available-funds">
                      <FormattedMessage
                        id="Payments.availableFunds"
                        defaultMessage="Available funds"
                      />
                    </p>
                    <p
                      className={clsx("funds", {
                        "no-funds": noFunds,
                        "low-balance": lowBalance,
                      })}
                    >
                      {getCurrentUserCurrency()} {numberToStringWithCommas(currentBalance) || 0}
                    </p>
                  </div>
                </Flex>
                <div className="payment-methods">
                  <AddPaymentMethods />
                </div>
              {currentUser.user_type_key === USER_TYPES.company ? (
                <p className="int-info-text">
                  <FormattedMessage
                    id="Payments.corporateCard"
                    defaultMessage="We kindly ask you to attach a corporate card in order to receive official transaction statements or proceed with other actions in compliance with our"
                  />{" "}
                  <a
                    href={termsOfServiceLink}
                    target="_blank"
                    className="link-hover"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage
                      id="Auth.termOfService"
                      defaultMessage="Terms of Service"
                    />
                    {"."}
                  </a>
                </p>
                ): null
              }
            </ContentWrapper>
          </div>
          <MaiRow mp0 className="refill-history">
            <MaiRow.Col size={12}>
              <ContentWrapper>
                <div className="balance-history-wrapper">
                  <MaiFilters
                    showPagination
                    pageCount={paymentHistoryData?.total_size || 0 / 10}
                    onReady={getRefillBalanceHistory}
                    onChange={getRefillBalanceHistory}
                  >
                    <MaiFilters.Container
                      title={formatMessage(
                        billingMessages.balanceRefillHistory,
                      )}
                    >
                    <MaiFilterField
                          name="date"
                          component={MaiFiltersDate}
                          dateTitle={formatMessage(billingMessages.dateTitle)}
                          fullWidth
                        />
                      </MaiFilters.Container>
                      <RefillBalanceHistory
                        getInvoiceData={getInvoiceData}
                        loading={isPaymentHistoryLoaded}
                        paymentData={paymentHistoryData?.data}
                      />
                    </MaiFilters>
                  </div>
                </ContentWrapper>
              </MaiRow.Col>
            </MaiRow>
          </div>
          {isOpen && (
            <Modal
              modalData={modalData}
              modalType={modalType}
              toggle={toggle}
              isOpen={isOpen}
              openModal={openModal}
              closeModal={closeModal}
              modalTypes={modalTypes}
            />
          )}
        </>
      </AuthTitleWrapper>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  spaceId: select.authentication.selectCurrentUserSpaceId(state),
  currentUser: select.authentication.selectCurrentUser(state),
  paymentMethods: select.payment.selectPaymentMethods(state),
  defaultPaymentId: select.payment.selectDefaultPaymentId(state),
  currentBalance: select.space.currentBalanceSelector(state),
  paymentHistoryData: select.payment.selectPaymentHistoryData(state),
  isPaymentHistoryLoaded: select.payment.selectPaymentHistoryLoaded(state),
  isOwnerAccount: select.authentication.isOwnerAccountSelector(state),
  termsOfServiceLink: select.admin.termsOfServiceLinkSelector(state),
  invoice: select.campaign.invoiceDataSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCurrentSpaceBalance: dispatch.space.getCurrentSpaceBalance,
  getRefillBalanceHistory: dispatch.payment.getRefillBalanceHistory,
  makeBindingPayment: dispatch.payment.makeBindingPayment,
  getSpacePaymentsMethods: dispatch.payment.getSpacePaymentsMethods,
  setCompanySpaceRefill: dispatch.payment.setCompanySpaceRefill,
  savePaymentCard: dispatch.payment.savePaymentCard,
  nullifySpacePayments: dispatch.payment.nullifySpacePayments,
  APIResponseFail: bindActionCreators(APIResponseFail, dispatch),
  APIResponseSuccess: bindActionCreators(APIResponseSuccess, dispatch),
  APIResponseNullify: bindActionCreators(APIResponseNullify, dispatch),
  getInvoiceData: dispatch.campaign.getInvoiceData,
});

const Billing = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithModal(injectIntl(WrappedBilling)))
);

Billing.displayName = "Billing";

export default Billing;
