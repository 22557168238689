import React, { Component } from "react";
import { ContentWrapper, MaiRow, Button } from "@intent-ai/mandal-npm-lib-v2";
import { injectIntl } from "react-intl";
import { AppsAndWebsitesSearch } from "../../../../components";
import { AppsAndWebsitesPreview } from "../../../../containers";
// import { ICampaign, IDictionaryField, ICampaignPolygons } from "../../../../types";
import "./style.scss";

// interface IProps {
//   intl: IntlShape;
//   ageGroupToData: any;
//   ageGroupFromData: any;
//   change: Function;
//   formChange: Function;
//   toggleChange: Function;
//   handleLocationChange: Function;
//   handleCampaignGaugeFieldsChange: Function;
//   currentData: ICampaign;
//   dictGender: IDictionaryField[];
//   dictInterest: IDictionaryField[];
//   dictBehavior: IDictionaryField[];
//   dictLanguages: IDictionaryField[];
//   dictLocations: IDictionaryField[];
//   dictLocationOptions: IDictionaryField[];
//   polygonsData: ICampaignPolygons[];
//   initialValues: any;
//   initialize: Function;
//   apps: any;
// }
const Wrapper = ({ children, isCampaign }: any) => {
  return !isCampaign ? (
    <ContentWrapper title={"Apps and Websites"} noBorder>
      {children}
    </ContentWrapper>
  ) : (
    children
  );
};
class AudienceAppsAndWebsites extends Component<any> {
  state = {
    isExclude: false,
    isNarrow: false,
  };
  componentDidMount() {
    const {
      props: { handleCampaignGaugeFieldsChange },
    } = this;
    handleCampaignGaugeFieldsChange();
  }

  render() {
    const {
      state: { isExclude, isNarrow },
      props: {
        // intl: { formatMessage },
        //currentData,
        formChange,
        dictInterest,
        apps,
        isCampaign,
        handleCampaignGaugeFieldsChange,
      },
    } = this;
    const isExcludeActive = apps.exclude?.length || isExclude;
    // const includeLength = apps.include.length;
    const include = apps.include?.length || isNarrow;
    // const ContentWrapper =
    return (
      <div className="target-campaign-form audience-basic-info apps-and-websites">
        <Wrapper
          isCampaign={isCampaign}
          // title={"Apps and Websites"}
          // toogleOffTile={formatMessage(campaignMessages.targetOff)}
          // toggleOnTitle={formatMessage(campaignMessages.targetOn)}
          // noBorder
        >
          <h1>INCLUDE USERS OF ANY OF THE FOLLOWING APPS AND WEBSITES</h1>
          <MaiRow mp0>
            <AppsAndWebsitesPreview
              handleCampaignGaugeFieldsChange={handleCampaignGaugeFieldsChange}
              currentData={apps.includeAny ?? []}
              formChange={formChange}
              type="includeAny"
            />
          </MaiRow>
          <MaiRow mp0>
            <AppsAndWebsitesSearch
              handleCampaignGaugeFieldsChange={handleCampaignGaugeFieldsChange}
              dictInterest={dictInterest}
              currentData={apps.includeAny ?? []}
              placeholder={"Search sites or mobile apps"}
              name="includeAny"
            />
          </MaiRow>

          {isExcludeActive && (
            <div>
              <h1>EXCLUDE PEOPLE THAT USE ANY OF THE FOLLOWING</h1>
              <MaiRow mp0>
                <AppsAndWebsitesPreview
                  handleCampaignGaugeFieldsChange={
                    handleCampaignGaugeFieldsChange
                  }
                  currentData={apps.exclude ?? []}
                  formChange={formChange}
                  type="exclude"
                />
              </MaiRow>
              <MaiRow mp0>
                <AppsAndWebsitesSearch
                  handleCampaignGaugeFieldsChange={
                    handleCampaignGaugeFieldsChange
                  }
                  dictInterest={dictInterest}
                  currentData={apps.exclude ?? []}
                  placeholder={"Search sites or mobile apps"}
                  name="exclude"
                />
              </MaiRow>
            </div>
          )}
          {include && (
            <div>
              <h1>Leave only people that have all of the following</h1>
              <MaiRow mp0>
                <AppsAndWebsitesPreview
                  handleCampaignGaugeFieldsChange={
                    handleCampaignGaugeFieldsChange
                  }
                  currentData={apps.include ?? []}
                  formChange={formChange}
                  type="include"
                />
              </MaiRow>
              <MaiRow mp0>
                <AppsAndWebsitesSearch
                  handleCampaignGaugeFieldsChange={
                    handleCampaignGaugeFieldsChange
                  }
                  dictInterest={dictInterest}
                  currentData={apps.include ?? []}
                  placeholder={"Search sites or mobile apps"}
                  name="include"
                />
              </MaiRow>
            </div>
          )}
          <MaiRow className="audience-basic-info-buttons">
            {!isExcludeActive && (
              <Button
                staticText
                onClick={() => {
                  this.setState({ isExclude: !isExclude });
                }}
              >
                Exclude
              </Button>
            )}
            {!include && (
              <Button
                staticText
                onClick={() => {
                  this.setState({ isNarrow: !isNarrow });
                }}
              >
                Narrow Audience
              </Button>
            )}
          </MaiRow>
        </Wrapper>
      </div>
    );
  }
}

export default injectIntl(AudienceAppsAndWebsites);
