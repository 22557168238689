import { FetchUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { GET, POST, PUT } from "../_constants";
import { ICampaignFilter } from "../types";

const { createRequest } = FetchUtils;
const EMPTY_OBJECT = {};
const HEADER_PLAIN_TEXT = {
  "Content-Type": "text/plain",
};
// export const getAudienceListRequest = (
//     id: any
//   ) => createRequest(`/audience/list/${id}`);

export const getAudienceListRequest = (
  data: ICampaignFilter,
  params = EMPTY_OBJECT
) => createRequest("/audience/list", POST, params, data);

export const createAudienceRequest = (data: any) =>
  createRequest("/audience", POST, EMPTY_OBJECT, data); //campaign

export const updateAudienceRequest = (data: any) =>
  createRequest("/audience", PUT, EMPTY_OBJECT, data); //campaign

export const publishAudienceRequest = (id: string) =>
  createRequest("/audience/publish", POST, EMPTY_OBJECT, id, HEADER_PLAIN_TEXT);

export const getAudienceRequest = (id: string) =>
  createRequest(`/audience/${id}`);

export const audienceResourceSearchRequest = (query?: any, id?: any) => {
  return createRequest(`/audience/audience-resource`, GET, {
    ...(query && { name: query }),
    ...(id && { "category-id": id }),
  });
};

// /audience/audience-resource
