import { defineMessages } from "react-intl";

export default defineMessages({
  sectionTitleCurrentBalanceAcrossSpaces: {
    id: "Payments.sectionTitleCurrentBalanceAcrossYourSpaces",
    defineMessages: "Current Balance Across Your Spaces",
  },
  sectionTitleBalanceRefillHistory: {
    id: "Payments.sectionTitleBalanceRefillHistory",
    defineMessages: "Balance Refill History",
  },
  sectionTitleIncomeOverview: {
    id: "Payments.sectionTitleIncomeOverview",
    defineMessages: "Income Overview",
  },
  tableHeadSpace: {
    id: "Payments.tableHeadSpace",
    defineMessages: "Space",
  },
  tableHeadNumberOfCampaigns: {
    id: "Payments.tableHeadNumberOfCampaigns",
    defineMessages: "Number of Campaigns",
  },
  tableHeadRemaining: {
    id: "Payments.tableHeadRemaining",
    defineMessages: "Remaining",
  },
  tableHeadPaymentDate: {
    id: "Payments.tableHeadPaymentDate",
    defineMessages: "Payment Date",
  },
  tableHeadAmount: {
    id: "Payments.tableHeadAmount",
    defineMessages: "Amount",
  },
  tableHeadPaymentMethod: {
    id: "Payments.tableHeadPaymentMethod",
    defineMessages: "Payment Method",
  },
  tableHeadPaidBy: {
    id: "Payments.tableHeadPaidBy",
    defineMessages: "Paid By",
  },
  tableHeadFundsAvailable: {
    id: "Payments.tableHeadFundsAvailable",
    defineMessages: "Funds Available",
  },
  tableHeadAllTimeIncome: {
    id: "Payments.tableHeadAllTimeIncome",
    defineMessages: "All Time Income",
  },
  tableHeadIncomeThisMonth: {
    id: "Payments.tableHeadIncomeThisMonth",
    defineMessages: "Income This Month",
  },
  noWithdraws: {
    id: "Payments.noWithdraws",
    defaultMessage: "You haven’t made any withdrawals",
  },
  noHistory: {
    id: "Payments.noHistory",
    defaultMessage: "No History To Display",
  },
});
