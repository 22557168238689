import React from "react";
import './style.scss';
import { Loading } from "../../modules";
import { MaiMetric, Tooltip } from "@intent-ai/mandal-npm-lib-v2";

interface IProps {
  data: any[];
  loading: boolean;
  active?: boolean;
  disabled?: boolean;
}

const DashboardCard = (props: IProps) => {

  const {active, disabled, loading, data} = props;

  const CardItem = (item: any) => {
    const {
      label,
      value = 0,
      tooltip,
      showMoneySymbol,
      showPercentageSymbol,
      prevValue,
      versusLabel,
      decimalLength = 0
    } = item;

    return (
      <div className={"mr-1"}>
        <p className={"dashboard-stats-label"}> {label}
        <Tooltip tooltip={tooltip} />
        </p>
        {!isNaN(Number(item.value)) ? (
          <div className={"dashboard-stats-container"}>
           <p className={"dashboard-stats-value"}>
             {`${ showMoneySymbol ?  '$' : ''} ${ value ? value.toLocaleString() : 0 } ${ showPercentageSymbol ? '%' : ''}`}
           </p>
         </div>) : !isNaN(Number(prevValue)) ? (
           <MaiMetric
            showValue={false}
            value={value}
            prevValue={prevValue}
            showMoneySymbol={showMoneySymbol}
            versusLabel={versusLabel}
            decimalLength={decimalLength}
          />
        ) : null}
      </div>
    )
  }

  return (
    <div className={`dashboard-stats ${active ? 'active' : ""} ${disabled ? 'disabled' : ""}`}>
      {loading ? <Loading /> :
        data.map((item: any, index: number) => (
          <div key={index} className={"dashboard-stats-wrapper"}>
            <CardItem {...item} />
          </div>))
      }
    </div>
  )
}

export default DashboardCard;