import { AuthTitleWrapper } from "../../../../components";
import { useTranslation } from "react-i18next";
import { PersonalInfoForm, ProfileLegalForm, VoiceCPMMultiplierForm } from "../index";
import { useSelector } from "react-redux";
import { getUserIdSelector } from "../../../store";
import { useEffect, useState } from "react";
import { UserService } from "../../../user/services";
import { StatusCode } from "../../../global/types";
import { Box } from "@mui/material";
import { style } from './style';
import { IUser } from "../../../../types";

function Settings() {
  const { t } = useTranslation();
  const [user, setUser] = useState<IUser | null>(null);

  const userId = useSelector(getUserIdSelector);

  function getUser(id: string) {
    UserService.getUserById(id)
      .then(response => {
        if (response.type.code == StatusCode.OK) { // eslint-disable-line
          setUser(response.data);
        }
      })
  }

  useEffect(() => {
    if (userId) {
      getUser(userId);
    }
  }, [userId]);

  return (
    <AuthTitleWrapper
      iconName='profile'
      title={t(`navigation.profile.user`)}
    >
      <Box sx={style.settings}>
        {user && (
          <>
            <VoiceCPMMultiplierForm user={user} />
            <PersonalInfoForm user={user} />
            <ProfileLegalForm user={user} />
          </>
        )}
      </Box>
    </AuthTitleWrapper>
  );
}

Settings.displayName = 'Settings';

export default Settings;