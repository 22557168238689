import React from "react";
import { Flex, ValidatableInputField } from "@intent-ai/mandal-npm-lib-v2";
import { change } from "redux-form";
import { IRootState, Dispatch, select } from "../../store";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import { FetchUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { AppsAndWebsitesPreviewList } from "../../components";
import { search_icon } from "../../assets";

import "./style.scss";
import { bindActionCreators } from "redux";

const { debounce } = FetchUtils;

interface IProps {
  label?: string;
  placeholder?: string;
  intl: IntlShape;
  audienceResourceSearch: Function;
  audienceResource: any;
  currentData: any;
  change: Function;
  name: string;
  dictInterest: any;
  nullifyAudienceResource: Function;
  handleCampaignGaugeFieldsChange: Function;
}
interface IState {
  query?: string;
  clickedOutside?: boolean;
  BrowsClickedOutside?: boolean;
}
class AppsAndWebsitesSearch extends React.Component<
  IProps & RouteComponentProps & IState
> {
  state = {
    query: "",
    clickedOutside: false,
    BrowsClickedOutside: false,
  };

  debounceEvent = debounce(() => {
    const {
      props: { audienceResourceSearch },
      state: { query },
    } = this;
    return audienceResourceSearch({ query });
  }, 250);

  onQueryChange = (e: any) => {
    const { debounceEvent } = this;
    const value = e.target.value;

    this.setState(
      () => ({ query: value, clickedOutside: true }),
      () => {
        debounceEvent();
      }
    );
  };
  myRef: any = React.createRef();
  browsRef: any = React.createRef();

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("mousedown", this.browsCandleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("mousedown", this.browsCandleClickOutside);
  }

  handleClickOutside = (e: any) => {
    if (!this.myRef.current) return;
    if (!this.myRef.current.contains(e.target)) {
      this.setState({ clickedOutside: false });
    }
  };
  browsCandleClickOutside = (e: any) => {
    if (!this.browsRef.current) return;
    if (!this.browsRef.current.contains(e.target)) {
      this.setState({ BrowsClickedOutside: false });
    }
  };

  handleOnBrowsClick = (item: any) => {
    const {
      props: { name, currentData, change, handleCampaignGaugeFieldsChange },
    } = this;
    change("CAMPAIGN_FORM", `targeting.apps.${name}`, [...currentData, item]);
    handleCampaignGaugeFieldsChange();
    this.setState({ BrowsClickedOutside: false });
  };

  render() {
    //@typescript-eslint/no-unused-vars
    const {
      props: {
        name,
        //label,
        audienceResource,
        currentData,
        change,
        //dictInterest,
        nullifyAudienceResource,
        //audienceResourceSearch,
        handleCampaignGaugeFieldsChange,
      },
      state: { query, clickedOutside},
      onQueryChange,
    } = this;
    const input = {
      value: query,
      onChange: onQueryChange,
    };
    return (
      <div className="apps-and-websites-search">
        <div className="search-field"></div>
        <Flex>
          <img src={search_icon} alt="asd" className="search-icon" />
          <div
            style={{ width: "100%", position: "relative" }}
            onClick={() => this.setState({ BrowsClickedOutside: true })}
            >
            <ValidatableInputField
              placeholder="Search sites or mobile apps"
              input={input}
              onFocus={() => {
                nullifyAudienceResource();
              }}
            />
            {clickedOutside && (
              <ul
                className="apps-and-websites-search-list"
                ref={this.myRef}
                style={{
                  bottom:
                    name === "exclude" || name === "includeAny"
                      ? "100%"
                      : "auto",
                }}
              >
                {audienceResource?.map((e: any) => {
                  return (
                    <li
                      onClick={() => {
                        change("CAMPAIGN_FORM", `targeting.apps.${name}`, [
                          ...currentData,
                          e,
                        ]);
                        handleCampaignGaugeFieldsChange();
                        this.setState({ clickedOutside: false, query: "" });
                      }}
                    >
                      <AppsAndWebsitesPreviewList
                        deleteItem={() => {}}
                        withCloseIcon={false}
                        items={e}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <div
            style={{ position: "relative" }}
          >

          </div>
        </Flex>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  audienceResource: select.audience.audienceResourceSelector(state),
  audienceResourceLoadingSelector:
    select.audience.audienceResourceLoadingSelector(state),
  userRole: select.authentication.selectCurrentUserRoleId(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  audienceResourceSearch: dispatch.audience.audienceResourceSearch,
  nullifyAudienceResource: dispatch.audience.nullifyAudienceResource,
  change: bindActionCreators(change, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(AppsAndWebsitesSearch)));
