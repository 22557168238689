import React, { Component } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { AdminAccountsContainer } from "../../containers";
import { AdminHeader } from "../../components";
import { adminMessages } from "../../messages";

interface IProps {
  intl: IntlShape;
}

class WrappedAdminAccounts extends Component<IProps> {
  render() {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;

    return (
      <div>
        <AdminHeader title={formatMessage(adminMessages.accountsReport)}>
          <AdminAccountsContainer />
        </AdminHeader>
      </div>
    );
  }
}

const AdminAccounts = injectIntl(WrappedAdminAccounts);

AdminAccounts.displayName = "AdminAccounts";

export default AdminAccounts;
