import React, { Component } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { AdminPublisherContainer } from "../../containers";
import { AdminHeader } from "../../components";
import { adminMessages } from "../../messages";

interface IProps {
  intl: IntlShape;
}

class WrappedAdminPublishers extends Component<IProps> {
  render() {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;

    return (
      <div>
        <AdminHeader title={formatMessage(adminMessages.publishers)}>
          <AdminPublisherContainer />
        </AdminHeader>
      </div>
    );
  }
}

const AdminPublishers = injectIntl(WrappedAdminPublishers);

AdminPublishers.displayName = "AdminPublishers";

export default AdminPublishers;
