import React, { Component } from "react";
import { FileUpload } from "@intent-ai/mandal-npm-lib-v2";
import "./style.scss";
import { injectIntl, IntlShape, FormattedMessage } from "react-intl";
import { campaignMessages } from "../../../messages";
import { trash_icon } from "../../../assets";
import { ICampaignBanner, IFile } from "../../../types";
import { CAMPAIGN_TYPES } from "../../../_constants";
import clsx from "clsx";

interface IProps {
  fileId?: string;
  width: string | number;
  height: string | number;
  adaptiveData: string;
  intl: IntlShape;
  handleFailUpload: Function;
  handleSuccessUpload: Function;
  removeBanner: Function;
  showAdaptiveValue: boolean;
  banner: ICampaignBanner | undefined;
  bannerKey: number;
  whitelabelId: string;
  campaignType: number;
  url?: string;
}

interface IState {
  scale: number;
  upload: boolean;
}

class BannerPreview extends Component<IProps, IState> {
  private bannerIframe: React.RefObject<HTMLIFrameElement>;
  constructor(props: IProps) {
    super(props);
    this.bannerIframe = React.createRef();
  }

  state = {
    scale: 1,
    upload: false,
  };

  componentDidMount() {
    const { getScale } = this;
    getScale();
  }

  getScale = () => {
    const {
      props: { width },
      bannerIframe,
    } = this;
    if (bannerIframe.current) {
      const offsetWidth: number = bannerIframe.current.offsetWidth || 970;
      this.setState(() => ({
        scale: Number(Number(offsetWidth) / Number(width)),
      }));
    }
  };

  onSuccessUpload = (
    file: IFile,
    width: string,
    height: string,
    type_key: number
  ) => {
    const {
      props: { handleSuccessUpload, bannerKey },
    } = this;

    handleSuccessUpload(file, width, height, type_key, bannerKey);
    this.setState(() => ({ upload: false }));
  };
  render() {
    const {
      props: {
        intl: { formatMessage },
        url,
        fileId,
        width,
        height,
        adaptiveData,
        handleFailUpload,
        removeBanner,
        showAdaptiveValue,
        banner,
        //whitelabelId,
        campaignType,
      },
      state: { scale, upload },
      bannerIframe,
      onSuccessUpload,
    } = this;
    const showFullscreenSquare =
      Number(width) === 800 &&
      Number(height) === 600 &&
      campaignType === CAMPAIGN_TYPES.FULLSCREEN &&
      !fileId &&
      !!adaptiveData;
    const showUpload =
      (!fileId && (!adaptiveData || !showAdaptiveValue)) || upload;

    const childeStyle = {
      height: `${showFullscreenSquare ? 450 : height}px`,
      transform: `scale(${scale})`,
      width: `${Number(100 / scale)}%`,
    };

    return (
      <div className="mb-1 w-100">
        <div
          className={clsx({
            "banner-container": campaignType === CAMPAIGN_TYPES.FULLSCREEN,
          })}
        >
          <div
            className={clsx("preview-visuals-campign-form", {
              "fullscreen-preview-square": showFullscreenSquare,
            })}
            style={{
              height: `${Number(Number(height) * scale)}px`,
              maxWidth: `${width}px`,
              maxHeight: `${height}px`,
            }}
          >
            {showUpload ? (
                <div
                  style={childeStyle}
                  className="preview-visuals-campign-form-upload"
                  ref={bannerIframe}
                >
                  <FileUpload
                    type="area"
                    label={formatMessage(campaignMessages.uploadFile)}
                    handleFailUpload={handleFailUpload}
                    handleSuccessUpload={onSuccessUpload}
                    fielAccept=".jpg, .png, .jpeg, .zip, .mp4"
                    width={width}
                    height={height}
                  />
                </div>
            ) : (
              <>
                <iframe
                  src={url}
                  frameBorder="0"
                  title="Ad preview"
                  ref={bannerIframe}
                  className="banenr-ad-preview-iframe"
                  style={childeStyle}
                />
                {fileId ? (
                  <div
                    onClick={removeBanner(fileId)}
                    className="image-section-remove-container"
                  >
                    <img src={trash_icon} alt="Remove icon" />
                  </div>
                ) : null}
              </>
              )
            }

          </div>
        </div>
        <div
          style={{
            maxWidth: `${width}px`,
          }}
          className="preview-visuals-campign-form-title-container"
        >
          <span className="preview-visuals-campign-form-title">
            {width} x {height}
          </span>
          {banner?.sizeError ? (
            <div className="visual-image-field-error">
              <FormattedMessage
                id="CampaignForm.bannerInvalidSize"
                defaultMessage="The file size must not exceed 500 Kb"
              />
            </div>
          ) : null}

        { showAdaptiveValue && !fileId && adaptiveData && !upload ? (
          <>
          {" "}
            |
            <span
              className="preview-visuals-campign-active-title"
              onClick={() => {
                this.setState(() => ({ upload: true }));
              }}
            >
              {" "}
              <FormattedMessage
                id="CampaignForm.change"
                defaultMessage="Change"
              />
            </span>
          </>
          ) : showAdaptiveValue && !fileId && adaptiveData && upload ? (
          <>
            {" "}
            |
            <span
              className="preview-visuals-campign-active-title"
              onClick={() => {
                this.setState(() => ({ upload: false }));
              }}
            >
              {" "}
              <FormattedMessage
                id="CampaignForm.useAdaptive"
                defaultMessage="Use Adaptive"
              />
            </span>
          </>
          ) : null
        }

          {/* |<span> Upload Custom Banner</span> */}
        </div>
      </div>
    );
  }
}

export default injectIntl(BannerPreview);
