import React, { Component } from "react";
import { connect } from "react-redux";
import { IncomeOverview } from "../../containers";

import { Dispatch, select, IStore } from "../../store";
import { AdminHeader } from "../../components";
import { defineMessages, IntlShape, injectIntl } from "react-intl";
import { Loading } from "../../modules/global";

interface IProps {
  getWithdrawBalance: Function;
  getIncomeOverview: Function;
  withdrawBalance: number;
  incomeHistoryData: any;
  intl: IntlShape;
}

const messages = defineMessages({
  title: {
    id: "Payments.income",
    defaultMessage: "Income",
  },
});

export class WrappedIncomePage extends Component<IProps> {
  componentDidMount() {
    const {
      props: { getWithdrawBalance, getIncomeOverview },
    } = this;
    getWithdrawBalance({});
    getIncomeOverview({});
  }

  render() {
    const {
      props: {
        withdrawBalance,
        incomeHistoryData,
        intl: { formatMessage },
      },
    } = this;
    const isPending = withdrawBalance === null || incomeHistoryData === null;
    if (isPending) {
      return <Loading />;
    }

    return (
      <div>
        <AdminHeader title={formatMessage(messages.title)}>
          <IncomeOverview
            withdrawBalance={withdrawBalance}
            incomeHistoryData={incomeHistoryData}
          />
        </AdminHeader>
      </div>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  withdrawBalance: select.admin.withdrawBalanceSelector(state),
  incomeHistoryData: select.admin.incomeDataSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getWithdrawBalance: dispatch.admin.getWithdrawBalance,
  getIncomeOverview: dispatch.admin.getIncomeOverview,
});

export const Income = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WrappedIncomePage));

Income.displayName = "Income";
