export const styles: any = {
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerLeftBox: {},
  headerRightBox: {},
  headerOwner: {
    fontSize: 12,
    lineHeight: 1.3,
  },
  headerAccountId: {
    fontSize: 8,
    lineHeight: 0.8,
  },
  logo: {
    width: 88,
  },
  body: {
    padding: "36px 50px 75px 50px",
  },
  section: {
    padding: 10,
  },
  paymentSection: {
    padding: 12,
    paddingLeft: 24,
    backgroundColor: "#F5F6FC",
    borderRadius: 16,
    display: "flex",
    flexDirection: "row",
    marginTop: 12,
  },
  paymentSectionLeftBox: {
    borderRight: "2px dashed #E5E5E5",
    flexBasis: "55%",
  },

  paid: {
    fontSize: 12,
    lineHeight: 1.3,
  },
  paymentSectionCurrency: {
    fontSize: 22,
    // fontWeight: 'bold',
  },
  description: {
    fontSize: 7,
    lineHeight: 0.8,
    marginTop: 6,
  },
  dateContainer: {
    marginTop: 40,
  },
  invoicePayment: {
    fontSize: 8,
    lineHeight: 1,
  },
  date: {
    fontSize: 8,
    lineHeight: 0.9,
    marginTop: 4,
  },
  paymentSectionRightBox: {
    marginLeft: 25,

    fontSize: 10,
    // fontWeight: "normal",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    fontSize: 8,
  },
  paymentSectionRightBoxInner: {
    marginBottom: 12,
    marginRight: 12,
  },
  rightBoxDescription: {
    fontSize: 10,
    marginTop: 4,
  },
};
