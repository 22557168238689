/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 8/15/2023
 * All Rights Reserved.
 */
import DatePicker from "react-datepicker";
import { SyntheticEvent, useRef } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { formatDate } from "../../../utils";
import { CalendarIcon } from "../../Icons";
import { style } from './style';

export type IntDatePickerProps = {
  onChange: Function;
  value: Date;
  name: string;
  placeholder?: string;
}

function IntDatePicker({ onChange, value, name, placeholder, ...props }: IntDatePickerProps) {

  const dateInputRef = useRef(null);

  function openDatePicker() {
    // @ts-ignore
    if (dateInputRef.current && typeof dateInputRef.current.setOpen == 'function') {
      // @ts-ignore
      dateInputRef.current.setOpen(true);
    }
  }

  function handleChange(date: Date | null, event: SyntheticEvent<any> | undefined) {
    if (date instanceof Date) {
      onChange(date);
    }
  }

  return (
    <DatePicker
      onChange={handleChange}
      selected={value}
      ref={dateInputRef}
      placeholderText={placeholder}
      customInput={(
        <TextField
          value={formatDate(value)}
          onFocus={openDatePicker}
          name={name}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end' sx={style.inputAdornment}>
                <CalendarIcon sx={style.icon} />
              </InputAdornment>
            )
          }}
        />
      )}
      popperProps={{
        positionFixed: true
      }}
      popperPlacement="bottom-end"
      {...props}
    />
  )
}

export default IntDatePicker;
