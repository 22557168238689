import React, { Component } from "react";
import { IUser, IDictionaryField } from "../../../types";
import {
  ContentWrapper,
  ValidatableInputField,
  DatepickerField,
  MaiSelect,
} from "@intent-ai/mandal-npm-lib-v2";
import { profileMessages } from "../../../messages";
import { injectIntl, IntlShape } from "react-intl";
import { InjectedFormProps, reduxForm, Field } from "redux-form";
import { MODERATION_USER_FORM } from "../../../_constants";
import { Loading } from "../../../modules/global";

interface IProps {
  user: IUser;
  intl: IntlShape;
  isLegalEntityForm?: boolean;
  dictUserStatus?: IDictionaryField[];
}

interface InjectedProps extends InjectedFormProps<IUser, IProps> {}
interface ComponentProps extends IProps, InjectedProps {}

class ModerationUserForm extends Component<ComponentProps> {
  render() {
    const {
      props: {
        intl: { formatMessage },
        user,
        isLegalEntityForm,
        dictUserStatus,
      },
    } = this;

    if (!user) {
      return <Loading />;
    }

    return (
      <div>
        <form>
          <ContentWrapper title={formatMessage(profileMessages.contactPerson)}>
            <Field
              name="first_name"
              component={ValidatableInputField}
              label={formatMessage(profileMessages.firstName)}
              withLabel
              disabled
            />
            <Field
              name="last_name"
              component={ValidatableInputField}
              label={formatMessage(profileMessages.lastName)}
              withLabel
              disabled
            />
            <Field
              name="email"
              component={ValidatableInputField}
              label={formatMessage(profileMessages.email)}
              withLabel
              disabled
            />
            <Field
              name="phone"
              component={ValidatableInputField}
              label={formatMessage(profileMessages.phone)}
              withLabel
              disabled
            />
            <Field
              name="status_key"
              component={MaiSelect}
              label={formatMessage(profileMessages.status)}
              selectData={dictUserStatus}
              multiple={false}
              withLabel
              disabled
            />
            <Field
              name="created_at"
              component={DatepickerField}
              label={formatMessage(profileMessages.registrationDate)}
              withLabel
              disabled
            />

            {isLegalEntityForm && (
              <>
                <Field
                  name="legal_entity_name"
                  component={ValidatableInputField}
                  label={formatMessage(profileMessages.legalEntityName)}
                  withLabel
                />
                <Field
                  name="tin_number"
                  component={ValidatableInputField}
                  label={formatMessage(profileMessages.tinNumber)}
                  withLabel
                />
              </>
            )}
          </ContentWrapper>
        </form>
      </div>
    );
  }
}

export default injectIntl(
  reduxForm<IUser, IProps>({
    form: MODERATION_USER_FORM,
  })(ModerationUserForm)
);
