// @ts-nocheck

import React, { Component } from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { Modal } from "../../../containers";
import { MaiRow, ImageCropperField, Button } from "@intent-ai/mandal-npm-lib-v2";
import { IUploadImage } from "../../../types";
import "./style.scss";
import { REACT_APP_BASE_URL } from "../../../_constants";

interface IProps {
  modalData: {
    image: IUploadImage;
    handleUseImagesClick: Function;
    adaptive: any;
  };
  intl: IntlShape;
  closeModal: Function;
}

class AdaptiveModal extends Component<IProps> {
  state = {
    value: [],
  };

  componentDidMount() {
    const {
      props: {
        modalData: { adaptive },
      },
    } = this;
    this.setState(() => ({ value: adaptive ? adaptive : [] }));
  }

  onChange = (value: any) => this.setState(() => ({ value }));

  handleUseImagesClick = () => {
    const {
      props: {
        modalData: { handleUseImagesClick, image },
      },
      state: { value },
    } = this;
    handleUseImagesClick({
      banners: value,
      image,
    });
  };

  render() {
    const {
      props: {
        modalData: { image },
        closeModal,
      },
      state: { value },
      onChange,
      handleUseImagesClick,
    } = this;

    const input = {
      value,
      onChange,
    };

    return (
      <div className="adaptive-modal">
          <Modal.Header>
           <FormattedMessage
            id={"CampaignForm.cropImageInfo"}
            defaultMessage={"Crop the image to meet the requirements of Adaptive Ad content. Make sure to review both ratios: square and landscape."}
          />
        </Modal.Header>
        <MaiRow>
          <MaiRow.Col customClassName="adaptive-modal-info" size={2}>
            <div>
              <p className="adaptive-modal-info-label label">
                <FormattedMessage
                  id="CampaignForm.imageFileName"
                  defaultMessage="File Name"
                />
              </p>
              <p>
                <strong>{image.file_name}</strong>
              </p>
              <p className="adaptive-modal-info-label label">
                <FormattedMessage
                  id="CampaignForm.imageDimensions"
                  defaultMessage="Dimensions"
                />
              </p>
              <p>
                <strong>
                  {image.width}x{image.height}
                </strong>
              </p>
            </div>
          </MaiRow.Col>
          <MaiRow.Col size={10}>
            <ImageCropperField
              input={input}
              image={`${REACT_APP_BASE_URL}/v1/file/${image.file_id}`}
              acceptedSizes={[
                { height: 450, width: 450, key: 201 },
                { height: 150, width: 450, key: 203 },
              ]}
            />
          </MaiRow.Col>
        </MaiRow>
        <Modal.Footer>
          <MaiRow mp0>
            <MaiRow.Col size={12} customClassName="justify-content-between">
              <Button
                i18nId="ButtonLabel.cancel"
                type="border"
                style={{ width: "20%" }}
                onClick={closeModal}
              />
              <Button
                i18nId="CampaignForm.useImages"
                style={{ width: "30%" }}
                onClick={handleUseImagesClick}
              />
            </MaiRow.Col>
          </MaiRow>
        </Modal.Footer>
      </div>
    );
  }
}

export default injectIntl(AdaptiveModal);
