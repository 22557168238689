import React, { Component } from "react";
import {
  ContentWrapper,
  MaiRow,
  MaiSelectInclExclFieldV2,
  Button,
} from "@intent-ai/mandal-npm-lib-v2";
import { campaignMessages } from "../../../../messages";
import { injectIntl } from "react-intl";

// import { ICampaign, IDictionaryField, ICampaignPolygons } from "../../../../types";
import "./style.scss";

// const sydneyPolygonOptions = {
//   fillColor: "#6ca1f2",
//   fillOpacity: 0.5,
//   strokeColor: "#3a77eb",
//   strokeOpacity: 1,
//   strokeWeight: 2,
//   zIndex: 1,
// };

// interface IProps {
//   intl: IntlShape;
//   ageGroupToData: any;
//   ageGroupFromData: any;
//   change: Function;
//   formChange: Function;
//   toggleChange: Function;
//   handleLocationChange: Function;
//   handleCampaignGaugeFieldsChange: Function;
//   currentData: ICampaign;
//   dictGender: IDictionaryField[];
//   dictInterest: IDictionaryField[];
//   dictBehavior: IDictionaryField[];
//   dictLanguages: IDictionaryField[];
//   dictLocations: IDictionaryField[];
//   dictLocationOptions: IDictionaryField[];
//   polygonsData: ICampaignPolygons[];
// }

class AudienceInterestsAndBehavior extends Component<any> {
  state = {
    isExclude: false,
    isNarrow: false,
  };
  componentDidMount() {
    const {
      props: { handleCampaignGaugeFieldsChange },
    } = this;
    handleCampaignGaugeFieldsChange();
  }

  render() {
    const {
      state: { isExclude, isNarrow },
      props: {
        intl: { formatMessage },
        // ageGroupToData,
        // change,
        currentData,
        // dictGender,
        dictInterest,
        formChange,
        // dictBehavior,
        // dictLanguages,
        // dictLocations,
        // dictLocationOptions,
        // polygonsData,
        // handleLocationChange,
        // toggleChange,
        handleCampaignGaugeFieldsChange,
      },
    } = this;
    const finalDictInterest = dictInterest.map((value: any) => {
      if (value.children) {
        return { ...value, disabled: true };
      }
      return value;
    });

    // const handleToggleChange = (value: boolean) => {
    //   toggleChange("targeting_on")(value);
    //   handleCampaignGaugeFieldsChange();
    // };
    const isExcludeActive =
      currentData?.targeting.interests.exclude.length || isExclude;
    // const includeLength = currentData?.targeting.interests.include.length;
    const includeAny =
      currentData?.targeting.interests.includeAny.length || isNarrow;
    return (
      <div className="target-campaign-form audience-basic-info interests-and-behavior">
        <ContentWrapper
          title={"Interests and Behavior"}
          //   toggleValue={currentData.targeting_on}
          //   onToggleChange={handleToggleChange}
          toogleOffTile={formatMessage(campaignMessages.targetOff)}
          //   inputName="targeting_on"
          //   toggleSize="medium"
          toggleOnTitle={formatMessage(campaignMessages.targetOn)}
          // tooltip={formatMessage(campaignMessages.targetTooltip)}
          //   showToggle
          noBorder
        >
          <MaiSelectInclExclFieldV2
            name="targeting.interests.include"
            selectData={finalDictInterest}
            placeholder={formatMessage(
              campaignMessages.selectInterestsOrBrowse
            )}
            formChange={formChange}
            currentData={currentData}
            label={"Include people that have any of the following"}
            handelOnChange={handleCampaignGaugeFieldsChange}
            isAudience
            // excludeText={formatMessage(campaignMessages.excludeInterests)}
            // exclude={false}
          />
          {includeAny && (
            <MaiSelectInclExclFieldV2
              name="targeting.interests.includeAny"
              selectData={finalDictInterest}
              placeholder={formatMessage(
                campaignMessages.selectBehaviorsOrBrowse
              )}
              formChange={formChange}
              currentData={currentData}
              label={"Leave only people that have all of the following"}
              handelOnChange={handleCampaignGaugeFieldsChange}
              isAudience
              // excludeText={formatMessage(campaignMessages.excludeBehaviors)}
            />
          )}
          {isExcludeActive && (
            <MaiSelectInclExclFieldV2
              name="targeting.interests.exclude"
              selectData={finalDictInterest}
              placeholder={formatMessage(
                campaignMessages.selectBehaviorsOrBrowse
              )}
              formChange={formChange}
              currentData={currentData}
              label={"Exclude people that have any of the following"}
              handelOnChange={handleCampaignGaugeFieldsChange}
              isAudience
              // excludeText={formatMessage(campaignMessages.excludeBehaviors)}
            />
          )}
          <MaiRow className="audience-basic-info-buttons">
            {!isExcludeActive && (
              <Button
                staticText
                onClick={() => {
                  this.setState({ isExclude: !isExclude });
                }}
              >
                Exclude
              </Button>
            )}
            {!includeAny && (
              <Button
                staticText
                onClick={() => {
                  this.setState({ isNarrow: !isNarrow });
                }}
              >
                Narrow Audience
              </Button>
            )}
          </MaiRow>
        </ContentWrapper>
      </div>
    );
  }
}

export default injectIntl(AudienceInterestsAndBehavior);
