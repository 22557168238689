// @ts-nocheck

import jwt_decode from "jwt-decode";
import { createModel } from "@rematch/core";
import {
  FetchUtils,
  AuthUtils,
  WebStorageUtils,
} from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import {
  getUserRequest,
  updateUserRequest,
  changePasswordRequest,
  getCurrentUserAccountRequest,
  setCurrentUserAccountRequest,
  checkRegistrationTokenRequest,
  registerRequest,
  confirmRegistrationRequest,
  finishForgotPasswordRequest,
  initForgotPasswordRequest,
  getCurrentSpaceBalanceRequest,
  getCurrentSpaceRequest,
  changeLanguageRequest,
  updateUserLegalEntityRequest,
} from "../api";
import { loginRequest } from "@intent-ai/mandal-npm-lib-v2/dist/api";
import {
  AUTH_TOKEN,
  REFRESH_TOKEN,
  RT_REGISTERED_USER_NOT_VERIFIED,
  userRoles,
} from "../_constants";
import { IUser, ISpace, IUserAccount, ISignUpUser, IResponse } from "../types";
import { select, IStore } from "../store";
import { i18n } from "../modules/global";
import { RootModel } from "./index";

const { makeRequest } = FetchUtils;
const { get: LSGet, delete: LSDelete } = WebStorageUtils;

const { getToken } = new AuthUtils();

export interface AuthState {
  user: null | IUser;
  token?: object;
  isAuthenticated: boolean;
  isLoaded: boolean;
  isPersonalChangesLoaded: boolean;
  isSecurityChangesLoaded: boolean;
  isNotificationChangesLoaded: boolean;
  dictionary: { [key: string]: object };
  space: ISpace;
  account: IUserAccount;
  registerUser: ISignUpUser;
  hasCampaign: boolean | null;
}

export const auth = createModel({
  name: "authentication",
  state: {
    user: null,
    token: {},
    isAuthenticated: false,
    isLoaded: false,
    isPersonalChangesLoaded: false,
    isSecurityChangesLoaded: false,
    isNotificationChangesLoaded: false,
    space: {},
    account: {},
    registerUser: {},
    hasCampaign: false,
  } as AuthState,
  reducers: {
    setUser: (state, user: IUser): AuthState => ({
      ...state,
      user,
      isAuthenticated: true,
      isLoaded: true,
    }),
    setPersonalChangesLoaded: (state, value) => ({
      ...state,
      isPersonalChangesLoaded: value,
    }),
    setSecurityChangesLoaded: (state, value) => ({
      ...state,
      isSecurityChangesLoaded: value,
    }),
    setNotificationChangesLoaded: (state, value) => ({
      ...state,
      isNotificationChangesLoaded: value,
    }),
    setSpace: (state, space: ISpace): AuthState => ({
      ...state,
      space,
    }),
    setToken: (state, token: any): AuthState => ({
      ...state,
      token,
    }),
    setTokenInvalid: (state: AuthState): AuthState => ({
      ...state,
      token: {},
      user: null,
      isLoaded: true,
    }),
    setLogout: (state: AuthState): AuthState => ({
      ...state,
      token: {},
      user: null,
      isLoaded: true,
      isAuthenticated: false,
    }),
    setUpdatedUser: (state: AuthState, user: IUser): AuthState => ({
      ...state,
      user,
    }),
    setUserAccount: (state: AuthState, account: IUserAccount): AuthState => ({
      ...state,
      account,
    }),
    setRegisterUser: (
      state: AuthState,
      registerUser: ISignUpUser
    ): AuthState => ({
      ...state,
      registerUser,
    }),
    nullifyUserAccount: (state: AuthState): AuthState => ({
      ...state,
      account: {},
    }),
    setSpaceId: (state: AuthState, id): AuthState => ({
      ...state,
      space: { ...state.space, id },
    }),
    setHasCampaign: (state: AuthState, hasCampaign): AuthState => ({
      ...state,
      hasCampaign,
    }),
  },
  selectors: state => ({
    total() {
      return state((data: AuthState) => data.dictionary);
    },
    isUserLoaded() {
      return state((auth: AuthState) => auth.isLoaded);
    },
    selectPersonalChangesLoaded() {
      return state((auth: AuthState) => auth.isPersonalChangesLoaded);
    },
    selectSecurityChangesLoaded() {
      return state((auth: AuthState) => auth.isSecurityChangesLoaded);
    },
    selectNotificationChangesLoaded() {
      return state((auth: AuthState) => auth.isNotificationChangesLoaded);
    },
    selectCurrentUserName() {
      return state((auth: AuthState) => {
        return auth.user && `${auth.user.first_name} ${auth.user.last_name}`;
      });
    },
    selectCurrentUserImageId() {
      return state((auth: AuthState) => auth?.user?.profile_image?.picture_id);
    },
    selectCurrentUserRoleId() {
      return state((auth: AuthState) => auth?.user?.role_key);
    },
    selectCurrentUser() {
      return state((auth: AuthState) => {
        return auth.user && auth.user;
      });
    },
    selectCurrentUserSpaceId() {
      return state((auth: AuthState) => auth?.space?.id);
    },
    selectCurrentUserSpace() {
      return state((auth: AuthState) => auth?.space);
    },
    selectCurrentUserRegistrationDate() {
      return state((auth: AuthState) => auth?.space?.created_at);
    },
    selectCurrentUserSpaceName() {
      return state((auth: AuthState) => auth?.space?.name);
    },
    selectCurrentUserSpaces() {
      return state((auth: AuthState) => auth?.user?.spaces);
    },
    selectCurrentUserSpaceImage() {
      return state((auth: AuthState) => auth?.space?.picture_id);
    },
    selectCurrentUserRole() {
      return state((auth: AuthState) => auth?.user?.role_key);
    },
    selectCurrentUserAccount() {
      return state((auth: AuthState) => auth.account);
    },
    selectRegisterUser() {
      return state((auth: AuthState) => auth.registerUser);
    },
    selectWhiteLabelId() {
      return state((auth: AuthState) => auth.user?.whitelabel_id);
    },
    updateUserLoadingSelector() {
      return (rootState: any) =>
        rootState.loading.effects.authentication.setUpdatedUser;
    },
    currentUserLocaleSelector() {
      return state((auth: AuthState) => auth.user?.locale);
    },
    isOwnerAccountSelector() {
      return state((auth: AuthState) => {
        if (auth.user?.role_key) {
          return (
            [userRoles.WL_OWNER, userRoles.WL_MODERATOR].includes(
              auth.user?.role_key
            ) && auth.space.userId !== auth.user.id
          );
        }
        return false;
      });
    },
  }),
  effects: dispatch => ({
    async login({ data: userData, success }: any) {
      await makeRequest(loginRequest(userData), {
        success,
      });
    },
    async updateUser({ data: userData, success, fail, type }) {
      dispatch.authentication.setPersonalChangesLoaded(true);
      await makeRequest(updateUserRequest(userData), {
        success: (data: IUser) => {
          if (type === "space") {
            dispatch.space.setCurrentUserData(data);
          } else if (type === "user") {
            dispatch.authentication.setUpdatedUser(data);
          }
          dispatch.authentication.getCurrentSpace({
            id: data.current_space_id,
          });
          dispatch.authentication.setPersonalChangesLoaded(false);
          success(data);
        },
        fail,
      });
    },
    async changePassword({ data: userData, success, fail }) {
      dispatch.authentication.setSecurityChangesLoaded(true);

      await makeRequest(changePasswordRequest(userData), {
        success: (data: any) => {
          dispatch.authentication.setSecurityChangesLoaded(false);
          if (success) {
            success(data);
          }
        },
        fail: (data: object) => {
          dispatch.authentication.setSecurityChangesLoaded(false);
          if (fail) {
            fail(data);
          }
        },
      });
    },
    async changeLanguage({ data: userData, success, fail }) {
      await makeRequest(changeLanguageRequest({
        ...userData,
        language_key: userData.language_key.toString() === 'EN' ? 1 : 2,
      }), {
        success: (data: IUser) => {
          if (success) {
            success(data);
          }
        },
        fail: (data: any) => {
          if (fail) {
            fail(data);
          }
        },
      });
    },
    async getCurrentUserAccount() {
      await makeRequest(getCurrentUserAccountRequest(), {
        success: (data: IUserAccount) => {
          dispatch.authentication.setUserAccount(data);
        },
      });
    },
    async setCurrentUserAccount({ data, success, fail }) {
      dispatch.authentication.setNotificationChangesLoaded(true);
      await makeRequest(setCurrentUserAccountRequest(data), {
        success: (data: object) => {
          dispatch.authentication.setNotificationChangesLoaded(false);
          if (success) {
            success(data);
          }
        },
        fail: (data: any) => {
          dispatch.authentication.setNotificationChangesLoaded(false);
          if (fail) {
            fail(data);
          }
        },
      });
    },
    async setCurrentSpace(currentSpace: AuthState, store: IStore) {
      const spaces = select.authentication.selectCurrentUserSpaces(store);
      const space: ISpace =
        Object.values(spaces)
          .flat()
          .find(({ id }) => id === currentSpace.id) || {};
      dispatch.authentication.setSpace({...space, ...currentSpace});
    },
    async getUser({ success, spaceID }) {
      getToken().then(async (res: boolean) => {
        if (res) {
          await makeRequest(getUserRequest(select.authentication.space?.id || ''), {
            success: async (user: IUser) => {
              if (!user) {
                return;
              }
              if (user.locale === 'ru_RU') {
                i18n.changeLanguage('ru');
              } else if (user.locale === 'en_US') {
                i18n.changeLanguage('en');
              }
              dispatch.campaign.hasCampaign();
              const currentSpace: ISpace =
                Object.values(user.spaces)
                  .flat()
                  .find(({ id }) => id === user.current_space_id) || {};
              sessionStorage.setItem('spaceID', currentSpace.id);
              // sessionStorage.setItem('currentAccountCurrency', user.currency);
              // TODO: remove this code and make normal code
              if (currentSpace.id) {
                try {
                  dispatch.space.getCurrentSpaceBalance({
                    id: currentSpace.id,
                  });
                } catch (error) {
                  console.error(error);
                }
              }
              if (success) {
                success(user);
              }

              dispatch.authentication.setSpace(currentSpace);
              dispatch.authentication.setUser(user);
            },
          });
          const token = LSGet(AUTH_TOKEN);
          const decoded: any = jwt_decode(token);
          const currentTime = Date.now() / 1000;
          if (decoded && decoded.exp > currentTime) {
            dispatch.authentication.setToken(decoded);
          }
        } else {
          dispatch.authentication.setTokenInvalid();
        }
      });
    },
    async logout() {
      LSDelete(AUTH_TOKEN);
      LSDelete(REFRESH_TOKEN);
      sessionStorage.removeItem('currentAccountCurrency');
      dispatch.authentication.setLogout();
    },
    async checkRegistrationToken({ token, success, fail }) {
      await makeRequest(checkRegistrationTokenRequest(token), {
        success: (data: ISignUpUser) => {
          dispatch.authentication.setRegisterUser(data);
          success(data);
        },
        fail: (data: IResponse<ISignUpUser>) => {
          if (data.type.code === RT_REGISTERED_USER_NOT_VERIFIED) {
            dispatch.authentication.setRegisterUser(data.data);
          }
          fail(data);
        },
      });
    },
    async registerUser({ data, success, fail }) {
      await makeRequest(registerRequest(data), {
        success,
        fail,
      });
    },
    async verifyUser({ data, success, fail }) {
      await makeRequest(confirmRegistrationRequest(data), {
        success,
        fail,
      });
    },
    async finishForgotPassword({ data, success, fail }) {
      await makeRequest(finishForgotPasswordRequest(data), {
        success,
        fail,
      });
    },
    async initForgotPassword({ email, success, fail }) {
      await makeRequest(initForgotPasswordRequest(email), {
        success,
        fail,
      });
    },
    async getCurrentSpaceBalance({ id, success, fail }) {
      await makeRequest(getCurrentSpaceBalanceRequest(id), {
        success,
        fail,
      });
    },
    async updateUserLegalEntity({ data, success, fail }) {
      await makeRequest(updateUserLegalEntityRequest(data), {
        success: (user: IUser) => {
          if (user.tos_status_type_key === 2) {
            dispatch.payment.setIsAllowMakePayment(true);
          }

          dispatch.authentication.setUser(user);
          if (typeof success === "function") {
            success(user);
          }
        },
        fail,
      });
    },
    async getCurrentSpace({ id, success, fail }) {
      await makeRequest(getCurrentSpaceRequest(id), {
        success: (space: ISpace) => {
          dispatch.authentication.setSpace(space);
        },
        fail,
      });
    },
  }),
});

export type RematchDispatch = RematchDispatch<RootModel>
