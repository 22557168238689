import React, { Component } from "react";
import { connect } from "react-redux";
import {
  injectIntl,
  IntlShape,
  defineMessages,
  FormattedMessage,
} from "react-intl";
import { AdminHeader, ModerationUserForm } from "../../components";
import { match } from "react-router-dom";
import { IRootState, Dispatch, select } from "../../store";
import { IUser, IDictionaryField } from "../../types";
import { Button, Flex } from "@intent-ai/mandal-npm-lib-v2";
import { History } from "history";
import { bindActionCreators } from "redux";
import {
  APIResponseFail,
  APIResponseSuccess,
} from "@intent-ai/mandal-npm-lib-v2/dist/actions";
import { adminMessages } from "../../messages";
import { USER_STATUSES } from "../../_constants";
import { routerPaths } from "../../config/routes/paths";

interface IMatchParams {
  id: string;
}

interface IProps {
  isModerationRegistrationApproved: boolean;
  intl: IntlShape;
  match: match<IMatchParams>;
  moderationUser: IUser;
  getModerationRegistration: Function;
  setModerationRegistrationApprove: Function;
  history: History;
  APIResponseFail: Function;
  APIResponseSuccess: Function;
  resendConfirmationEmail: Function;
  getDictionaryValues: Function;
  dictUserStatus: IDictionaryField[];
}

const messages = defineMessages({
  title: {
    id: "Admin.approveAccount",
    defaultMessage: "Approve Account",
  },
});

class WrappedModerationUser extends Component<IProps> {
  componentDidMount() {
    const {
      props: {
        match: {
          params: { id },
        },
        getModerationRegistration,
        getDictionaryValues,
      },
    } = this;
    getDictionaryValues(["user_status"]);
    getModerationRegistration({ id });
  }

  approveUser = () => {
    const {
      props: {
        setModerationRegistrationApprove,
        moderationUser,
        history: { push },
        APIResponseSuccess,
        intl: { formatMessage },
      },
    } = this;

    setModerationRegistrationApprove({
      data: {
        user_id: moderationUser.id,
      },
      success: () => {
        push(routerPaths.Moderation);
        APIResponseSuccess(
          formatMessage(adminMessages.verified),
          formatMessage(adminMessages.accountVerified)
        );
      },
    });
  };

  resendEmail = () => {
    const {
      props: {
        resendConfirmationEmail,
        moderationUser,
        history: { push },
        APIResponseSuccess,
        intl: { formatMessage },
      },
    } = this;

    resendConfirmationEmail({
      id: moderationUser.email,
      success: () => {
        push(routerPaths.Moderation);
        APIResponseSuccess(
          formatMessage(adminMessages.reSent),
          formatMessage(adminMessages.reSentSuccessfully)
        );
      },
    });
  };

  render() {
    const {
      props: {
        intl: { formatMessage },
        moderationUser,
        isModerationRegistrationApproved,
        dictUserStatus,
      },
      approveUser,
      resendEmail,
    } = this;

    return (
      <AdminHeader title={formatMessage(messages.title)}>
        <div>
          <ModerationUserForm
            initialValues={moderationUser}
            user={moderationUser}
            dictUserStatus={dictUserStatus}
          />
          {USER_STATUSES.APPROVED === moderationUser?.status_key && (
            <p className="int-info-text text-center">
              <FormattedMessage
                id="Admin.reSentNotice"
                defaultMessage="Notice: After sending new confirmation email, the previously sent confirmation link will become invalid."
              />
            </p>
          )}
          <Flex justifyContent="center">
            <Button
              loading={isModerationRegistrationApproved}
              i18nId={
                USER_STATUSES.NEED_VERIFICATION === moderationUser?.status_key
                  ? "Auth.verify"
                  : "Auth.resendConfirmationEmail"
              }
              style={{ width: "30%" }}
              type="border"
              onClick={
                USER_STATUSES.NEED_VERIFICATION === moderationUser?.status_key
                  ? approveUser
                  : resendEmail
              }
            />
          </Flex>
        </div>
      </AdminHeader>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  moderationUser: select.admin.moderationUserSelector(state),
  isModerationRegistrationApproved: select.admin.isModerationRegistrationApprovedSelector(
    state
  ),
  dictUserStatus: select.dictionary.selectDictionaryField(state, "user_status"),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  APIResponseFail: bindActionCreators(APIResponseFail, dispatch),
  APIResponseSuccess: bindActionCreators(APIResponseSuccess, dispatch),
  getModerationRegistration: dispatch.admin.getModerationRegistration,
  setModerationRegistrationApprove:
    dispatch.admin.setModerationRegistrationApprove,
  resendConfirmationEmail: dispatch.admin.resendConfirmationEmail,
  getDictionaryValues: dispatch.dictionary.getDictionaryValues,
});

const ModerationUser = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WrappedModerationUser));

ModerationUser.displayName = "ModerationUser";

export default ModerationUser;
