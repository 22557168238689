import { FetchUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { GET, POST, PUT, DELETE, CUSTOM_CAMPAIGN } from "../_constants";
import { ICampaignFilter, ICampaign, ICampaignTarget } from "../types";

const { createRequest } = FetchUtils;
const EMPTY_OBJECT = {};
const HEADER_PLAIN_TEXT = {
  "Content-Type": "text/plain",
};

export const getDashboardCampaignsRequest = (spaceId: string) =>
  createRequest(
    "/campaign/dashboard",
    POST,
    EMPTY_OBJECT,
    spaceId,
    HEADER_PLAIN_TEXT
  );

export const getFilteredSpaceCampaigns = (
  data: ICampaignFilter,
  params = EMPTY_OBJECT
) => createRequest("/campaign/list", POST, params, data);

export const getCampaignRequest = (id: string) =>
  createRequest(`/campaign/${id}`);

export const getSpaceCampaignCountRequest = (spaceId: string) =>
  createRequest(`/campaign/count/${spaceId}`);

export const createCampaignRequest = (data: any) =>
  createRequest("/campaign", POST, EMPTY_OBJECT, {
    ...data,
    // TODO change, fast-fix
    targeting: {
      ...data.targeting,
      similarity: +data.similarity,
    }
  }); //campaign

export const updateCampaignRequest = (data: any) =>
  createRequest("/campaign", PUT, EMPTY_OBJECT, {
    // TODO change, fast-fix
    ...data,
    targeting: {
      ...data.targeting,
      similarity: +data.similarity,
    }
  }); //campaign

export const saveAndPublishCampaignRequest = (data: ICampaign) =>
  createRequest("/campaign/saveAndPublish", POST, EMPTY_OBJECT, {
    ...data,
    // TODO change, fast-fix
    targeting: {
      ...data.targeting,
      // @ts-ignore
      similarity: +data.similarity,
    }
  });

export const publishCampaignRequest = (id: string) =>
  createRequest("/campaign/publish", POST, EMPTY_OBJECT, id, HEADER_PLAIN_TEXT);

export const runCampaignRequest = (id: string) =>
  createRequest("/campaign/run", POST, EMPTY_OBJECT, id, HEADER_PLAIN_TEXT);

export const pauseCampaignRequest = (id: string) =>
  createRequest("/campaign/pause", POST, EMPTY_OBJECT, id, HEADER_PLAIN_TEXT);

export const deleteCampaign = (id: string) =>
  createRequest(`/campaign/delete/${id}`, DELETE);

// TODO: default value
export const cpmEstimationRequest = (type = CUSTOM_CAMPAIGN) =>
  createRequest(`/campaign/cpm-estimation/${type}`);

export const invoice = (id = "") => createRequest(`/invoice/${id}`);

export const getCampaignAudienceRequest = (spaceId: string) =>
  createRequest(`/campaign/audience/${spaceId}`, GET, EMPTY_OBJECT);

export const toggleFavoriteCampaignRequest = (id: string) =>
  createRequest(
    "/campaign/favorite",
    POST,
    EMPTY_OBJECT,
    id,
    HEADER_PLAIN_TEXT
  );

export const getAudienceSizeRequest = (data: ICampaignTarget) =>
  createRequest("/audience/audience-size", POST, EMPTY_OBJECT, data);

export const getLocationCoordinates = (data: number[]) =>
  createRequest("/campaign/location", POST, EMPTY_OBJECT, data);

export const archiveCampaignRequest = (campaignId: string) =>
  createRequest(
    "/campaign/archive/toggle",
    POST,
    EMPTY_OBJECT,
    campaignId,
    HEADER_PLAIN_TEXT
  );
