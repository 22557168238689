import React, { Component } from "react";
import { Table } from "@intent-ai/mandal-npm-lib-v2";
import { IUser } from "../../../types";
import { profileMessages, adminMessages } from "../../../messages";
import { goToRouteLinkWithId } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { IntlShape, injectIntl } from "react-intl";
import "./style.scss";
import { routerPaths } from "../../../config/routes/paths";

interface IProps {
  legalEntityUsers: IUser[];
  intl: IntlShape;
  // routerPaths: IRoutePaths;
}

class AdminLegalModerationAccounts extends Component<IProps> {
  getColumns = () => {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;
    return [
      {
        title: formatMessage(profileMessages.name),
        dataIndex: "name",
      },
      {
        title: formatMessage(profileMessages.email),
        dataIndex: "email",
      },
      {
        title: formatMessage(adminMessages.legalEntityName),
        dataIndex: "legal_entity_name",
      },
      {
        title: formatMessage(profileMessages.tinNumber),
        dataIndex: "tin_number",
      },
    ];
  };
  getData = () => {
    const {
      props: { legalEntityUsers },
    } = this;

    if (!Array.isArray(legalEntityUsers)) return [];
    if (legalEntityUsers.length === 0) return [];

    return legalEntityUsers.map(
      ({
        first_name,
        id,
        last_name,
        email,
        // phone,
        // created_at,
        legal_entity_name,
        tin_number,
      }) => {
        return {
          name: `${first_name} ${last_name}`,
          email,
          legal_entity_name,
          tin_number,
          link: goToRouteLinkWithId(routerPaths.LegalEntityUser, id),
        };
      }
    );
  };
  render() {
    const { getColumns, getData } = this;
    return (
      <div className="accounts-moderation-table-wrapper">
        <Table
          // notFound={renderNotfound()}
          borderBottom
          columns={getColumns()}
          data={getData()}
          firtsFullWidth
        />
      </div>
    );
  }
}

export default injectIntl(AdminLegalModerationAccounts);
