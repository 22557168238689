import { FetchUtils, WebStorageUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { POST, PUT, AUTH_TOKEN, REFRESH_TOKEN } from "../_constants";
import {
  ISignUpUser,
  IUser,
  IUserPasswordChange,
  IUserLegalEntity,
} from "../types";

const { get: LSGet } = WebStorageUtils;

const { createRequest } = FetchUtils;
const EMPTY_OBJECT = {};

export const getUserRequest = (spaceID?:string) => {
  if (!spaceID) {
    spaceID = sessionStorage.getItem('spaceID') || '';
  }
  if (spaceID) {
    return createRequest(`/user/current`, 'GET', { space_id: spaceID });
  }
  return createRequest(`/user/current`);
};

export const registerRequest = (data: ISignUpUser) =>
  createRequest("/registration", POST, EMPTY_OBJECT, data);

export const completeInvitationRequest = (data: IUser) =>
  createRequest("/user/invite-completion", PUT, EMPTY_OBJECT, data);

export const confirmRegistrationRequest = (data: IUser) =>
  createRequest("/registration/complete", POST, EMPTY_OBJECT, data);

export const getRegistrationRequest = (id: string) =>
  createRequest(`/registration/${id}`);

export const initForgotPasswordRequest = (email: string) =>
  createRequest("/user/reset-password/init", POST, EMPTY_OBJECT, email, {
    "Content-Type": "text/plain",
  });

export const finishForgotPasswordRequest = (data: IUserPasswordChange) =>
  createRequest("/user/reset-password/finish", POST, EMPTY_OBJECT, data);

export const checkRegistrationTokenRequest = (token: string) =>
  createRequest(`/registration/verify-token/${token}`, POST);

export const checkInvitationTokenRequest = (token: string) =>
  createRequest(`/space/invitation-verification/${token}`, POST);

export const updateUserRequest = (data: IUser) =>
  createRequest("/user", PUT, EMPTY_OBJECT, data);

export const changePasswordRequest = (data: IUserPasswordChange) =>
  createRequest("/user/change-password", POST, EMPTY_OBJECT, data);

export const getCurrentUserAccountRequest = () =>
  createRequest("/user/current/account");

export const setCurrentUserAccountRequest = (data: IUser) =>
  createRequest("/user/account/settings", PUT, EMPTY_OBJECT, data);

export const changeLanguageRequest = (data: IUser) =>
  createRequest("/user/change-language", PUT, EMPTY_OBJECT, data);

export const updateUserLegalEntityRequest = (data: IUserLegalEntity) =>
  createRequest("/user/legal", PUT, EMPTY_OBJECT, data);

export const changeSpaceRequest = (spaceId: string) => {
  const formData = [];
  formData.push(
    `${encodeURIComponent("grant_type")}=${encodeURIComponent("refresh_token")}`
  );
  formData.push(
    `${encodeURIComponent("refresh_token")}=${encodeURIComponent(
      LSGet(REFRESH_TOKEN)
    )}`
  );

  return createRequest(
    `/oauth/switch-space/${spaceId}`,
    POST,
    EMPTY_OBJECT,
    formData.join("&"),
    {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${LSGet(AUTH_TOKEN)}`,
    },
    EMPTY_OBJECT,
    false
  );
};
