import React, { Component } from "react";
import { Tooltip, Switcher, MaiSelect } from "@intent-ai/mandal-npm-lib-v2";
import { PieGraph, VerticalBarGraph, PercentageGraph } from "../../../components";
import "./style.scss";
import { Field } from "redux-form";
import { Loading } from "../../../modules/global";

interface IProps {
  isPieChart?: boolean;
  isBarChart?: boolean;
  data: any;
  title: string;
  titleTooltip: string;
  types: { value: string; title: string }[];
  showTypes?: boolean;
  showSort?: boolean;
  isPercentageChart?: boolean;
  dataKey: string;
  versusDataKey?: string;
  showTotalInCenter?: boolean;
  onClick?: Function;
  loading: boolean;
  percentageKey?: string;
  handlePercentageClick?: Function;
}

interface IState {
  type: string;
}

export default class ReportsSingleDetailsContainer extends Component<
  IProps,
  IState
> {
  state = {
    type: "",
  };

  componentDidMount() {
    const {
      props: { types },
    } = this;
    if (types) {
      this.setState(() => ({ type: types[0].value }));
    }
  }

  handleTypeChange = (type: string) => {
    this.setState(() => ({ type }));
  };

  render() {
    const {
      props: {
        title,
        titleTooltip,
        showTypes,
        types,
        isPieChart,
        data,
        dataKey,
        versusDataKey,
        isBarChart,
        showSort,
        showTotalInCenter,
        onClick,
        loading,
        isPercentageChart,
        percentageKey,
        handlePercentageClick,
      },
      state: { type },
      handleTypeChange,
    } = this;

    const typeInput = {
      value: type,
      onChange: handleTypeChange,
      name: title,
    };

    if (!type) {
      return null;
    }

    const typeLabel = types.find(({ value }) => value === type);

    return (
      <div className="reports-single-details-container">
        <div className="reports-single-details-container-header">
          <p className="reports-single-details-container-header-title">
            {title}
          </p>
          <Tooltip tooltip={titleTooltip} />
        </div>
        {loading ?  <Loading /> : (
          <div className="reports-single-details-container-content">
            {showTypes ? (
              <div className="reports-single-details-container-type">
                <Switcher
                  input={typeInput}
                  switchType="inline"
                  options={types}
                  size="medium"
                />
              </div>) : null}
            {showSort ? (
              <div className="reports-single-details-container-sort">
                <Field
                  name="top_publishers_highest_first"
                  component={MaiSelect}
                  selectData={[
                    {
                      title: "High to Low",
                      value: 1,
                    },
                    {
                      title: "Low to High",
                      value: 2,
                    },
                  ]}
                  showSearch={false}
                  multiple={false}
                />
              </div>) : null }

          {isPieChart ? (
            <div className="reports-single-details-container-pie-chart">
              <PieGraph
                data={data[type]}
                dataKey={dataKey}
                versusDataKey={versusDataKey}
                withTooltip
                showTotalInCenter={showTotalInCenter}
                onClick={onClick}
                labelName={typeLabel?.title}
              />
            </div>
          ) : null}

          {isBarChart ? (
            <div>
              <VerticalBarGraph data={data[type]} onClick={onClick} />
            </div>
          ) : null}

          {isPercentageChart ? (
            <div>
              <PercentageGraph
                onClick={handlePercentageClick}
                data={data[type][percentageKey || ""]}
              />
            </div>
          ) : null }

          </div>
        )}
      </div>
    );
  }
}
