import React, { Component } from "react";
import { CampaignListName } from "../../../components";
import { ICampaign } from "../../../types";
import { Table } from "@intent-ai/mandal-npm-lib-v2";
import { campaignMessages } from "../../../messages";
import { IntlShape, injectIntl } from "react-intl";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { NEW_ROUTES } from "../../../modules";

interface IProps {
  moderationCampaigns: ICampaign[];
  intl: IntlShape;
  setAdminNewSpace: Function;
}

class AdminModerationCampaigns extends Component<IProps & RouteComponentProps> {
  getColumns = () => {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;
    return [
      {
        title: formatMessage(campaignMessages.campaignName),
        dataIndex: "name",
      },
    ];
  };
  getData = () => {
    const {
      props: {
        moderationCampaigns,
        history: { replace, push },
        setAdminNewSpace,
      },
    } = this;

    if (!Array.isArray(moderationCampaigns)) return [];
    if (moderationCampaigns.length === 0) return [];

    return moderationCampaigns.map(
      ({ name, id, favorite, status_key, campaign_start_at, space_id }) => {
        return {
          name: (
            <div
              onClick={() => {
                push({ pathname: "/empty" });
                setTimeout(() => {
                  setAdminNewSpace({ id: space_id });
                  replace({
                    pathname: NEW_ROUTES.campaign.edit.onEdit(id as string),
                  });
                });
              }}
            >
              <CampaignListName
                name={name}
                favorite={favorite}
                status_key={status_key}
                campaign_start_at={campaign_start_at}
              />
            </div>
          ),
        };
      }
    );
  };

  render() {
    const { getColumns, getData } = this;
    return (
      <div>
        <Table
          // notFound={renderNotfound()}
          borderBottom
          columns={getColumns()}
          data={getData()}
          firtsFullWidth
          // showLoading
          // isLoading={loading}
        />
      </div>
    );
  }
}

export default injectIntl(withRouter(AdminModerationCampaigns));
