import { IStore } from "../../../../store";
import { createSelector } from "reselect";
import { SelectOption } from "../../../global/components/IntForm/IntSelect"
import { t } from 'i18next';

function getDictionary(store: IStore) {
  return store.dictionary;
}

const getActiveDeviceTypes = createSelector(getDictionary, function getActiveDeviceTypesFromDict(dictionary) {
  const deviceTypes = dictionary?.data?.device_types || [];
  return deviceTypes.filter(({ disabled }) => !disabled);
});

const getDictionaryBannerSizesAndDeviceTypesSelector = createSelector(
  getDictionary,
  function getDictionaryBannerSizesAndDeviceTypes(dictionary) {
    // value for All Banner Sizes 0
    const bannerSizes: SelectOption[] = [{ value: 1, label: t('reporting.filter.allSizes') }];
    const deviceTypes: SelectOption[] = [];
    const sizes = [
      ...(dictionary.data?.visuals_banners_horizontal || []),
      ...(dictionary.data?.visuals_banners_square || []),
      ...(dictionary.data?.visuals_banners_vertical || []),
    ];

    sizes.forEach((({title, value }) => {
      bannerSizes.push({ label: title, value });
    }));

    (dictionary.data?.report_device_types || []).forEach(({ title, value }) => {
      deviceTypes.push({ label: title, value });
    })

    return {
      bannerSizes,
      deviceTypes,
    }
});

export {
  getActiveDeviceTypes,
  getDictionaryBannerSizesAndDeviceTypesSelector,
}