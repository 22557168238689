import React, { Component } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { IRootState, select, Dispatch } from "../../../store";
import { connect } from "react-redux";

import { AdminLegalModerationAccounts } from "../../../containers";
import { IPaginationData, IUser } from "../../../types";
import { ContentWrapper, MaiFilters } from "@intent-ai/mandal-npm-lib-v2";
import "./style.scss";

interface IProps {
  intl: IntlShape;
  getModerationLegalUsers: Function;
  legalEntityUsers: IPaginationData<IUser[]>;
}

class WrappedAdminLegalEntity extends Component<IProps> {
  render() {
    const {
      props: { getModerationLegalUsers, legalEntityUsers},
    } = this;
    return (
      <div className="admin-moderation-tab">
        <MaiFilters
          showSearch
          title=""
          onReady={getModerationLegalUsers}
          onChange={getModerationLegalUsers}
          showPagination
          pageCount={Math.ceil(legalEntityUsers?.total_size / 10)}
          additionalData={{ status_filter: [1, 2, 3] }}
        >
          <div>
            <ContentWrapper>
              <AdminLegalModerationAccounts
                legalEntityUsers={legalEntityUsers?.data}
              />
            </ContentWrapper>
          </div>
        </MaiFilters>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  moderationUsers: select.admin.moderationUsersSelector(state),
  legalEntityUsers: select.admin.legalEntityUsersSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getModerationLegalUsers: dispatch.admin.getModerationLegalUsers,
});

const AdminLegalEntity = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WrappedAdminLegalEntity));

AdminLegalEntity.displayName = "AdminLegalEntity";

export default AdminLegalEntity;
