import { NotificationService } from "./notification.service";
import { StatusCode } from "../types";

type AppendedHeaders = {
  Authorization?: string;
  'Content-Type'?: string;
}
class ApiService {
  static cache: Record<string, any> = {};

  static get(url: string, authorized = true, cache = false) {
    if (ApiService.cache[url]) {
      return Promise.resolve(ApiService.cache[url]);
    }

    let authorization: AppendedHeaders = {};
    if (authorized) {
      const token = localStorage.getItem('DSP_AUTH_TOKEN');
      authorization['Authorization'] = `Bearer ${token}`;
    }
    return fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        ...authorization,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (cache) {
          ApiService.cache[url] = response;
        }
        return response;
      });
  }

  static put<T>(url: string, body: T | null = null, headers: HeadersInit = {}, authorized = true) {
    let authorization: AppendedHeaders = {};
    if (authorized) {
      const token = localStorage.getItem('DSP_AUTH_TOKEN');
      authorization['Authorization'] = `Bearer ${token}`;
    }
    const transformedHeaders = {
      'Content-Type': 'application/json',
      ...headers,
      ...authorization,
    };
    return fetch(url, {
      method: 'PUT',
      headers: transformedHeaders,
      body: JSON.stringify(body),
    }).then(response => {
      if (transformedHeaders['Content-Type'] === 'application/json') {
        return response.json();
      }
      return response;
    }).then(response => {
      if (response.type && response.type.code != StatusCode.OK) { // eslint-disable-line
        NotificationService.handleError(response.type.message);
      }

      return response;
    });
  }

  static post<T>(url: string, body: T | null = null, headers: HeadersInit = {}, authorized = true, stringify = true) {
    let appended: AppendedHeaders = {};
    let requestBody: string | FormData = (body as FormData);
    // @ts-ignore
    const fileUpload = headers['Content-Type'] === 'text/csv';
    if (authorized) {
      const token = localStorage.getItem('DSP_AUTH_TOKEN');
      appended['Authorization'] = `Bearer ${token}`;
    }
    if (!(body instanceof FormData) && stringify) {
      appended['Content-Type'] = 'application/json';
      requestBody = JSON.stringify(body);
    }
    const transformedHeaders = {
      ...headers,
      ...appended,
    };
    return fetch(url, {
      method: 'POST',
      headers: transformedHeaders,
      body: requestBody,
    }).then(response => {
      if (response.status === StatusCode.UNAUTHORIZED) {
        return { type: {} };
      }
      if (response.status != StatusCode.NO_CONTENT) { // eslint-disable-line
        if (fileUpload) {
          return response.blob();
        }
        return response.json();
      }
      return response;
    }).then(response => {
      if (response.type && response.type.code) {
        NotificationService.handleError(response.type.message);
      }

      return response;
    });
  }

  static delete(url: string, headers: HeadersInit = {}, authorized = true) {
    let authorization: AppendedHeaders = {};
    if (authorized) {
      const token = localStorage.getItem('DSP_AUTH_TOKEN');
      authorization['Authorization'] = `Bearer ${token}`;
    }
    const transformedHeaders = {
      'Content-Type': 'application/json',
      ...headers,
      ...authorization,
    };

    return fetch(url, {
      method: 'DELETE',
      headers: transformedHeaders
    }).then(response => {
      if (response.status != StatusCode.NO_CONTENT) { // eslint-disable-line
        return response.json();
      }
      return response;
    }).then(response => {
      if (response.type && response.type.code != StatusCode.OK) { // eslint-disable-line
        NotificationService.handleError(response.type.message);
      }

      return response;
    });
  }

  static transformDate(date: Date | undefined) {
    if (date) {
      let months: string | number = date.getMonth() + 1;
      months = months < 10 ? `0${months}` : months;
      let day: string | number = date.getDate();
      day = day < 10 ? `0${day}` : day;
      return `${date.getFullYear()}-${months}-${day}`;
    }
    return null;
  }
}

export {
  ApiService,
}