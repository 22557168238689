import React, { Component } from "react";
import { Link, match } from "react-router-dom";
import { History } from "history";
import { connect } from "react-redux";
import { injectIntl, IntlShape } from "react-intl";
import { AuthFormWrapper, Button, Flex } from "@intent-ai/mandal-npm-lib-v2";
import { AuthWrapper } from "../../components";
import { ISignUpUser } from "../../types";
import { IRootState, Dispatch, select } from "../../store";
import "./style.scss";
import {
  RT_REGISTERED_USER_VERIFIED,
  RT_TOKEN_INVALID,
  RT_TOKEN_EXPIRED,
} from "../../_constants";
import messages from "./messages";
import { SignupForm } from "@intent-ai/mandal-npm-lib-v2/dist/forms";
import { profileMessages } from "../../messages";
import { Loading } from "../../modules/global";
import { routerPaths } from "../../config/routes/paths";

interface IMatchParams {
  token: string;
}

interface IProps {
  checkRegistrationToken: Function;
  verifyUser: Function;
  match: match<IMatchParams>;
  history: History;
  intl: IntlShape;
  initialValues: ISignUpUser;
}

interface IState {
  message: string;
  error: string;
  isLoaded: boolean;
}

class WrappedConfirmRegistration extends Component<IProps, IState> {
  state = {
    message: "",
    isLoaded: false,
    error: "",
  };

  componentDidMount() {
    const {
      props: {
        checkRegistrationToken,
        match: {
          params: { token },
        },
        history: { push },
      },
      handleCheckTokenFail: fail,
      handleCheckTokenSuccess: success,
    } = this;
    if (!token) {
      push(routerPaths.Login);
      return;
    }
    checkRegistrationToken({ token, success, fail });
  }

  handleCheckTokenFail = (response: any) => {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;
    const {
      type: { code },
    } = response;
    let message = "";
    if (code === RT_REGISTERED_USER_VERIFIED) {
      this.redirectToLogin();
      return;
    }
    if (code === RT_TOKEN_INVALID) {
      message = formatMessage(messages.tokenInvalid);
    } else if (code === RT_TOKEN_EXPIRED) {
      message = formatMessage(messages.tokenExpired);
    }
    this.setState({ message, isLoaded: true });
  };

  handleCheckTokenSuccess = () => {};

  handleRegisterFail = (response: any) => {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;
    const {
      type: { code },
    } = response;
    let message = "";
    if (code === RT_REGISTERED_USER_VERIFIED) {
      this.redirectToLogin();
      return;
    }
    if (code === RT_TOKEN_INVALID) {
      message = formatMessage(messages.tokenInvalid);
    } else if (code === RT_TOKEN_EXPIRED) {
      message = formatMessage(messages.tokenExpired);
    }
    this.setState({ message, isLoaded: true });
  };

  handleRegisterSuccess = () => {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;
    this.setState(() => ({
      message: formatMessage(messages.confirmEmailSuccess),
    }));
  };

  redirectToLogin = () => {
    const { history } = this.props;
    history.push(routerPaths.Login);
  };

  formSubmit = (data: ISignUpUser) => {
    const {
      props: { verifyUser },
      handleRegisterFail: fail,
      handleRegisterSuccess: success,
    } = this;
    verifyUser({ data, fail, success });
  };

  render() {
    const {
      props: {
        initialValues,
        intl: { formatMessage },
      },
      state: { message, isLoaded },
      formSubmit,
    } = this;

    if (!isLoaded) {
      return <Loading />;
    }

    return (
      <AuthWrapper>
        <div>
          <div className="logo-container" />
          <div className="confirm-registration-text">
            {message ? (
              <AuthFormWrapper width={580}>
                <div>
                  <p>{message}</p>
                  <Flex customClassName="mt-1" justifyContent="center">
                    <Link to={routerPaths.Login}>
                      <Button i18nId="Auth.login" />
                    </Link>
                  </Flex>
                </div>
              </AuthFormWrapper>
            ) : (
              <SignupForm
                onSubmit={formSubmit}
                initialValues={initialValues}
                title={formatMessage(messages.completeRegistration)}
                type="dsp"
                description={formatMessage(
                  profileMessages.submitApplicationDescription
                )}
                confirmForm
              />
            )}
          </div>
        </div>
      </AuthWrapper>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  initialValues: select.authentication.selectRegisterUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  checkRegistrationToken: dispatch.authentication.checkRegistrationToken,
  verifyUser: dispatch.authentication.verifyUser,
});

const ConfirmRegistration = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WrappedConfirmRegistration));

ConfirmRegistration.displayName = "ConfirmRegistration";

export default ConfirmRegistration;
