import React, { Component } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { ITransaction } from "../../../types";
import { transactionsMessages } from "../../../messages";
import { Table } from "@intent-ai/mandal-npm-lib-v2";
import { TableNotFound } from "../../../components";
import { getPaymentPDFInvoice } from "../../../api";
import { formatDate, numberToStringWithCommas } from "../../../modules";


interface IProps {
  intl: IntlShape;
  transactionsHistory: ITransaction[];
  loading: boolean;
  onRowClick: Function;
}

class TransactionsList extends Component<IProps> {
  renderNotfound = () => {
    return (
      <TableNotFound
        titleId="Transactions.transactionsNotFound"
        titleDefaultMessage="No Transactions has been made!"
        descriptionId="Transactions.transactionsNotFoundDescription"
        descriptionDefaultMessage="Try adjusting filters or choosing different date range."
      />
    );
  };

  getColumns = () => {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;
    return [
      {
        title: formatMessage(transactionsMessages.orderId),
        dataIndex: "orderId",
      },
      {
        title: formatMessage(transactionsMessages.authCode),
        dataIndex: "authCode",
      },
      {
        title: formatMessage(transactionsMessages.date),
        dataIndex: "date",
      },
      {
        title: formatMessage(transactionsMessages.contactPerson),
        dataIndex: "contactPerson",
      },
      {
        title: formatMessage(transactionsMessages.entity),
        dataIndex: "entity",
      },
      {
        title: formatMessage(transactionsMessages.tinNumber),
        dataIndex: "tinNumber",
      },
      {
        title: formatMessage(transactionsMessages.cardholder),
        dataIndex: "cardholder",
      },
      {
        title: formatMessage(transactionsMessages.cardNumber),
        dataIndex: "cardNumber",
      },
      {
        title: formatMessage(transactionsMessages.amount),
        dataIndex: "amount",
      },
      {
        title: formatMessage(transactionsMessages.invoice),
        dataIndex: "invoice",
      },
    ];
  };

  downloadPDFInvoice =
    (id: string, date: Date) => (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      getPaymentPDFInvoice(id)
        .then((response: any) => response.blob())
        .then((blob: any) => {
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([blob]));
          link.download = `payment-${formatDate(date,'MMM DD, YYYY HH:mm')}.pdf`;
          link.click();
        })
        .catch((err: any) => {
          console.error(err);
        });
    };

  getData = () => {
    const {
      props: { transactionsHistory, onRowClick },
      downloadPDFInvoice,
    } = this;
    if (!Array.isArray(transactionsHistory)) return [];
    if (transactionsHistory.length === 0) return [];

    return (
      transactionsHistory &&
      transactionsHistory.map((transaction) => {
        const {
          amount,
          card_number,
          cardholder_name,
          order_id,
          payment_id,
          space_name,
          transaction_date,
          auth_code,
          company_tin,
          entity_name,
        } = transaction;

        const onInvoiceClick = downloadPDFInvoice(payment_id, transaction_date);
        const handleTransactionClick = () => {
          onRowClick(transaction);
        };
        return {
          order_id,
          auth_code,
          transaction_date: <p>{formatDate(transaction_date,'MMM DD, YYYY HH:mm')}</p>,
          space_name,
          entity_name: entity_name ? entity_name : "-",
          company_tin: company_tin ? company_tin : "-",
          cardholder_name,
          card_number,
          amount: <strong> {`$ ${numberToStringWithCommas(amount, 2)}`}</strong>,
          image: (
            <div onClick={onInvoiceClick}>
              {/*<IntIcon*/}
              {/*  type="download_icon"*/}
              {/*  style={{*/}
              {/*    width: "28px",*/}
              {/*    height: "30px",*/}
              {/*    fill: "var(--primary_color)",*/}
              {/*    cursor: "pointer",*/}
              {/*  }}*/}
              {/*/>*/}
            </div>
          ),
          onClick: handleTransactionClick,
        };
      })
    );
  };

  render() {
    const {
      props: { loading },
      getColumns,
      getData,
    } = this;
    return (
      <div>
        <Table
          notFound={this.renderNotfound()}
          borderBottom
          columns={getColumns()}
          data={getData()}
          firtsFullWidth
          showLoading
          isLoading={loading}
        />
      </div>
    );
  }
}

export default injectIntl(TransactionsList);
