import React, { Component } from "react";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import {
  ContentWrapper,
  FileInputFiled,
  Button,
  ColorPickerField,
  MaiRow,
  ValidatableInputField,
} from "@intent-ai/mandal-npm-lib-v2";
import { injectIntl, IntlShape } from "react-intl";
import {
  MY_ACCOUNT_CUSTOMIZE_FORM,
  FAVICON_SMALL_SIZE,
  FAVICON_MEDIUM_SIZE,
  FAVICON_BIG_SIZE,
  imageTypes,
  AUTH_SMALL_BACKGROUND,
  AUTH_BIG_BACKGROUND,
  EMAIL_SMALL_BACKGROUND,
  EMAIL_BIG_BACKGROUND,
  AD_LOGO_SIZE,
} from "../../../_constants";
import { profileMessages } from "../../../messages";
// import validate from "./validate";
import { IUserCustomization } from "../../../types";
import "./style.scss";

interface IProps {
  intl: IntlShape;
  companyName: string;
}

interface InjectedProps extends InjectedFormProps<IUserCustomization, IProps> {}
interface ComponentProps extends IProps, InjectedProps {}

class WrappedMyAccountCustomizeForm extends Component<ComponentProps> {
  componentDidMount() {
    const {
      props: { initialValues, initialize },
    } = this;
    initialize(initialValues);
  }

  render() {
    const {
      handleSubmit,
      intl: { formatMessage },
      companyName,
    } = this.props;

    return (
      <ContentWrapper>
        <form onSubmit={handleSubmit}>
          <div>
            <ValidatableInputField
              component={ValidatableInputField}
              label={formatMessage(profileMessages.companyName)}
              withLabel
              bottomBorder
              disabled
              currentValue={companyName}
            />
            <Field
              name="company_logo"
              component={FileInputFiled}
              label={formatMessage(profileMessages.logoLabel)}
              info={formatMessage(profileMessages.logoInfo)}
              withLabel
              bottomBorder
              supportedFormats={[imageTypes["png"], imageTypes["svg+xml"]]}
              supportedSizes={['200x200']}
              validateSize={true}
              validWidth={200}
              validHeight={200}
            />

            <Field
              name="main_background"
              component={FileInputFiled}
              label={formatMessage(profileMessages.authBackgroundLabel)}
              info={formatMessage(profileMessages.authBackgroundInfo)}
              withLabel
              bottomBorder
              supportedSizes={[AUTH_SMALL_BACKGROUND, AUTH_BIG_BACKGROUND]}
              supportedFormats={[
                imageTypes["png"],
                imageTypes["webp"],
                imageTypes["jpg"],
                imageTypes["jpeg"],
              ]}
            />
            <Field
              name="email_header"
              component={FileInputFiled}
              label={formatMessage(profileMessages.emailHeaderLabel)}
              info={formatMessage(profileMessages.emailHeaderInfo)}
              withLabel
              bottomBorder
              supportedSizes={[EMAIL_SMALL_BACKGROUND, EMAIL_BIG_BACKGROUND]}
              supportedFormats={[
                imageTypes["png"],
                imageTypes["webp"],
                imageTypes["jpg"],
                imageTypes["jpeg"],
              ]}
            />

            <Field
              name="favicon"
              component={FileInputFiled}
              label={formatMessage(profileMessages.faviconLabel)}
              info={formatMessage(profileMessages.faviconInfo)}
              withLabel
              bottomBorder
              supportedFormats={[
                imageTypes["png"],
                imageTypes["vnd.microsoft.icon"],
              ]}
              supportedSizes={[
                FAVICON_SMALL_SIZE,
                FAVICON_MEDIUM_SIZE,
                FAVICON_BIG_SIZE,
              ]}
            />
            <Field
              name="fb_page_id"
              component={ValidatableInputField}
              label={formatMessage(profileMessages.facebookPageIdLabel)}
              withLabel
              bottomBorder
            />
            <Field
              name="term_of_service_url"
              component={ValidatableInputField}
              label={formatMessage(profileMessages.customizeTermsOfService)}
              withLabel
              bottomBorder
            />
            <Field
              name="privacy_policy_url"
              component={ValidatableInputField}
              label={formatMessage(profileMessages.customizePrivacyPolicy)}
              withLabel
              bottomBorder
            />
            <Field
              name="ad_logo"
              component={FileInputFiled}
              label={formatMessage(profileMessages.customizeAdLogo)}
              info={formatMessage(profileMessages.customizeAdLogoInfo)}
              withLabel
              bottomBorder
              validWidth={98}
              validHeight={32}
              supportedFormats={[
                imageTypes["png"],
                imageTypes["vnd.microsoft.icon"],
              ]}
              supportedSizes={[AD_LOGO_SIZE]}
            />
            <MaiRow>
              <MaiRow.Col
                size={12}
                customClassName="my-account-customize-color-container"
              >
                <Field
                  name="primary_color"
                  component={ColorPickerField}
                  label={formatMessage(profileMessages.color1)}
                />
                <Field
                  name="secondary_color"
                  component={ColorPickerField}
                  label={formatMessage(profileMessages.color2)}
                />
                <Field
                  name="gauge_color"
                  component={ColorPickerField}
                  label={formatMessage(profileMessages.color3)}
                  right
                />
                <Field
                  name="warning_color"
                  component={ColorPickerField}
                  label={formatMessage(profileMessages.color4)}
                  right
                />
              </MaiRow.Col>
            </MaiRow>
          </div>
          <ContentWrapper.Footer contentRight>
            <Button buttonType="submit" i18nId="ButtonLabel.saveChanges" />
          </ContentWrapper.Footer>
        </form>
      </ContentWrapper>
    );
  }
}

export default injectIntl(
  reduxForm<IUserCustomization, IProps>({
    form: MY_ACCOUNT_CUSTOMIZE_FORM,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    // validate,
  })(WrappedMyAccountCustomizeForm)
);
