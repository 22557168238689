import { Models } from "@rematch/core";
import { auth } from "./auth";
import { dictionary } from "./dictionary";
import { campaign } from "./campaign.model";
import { payment } from "./payment.model";
import { reports, reportFilterInitialValues } from "./reports.model";
import { space } from "./space.model";
import { admin } from "./admin.model";
import { dialog } from "./dialog.model";
import { transactions } from "./transactions.model";
import { audience} from "./audience.model"


export interface RootModel extends Models {
  auth: typeof auth;
  dictionary: typeof dictionary;
  campaign: any;
  payment: typeof payment;
  reports: typeof reports;
  space: typeof space;
  admin: typeof admin;
  dialog: typeof dialog;
  transactions: typeof transactions;
  audience: typeof audience;
}

export const models: RootModel = {
  auth,
  dictionary,
  campaign,
  payment,
  reports,
  space,
  admin,
  dialog,
  transactions,
  audience,
};

export { reportFilterInitialValues };
