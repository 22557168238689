import React, { Component } from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
import {
  IReportPlatformsData,
  IReportPublisherData,
  IReportBannersData,
  IDictionaryField,
  IReportSingleValue,
  IReportTotals,
} from "../../../types";
import { ReportsSingleDetailsContainer } from "../../../containers";
import { Flex } from "@intent-ai/mandal-npm-lib-v2";
import { Dispatch, IRootState, select } from "../../../store";
import { bindActionCreators } from "redux";
import { REPORTS_FORM } from "../../../_constants";
import { injectIntl, IntlShape } from "react-intl";
import { reportMessages } from "../../../messages";

interface IProps {
  reportPlatformData: IReportPlatformsData;
  reportPublisherData: IReportPublisherData;
  reportBannersData: IReportBannersData;
  change: Function;
  dictDeviceTypes: IDictionaryField[];
  visualsBanners: IDictionaryField[];
  reportPlatformDataIsLoading: boolean;
  reportPublisherDataIsLoading: boolean;
  reportBannersIsLoading: boolean;
  intl: IntlShape;
  reportTotals: IReportTotals;
  reportDataIsLoading: boolean;
  isInVideo: boolean;
}

class ReportsDetailContainer extends Component<IProps> {
  handlePlatformClick = (e: any) => {
    const {
      props: { change },
    } = this;
    if (e.type) {
      change(REPORTS_FORM, "device_type_key", e.type);
    }
  };

  handlePublisherClick = ({ value, name }: IReportSingleValue) => {
    const {
      props: { change },
    } = this;
    if (name) {
      change(REPORTS_FORM, "publisher", name);
    }
  };

  handleVisualSizeClick = ({ value, name }: IReportSingleValue) => {
    const {
      props: { change, visualsBanners },
    } = this;

    if (name) {
      const banner = visualsBanners.find(
        ({ title }) => title === name.replace("×", "x")
      );
      if (banner?.value) {
        change(REPORTS_FORM, "visual_size_key", banner.value);
      }
    }
  };

  handleVisualTypeClick = ({ value, name, type }: any) => {
    const {
      props: { change },
    } = this;

    if (type) {
      change(REPORTS_FORM, "ad_type_key", type);
    }
  };

  render() {
    const {
      props: {
        intl: { formatMessage },
        reportPlatformData,
        reportPublisherData,
        reportBannersData,
        reportPlatformDataIsLoading,
        reportPublisherDataIsLoading,
        reportBannersIsLoading,
        reportDataIsLoading,
        isInVideo,
      },
      handlePlatformClick,
      handlePublisherClick,
      handleVisualSizeClick,
      handleVisualTypeClick,
    } = this;

    return (
      <Flex customClassName="reports-detail-container">
        <ReportsSingleDetailsContainer
          data={reportPlatformData}
          title={formatMessage(reportMessages.detailsPlatformAndOS)}
          titleTooltip={formatMessage(
            reportMessages.detailsPlatformAndOSTooltip
          )}
          types={[
            {
              value: "impressions",
              title: formatMessage(reportMessages.impression),
            },
            {
              value: "clicks",
              title: formatMessage(reportMessages.clicks),
            },
          ]}
          dataKey="os"
          versusDataKey="platforms"
          onClick={handlePlatformClick}
          loading={reportPlatformDataIsLoading || reportDataIsLoading}
          showTypes
          isPieChart
        />
        <ReportsSingleDetailsContainer
          data={reportPublisherData}
          title={formatMessage(reportMessages.detailsTopPublishers)}
          titleTooltip={formatMessage(
            reportMessages.detailsTopPublishersTooltip
          )}
          types={[
            {
              value: "impressions",
              title: formatMessage(reportMessages.impression),
            },
            {
              value: "clicks",
              title: formatMessage(reportMessages.clicks),
            },
          ]}
          dataKey="value"
          onClick={handlePublisherClick}
          loading={reportPublisherDataIsLoading || reportDataIsLoading}
          showTypes
          isBarChart
          showSort
        />
        {isInVideo || (
          <ReportsSingleDetailsContainer
            data={reportBannersData}
            title={formatMessage(reportMessages.detailsTopBannerSizesAndTypes)}
            titleTooltip={formatMessage(
              reportMessages.detailsTopBannerSizesAndTypesTooltip
            )}
            types={[
              {
                value: "impressions",
                title: formatMessage(reportMessages.impression),
              },
              {
                value: "clicks",
                title: formatMessage(reportMessages.clicks),
              },
            ]}
            dataKey="values"
            showTotalInCenter
            onClick={handleVisualSizeClick}
            loading={reportBannersIsLoading || reportDataIsLoading}
            showTypes
            isPieChart
            isPercentageChart
            percentageKey="ad_types"
            handlePercentageClick={handleVisualTypeClick}
          />
        )}
      </Flex>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  reportPlatformDataIsLoading: select.reports.reportPlatformDataIsLoadingSelector(
    state
  ),
  reportPublisherDataIsLoading: select.reports.reportPublisherDataIsLoadingSelector(
    state
  ),
  reportBannersIsLoading: select.reports.reportBannersIsLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  change: bindActionCreators(change, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ReportsDetailContainer));
