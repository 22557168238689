import { ApiService } from "../../global/services";

class DictService {
  static ENDPOINT = `${window.REACT_APP_BASE_URL}/v1/dictionary`;

  static cache: { [key: string]: any } = {};

  static getDictionary() {
    const URL = `${DictService.ENDPOINT}/en_US`;

    if (DictService.cache[URL]) {
      return DictService.cache[URL];
    }

    DictService.cache[URL] = ApiService.get(URL);
    return DictService.cache[URL];
  }
}

export default DictService;