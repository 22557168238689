import { createModel } from "@rematch/core";
import {
  TreeDataUtils,
  FetchUtils,
  ObjectUtils,
} from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { CAMPAIGN_FORM, PAUSED, RUNNING} from "../_constants";
import { formValueSelector } from "redux-form";
import { IStore, select, IRootState } from "../store";
import {
  ICampaign,
  ICampaignCpmData,
  ICampaignPolygons,
  ICampaignTarget,
  ICampaignAudienceSize,
  ICampaignFilter,
  ICampaignList,
  IVisualsImage,
  ICampaignListData,
  ICampaignBanner,
} from "../types";
import {
  cpmEstimationRequest,
  getLocationCoordinates,
  getAudienceSizeRequest,
  updateCampaignRequest,
  getFilteredSpaceCampaigns,
  getCampaignRequest,
  saveAndPublishCampaignRequest,
  publishCampaignRequest,
  runCampaignRequest,
  pauseCampaignRequest,
  getSpaceCampaignCountRequest,
  archiveCampaignRequest,
  invoice,
} from "../api";
import { initialAgeGroupData } from "../utils";

const AUDIENCE_SIZE = "AUDIENCE_SIZE";

// const { get: LSGet, update: LSSet, delete: LSDelete } = WebStorageUtils;

const getAdaptiveValue = (value = "") => encodeURIComponent(value);

const initialValues = {
  interactive: false,
  budget_type_key: 2,
  frequencyCap: false,
  setScheduling: false,
  device_type_key: 1,
  scheduling: {},
  exchange_id: [],
  similarity: 1,
  dynamic_ad: {
    titles: [],
    descriptions: [],
    call_to_action: "",
    company_name: "",
    images: [],
    logos: [],
  },
  targeting: {
    gender: 1,
    locations: {
      include: [],
      exclude: [],
    },
    interests: {
      include: [],
      exclude: [],
      includeAny: [],
    },
    behaviors: {
      include: [],
      exclude: [],
    },
    languages: {
      include: [],
      exclude: [],
      includeAny: [],
    },
    apps: {
      include: [],
      exclude: [],
      includeAny: [],
    },
    websites: {
      include: [],
      exclude: [],
      includeAny: [],
    },
    fromAge: 18,
    toAge: 65,
    location_option_key: "1",
  },
  excluded_categories: [],
  custom_audience: [],
  placement: {
    device_type_key: 1,
    min_os_version: 1,
    max_os_version: 5,
  },
  frequency_cap: {
    1: "",
    2: "",
    3: "",
  },
  visuals: {
    adaptive: {},
  },
  allow_adaptive: true,
  optimization_type_key: 1,
  // budget: 0,
};

const { formatValueForTreeData } = TreeDataUtils;
const { makeRequest } = FetchUtils;
const { mergeDeep } = ObjectUtils;

const selector = formValueSelector(CAMPAIGN_FORM);

export interface CampaignState {
  campaignId: string;
  cpmData: ICampaignCpmData;
  polygons: ICampaignPolygons[];
  audienceEstimatedSize: ICampaignAudienceSize;
  campaigns: ICampaignList;
  campaign: ICampaign;
  campaignIsLoading: boolean;
  campaignsIsLoading: boolean;
  cpmDataIsLoading: boolean;
  isSaveCampaignsLoaded: boolean;
  isPublishCampaignsLoaded: boolean;
  hasCampaign: null | boolean;
  audienceEstimatedSizeIsLoading: boolean;
  invoice: any;
}

const initialState: CampaignState = {
  campaignId: "",
  cpmData: {},
  cpmDataIsLoading: true,
  polygons: [],
  audienceEstimatedSize: {
    total: 1,
    estimate: 0,
    estimate_impressions: 1,
    total_impressions: 1,
  },
  campaigns: {
    data: [],
    current_page: 0,
    page_size: 10,
    total_size: 10,
  },
  campaign: {},
  isPublishCampaignsLoaded: false,
  isSaveCampaignsLoaded: false,
  campaignIsLoading: true,
  campaignsIsLoading: true,
  hasCampaign: null,
  audienceEstimatedSizeIsLoading: true,
  invoice: [],
};

export const campaign: any = createModel({
  name: "campaign",
  state: initialState as CampaignState,
  reducers: {
    setInvoiceData: (state: CampaignState, invoice: any): CampaignState => ({
      ...state,
      invoice,
    }),
    setCpmData: (state, cpmData: ICampaignCpmData): CampaignState => ({
      ...state,
      cpmData,
      cpmDataIsLoading: false,
    }),
    setPublishCampaignsLoaded: (state, value: any): CampaignState => ({
      ...state,
      isPublishCampaignsLoaded: value,
    }),
    setSaveCampaignsLoaded: (state, value: any): CampaignState => ({
      ...state,
      isSaveCampaignsLoaded: value,
    }),
    setPolygonsData: (state, polygons: ICampaignPolygons[]): CampaignState => ({
      ...state,
      polygons,
    }),
    setCampaigns: (state, campaigns: ICampaignList): CampaignState => ({
      ...state,
      campaigns,
      campaignsIsLoading: false,
    }),
    setCampaign: (state, campaign: ICampaign): CampaignState => ({
      ...state,
      campaign,
      campaignIsLoading: false,
    }),
    nullifyCampaign: (state): CampaignState => ({
      ...state,
      campaign: {},
      campaignIsLoading: false,
      cpmDataIsLoading: true,
      audienceEstimatedSizeIsLoading: true,
    }),
    nullifyCampaigns: (state): CampaignState => ({
      ...state,
      campaigns: initialState.campaigns,
      campaignsIsLoading: true,
    }),
    cmpaignIsLoadingStart: (state): CampaignState => ({
      ...state,
      campaignIsLoading: true,
    }),
    setAudienceEstimatedSizeData: (
      state,
      audienceSize: ICampaignAudienceSize
    ): CampaignState => ({
      ...state,
      audienceEstimatedSize: audienceSize,
    }),
    setAudienceEstimatedSizeDataIsLoading: (
      state,
      audienceEstimatedSizeIsLoading: boolean
    ): CampaignState => ({
      ...state,
      audienceEstimatedSizeIsLoading,
    }),
    setHasCampaign: (state, hasCampaign: boolean): CampaignState => ({
      ...state,
      hasCampaign,
    }),
  },
  selectors: (slice, createSelector, hasProps): any => ({
    toAgeSelector() {
      return (rootState: IStore): string => {
        return selector(rootState, "ageGroup.to");
      };
    },
    publishCampaignsLoadedSelector() {
      return (rootState: IStore): any =>
        rootState.campaign.isPublishCampaignsLoaded;
    },
    saveCampaignsLoadedSelector() {
      return (rootState: IStore): any =>
        rootState.campaign.isSaveCampaignsLoaded;
    },
    adaptiveDataSelector() {
      return (rootState: IStore): any => {
        const visuals = selector(rootState, "visuals");
        if (visuals && visuals.adaptive) {
          let data = "";
          const {
            title,
            description,
            description_2,
            cta_text,
            company_name,
            banners,
          } = visuals.adaptive;

          if (
            !title &&
            !description &&
            !description_2 &&
            !cta_text &&
            !company_name
          ) {
            return "";
          }
          data = `title=${getAdaptiveValue(title)}&desc=${getAdaptiveValue(
            description
          )}&desc2=${getAdaptiveValue(
            description_2
          )}&cta_text=${getAdaptiveValue(
            cta_text
          )}&company_name=${getAdaptiveValue(company_name)}`;
          if (banners) {
            banners.forEach(
              ({ file_id, width, height, deleted }: IVisualsImage) => {
                if (deleted) return false;
                if (width === 450 && height === 450) {
                  data += `&img_id=${file_id}`;
                }
                if (width === 64 && height === 64) {
                  data += `&company_logo_id=${file_id}`;
                }
                if (width === 450 && height === 150) {
                  data += `&horizontalImage=${file_id}`;
                }
              }
            );
          }
          return data;
        }
        return "";
      };
    },
    typeKeySelector() {
      return createSelector(
        this.bannersDataSelector,
        (bannersData: ICampaignBanner[]) => {
          let isBanner = false;
          if (bannersData) {
            isBanner = bannersData.some((el: any) => el.type_key === 2);
          }
          return isBanner;
        }
      );
    },
    bannersDataSelector() {
      return (rootState: IStore): string => {
        return selector(rootState, "visuals.banners");
      };
    },
    fromAgeSelector() {
      return (rootState: IStore): string =>
        selector(rootState, "ageGroup.from");
    },
    campaignTargerSelector() {
      return (rootState: IStore): ICampaignTarget => {
        return selector(rootState, "targeting");
      };
    },
    ageGroupFromSelector() {
      return createSelector(this.toAgeSelector, (toAge) => {
        const ageData = initialAgeGroupData();
        if (!toAge) {
          return formatValueForTreeData(ageData);
        }

        const index = ageData.indexOf(String(toAge));
        ageData.splice(index + 1);
        return formatValueForTreeData(ageData);
      });
    },
    ageGroupToSelector() {
      return createSelector(this.fromAgeSelector, (fromAge) => {
        const ageData = initialAgeGroupData();
        ageData.pop();
        ageData.push("65+");
        if (!fromAge) {
          return formatValueForTreeData(ageData);
        }
        const index = ageData.indexOf(String(fromAge));
        return formatValueForTreeData(ageData.splice(index));
      });
    },
    cpmDataSelector() {
      return (rootState: IStore) => {
        const optimizationTypeKey = selector(
          rootState,
          "optimization_type_key"
        );
        const avgCpm =
          Number(optimizationTypeKey) === 1
            ? rootState?.campaign?.cpmData?.imp_cpm
            : Number(optimizationTypeKey) === 2
            ? rootState?.campaign?.cpmData?.clk_cpm
            : rootState?.campaign?.cpmData?.avg_cpm;
        return {
          ...rootState?.campaign?.cpmData,
          avgCpm: avgCpm || 0,
          minCpm: rootState?.campaign?.cpmData?.min_cpm || 0,
          maxCpm: rootState?.campaign?.cpmData?.max_cpm || 0,
        };
      };

      // return slice((campaign: CampaignState) => {
      //   return
      // });
    },
    polygonsDataSelector() {
      return (rootState: any) => rootState.campaign.polygons;
    },
    invoiceDataSelector() {
      return (rootState: any) => rootState.campaign.invoice;
    },
    campaignIsLoadingSelector() {
      return (rootState: any) => rootState.campaign.campaignIsLoading;
    },
    getCampaignSelector() {
      return (rootState: any) => rootState.campaign.campaign;
    },
    audienceSizeDataSelector() {
      return (rootState: any) => rootState.campaign.audienceEstimatedSize;
    },
    audienceEstimatedSizeIsLoadingSelector() {
      return (rootState: any) =>
        rootState.campaign.audienceEstimatedSizeIsLoading;
    },
    campaignListDataSelector() {
      return (rootState: any) => rootState.campaign.campaigns;
    },
    getCampaignsIsLoadingSelector() {
      return (rootState: any) => rootState.campaign.campaignsIsLoading;
    },
    getCpmDataIsLoadingSelector() {
      return (rootState: any) => rootState.campaign.cpmDataIsLoading;
    },
    hasCampaignSelector() {
      return (rootState: any) => rootState.campaign.hasCampaign;
    },
    getCampaignIsLoadingSelector() {
      return (rootState: any) => rootState.loading.effects.campaign.getCampaign;
    },
    initialValuesSelector() {
      return createSelector(
        [this.getCampaignIsLoadingSelector, this.getCampaignSelector],
        (isCampaignLoadeding, campaign: ICampaign) => {
          if (!isCampaignLoadeding && Object.keys(campaign).length) {
            return mergeDeep({ ...initialValues }, { ...campaign });
          }
          return initialValues;
        }
      );
    },
    ResponsiveDataForPreviewSelecttor() {
      return createSelector(
        [this.getCampaignIsLoadingSelector, this.getCampaignSelector],
        (isCampaignLoadeding, campaign: ICampaign) => {
          if (!isCampaignLoadeding && Object.keys(campaign).length) {
            return mergeDeep({ ...initialValues }, { ...campaign });
          }
          return initialValues;
        }
      );
    },
  }),
  effects: (dispatch) => ({
    async archiveCampaign({ id = "", success, fail }, store) {
      await makeRequest(archiveCampaignRequest(id), {
        success: (resp: ICampaign) => {
          const campaigns: ICampaignList =
            select.campaign.campaignListDataSelector(store);
          const newCampaignList = campaigns.data.filter((e) => e.id !== id);
          dispatch.campaign.setCampaigns({
            ...campaigns,
            data: newCampaignList,
          });
          success(resp);
        },
      });
    },
    async getInvoiceData({ id = "", success, fail }, store) {
      await makeRequest(invoice(id), {
        success: (resp: any) => {
          dispatch.campaign.setInvoiceData(resp);
          success(resp);
        },
      });
    },
    async getCpmEstimation({ type = 1, success, fail }) {
      await makeRequest(cpmEstimationRequest(type), {
        success: (data: ICampaignCpmData) => {
          success(data);
          dispatch.campaign.setCpmData(data);
        },
      });
    },
    async getLocationCoordinates(data: number[]) {
      await makeRequest(getLocationCoordinates(data), {
        success: (data: ICampaignPolygons) => {
          dispatch.campaign.setPolygonsData(data);
        },
      });
    },
    async getAudienceSize(data: ICampaignTarget, store: IStore) {
      dispatch.campaign.setAudienceEstimatedSizeDataIsLoading(true);
      await makeRequest(getAudienceSizeRequest(data), {
        success: (data: ICampaignAudienceSize) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const key = `${AUDIENCE_SIZE}_${store.campaign.campaign.id}`;
          dispatch.campaign.setAudienceEstimatedSizeData(data);
          dispatch.campaign.setAudienceEstimatedSizeDataIsLoading(false);
          //     if (data.estimate > 1) {
          //       LSDelete(key);

          //     } else {
          //       LSSet(key, JSON.stringify(store.campaign.audienceEstimatedSize));
          // dispatch.campaign.setAudienceEstimatedSizeDataIsLoading(false);

          //       if (LSGet(key)) {
          //         dispatch.campaign.setAudienceEstimatedSizeData(
          //           LSGet(JSON.parse(key))
          //         );
          // dispatch.campaign.setAudienceEstimatedSizeDataIsLoading(false);

          //       }
          //     }
          // dispatch.campaign.setAudienceEstimatedSizeDataIsLoading(false);
        },
        fail: () => {
          dispatch.campaign.setAudienceEstimatedSizeDataIsLoading(false);
        },
      });
    },
    async updateCampaign({ campaign, success, fail, isPublish }: any, store) {
      if (!isPublish) {
        dispatch.campaign.setSaveCampaignsLoaded(true);
      } else {
        dispatch.campaign.setPublishCampaignsLoaded(true);
      }
      const spaceId = select.authentication.selectCurrentUserSpaceId(store);
      await makeRequest(
        updateCampaignRequest({
          space_id: spaceId,
          ...({
            ...campaign,
            targeting: {
              ...campaign.targeting,
              apps: {
                include: campaign.targeting.apps.include.map((target: any) => ({ id: target.id })),
                exclude: campaign.targeting.apps.exclude.map((target: any) => ({ id: target.id })),
                includeAny: campaign.targeting.apps.includeAny.map((target: any) => ({ id: target.id })),
              },
            }
          }),
          manual_cpm: true,
        }),
        {
          success: (data: ICampaign) => {
            success(data);
            dispatch.campaign.setSaveCampaignsLoaded(false);
            dispatch.campaign.setHasCampaign(true);
          },
          fail: (data: any) => {
            if (fail) {
              fail(data);
            }
            dispatch.campaign.setPublishCampaignsLoaded(false);
            dispatch.campaign.setSaveCampaignsLoaded(false);
          },
        }
      );
    },
    async saveAndPublishCampaign({ campaign, success, fail }: any) {
      dispatch.campaign.setPublishCampaignsLoaded(true);
      const data = {
        ...campaign,
        targeting: {
          ...campaign.targeting,
          apps: {
            include: campaign.targeting.apps.include.map((target: any) => ({ id: target.id })),
            exclude: campaign.targeting.apps.exclude.map((target: any) => ({ id: target.id })),
            includeAny: campaign.targeting.apps.includeAny.map((target: any) => ({ id: target.id })),
          },
        }
      };
      await makeRequest(saveAndPublishCampaignRequest(data), {
        success: (data: any) => {
          dispatch.campaign.setPublishCampaignsLoaded(false);
          if (success) success(data);
        },
        fail: (data: any) => {
          dispatch.campaign.setPublishCampaignsLoaded(false);
          if (fail) fail(data);
        },
      });
    },
    async publishCampaign({ id, success, fail }: any) {
      dispatch.campaign.setPublishCampaignsLoaded(true);

      await makeRequest(publishCampaignRequest(id), {
        success: (data: any) => {
          dispatch.campaign.setPublishCampaignsLoaded(false);
          if (success) success(data);
        },
        fail: (data: any) => {
          dispatch.campaign.setPublishCampaignsLoaded(false);
          if (fail) fail(data);
        },
      });
    },
    async getCampaigns(data: ICampaignFilter, store: IRootState) {
      const currentSpaceId = store.authentication.space.id;
      await makeRequest(
        getFilteredSpaceCampaigns({ ...data, space_id: currentSpaceId }),
        {
          success: (data: ICampaignList) => {
            dispatch.campaign.setCampaigns(data);
          },
        }
      );
    },
    async hasCampaign(data, store: IRootState) {
      const currentSpaceId = store.authentication.space.id;
      if (!currentSpaceId) {
        dispatch.campaign.setHasCampaign(false);
        return;
      }
      await makeRequest(getSpaceCampaignCountRequest(currentSpaceId), {
        success: (data: number) => {
          if (data) {
            dispatch.campaign.setHasCampaign(true);
          } else {
            dispatch.campaign.setHasCampaign(false);
          }
        },
      });
    },
    async getCampaign({ id, success, fail }, store: IStore) {
      dispatch.campaign.cmpaignIsLoadingStart();
      const spaceId = select.authentication.selectCurrentUserSpaceId(store);
      await makeRequest(getCampaignRequest(id), {
        success: (data: ICampaign) => {
          if (data.space_id !== spaceId) {
            dispatch.admin.setAdminNewSpace({ id: data.space_id });
          }
          dispatch.campaign.setCampaign({
            ...data,
            similarity: data.targeting?.similarity,
          });
          success();
        },
      });
    },

    async runCampaign({ id, success, fail }, state) {
      await makeRequest(runCampaignRequest(id), {
        success: (campaign: ICampaign) => {
          if (campaign.id) {
            dispatch.campaign.setCampaignListItemStatus({
              id,
              status: RUNNING,
            });
          }
          if (success) {
            success(campaign);
          }
        },
        fail,
      });
    },
    async pauseCampaign({ id, success, fail }, state) {
      await makeRequest(pauseCampaignRequest(id), {
        success: (campaign: ICampaign) => {
          if (campaign.id) {
            dispatch.campaign.setCampaignListItemStatus({
              id: campaign.id,
              status: PAUSED,
            });
          }
          if (success) {
            success(campaign);
          }
        },
        fail,
      });
    },
    async setCampaignListItemStatus({ id, status, success, fail }, state) {
      const campaigns = select.campaign.campaignListDataSelector(state);
      const newCampaigns = setCampaignStatus(campaigns, id, status);
      dispatch.campaign.setCampaigns(newCampaigns);
    },
    async setCampaignListItem({ campaign, status, success, fail }, state) {
      const campaigns = select.campaign.campaignListDataSelector(state);
      const newCampaigns = setCampaignListItem(campaigns, campaign);
      dispatch.campaign.setCampaigns(newCampaigns);
    },
    async createSimilarCampaign({ type, success, fail }, state) {
      const initialCampaign: ICampaign =
        select.campaign.initialValuesSelector(state);
      initialCampaign.name += " - Copy";
      if (type !== initialCampaign.campaign_type_key)
        delete initialCampaign.visuals;
      initialCampaign.replicated_from = initialCampaign.id;
      delete initialCampaign.id;
      delete initialCampaign.bids;
      delete initialCampaign.budget_spent;
      delete initialCampaign.clicks;
      delete initialCampaign.daily_budget_spent;
      delete initialCampaign.deleted_at;
      delete initialCampaign.impressions;
      delete initialCampaign.last_run_at;
      delete initialCampaign.moderation_rejected_at;
      delete initialCampaign.moderation_start_at;
      delete initialCampaign.moderator_comment;
      delete initialCampaign.need_moderation;
      delete initialCampaign.revenue_percentage;
      delete initialCampaign.report;
      delete initialCampaign.status_key;
      delete initialCampaign.updated_at;
      delete initialCampaign.created_at;
      delete initialCampaign.campaign_start_at;
      initialCampaign.campaign_type_key = type;
      dispatch.campaign.setCampaign(initialCampaign);
      success(initialCampaign);
    },
  }),
});

const setCampaignStatus = (
  campaigns: ICampaignList,
  id: string,
  status_key: number
): ICampaignList => {
  return {
    ...campaigns,
    data: campaigns.data.map((campaign: ICampaignListData) => {
      if (campaign.id && campaign.id === id) {
        return { ...campaign, status_key };
      }
      return campaign;
    }),
  };
};

const setCampaignListItem = (
  campaigns: ICampaignList,
  newCampaign: ICampaignListData
): ICampaignList => {
  return {
    ...campaigns,
    data: campaigns.data.map((campaign: ICampaignListData) => {
      if (campaign.id && campaign.id === newCampaign.id) {
        return newCampaign;
      }
      return campaign;
    }),
  };
};
