import React, { Component } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { billingMessages } from "../../../messages";
import { Table } from "@intent-ai/mandal-npm-lib-v2";
import { PaymentSpaceAndImage, TableNotFound } from "../../../components";

import "./style.scss";
import {
  PAYMENT_STATUSES,
} from "../../../_constants";
import InvoicePDF from "../../../components/InvoicePDF/InvoicePDF";
import { pdf } from "@react-pdf/renderer";
import { formatDate, numberToStringWithCommas } from "../../../modules";


interface IProps {
  intl: IntlShape;
  paymentData: any;
  loading: boolean;
  getInvoiceData: Function;
}
const generatePdfDocument = async (documentData: any) => {
  const blob = await pdf(<InvoicePDF invoice={documentData} />).toBlob();
  return blob;
};
class RefillBalanceHistory extends Component<IProps> {
  renderNotfound = () => {
    return (
      <TableNotFound
        titleId="Inventory.placementsListNotFound"
        titleDefaultMessage=" "
        descriptionId="Payments.paymentsListNotFoundDescription"
        descriptionDefaultMessage="No transactions has been made during the selected period."
      />
    );
  };

  getColumns = () => {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;
    return [
      {
        title: formatMessage(billingMessages.paidBy),
        dataIndex: "clicks",
      },
      {
        title: formatMessage(billingMessages.paymentDate),
        dataIndex: "name",
      },
      {
        title: formatMessage(billingMessages.amount),
        dataIndex: "budget",
      },
      {
        title: formatMessage(billingMessages.method),
        dataIndex: "impression",
      },
      {
        title: formatMessage(billingMessages.status),
        dataIndex: "status",
      },
      {
        title: formatMessage(billingMessages.invoice),
        dataIndex: "invoice",
      },
    ];
  };

  downloadPDFInvoice = (id: string, date: string) => () => {
    const {
      props: { getInvoiceData },
    } = this;

    getInvoiceData({
      id,
      success: async (data: any) => {
        const blob: any = await generatePdfDocument(data);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = `invoice.pdf`;
        link.click();
      },
    });
  };

  getData = () => {
    const {
      props: { paymentData },
      downloadPDFInvoice,
    } = this;

    if (!Array.isArray(paymentData)) return [];
    if (paymentData.length === 0) return [];
    return (
      paymentData &&
      paymentData.map(
        ({
          picture_id,
          space_name,
          card_number,
          created_at,
          total,
          user_name,
          status,
          id,
          payment_transaction,
          ...rest
        }) => {
          // const status = [
          //   BALANCE_REFILL_HISTORY_SUCCESS_ATTACHED,
          //   BALANCE_REFILL_HISTORY_SUCCESS,
          // ].includes(status_key);
          const onInvoiceClick = downloadPDFInvoice(id, created_at);
          return {
            space: (
              <PaymentSpaceAndImage
                name={user_name}
                imageId={picture_id}
                status={status}
              />
            ),
            payment_date: <p>{formatDate(created_at,'MMM DD, YYYY HH:mm')}</p>,
            total: <strong> {`$ ${numberToStringWithCommas(total,2)}`}</strong>,
            card_number: payment_transaction ? (
              <p>{`${
                payment_transaction.credit_card_type
              } · ${payment_transaction.card_number.substring(
                payment_transaction.card_number.length - 4,
                payment_transaction.card_number.length
              )} `}</p>
            ) : (
              "-"
            ),
            status: <p>{PAYMENT_STATUSES[status]}</p>,
            image: (
              <div onClick={onInvoiceClick}>
                {/*<IntIcon*/}
                {/*  type="download_icon"*/}
                {/*  style={{*/}
                {/*    width: "28px",*/}
                {/*    height: "30px",*/}
                {/*    fill: "var(--primary_color)",*/}
                {/*    cursor: "pointer",*/}
                {/*  }}*/}
                {/*/>*/}
              </div>
            ),
          };
        }
      )
    );
  };

  render() {
    const {
      props: { loading },
      getColumns,
      getData,
    } = this;
    return (
      <div className="refill-balance-history">
        <Table
          notFound={this.renderNotfound()}
          borderBottom
          columns={getColumns()}
          data={getData()}
          firtsFullWidth
          showLoading
          isLoading={loading}
        />
      </div>
    );
  }
}

export default injectIntl(RefillBalanceHistory);
