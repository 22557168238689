import { Control, SetFieldValue, useWatch } from "react-hook-form";
import React, { MouseEventHandler, useEffect, useMemo, useState } from "react";
import { BannerReportState, ReportLightDTO } from "../../types";
import { ReportService } from "../../services";
import { StatusCode } from "../../../global/types";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { customTooltip, renderCustomizedLabel } from "../PlatformReport/customized-components";
import { Box, Button, CircularProgress, List, ListItem, ListItemText, Typography } from "@mui/material";
import { style } from './style';
import { BANNER_SIZE_DICT, BANNER_SIZES_COLORS } from "../../utils";
import { useTranslation } from "react-i18next";

type Props = {
  control: Control<any>;
  selectedTab: 'impressions' | 'clicks';
  setValue: SetFieldValue<any>;
  reset: MouseEventHandler<HTMLButtonElement>;
}

function BannerReport({ control, selectedTab, setValue, reset }: Props) {
  const [bannerData, setBannerDate] = useState<BannerReportState>({
    data: null,
    isLoading: true,
  });

  const { t } = useTranslation('translation', { keyPrefix: 'reporting' });

  const formValues = useWatch({
    control,
  });

  function getBannerData(filter: ReportLightDTO) {
    setBannerDate({ data: null, isLoading: true });
    ReportService.getBannersReport(filter)
      .then(response => {
        if (response.type?.code == StatusCode.OK) { // eslint-disable-line
          setBannerDate({
            data: response.data,
            isLoading: false,
          });
        }
      })
  }

  useEffect(() => {
    if (formValues.campaignId) {
      getBannerData(ReportService.buildReportLightDTO(formValues));
    }
  }, [formValues]);

  const chartData = useMemo(() => {
    if (bannerData.data) {
      const entries = Object.entries(bannerData.data[selectedTab].values);
      return entries.map(entry => ({ name: entry[0], value: entry[1]  }));
    }
    return [];
  }, [selectedTab, bannerData.data]);

  const listItems = chartData.map((data, index) => ({
    ...data,
    fill: BANNER_SIZES_COLORS[index],
  })).filter(({ value }) => !!value);

  const showChart = !bannerData.isLoading && !!chartData.length;

  function handleChartItemClick({ name }: { name: string }) {
    setValue('visualSizeKey', BANNER_SIZE_DICT[name]);
  }

  return (
   <>
     <Box sx={style.chart}>
       {showChart ? (
         <PieChart width={320} height={340}>
           <Pie
             data={chartData}
             labelLine={false}
             dataKey='value'
             stroke='none'
             outerRadius={160}
             innerRadius={70}
             label={renderCustomizedLabel(handleChartItemClick)}
             onClick={handleChartItemClick}
           >
             {chartData.map((d, index) => (
               <Cell key={`cell-${index}`} fill={BANNER_SIZES_COLORS[index]} />
             ))}
           </Pie>
           <Tooltip content={customTooltip(selectedTab)} />
         </PieChart>
       ) : (
         <Box sx={style.noDataBox}>
           {bannerData.isLoading ? (
             <CircularProgress />
           ) : (
             <>
               <Typography sx={style.noDataAvailable}>{t('noData')}</Typography>
               <Button variant='outlined' sx={style.resetFilters} onClick={reset}>{t('resetFilters')}</Button>
             </>
           )}
         </Box>
       )}
     </Box>
     {!!listItems.length && (
       <List sx={style.sizeList}>
         {listItems.map(({ name, value, fill }) => (
           <ListItem key={name} sx={style.listItem} onClick={() => handleChartItemClick({ name })}>
             <svg
               width="16"
               height="16"
               version="1.1"
               fill={fill}
               xmlns="http://www.w3.org/2000/svg"
             >
               <rect width="16" height="16" />
             </svg>
             <ListItemText>{name.replace('_', 'x')} <strong>({value})</strong></ListItemText>
           </ListItem>
         ))}
       </List>
     )}
   </>
  );
}

BannerReport.displayName = 'BannerReport';

export default BannerReport;