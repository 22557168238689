import React, { Component } from "react";
import { connect } from "react-redux";
import { AuthUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { Dispatch } from "../../store";
import { AUTH_TOKEN, AUTH_TOKEN_EXPIRY_DATE, REFRESH_TOKEN } from "../../_constants";
import { RouteComponentProps } from "react-router-dom";
import { Loading } from "../../modules/global";

const { checkTokenIsValid } = new AuthUtils();

interface IProps extends RouteComponentProps {
  getUser: Function;
}

class TokenValidation extends Component<IProps> {
  componentDidMount() {
    const {
      props: {
        getUser,
        location: { search },
        history: { push },
      },
    } = this;
    const query = new URLSearchParams(search);
    const access_token = query.get("access_token");
    const refresh_token = query.get("refresh_token");
    const expires_in = Number(query.get("expires_in"));
    if (!access_token || !refresh_token) {
      push("/");
    } else {
      if (checkTokenIsValid(access_token)) {
        localStorage.setItem(AUTH_TOKEN, access_token);
        localStorage.setItem(REFRESH_TOKEN, refresh_token);
        const date = new Date();
        date.setSeconds(date.getSeconds() + expires_in);
        localStorage.setItem(AUTH_TOKEN_EXPIRY_DATE, date.toISOString());
        getUser({});
      } else {
        push("/");
      }
    }
  }

  render() {
    return <Loading />;
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getUser: dispatch.authentication.getUser,
});

export default connect(null, mapDispatchToProps)(TokenValidation);
