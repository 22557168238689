import { init, RematchDispatch, RematchRootState } from "@rematch/core";
import createLoadingPlugin from "@rematch/loading";
import selectPlugin from "@rematch/select";
import updatedPlugin from "@rematch/updated";
import { reducer as form, FormState } from "redux-form";
import { apiresponse } from "@intent-ai/mandal-npm-lib-v2/dist/reducers/apiresponse.reducer";
import { router } from "@intent-ai/mandal-npm-lib-v2/dist/reducers/router.reducer";
import { models } from "./models";
import thunk from "redux-thunk";
import { TopProgressMiddleware } from "./middlewares/toprogress.middleware";

const loading = createLoadingPlugin({});

const updated = updatedPlugin();

const selectPlug = selectPlugin();

const store = init({
  models,
  plugins: [loading, selectPlug, updated],
  redux: {
    middlewares: [TopProgressMiddleware, thunk],
    reducers: {
      form,
      apiresponse,
      router,
    },
    devtoolOptions: {
      actionSanitizer: (action: any) => action,
    },
  },
});

const { select , dispatch} = store;
export { select, dispatch };

export default store;

export type IRootState = RematchRootState<typeof models> & FormState;
export type Dispatch = RematchDispatch<typeof models>;
export type IStore = typeof store & IRootState;
