import { createModel } from "@rematch/core";
import { FetchUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import {
  getAdminSearchResultRequest,
  setAdminNewSpaceRequest,
  getWhitelabelCustomizationDataRequest,
  setWhitelabelCustomizationDataRequest,
  getWithdrawBalanceRequest,
  getIncomeOverviewRequest,
  sendWithdrawAmountRequest,
  approveCampaignRequest,
  rejectCampaignRequest,
  getModerationRegistrationsRequest,
  getModerationCampaignRequest,
  getModerationRegistrationRequest,
  setModerationRegistrationApproveRequest,
  getCompanyWhitelabelDataRequest,
  getWhiteLabelWithdrawHistoryRequest,
  getModerationLegalUsersRequest,
  setModerationLegalUsersApproveRequest,
  setModerationLegalUsersRejectRequest,
  resendConfirmationEmailRequest,
  getAdminAccountsTotalsRequest,
  getAdminAccountsListRequest,
  getAdminPublisherListRequest,
} from "../api";
import {
  IAdminSearchResult,
  IWhitelabel,
  IUser,
  IPaginationData,
  ICampaign,
  IAdminWithdrawHistory,
  IBaseFilters,
  IAdminAccountsTotals,
  IAdminAccountsUser,
  IAdminPublisher,
} from "../types";
import { TERMS_AND_SERVICE_URL } from "../_constants";

const { makeRequest } = FetchUtils;

export interface IAdminState {
  isModerationRegistrationApproved: boolean;
  adminSearchResult: IAdminSearchResult;
  adminSearchResultLoading: boolean;
  whiteLabelWithdrawHistory: IAdminWithdrawHistory[];
  whitelabel?: IWhitelabel;
  withdrawBalance: number;
  incomeData: any;
  modeerationUsers: null | IPaginationData<IUser[]>;
  legalEntityUsers: null | IPaginationData<IUser[]>;
  publisherList: null | IPaginationData<IAdminPublisher[]>;
  modeerationUser: null | IUser;
  moderationCampaigns: null | IPaginationData<ICampaign>;
  adminAccountsTotals: null | IAdminAccountsTotals;
  adminAccountsTotalsIsLoading: boolean;
  adminAccountsUsers: null | IPaginationData<IAdminAccountsUser[]>;
  adminAccountsUsersIsLoading: boolean;
  adminPublisherIsLoading: boolean;
}
export const admin = createModel({
  name: "admin",
  state: {
    adminSearchResult: {
      campaigns: [],
      all: [],
      accounts: [],
      campaigns_in_moderation_count: 0,
      registrants_in_moderation_count: 0,
    },
    isModerationRegistrationApproved: false,
    adminSearchResultLoading: false,
    whitelabel: undefined,
    whiteLabelWithdrawHistory: [],
    withdrawBalance: 0,
    incomeData: null,
    modeerationUsers: null,
    legalEntityUsers: null,
    moderationCampaigns: null,
    publisherList: null,
    modeerationUser: null,
    adminAccountsTotals: null,
    adminAccountsUsers: null,
    adminAccountsUsersIsLoading: false,
    adminAccountsTotalsIsLoading: false,
    adminPublisherIsLoading: false,
  } as IAdminState,
  reducers: {
    setAdminResults: (state, adminSearchResult) => ({
      ...state,
      adminSearchResult,
    }),
    setWhiteLabelWithdrawHistory: (state, history) => ({
      ...state,
      whiteLabelWithdrawHistory: history,
    }),
    setAdminResultsLoading: (state, value) => ({
      ...state,
      adminSearchResultLoading: value,
    }),
    setWhitelabel: (state, whitelabel) => ({
      ...state,
      whitelabel,
    }),
    setWithdrawBalance: (state, withdrawBalance) => ({
      ...state,
      withdrawBalance,
    }),
    setIncomeData: (state, incomeData) => ({
      ...state,
      incomeData,
    }),
    setModeerationUsers: (state, modeerationUsers) => ({
      ...state,
      modeerationUsers,
    }),
    setModeerationUser: (state, modeerationUser) => ({
      ...state,
      modeerationUser,
    }),
    setModeerationCampaigns: (state, moderationCampaigns) => ({
      ...state,
      moderationCampaigns,
    }),
    setLegalEntityUsers: (state, legalEntityUsers) => ({
      ...state,
      legalEntityUsers,
    }),
    setModerationRegistrationApproved: (state, approved) => ({
      ...state,
      isModerationRegistrationApproved: approved,
    }),
    setAdminAccountsTotals: (state, adminAccountsTotals) => ({
      ...state,
      adminAccountsTotals,
    }),
    setAdminAccountsUsersIsLoading: (state, adminAccountsUsersIsLoading) => ({
      ...state,
      adminAccountsUsersIsLoading,
    }),
    setAdminPublisherIsLoading: (state, adminPublisherIsLoading) => ({
      ...state,
      adminPublisherIsLoading,
    }),
    setAdminAccountsTotalsIsLoading: (state, adminAccountsTotalsIsLoading) => ({
      ...state,
      adminAccountsTotalsIsLoading,
    }),
    setAdminAccountsUsers: (state, adminAccountsUsers) => ({
      ...state,
      adminAccountsUsers,
    }),
    setPublisherList: (state, publisherList) => ({
      ...state,
      publisherList,
    }),
  },
  selectors: (state) => ({
    isModerationRegistrationApprovedSelector() {
      return state(
        (state: IAdminState) => state.isModerationRegistrationApproved
      );
    },
    adminSearchResultSelector() {
      return state((state: IAdminState) => state.adminSearchResult);
    },
    whiteLabelNameSelector() {
      return state((state: IAdminState) => state.whitelabel?.name);
    },
    whitelabelIdSelector() {
      return state((state: IAdminState) => state.whitelabel?.id);
    },
    whiteLabelWithdrawHistorySelector() {
      return state((state: IAdminState) => state.whiteLabelWithdrawHistory);
    },
    isAdminSearchResultsLoadingSelector() {
      return state((state: IAdminState) => state.adminSearchResultLoading);
    },
    legalEntityUsersSelector() {
      return state((state: IAdminState) => state.legalEntityUsers);
    },
    whitelabelDataSelector() {
      return state((state: IAdminState) => state.whitelabel);
    },
    whitelabelSettingsSelector() {
      return state((state: IAdminState) => state.whitelabel?.whitelabel_settings);
    },
    whitelabelCreationDateSelector() {
      return state((state: IAdminState) => state.whitelabel?.created_at);
    },
    withdrawBalanceSelector() {
      return state((state: IAdminState) => state.withdrawBalance);
    },
    incomeDataSelector() {
      return state((state: IAdminState) => state.incomeData);
    },
    moderationUsersSelector() {
      return state((state: IAdminState) => state.modeerationUsers);
    },
    moderationUserSelector() {
      return state((state: IAdminState) => state.modeerationUser);
    },
    moderationCampaignSelector() {
      return state((state: IAdminState) => state.moderationCampaigns);
    },
    adminAccountsTotalsSelector() {
      return state((state: IAdminState) => state.adminAccountsTotals);
    },
    adminAccountsUsersSelector() {
      return state((state: IAdminState) => state.adminAccountsUsers);
    },
    adminAccountsUsersIsLoadingSelector() {
      return state((state: IAdminState) => state.adminAccountsUsersIsLoading);
    },
    adminPublisherListSelector() {
      return state((state: IAdminState) => state.publisherList);
    },
    adminPublisherIsLoadingSelector() {
      return state((state: IAdminState) => state.adminPublisherIsLoading);
    },
    adminAccountsTotalsIsLoadingSelector() {
      return state((state: IAdminState) => state.adminAccountsTotalsIsLoading);
    },
    termsOfServiceLinkSelector() {
      return state(
        (state: IAdminState) =>
          state.whitelabel?.whitelabel_settings?.term_of_service_url ||
          TERMS_AND_SERVICE_URL
      );
    },
  }),

  effects: (dispatch) => ({
    async getWhitelabel({ success, fail } = {}) {
      await makeRequest(getCompanyWhitelabelDataRequest(), {
        success: (whitelabel: IWhitelabel) => {
          dispatch.admin.setWhitelabel(whitelabel);
          if (success) {
            success(whitelabel);
          }
        },
        fail,
      });
    },
    async getAdminSearchResult({ data, success, fail }) {
      dispatch.admin.setAdminResultsLoading(true);
      await makeRequest(getAdminSearchResultRequest(data), {
        success: (adminSearchResult: number) => {
          dispatch.admin.setAdminResults(adminSearchResult);
          if (success) {
            success(adminSearchResult);
          }
          dispatch.admin.setAdminResultsLoading(false);
        },
        fail,
      });
    },
    async setAdminNewSpace({ id, success, fail }) {
      await makeRequest(setAdminNewSpaceRequest(id), {
        success: (data: IUser) => {
          const {currency} = data;
          dispatch.authentication.setCurrentSpace(data);
          dispatch.space.getCurrentSpaceBalance({ id });
          dispatch.campaign.hasCampaign(id);
          sessionStorage.setItem('spaceID', id);
          sessionStorage.setItem('currentAccountCurrency', currency);

          if (success) {
            success(id);
          }
        },
        fail,
      });
    },
    async getWhiteLabelWithdrawHistory({ data, success, fail }) {
      await makeRequest(getWhiteLabelWithdrawHistoryRequest(data), {
        success: (data: any) => {
          dispatch.admin.setWhiteLabelWithdrawHistory(data);
          success(data);
        },
        fail,
      });
    },
    async getWhitelabelCustomizationData({ id, success, fail }) {
      await makeRequest(getWhitelabelCustomizationDataRequest(id), {
        success: (data: IWhitelabel) => {
          dispatch.admin.setWhitelabel(data);
          if (success) {
            success(data);
          }
        },
        fail,
      });
    },
    async setWhitelabelCustomizationData({ data, success, fail }) {
      await makeRequest(setWhitelabelCustomizationDataRequest(data), {
        success: (data: IWhitelabel) => {
          dispatch.admin.setWhitelabel(data);
          if (success) {
            success(data);
          }
        },
        fail,
      });
    },
    async getWithdrawBalance({ data, success, fail }) {
      await makeRequest(getWithdrawBalanceRequest(), {
        success: (data: number) => {
          dispatch.admin.setWithdrawBalance(data);
          if (success) {
            success(data);
          }
        },
        fail,
      });
    },
    async getIncomeOverview({ data, success, fail }) {
      await makeRequest(getIncomeOverviewRequest(), {
        success: (data: any) => {
          dispatch.admin.setIncomeData(data);
          if (success) {
            success(data);
          }
        },
        fail,
      });
    },
    async sendWithdrawAmount({ amount, success, fail }) {
      await makeRequest(sendWithdrawAmountRequest(amount), {
        success: (data: any) => {
          dispatch.admin.setIncomeData(data);
          if (success) {
            success(data);
          }
        },
        fail,
      });
    },
    async approveCampaign({ data, success, fail }) {
      await makeRequest(approveCampaignRequest(data), {
        success: (data: any) => {
          if (success) {
            success(data);
          }
        },
        fail,
      });
    },
    async setModerationLegalUsersApprove({ data, success, fail }) {
      await makeRequest(setModerationLegalUsersApproveRequest(data), {
        success,
        fail,
      });
    },
    async setModerationLegalUsersReject({ data, success, fail }) {
      await makeRequest(setModerationLegalUsersRejectRequest(data), {
        success,
        fail,
      });
    },
    async rejectCampaign({ data, success, fail }) {
      await makeRequest(rejectCampaignRequest(data), {
        success: (data: any) => {
          if (success) {
            success(data);
          }
        },
        fail,
      });
    },
    async getModerationRegistrations(data) {
      await makeRequest(getModerationRegistrationsRequest(data), {
        success: (data: IPaginationData<IUser>) => {
          dispatch.admin.setModeerationUsers(data);
        },
      });
    },
    async getModerationLegalUsers(data) {
      await makeRequest(getModerationLegalUsersRequest(data), {
        success: (data: IPaginationData<IUser>) => {
          dispatch.admin.setLegalEntityUsers(data);
        },
      });
    },
    async getModerationRegistration({ id, success, fail }) {
      await makeRequest(getModerationRegistrationRequest(id), {
        success: (data: IUser) => {
          dispatch.admin.setModeerationUser(data);
          if (success) {
            success(data);
          }
        },
        fail,
      });
    },
    async getModerationCampaign(filters) {
      await makeRequest(getModerationCampaignRequest(filters), {
        success: (data: IPaginationData<ICampaign>) => {
          dispatch.admin.setModeerationCampaigns(data);
        },
      });
    },
    async setModerationRegistrationApprove({ data, success, fail }) {
      this.setModerationRegistrationApproved(true);
      await makeRequest(setModerationRegistrationApproveRequest(data), {
        success: (data: any) => {
          this.setModerationRegistrationApproved(false);
          if (success) {
            success(data);
          }
        },
        fail,
      });
    },
    async resendConfirmationEmail({ id, success, fail }) {
      await makeRequest(resendConfirmationEmailRequest(id), {
        success: (data: any) => {
          if (success) {
            success(data);
          }
        },
        fail,
      });
    },
    async getAdminAccountsTotals() {
      dispatch.admin.setAdminAccountsTotalsIsLoading(true);
      await makeRequest(getAdminAccountsTotalsRequest(), {
        success: (totals: IAdminAccountsTotals) => {
          dispatch.admin.setAdminAccountsTotals(totals);
          dispatch.admin.setAdminAccountsTotalsIsLoading(false);
        },
        fail: () => {
          dispatch.admin.setAdminAccountsTotalsIsLoading(false);
        },
      });
    },
    async getAdminAccountsList(filters: IBaseFilters) {
      dispatch.admin.setAdminAccountsUsersIsLoading(true);
      await makeRequest(getAdminAccountsListRequest(filters), {
        success: (data: IPaginationData<IAdminAccountsUser[]>) => {
          dispatch.admin.setAdminAccountsUsers(data);
          dispatch.admin.setAdminAccountsUsersIsLoading(false);
        },
        fail: () => {
          dispatch.admin.setAdminAccountsUsersIsLoading(false);
        },
      });
    },
    async getAdminPublisherList(filters: IBaseFilters) {
      dispatch.admin.setAdminPublisherIsLoading(true);
      await makeRequest(getAdminPublisherListRequest(filters), {
        success: (data: IPaginationData<IAdminPublisher[]>) => {
          dispatch.admin.setPublisherList(data);
          dispatch.admin.setAdminPublisherIsLoading(false);
        },
        fail: () => {
          dispatch.admin.setAdminPublisherIsLoading(false);
        },
      });
    },
  }),
});
