import React, { useState } from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import {
  ValidatableInputField,
  Button,
  MaiSelect,
  InputErrorMessage,
} from "@intent-ai/mandal-npm-lib-v2";
import { IPaymentMethods } from "../../../types";
import { billingMessages } from "../../../messages";
import "./style.scss";
import { numberToStringWithCommas } from "../../../modules";


interface IProps {
  currentBalance: number;
  linkedCardId: string;
  closeModal: Function;
  onSubmit: Function;
  amountError: boolean;
  paymentMethods: IPaymentMethods[];
  intl: IntlShape;
  handleCardChange: Function;
  loading?: boolean;
  setSpacePaymentsBindingLoaded: Function;
}

interface IPaymetnSelectData {
  value: string;
  title: string | JSX.Element;
}

export const RefillBalanceInput = ({
  loading,
  currentBalance,
  closeModal,
  onSubmit,
  amountError,
  paymentMethods,
  handleCardChange,
  linkedCardId,
  setSpacePaymentsBindingLoaded,
  intl: { formatMessage },
}: IProps) => {
  const paymentMethodsSelectData: IPaymetnSelectData[] = paymentMethods.map(
    ({ id, vendor_type, card_number, is_default }) => ({
      value: id,
      title: `Card - ${card_number} ${is_default ? "(Default)" : ""}`,
    })
  );
  paymentMethodsSelectData.unshift({
    value: "new_card",
    title: (
      <span className="main-color">
        {formatMessage(billingMessages.addNewCard)}
      </span>
    ),
  });

  const input = {
    value: linkedCardId,
    onChange: handleCardChange,
  };

  const [value, setValue] = useState("");
  const meta = {
    error: {
      id: "Validation.fieldRequired",
      defaultMessage: "This field is required",
    },
    touched: amountError,
  };

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value);
  const balance = Number(value) < 500;
  return (
    <>
      <div className="refill-modal-current-balance">
        <p className="refill-modal-current-balance-title">
          <FormattedMessage
            id="Payments.currentBalance"
            defaultMessage="Your Current Balance is"
          />
        </p>
        <p className="refill-modal-current-balance-value">
          $ {currentBalance ? numberToStringWithCommas(currentBalance) : 0}
        </p>
      </div>
      <form onSubmit={onSubmit(value)}>
        <div>
          <div className="input-wrapper">
            <p className="input-description label">
              <FormattedMessage
                id="Payments.selectAmount"
                defaultMessage="Select an amount to deposit to your account"
              />
            </p>
            <ValidatableInputField
              name="ammount"
              type="number"
              // placeholder="00"
              prepend="$"
              currentValue={value}
              onChange={_onChange}
              meta={meta}
            />
            {balance && String(value).length > 0 ? (
              <InputErrorMessage
                staticText={formatMessage(billingMessages.lowBalance)}
                style={{ marginTop: "0" }}
              />
            ) : null}
            <div className="mt-1">
              <MaiSelect
                selectData={paymentMethodsSelectData}
                input={input}
                label={formatMessage(billingMessages.selectYourPaymentMethod)}
                multiple={false}
                showSearch={false}
                withLabel
                topLabel
              />
            </div>
          </div>
          <div className="modal-footer-container">
            <Button
              loading={loading}
              disabled={balance}
              i18nId="Payments.refillBalance"
              customClassName="ml-1 modal-button"
              buttonType="submit"
              style={{ width: "50%" }}
            />
            <Button
              i18nId="ButtonLabel.cancel"
              customClassName="ml-1 modal-button"
              type="border"
              style={{ width: "50%" }}
              onClick={() => {
                setSpacePaymentsBindingLoaded(false);
                closeModal();
              }}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default injectIntl(RefillBalanceInput);
