import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch, select, IRootState } from "../../../store";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MaiFilters } from "@intent-ai/mandal-npm-lib-v2";
import { AdminPublisherList } from "../../../containers";
import { IGenericList, IAdminPublisher } from "../../../types";

interface IProps {
  getAdminPublisherList: Function;
  adminPublisherIsLoading: boolean;
  publisherList: IGenericList<IAdminPublisher>;
}

class WrappedAdminPublisherContainer extends Component<
  IProps & RouteComponentProps
> {
  render() {
    const {
      props: { getAdminPublisherList, adminPublisherIsLoading, publisherList },
    } = this;
    const pageCount = Math.ceil(
      publisherList?.total_size / publisherList?.page_size
    );

    return (
      <>
        <div className="campaign-list">
          <MaiFilters
            pageCount={pageCount}
            showPagination
            onReady={getAdminPublisherList}
            onChange={getAdminPublisherList}
          >
            <AdminPublisherList
              publisherList={publisherList?.data}
              loading={adminPublisherIsLoading}
            />
          </MaiFilters>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  adminPublisherIsLoading: select.admin.adminPublisherIsLoadingSelector(state),
  publisherList: select.admin.adminPublisherListSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAdminPublisherList: dispatch.admin.getAdminPublisherList,
});

const AdminPublisherContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WrappedAdminPublisherContainer)
);

export default AdminPublisherContainer;
