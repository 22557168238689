import { FetchUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { DELETE, POST } from "../_constants";
import {
  ISpacePaymentRequest,
  IPaymentMethodsRequest,
  IPaymentCardRequest,
} from "../types";

const { createRequest } = FetchUtils;
const EMPTY_OBJECT = {};

export const getCompanySpaceBalanceRequest = () =>
  createRequest(`/company/spaces/balance`);

export const setCompanySpaceRefillRequest = (data: string) =>
  createRequest(`/payment/space/finalize`, POST, EMPTY_OBJECT, data);

export const getRefillPaymentIdRequest = (data: ISpacePaymentRequest) =>
  createRequest(`/payment/space/init`, POST, EMPTY_OBJECT, data);

export const setNewCardBindRequest = (data: ISpacePaymentRequest) =>
  createRequest(`/payment/space/method/bind`, POST, EMPTY_OBJECT, data);

export const savePaymentCardRequest = (data: ISpacePaymentRequest) =>
  createRequest(`/payment/space/save`, POST, EMPTY_OBJECT, data);

export const setSpacePaymentsMethodsRequest = (data: number) =>
  createRequest(`/payment/space/method/add`, POST, EMPTY_OBJECT, data);

export const getSpacePaymentsMethodsRequest = (data: IPaymentMethodsRequest) =>
  createRequest(`/payment/braintree/method`);

// export const getSpacePaymentsMethodsRequest = (data: IPaymentMethodsRequest) =>
//   createRequest(`/payment/space/method`, POST, EMPTY_OBJECT, data);

export const getPaymentMethodsRequest = () => {
  const params: { space_id?: string } = {};
  const spaceID = sessionStorage.getItem('spaceID');
  if (spaceID) {
    params.space_id = spaceID;
  }
  return createRequest(`/payment/stripe/method`, 'GET', params).then((resp: Response) => {
    if (!resp.ok) {
      throw new Error(resp.statusText);
    }
    return resp.json();
  });
};

export const getBraintreeClientTokenRequest = () =>
  createRequest("/payment/braintree/client-token").then((resp: Response) => {
    if (!resp.ok) {
      throw new Error(resp.statusText);
    }
    return resp.text();
  });

export const getClientTokenRequest = () => {
  const params: { space_id?: string } = {};
  const spaceID = sessionStorage.getItem('spaceID');
  if (spaceID) {
    params.space_id = spaceID;
  }
  return createRequest("/payment/stripe/client-token", 'GET', params).then((resp: Response) => {
    if (!resp.ok) {
      throw new Error(resp.statusText);
    }
    return resp.text();
  });
}

export const addPaymentMethodRequest = (nonce: string) => {
  const data = { payment_method_nonce: nonce };
  let formBodyList: any[] = [];
  for (const property in data) {
    const encodedKey = encodeURIComponent(property);
    //@ts-ignore
    const encodedValue = encodeURIComponent(data[property]);
    formBodyList.push(encodedKey + "=" + encodedValue);
  }
  let formBody = formBodyList.join("&");
  return createRequest(
    `/payment/braintree/method/add`,
    POST,
    EMPTY_OBJECT,
    formBody,
    { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" }
  );
};

export const removePaymentMethodRequest = (method_id: string) => {
  const params: { space_id?: string } = {};
  const spaceID = sessionStorage.getItem('spaceID');
  if (spaceID) {
    params.space_id = spaceID;
  }
  return createRequest(
    `/payment/stripe/method/${method_id}`,
    DELETE,
    params,
  );
};

export const getRefillPaymentHistoryRequest = (data = EMPTY_OBJECT) =>
  createRequest(`/invoice`, POST, EMPTY_OBJECT, data);

export const makeDefaultPaymentMethodRequest = (id: string) => {
  const params: { space_id?: string } = {};
  const spaceID = sessionStorage.getItem('spaceID');
  if (spaceID) {
    params.space_id = spaceID;
  }
  return createRequest(`/payment/stripe/method/default/${id}`, POST, params)
};

export const getWithdrawHistoryRequest = (data = EMPTY_OBJECT) =>
  createRequest(`/cash-out/history`, POST, EMPTY_OBJECT, data);

export const setSpaceBindingRequest = (data: ISpacePaymentRequest) =>
  createRequest(`/payment/space/binding`, POST, EMPTY_OBJECT, data);

export const removeSpacePaymentMethod = (data: IPaymentCardRequest) =>
  createRequest(`/payment/space/method/remove`, POST, EMPTY_OBJECT, data);

export const setAsSpaceDefaultPaymentMethod = (data: IPaymentCardRequest) =>
  createRequest(`/payment/space/method/default`, POST, EMPTY_OBJECT, data);

export const makeBindingPaymentRequest = (data: any) =>
  createRequest(`/payment/space/binding`, POST, EMPTY_OBJECT, data);

export const checkPaymentRequestAccessRequest = () =>
  createRequest(`/payment/user/check`, POST, EMPTY_OBJECT, EMPTY_OBJECT);

export const getPaymentPDFInvoice = (id: string) =>
  createRequest(`/payment/pdf/${id}`);
