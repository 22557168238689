import {
  CampaignFormDTO,
  CampaignMenu,
  CampaignObjective,
  CampaignObjectiveType,
  DynamicAdForm,
  MenuItem,
} from "../types";
import iconPlaceholder from '../../../assets/images/ic_placeholder.png';
import { FieldErrors } from "react-hook-form";
import { DEFAULT_ATTRIBUTION_TAGS } from "./constants";
import { FunctionComponent } from "react";
import {
  CampaignBasicInfo,
  CampaignTargeting,
  CampaignTracks,
  DynamicAdsForm,
  AdvancedBidding,
  ChangeLogList,
  NativeAds,
  PlacementForm,
  VisualsForm,
} from "../components";
import VideoAds from "../components/VideoAds";
import Frequency from "../components/Frequency";
import OwnerForm from "../components/OwnerForm";
import VoiceTargeting from "../components/VoiceTargeting";
import GeographyForm from "../components/GeographyForm";
import { isDev, isLocal, isNG } from "../../../_constants/contentType";
import moment from "moment/moment";

function setNativeAppIframeContent(iframe: HTMLIFrameElement, fields: CampaignFormDTO['nativeAd']) {
  const iframeDocument = iframe.contentDocument;
  if (!iframeDocument) {
    return;
  }
  const appIcon = iframeDocument.querySelector<HTMLImageElement>('.app_icon');
  if (appIcon) {
    appIcon.src = fields.logo?.url || '';
  }
  const appName = iframeDocument.querySelector<HTMLSpanElement>('.app_name');
  if (appName) {
    appName.textContent = fields.advertiser || '';
  }
  const appDescription = iframeDocument.querySelector<HTMLSpanElement>('.ad_description');
  if (appDescription) {
    appDescription.textContent = fields.body || '';
  }
  const video = iframeDocument.querySelector<HTMLSourceElement>('.video');
  const image = iframeDocument.querySelector<HTMLImageElement>('.image');
  const videoElement = iframeDocument.querySelector('video');
  if (video && fields.video) {
    if (video.src !== fields.video?.url) {
      video.src = fields.video?.url || '';
      if (videoElement) {
        videoElement.load();
        videoElement.classList.remove('none');
      }
      if (image) {
        image.classList.add('none');
      }
    }
  } else if (fields.images.length && video && fields.images[0]?.url) {
    if (image) {
      image.src = fields.images[0].url || '';
      image.classList.remove('none');
    }
    if (videoElement) {
      videoElement.classList.add('none');
    }
  }
  const cta = iframeDocument.querySelector('.cta');
  if (cta) {
    cta.textContent = fields.cta || '';
  }
}

function setVideoAdIframeContent(
  iframe: HTMLIFrameElement,
  fields: { headline: string; video: string; cta: string; }
) {
  const iframeDocument = iframe.contentDocument;
  if (!iframeDocument) {
    return;
  }
  const bannerHeadline = iframeDocument.querySelector('.headline');
  const video = iframeDocument.querySelector<HTMLSourceElement>('.video');
  const action = iframeDocument.querySelector('.action');
  if (bannerHeadline) {
    bannerHeadline.textContent = fields.headline || '';
  }
  if (video && video.src !== fields.video) {
    video.src = fields.video || '';
    const videoElement = iframeDocument.querySelector('video');
    if (videoElement) {
      videoElement.load();
    }
  }
  if (action) {
    action.textContent = fields.cta || '';
  }
}

function setIframeContent(iframe: HTMLIFrameElement, fields: DynamicAdForm) {
  const iframeDocument = iframe.contentDocument;
  if (!iframeDocument) {
    return;
  }
  const bannerTitle = iframeDocument.querySelector('.banner-title');
  const bannerDescription = iframeDocument.querySelector('.banner-description');
  const bannerCompanyName = iframeDocument.querySelector('.banner-logo-title');
  const bannerCallToAction = iframeDocument.querySelector<HTMLButtonElement>('.banner-cta');
  const bannerImage = iframeDocument.querySelector<HTMLImageElement>('.banner-image');
  const bannerLogo = iframeDocument.querySelector<HTMLImageElement>('.banner-logo-icon');
  if (typeof fields.titles[0].title === 'string' && bannerTitle) {
    bannerTitle.textContent = fields.titles[0].title;
  }
  if (typeof fields.descriptions[0].description === 'string' && bannerDescription) {
    bannerDescription.textContent = fields.descriptions[0].description;
  }
  if (typeof fields.companyName === 'string' && bannerCompanyName) {
    bannerCompanyName.textContent = fields.companyName;
  }
  if (bannerImage) {
    bannerImage.src = fields.images[0]?.url || iconPlaceholder;
  }
  if (bannerLogo) {
    bannerLogo.src = fields.logo[0]?.url || iconPlaceholder;
  }
  if (bannerCallToAction) {
    bannerCallToAction.style.background = `#${fields.callToActionColor}`;
    if (typeof fields.callToAction == 'string') {
      bannerCallToAction.textContent = fields.callToAction;
    }
  }
}

// TODO remove
function changeContentStyle() {
  const authChildren = document.querySelector<HTMLElement>('.auth-children');
  if (authChildren) {
    authChildren.style.background = '#fff';
    authChildren.style.marginLeft = '350px';
  }
}

function getDefaultEstimates() {
  const isNG = window.location.host.includes('ng-app');
  const isTN = window.location.host.includes('tn-dsp');
  if (isNG) {
    return {
      estimate: 69_000_000,
      impressions: 110_000_000_000,
    };
  } else if (isTN) {
    return {
      estimate: 12_500_000,
      impressions: 30_000_000_000,
    }
  }
  return {
    estimate: 2_900_000_000,
    impressions: 2_000_000_000,
  }
}

function createMenuItem(
  id: number,
  value: CampaignMenu,
  label: string,
  Component: FunctionComponent<any>,
  children: MenuItem[] = [],
  hasError = false,
  expanded: boolean = false,
  ): MenuItem {
  return { id, value, label, hasError, children, Component, expanded };
}


function getAdministrationMenuItem(errors: FieldErrors<CampaignFormDTO>) {

  const children: MenuItem[] = [
    createMenuItem(11, CampaignMenu.OWNER_MODERATION, 'ownerFields', OwnerForm, [], false, false),
    createMenuItem(12, CampaignMenu.OWNER_ADVANCED_BIDDING, 'advancedBidding', AdvancedBidding,[], false, false),
  ];
  return createMenuItem(1, CampaignMenu.OWNER, 'administration', () => null, children, false, false);
}
function getVisualsMenuItem(errors: FieldErrors<CampaignFormDTO>, audioAdEnabled: boolean) {
  if (audioAdEnabled) {
    return createMenuItem(4, CampaignMenu.TRACKS, 'tracks', CampaignTracks,[], false, false);
  }
  const children: MenuItem[] = [
    createMenuItem(41, CampaignMenu.VISUALS_RESPONSIVE_ADS, 'dynamicAds', DynamicAdsForm,[], false, false),
    createMenuItem(42, CampaignMenu.VISUALS_BANNER_ADS, 'bannerAds', VisualsForm,[], false, false),
    createMenuItem(43, CampaignMenu.VISUALS_NATIVE_ADS, 'nativeAds', NativeAds, [], !!errors.nativeAd, false),
    createMenuItem(44, CampaignMenu.VISUALS_VIDEO_ADS, 'videoAds', VideoAds, [], false, false)
  ];
  return createMenuItem(4, CampaignMenu.VISUALS, 'creatives', () => null, children, false, false);
}

function getAdvancedMenuItems(onlyScheduling: boolean, appInstallsCampaign: boolean) {
  const advancedItems = [];
  advancedItems.push(createMenuItem(51, CampaignMenu.ADVANCED_FREQUENCY, onlyScheduling ? 'scheduling' : 'frequency', Frequency,[], false, false));
  advancedItems.push(createMenuItem(52, CampaignMenu.ADVANCED_PLACEMENT, 'placements', PlacementForm,[], false, false));
  if(isNG || isDev || isLocal){
    advancedItems.push(createMenuItem(53, CampaignMenu.ADVANCED_GEOGRAPHY, 'geography', GeographyForm,[], false, false));
  }
  // if (!appInstallsCampaign) {
  //   advancedItems.push(createMenuItem(2, CampaignMenu.ADVANCED_ATTRIBUTION, 'attribution', AttributionForm));
  // }

  return advancedItems;
}

function getCampaignNavigationItems(
  isWLOwner: boolean,
  errors: FieldErrors<CampaignFormDTO>,
  goal: string,
  type: string,
) {
  const isAwarenessCampaign = type === CampaignObjectiveType.AWARENESS;
  const isMoreReach = goal === CampaignObjective.MAXIMIZED_REACH;
  const onlyScheduling = isMoreReach && isAwarenessCampaign;
  const audioAdEnabled = goal === CampaignObjective.AUDIO_ADVERTISING;
  const appInstallsCampaign = type === CampaignObjectiveType.CONSIDERATION && goal === CampaignObjective.APP_INSTALLS;

  const menuItems = [];

  if (isWLOwner) {
    menuItems.push(getAdministrationMenuItem(errors));
  }

  menuItems.push(createMenuItem(2, CampaignMenu.BASIC_INFO, 'campaignSetup', CampaignBasicInfo,[], false, false));
  menuItems.push(createMenuItem(
    3,
    CampaignMenu.TARGETING,
    'targeting',
    audioAdEnabled ? VoiceTargeting : CampaignTargeting,[], false, false
  ));

  menuItems.push(getVisualsMenuItem(errors, audioAdEnabled));
  if (!!goal && !audioAdEnabled) {
    const advancedItems = getAdvancedMenuItems(onlyScheduling, appInstallsCampaign);
    menuItems.push(createMenuItem(5, CampaignMenu.ADVANCED, 'advanced', () => null, advancedItems, false, false));
  }
if(isWLOwner){
  menuItems.push(createMenuItem(6, CampaignMenu.CHANGE_LOG, 'changeLog', ChangeLogList,[], false, false));
}

  return menuItems;
}

function checkAttributionTags (tag: string) {
  const link = tag.split('?')[0];
  let params = tag.substring(tag.indexOf('?')+1);
  if(params.includes('?')){
    params = params.replace('?', '&')
  }
  return `${link}?${params}`;
}

function getAttributionTags(productURL: string) {
  return `${productURL + (productURL.includes('?') ? '&' : '?') || ''}${DEFAULT_ATTRIBUTION_TAGS}`;
}

function generateWebsitePromotionCustomHTML(name: string) {
  return `<script>
  window.intentData = window.intentData || [];
  intentData.push(["$$ADVERTISER_ID$$", "$$NAME$$"]);
</script>`.replace('$$ADVERTISER_ID$$', sessionStorage.getItem('spaceID') || "")
    .replace('$$NAME$$', name);

}

function convertToWhitelabelTimezoneStart(date: string | Date, timezoneOffset: number) {
  return moment(date).utcOffset(timezoneOffset, true ).utc().format('YYYY-MM-DDTHH:mm:ss.SSS+0000');
}
function convertToWhitelabelTimezoneEnd(date: string | Date, timezoneOffset: number) {
  return moment(date).subtract(1, 'seconds').utcOffset(timezoneOffset, true ).utc().format('YYYY-MM-DDTHH:mm:ss.SSS+0000');
}

function convertFromWhitelabelTimezone(date: string | Date, timezoneOffset: number) {
  const m = moment(date);
  return m.add(timezoneOffset - m.utcOffset()  , "m").toDate()
}

export {
  setIframeContent,
  changeContentStyle,
  getDefaultEstimates,
  getCampaignNavigationItems,
  setNativeAppIframeContent,
  getAttributionTags,
  setVideoAdIframeContent,
  generateWebsitePromotionCustomHTML,
  convertToWhitelabelTimezoneStart,
  convertToWhitelabelTimezoneEnd,
  convertFromWhitelabelTimezone,
  checkAttributionTags,
}