import React, { Component } from "react";
import { IModalProps} from "../../../types";
import { Modal } from "../../../containers";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { ValidatableInputField, Flex, Button } from "@intent-ai/mandal-npm-lib-v2";
import "./style.scss";
import { connect } from "react-redux";
import { adminMessages } from "../../../messages";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { remove_icon } from "../../../assets";

interface IProps extends IModalProps {
  intl: IntlShape;
}

interface IState {
  value: string;
}

class CommentModal extends Component<IProps & RouteComponentProps, IState> {
  state = {
    value: "",
  };

  handleTextChange = (e: any) => {
    const value = e.target.value;
    this.setState(() => ({ value }));
  };

  handleRejectClick = () => {
    const {
      state: { value },

      props: {
        modalData: { rejectAction },
      },
    } = this;
    rejectAction(value);
  };

  render() {
    const {
      state: { value },
      props: {
        intl: { formatMessage },
        closeModal,
      },
      handleTextChange,
      handleRejectClick,
    } = this;
    const input = {
      value,
      onChange: handleTextChange,
    };
    return (
      <div className="in-moderation-modal">
        <img
          src={remove_icon}
          alt="remove-icon"
          className="reject-modal-close-icon"
          onClick={() => closeModal()}
        />
        <Modal.Header>
          <FormattedMessage id="Admin.reasonReject" defaultMessage="Reason" />
        </Modal.Header>
        <div className="admin-reject-comment">
          <p>
            <FormattedMessage
              id="Admin.reasonRejectDescription"
              defaultMessage="Please, provide the reason of rejection, which will be sent to the advertiser for future updates."
            />
          </p>
          <div className="admin-reject-comment-text-area">
            <ValidatableInputField
              input={input}
              inputType="textarea"
              placeholder={formatMessage(adminMessages.reasonRejectPlaceholder)}
            />
          </div>
          <Flex justifyContent="center">
            <Button
              i18nId="ButtonLabel.confirm"
              color="red"
              onClick={handleRejectClick}
            />
          </Flex>
        </div>
      </div>
    );
  }
}

export default connect()(injectIntl(withRouter(CommentModal)));
