import React from "react";
import "./style.scss";

interface IProps {
  title: string;
  isActive: boolean;
}

export const AudienceUploadInfo: React.FC<IProps> = ({ title, isActive }) => {
  return (
    <div className="audience-upload-info">
      <p className="audience-upload-info-title">{title}</p>
      {isActive && (
        <div className="audience-upload-info-indicator-container">
          <span className="audience-upload-info-indicator" />
        </div>
      )}
    </div>
  );
};
