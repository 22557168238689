import React, { Component } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { IAdminAccountsUser } from "../../../../types";
import { adminMessages } from "../../../../messages";
import { Table } from "@intent-ai/mandal-npm-lib-v2";
import { TableNotFound } from "../../../../components";
import { getPaymentPDFInvoice } from "../../../../api";
import { USER_TYPE_KEY } from "../../../../_constants";
import "./style.scss";
import { formatDate, numberToStringWithCommas } from "../../../../modules";

const { LEGAL } = USER_TYPE_KEY;

interface IProps {
  intl: IntlShape;
  adminAccountsUsers: IAdminAccountsUser[];
  loading: boolean;
  onRowClick: Function;
  onIconClick: Function;
}

class AdminAccountsList extends Component<IProps> {
  renderNotfound = () => {
    return (
      <TableNotFound
        titleId="Admin.accountsNotFoundTitle"
        titleDefaultMessage="No Accounts found"
        descriptionId="Admin.accountsNotFoundDescription"
        descriptionDefaultMessage="Try adjusting filters or choosing different date range."
      />
    );
  };

  getColumns = () => {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;

    return [
      {
        title: formatMessage(adminMessages.accountsListName),
        dataIndex: "name",
      },
      {
        title: formatMessage(adminMessages.accountsListCompanyName),
        dataIndex: "companyName",
      },
      {
        title: formatMessage(adminMessages.accountsListEntityName),
        dataIndex: "entityName",
      },
      {
        title: formatMessage(adminMessages.accountsListRunningCampaigns),
        dataIndex: "runningCampaigns",
      },
      // {
      //   title: formatMessage(adminMessages.accountsListImpressions),
      //   dataIndex: "impressions",
      // },
      {
        title: formatMessage(adminMessages.accountsListTotalBalance),
        dataIndex: "totalBalance",
      },
    ];
  };

  // @typescript-eslint/no-unused-vars disable next line
  downloadPDFInvoice =
    (id: string, date: Date) => (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      getPaymentPDFInvoice(id)
        .then((response: any) => response.blob())
        .then((blob: any) => {
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([blob]));
          link.download = `payment-${formatDate(date,'MMM DD, YYYY HH:mm')}.pdf`;
          link.click();
        })
        .catch((err: any) => {
          console.error(err);
        });
    };
  handleIconClick = (item: any) => (e: React.MouseEvent<HTMLElement>) => {
    const {
      props: { onIconClick },
    } = this;
    e.preventDefault();
    e.stopPropagation();
    onIconClick(item);
  };
  getData = () => {
    const {
      props: { adminAccountsUsers, onRowClick },
      //downloadPDFInvoice, //todo remove after checking
      handleIconClick,
    } = this;
    if (!Array.isArray(adminAccountsUsers)) return [];
    if (adminAccountsUsers.length === 0) return [];

    return (
      adminAccountsUsers &&
      adminAccountsUsers.map((user) => {
        const {
          balance,
          company_name,
          entity_name,
          //impressions,
          running_campaigns,
          full_name,
          user_type_key,
        } = user;
        const handleAccountClick = () => {
          onRowClick(user);
        };
        const isLegal = [LEGAL].includes(user_type_key);
        return {
          name: full_name,
          company_name: company_name ? company_name : "-",
          entity_name: (
            <div className="table-entity-name-container">
              <p> {entity_name ? entity_name : "-"}</p>
              {isLegal ? (
                <div onClick={handleIconClick(user)}>
                  {/*<IntIcon type="edit" />*/}
                </div>
              ) : null}
            </div>
          ),
          running_campaigns: numberToStringWithCommas(running_campaigns),
          balance: <strong> {`$ ${numberToStringWithCommas(balance, 2)}`}</strong>,
          onClick: handleAccountClick,
        };
      })
    );
  };

  render() {
    const {
      props: { loading },
      getColumns,
      getData,
    } = this;
    return (
      <div className="admin-accounts-table-wrapper">
        <Table
          notFound={this.renderNotfound()}
          borderBottom
          columns={getColumns()}
          data={getData()}
          firtsFullWidth
          showLoading
          isLoading={loading}
        />
      </div>
    );
  }
}

export default injectIntl(AdminAccountsList);
