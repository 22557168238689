import { AuthTitleWrapper } from "../../../../components";
import { Box, Typography } from "@mui/material";
import { style } from './style';
import { ReportChartView, ReportHeaderFilter, ReportTotals } from "../index";
import { useForm, useWatch } from "react-hook-form";
import { REPORT_FILTERS_DEFAULT_VALUES } from "../../utils";
import { useState } from "react";
import { ReportTotalTab } from "../../types";
import { useTranslation } from "react-i18next";
import { CampaignService } from "../../../campaign/services";
import { ReportService } from "../../services";
import { downloadBlob } from "../../../global";
import { NotificationService } from "../../../global/services";
import ReportList from "../ReportList";

// const PlatformReport = lazy(() => import('../PlatformReport'));
// const TrafficSourceReport = lazy(() => import('../TrafficSourceReport'));
// const BannerReport = lazy(() => import('../BannerReport'));

function Report() {
  const { control, setValue, getValues } = useForm({
    defaultValues: REPORT_FILTERS_DEFAULT_VALUES,
  });

  const [tab,] = useState<ReportTotalTab>(ReportTotalTab.COMPARE);

  const { t } = useTranslation('translation', { keyPrefix: 'reporting' });

  const formValues = useWatch({ control, });

  // const campaignId = useWatch({
  //   name: 'campaignId',
  //   control,
  // });
  // function resetForm() {
  //   reset({
  //     ...REPORT_FILTERS_DEFAULT_VALUES,
  //     campaignId,
  //   });
  // }

  async function trafficSourceExportToCSV(dimension: string) {
    const formValues = getValues();
    const blob = await CampaignService.exportCampaignTrafficSource({...ReportService.buildReportLightDTO(formValues), dimension});
    downloadBlob(blob, 'traffic.csv');
    NotificationService.handleSuccess(t('exportSuccess'));
  }

  async function exportToCSV() {
    const values = ReportService.buildReportLightDTO(formValues as any);
    const blob = await CampaignService.exportCampaignReport(values);
    downloadBlob(blob, 'report.csv');
    NotificationService.handleSuccess(t('exportSuccess'));
  }

  return (
    <AuthTitleWrapper
      style={{padding: '0 !important'}}
      title={'Reports'}
      iconName="dashboard"
      header={(
        <ReportHeaderFilter
          control={control}
          setValue={setValue}
        />
      )}
      >
      <Box sx={style.reports}>
        <Typography sx={{...style.exportCSV, marginBottom: '12px',
          textAlign: 'end'}} onClick={exportToCSV}>
          {t('exportCSV')}
        </Typography>
        <ReportTotals control={control} tab={tab} />
        <ReportChartView control={control} tab={ReportTotalTab.COMPARE} />
      </Box>
      <Box sx={{margin: '0 -86px 0 -86px', padding: '16px'}}>
        <ReportList control={control} exportCSV={trafficSourceExportToCSV}/>
      </Box>
    </AuthTitleWrapper>
  );
}

Report.displayName = 'Reports';

export default Report;
