import { FetchUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { IReportFilter } from "../types";
import { POST } from "../_constants";

const { createRequest } = FetchUtils;

const EMPTY_OBJECT = {};

export const getReportsRequest = (filters: IReportFilter) =>
  createRequest("/reports", POST, EMPTY_OBJECT, filters);

export const getReportPlatformsRequest = (filters: IReportFilter) =>
  createRequest("/reports/platforms", POST, EMPTY_OBJECT, filters);

export const getReportPublishersRequest = (filters: IReportFilter) =>
  createRequest("/reports/publishers", POST, EMPTY_OBJECT, filters);

export const getReportBannersRequest = (filters: IReportFilter) =>
  createRequest("/reports/banners", POST, EMPTY_OBJECT, filters);

export const getReportsCampaignRequest = (id: string) =>
  createRequest(`/campaign/report/list/${id}`);

export const getReportsCampaignExcelDownloadRequest = (
  filters: IReportFilter
) => createRequest(`/reports/excel`, POST, EMPTY_OBJECT, filters);
