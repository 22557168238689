import React from "react";
import "./styles.scss";
import GaugeSvg from "./GaugeSvg";
import { ICampaign } from "../../types";
import { FormattedMessage } from "react-intl";
import { fail_icon } from "../../assets";
import { getPercentageOfValue, Loading, numberToStringWithCommas } from "../../modules/global";

interface IProps {
  value: number;
  maxValue: number;
  valueName: string;
  unit: string;
  isAudienceLoading: boolean;
  currentData: ICampaign;
}

function kFormatter(num: number) {
  return numberToStringWithCommas(Math.floor(num / 1000) * 1000);
}

export const Gauge = ({
  value,
  maxValue,
  unit,
  valueName,
  isAudienceLoading,
  currentData,
}: IProps) => {
  return (
    <>
      <div className="gauge-container">
        { isAudienceLoading ?  <Loading /> : null }
        <div className={isAudienceLoading ? "gauge-loading" : ""}>
          <GaugeSvg percentage={getPercentageOfValue(maxValue, value)} />
          <div className="gauge-report-container">
            <div className="gauge-report-overlay text-center">

              { currentData.targeting &&
                  currentData.targeting?.interests?.include?.length >= 11 ? (
                <div className="gauge-report-text">
                  <FormattedMessage
                    id="CampaignForm.gaugeMoreThan"
                    defaultMessage="More Than"
                  />
                </div>
              ) : null}

              <div className="gauge-report-number">{kFormatter(value)}</div>
              <div className="gauge-report-text">{unit}</div>
            </div>
          </div>
        </div>
      </div>
     { currentData.targeting &&
          currentData.targeting?.interests?.include?.length >= 11 ? (
       <div className="gauge-more-select">
         <div className="gauge-more-select-image">
           <img src={fail_icon} alt="Gauge fail more select" />
         </div>
         <p>
           It is good to choose many interests to increase the audience size,
           however we are unable to calculate the estimated audience size for
           more than 11 interests.
         </p>
       </div>
       ) : null}
    </>
  );
};
