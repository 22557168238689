/* eslint-disable */

import React, { Component } from "react";
import { Switcher } from "@intent-ai/mandal-npm-lib-v2";
import { PaymentCard, ModalPaymentCard } from "../../../components";
import { IPaymentMethods } from "../../../types";
import "./styles.scss";
import { Loading } from "../../../modules/global";

interface IProps {
  spacePaymentMethods: IPaymentMethods[];
  value: string;
  onChange: Function;
}

export class PaymentsLinkedCards extends Component<IProps> {
  getOptions = () => {
    const {
      props: { spacePaymentMethods },
    } = this;
    return spacePaymentMethods.map(
      ({
        id,
        card_number,
        exp_year,
        exp_month,
        credit_card_type_key,
        type_key,
      }: IPaymentMethods) => {
        return {
          value: id,
          title: (
            <ModalPaymentCard
              expiration_month={exp_month}
              expiration_year={exp_year}
              credit_card_type_key={type_key}
              masked_number={card_number}
            />
          ),
        };
      }
    );
  };

  render() {
    const {
      props: { spacePaymentMethods, value, onChange },
      getOptions,
    } = this;

    if (spacePaymentMethods === null) {
      return <Loading />;
    }
    return (
      <div className="payment-linked-cards">
        <Switcher
          options={getOptions()}
          currentValue={value}
          handelChange={onChange}
        />
      </div>
    );
  }
}
