const ACCOUNT_TYPE = {
  FREELANCER: 'freelancer',
  AGENCY: 'agency',
  BRAND: 'brand',
}

const ACCOUNT_SOURCE = {
  LINKEDIN_AD: 'linkedin_ad',
  LINKEDIN_PERSONAL_NETWORK: 'linkedin_personal_network',
  COLD_EMAIL: 'cold_email',
  GOOGLE_ADS: 'google_ads',
  INTENT_ADS: 'intent_ads',
  REFERRED: 'referred',
  OTHER: 'other',
}

const WHITELABEL_TYPES = {
  ARMENIA: 'am',
  NIGERIA: 'ng',
  TUNISIA: 'tn',
}

const WHITELABEL_TO_WHITELABEL_ID = {
  am: '5e395d6496831f129e513310',
  ng: '641c02e22d3c016ce24ef90b',
  tn: '645e1e812807549290d56c7b'
}

const ADD_ACCOUNT_FORM_DEFAULT_VALUES = {
  companyName: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  type: '',
  source: '',
  whitelabelId: '',
  niche: '',
  position: '',
  other: '',
  rewarded_ads_cpm_rate: 1,
}


export {
  ACCOUNT_TYPE,
  ACCOUNT_SOURCE,
  WHITELABEL_TYPES,
  ADD_ACCOUNT_FORM_DEFAULT_VALUES,
  WHITELABEL_TO_WHITELABEL_ID,
}