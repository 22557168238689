import "./style.scss";
import { Box, Button, Divider, Typography } from "@mui/material";
import { UserRole } from "../../../profile/types";
import { useSelector } from "react-redux";
import { getUserRole } from "../../../store";
import { ReactNode, useEffect, useState } from "react";
import { AddIcon } from "../../../global";
import { useTranslation } from "react-i18next";
import BiddingItem from "./components/BiddingItem";
import { useFormContext, useWatch } from "react-hook-form";
import { CampaignFormDTO } from "../../types";

type Props = {
  footer: ReactNode;
}

const initialBidding = {
  id: 0,
  name: "",
  multiplier: 1,
  rules: {},
}

function AdvancedBidding({ footer }: Props) {
  const role = useSelector(getUserRole);
  const isWLOwner = role === UserRole.WL_OWNER;
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.owner' });
  const { setValue } = useFormContext<CampaignFormDTO>();
  const traffic_targeting: IBidding[] = useWatch({ name: 'traffic_targeting'});

  const [biddingList, setBiddingList] = useState<IBidding[]>(traffic_targeting);

  useEffect(() => {
    if(traffic_targeting.length)
    setBiddingList(traffic_targeting)
  }, [traffic_targeting]);


  const saveRules = (rule: IBidding) => {
    const _biddingList = [...biddingList];
    const _index = _biddingList.findIndex(item => item.id === rule.id);
    _biddingList[_index] = {...rule}
    setBiddingList(_biddingList);
    setValue('traffic_targeting', _biddingList);
  }

  const addBiddingItem = () => {
    const _biddingList = [...biddingList];
    const _lastItem = _biddingList.length > 0 ? _biddingList[_biddingList.length - 1] : initialBidding;
    _biddingList.push({...initialBidding, id: _lastItem.id + 1, name: `Bidding ${_lastItem.id + 1}`})
    console.log(6661, _biddingList);
    setBiddingList(_biddingList);
    setValue('traffic_targeting', _biddingList);
  }

  const removeBiddingItem = (id: number) => {
    const _biddingList = biddingList.filter(item => item.id !== id);
    setBiddingList(_biddingList);
    setValue('traffic_targeting', _biddingList);
  }
  const moveBiddingItem = (position: number, movement: TMovement) => {
    const _biddingList = [...biddingList];
    const elem = _biddingList.splice(position, 1)[0];
    if(movement === 'UP'){
      _biddingList.splice(position - 1, 0, elem);
      setBiddingList(_biddingList);
      setValue('traffic_targeting', _biddingList);
    } else {
      _biddingList.splice(position + 1, 0, elem);
      setBiddingList(_biddingList);
      setValue('traffic_targeting', _biddingList);
    }
  }

  return isWLOwner ? (
    <Box className={'advanced-bidding-wrap'}>
      <Box className={'advanced-bidding-header'}>
        <Typography className={'title'}>{t('advancedBidding')}</Typography>
        <Box className={'add-bidding'}>
          <Button
            className={'add'}
            variant='contained'
            startIcon={<AddIcon fontSize={'large'} sx={{ stroke: '#fff', fontSize: '24px' }}/>}
            onClick={addBiddingItem} />
        </Box>
      </Box>
      <Divider />

      <Box className={'advanced-bidding-body'}>
        {biddingList.map((item, i) => (
          <BiddingItem
            data={item}
            position={i}
            key={item.id}
            totalCount={biddingList.length - 1}
            onMove={moveBiddingItem}
            onRemove={removeBiddingItem}
            saveRules={(rule) => saveRules(rule)}
            />
        ))}
      </Box>
    {footer}
    </Box>
  ): null
}

AdvancedBidding.displayName = 'AdvancedBidding';

export default AdvancedBidding;
