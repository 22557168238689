import { Box, Typography } from "@mui/material";
import { style } from './style';
import { getFileFromCache, Loading, NEW_ROUTES } from "../../../global";
import { LoginForm } from "../index";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getLoginFormValidation, LOGIN_FORM_DEFAULT_VALUES } from "../../utils";
import { LoginFormDTO } from "../../types";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthService } from "../../services";
import { useSelector } from "react-redux";
import { getWhiteLabelCompanyLogo } from "../../../store";
import { AUTH_TOKEN, AUTH_TOKEN_EXPIRY_DATE, REFRESH_TOKEN } from "../../../../_constants";
import React, { useState } from "react";
import { dispatch} from "../../../../store";

function Login() {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, formState: { isSubmitting } } = useForm<LoginFormDTO>({
    defaultValues: LOGIN_FORM_DEFAULT_VALUES,
    resolver: yupResolver(getLoginFormValidation()),
  });
  const { t } = useTranslation('translation', { keyPrefix: 'auth.login' });

  const history = useHistory();

  const whiteLabelLogo = useSelector(getWhiteLabelCompanyLogo);

  function onCreateAccountClick() {
    history.replace(NEW_ROUTES.auth.register.fullPath);
  }

  async function onSubmit(data: LoginFormDTO) {
    const response = await AuthService.getToken(data);
    if (response?.data) {
      setLoading(true);
      const { access_token, refresh_token, expires_in, space_id } = response.data;
      const date = new Date();
      date.setSeconds(date.getSeconds() + expires_in);

      sessionStorage.setItem('spaceID', space_id);
      localStorage.setItem(AUTH_TOKEN, access_token);
      localStorage.setItem(REFRESH_TOKEN, refresh_token);
      localStorage.setItem(AUTH_TOKEN_EXPIRY_DATE, date.toISOString());

      dispatch.authentication.getUser({})
    }
  }

  return loading ? <Loading /> : (
    <Box sx={style.view}>
      {whiteLabelLogo?.file_id && (
        <Box
          component='img'
          src={getFileFromCache(whiteLabelLogo.file_id)}
          alt='Company logo'
          sx={style.companyLogo}
        />
      )}
      <LoginForm control={control} onSubmit={handleSubmit(onSubmit)} disabled={isSubmitting} />
      <Typography
        sx={style.createAccount}
        data-testid='create-account'
        onClick={onCreateAccountClick}
      >{t('createAccount')}</Typography>
    </Box>
  )
}

Login.displayName = 'Login';

// export default Login;
// const mapDispatchToProps = (dispatch: Dispatch) => {
//   return ({
//     getUser: dispatch.authentication.getUser,
//   });
// }
// export default connect(null, mapDispatchToProps)(Login);

export default Login;