import { createModel } from "@rematch/core";
import { FetchUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import {
  getRefillPaymentHistoryRequest,
  getRefillPaymentIdRequest,
  getSpacePaymentsMethodsRequest,
  removeSpacePaymentMethod,
  setCompanySpaceRefillRequest,
  setAsSpaceDefaultPaymentMethod,
  makeBindingPaymentRequest,
  setSpaceBindingRequest,
  savePaymentCardRequest,
  setNewCardBindRequest,
  checkPaymentRequestAccessRequest,
  // getPaymentMethodsRequest,
} from "../api";
import {
  PaymentHistory,
  IPaymentFilterRequest,
  IPaymentMethods,
  IPaymentCardRequest,
  IRequestType,
  ISpacePaymentRequest,
} from "../types";
import { billingMessages } from "../messages";
import { select } from "../store";

const { makeRequest } = FetchUtils;

export interface PaymentState {
  isPaymentHistoryLoaded: boolean;
  refillBalanceHistoryData: PaymentHistory;
  paymentMethods: Array<IPaymentMethods>;
  isAllowMakePayment: boolean;
}
export const payment = createModel({
  name: "payment",
  state: {
    isSpacePaymentsBindingLoaded: false,
    isPaymentHistoryLoaded: false,
    refillBalanceHistoryData: {
      current_page: 0,
      page_size: 10,
      total_size: 10,
      data: [],
    },
    paymentMethods: [],
    isAllowMakePayment: true,
  } as PaymentState,
  reducers: {
    setRefillBalanceHistory: (
      state,
      refillBalanceHistoryData: PaymentHistory
    ): PaymentState => ({
      ...state,
      refillBalanceHistoryData,
    }),
    setPaymentHistoryLoaded: (state, value) => ({
      ...state,
      isPaymentHistoryLoaded: value,
    }),
    setSpacePaymentsBindingLoaded: (state, value) => ({
      ...state,
      isSpacePaymentsBindingLoaded: value,
    }),
    setPaymentMethods: (state, paymentMethods) => ({
      ...state,
      paymentMethods,
    }),
    setIsAllowMakePayment: (state, isAllowMakePayment) => ({
      ...state,
      isAllowMakePayment,
    }),
    nullifySpacePayments: state => ({ ...state, refillBalanceHistoryData: {} }),
  },
  selectors: state => ({
    isAllowMakePaymentSelector() {
      return state((state: PaymentState) => state.isAllowMakePayment);
    },
    selectPaymentMethods() {
      return (rootState: any) => {
        return rootState.payment.paymentMethods;
      };
    },
    selectPaymentHistoryLoaded() {
      return (rootState: any) => {
        return rootState.payment.isPaymentHistoryLoaded;
      };
    },
    selectSpacePaymentsBindingLoaded() {
      return (rootState: any) => {
        return rootState.payment.isSpacePaymentsBindingLoaded;
      };
    },
    selectPaymentHistoryData() {
      return (rootState: any) => {
        return rootState.payment.refillBalanceHistoryData;
      };
    },
    selectPaymentDefaultId() {
      return (rootState: any) => {
        const defaultCard = rootState.payment.paymentMethods?.find(
          ({ is_default }: IPaymentMethods) => is_default
        );
        return defaultCard ? defaultCard.id : "";
      };
    },
    selectDefaultPaymentId() {
      return (rootState: any) => {
        const defaultPaymentMethod = rootState.payment.paymentMethods?.find(
          (method: any) => method.is_default === true
        );
        return defaultPaymentMethod?.id;
      };
    },
  }),

  effects: dispatch => ({
    async getRefillBalanceHistory(data: IPaymentFilterRequest, store) {
      dispatch.payment.setPaymentHistoryLoaded(true);
      const currentUser = select.authentication.selectCurrentUser(store);
      await makeRequest(
        getRefillPaymentHistoryRequest({
          ...data,
          user_id: currentUser.id,
          page: data.page - 1,
        }),
        {
          success: (data: PaymentHistory) => {
            dispatch.payment.setRefillBalanceHistory(data);
            dispatch.payment.setPaymentHistoryLoaded(false);
          },
        }
      );
    },
    async getCompanyRefillPaymentId({ data, success, fail }: any) {
      dispatch.payment.setSpacePaymentsBindingLoaded(true);
      dispatch.dialog.openDialog({
        data: {
          open: true,
          onConfirm: async () => {
            await makeRequest(getRefillPaymentIdRequest(data), {
              success,
              fail,
            });
          },
          title: billingMessages.dialogNotice,
          description: billingMessages.dialogAddNewCardDescription,
          data,
          closeDialogCallback: () =>
            dispatch.payment.setSpacePaymentsBindingLoaded(false),
        },
      });
    },
    async getSpacePaymentsMethods({ data, success, fail }) {
      await makeRequest(getSpacePaymentsMethodsRequest(data), {
        success: (data: any) => {
          this.setPaymentMethods(data);
          if (success) {
            success(data);
          }
        },
      });
    },
    // async getPaymentMethods({ success }) {
    //   await makeRequest(getPaymentMethodsRequest(), {
    //     success: (data: any) => {
    //       this.setPaymentMethods(data);
    //       if (success) {
    //         success(data);
    //       }
    //     },
    //   });
    // },
    async checkPaymentRequestAccess({ data, success, fail } = {}) {
      await makeRequest(checkPaymentRequestAccessRequest(), {
        success: (data: boolean) => {
          dispatch.payment.setIsAllowMakePayment(data);
          if (success) {
            success(data);
          }
        },
      });
    },
    async removeSpacePaymentMethod({ data, success, fail }) {
      dispatch.dialog.openDialog({
        data: {
          open: true,
          onConfirm: async () => {
            await makeRequest(removeSpacePaymentMethod(data), {
              success: (resp: any) => {
                this.getSpacePaymentsMethods({ data });
                success(resp);
              },
              fail,
            });
          },
          title: billingMessages.dialogAreYouSure,
          description: billingMessages.dialogRemoveCardDescription,
          data,
        },
      });
    },
    async setSpaceDefaultPayment(data: IPaymentCardRequest) {
      await makeRequest(setAsSpaceDefaultPaymentMethod(data), {
        success: () => {
          this.getSpacePaymentsMethods({ data });
        },
      });
    },
    async setCompanySpaceRefill({ data, success, fail }) {
      await makeRequest(setCompanySpaceRefillRequest(data), {
        success: (data: any) => {
          if (success) {
            success(data);
          }
        },
        fail,
      });
    },
    async setSpacePaymentsBindingMethod({ data, success, fail }) {
      dispatch.payment.setSpacePaymentsBindingLoaded(true);
      dispatch.dialog.openDialog({
        data: {
          open: true,
          onConfirm: async () => {
            await makeRequest(setSpaceBindingRequest(data), {
              success,
              fail,
            });
          },
          title: billingMessages.dialogNotice,
          description: billingMessages.dialogBindCardDescription,
          data,
          closeDialogCallback: () => {
            dispatch.payment.setSpacePaymentsBindingLoaded(false);
          },
        },
      });
    },
    async setNewCardBind({ data, success, fail }) {
      dispatch.dialog.openDialog({
        data: {
          open: true,
          onConfirm: async () => {
            await makeRequest(setNewCardBindRequest(data), {
              success,
              fail,
            });
          },
          title: billingMessages.dialogNotice,
          description: billingMessages.dialogAddNewCardDescription,
          data,
        },
      });
    },
    async savePaymentCard({
      data,
      success,
      fail,
    }: IRequestType<ISpacePaymentRequest>) {
      await makeRequest(savePaymentCardRequest(data), {
        success: (data: any) => {
          this.getSpacePaymentsMethods({ data: { space_id: data.space_id } });
          success(data);
        },
        fail,
      });
    },
    async makeBindingPayment(data) {
      await makeRequest(makeBindingPaymentRequest(data), {});
    },
  }),
});
