import React, { Component } from "react";
import { Table, Tooltip } from "@intent-ai/mandal-npm-lib-v2";
import { IUser, IDictionaryField } from "../../../types";
import { profileMessages } from "../../../messages";
import {
  goToRouteLinkWithId,
  DictionaryUtils,
} from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { IntlShape, injectIntl } from "react-intl";
import "./style.scss";
import { USER_STATUSES } from "../../../_constants";
import { routerPaths } from "../../../config/routes/paths";
import { formatDateToDMY } from "../../../modules/global/utils/date-utils";

const { formatDictArrayToObject } = DictionaryUtils;

interface IProps {
  moderationUsers: IUser[];
  intl: IntlShape;
  dictUserStatus: IDictionaryField[];
}

class AdminModerationAccounts extends Component<IProps> {
  getColumns = () => {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;
    return [
      {
        title: formatMessage(profileMessages.name),
        dataIndex: "name",
      },
      {
        title: formatMessage(profileMessages.companyName),
        dataIndex: "company_name",
      },
      {
        title: formatMessage(profileMessages.email),
        dataIndex: "email",
      },
      {
        title: formatMessage(profileMessages.phone),
        dataIndex: "phone",
      },
      {
        title: formatMessage(profileMessages.registrationDate),
        dataIndex: "registration_date",
      },
      {
        title: formatMessage(profileMessages.status),
        dataIndex: "status",
      },
    ];
  };
  getData = () => {
    const {
      props: {
        intl: { formatMessage },
        moderationUsers,
        dictUserStatus,
      },
    } = this;

    if (!Array.isArray(moderationUsers)) return [];
    if (moderationUsers.length === 0) return [];
    const dictUserStatusObject = formatDictArrayToObject(dictUserStatus);

    return moderationUsers.map(
      ({
        first_name,
        id,
        last_name,
        email,
        phone,
        created_at,
        company_name,
        status_key,
      }) => {
        return {
          name: `${first_name} ${last_name}`,
          company_name: company_name ? company_name : "-",
          email,
          phone,
          registration_date: formatDateToDMY(created_at),
          status_key: (
            <span>
              {dictUserStatusObject[status_key]}{" "}
              {USER_STATUSES.APPROVED === status_key && (
                <Tooltip
                  tooltip={formatMessage(profileMessages.waitingUserConfirm)}
                />
              )}
            </span>
          ),
          link: goToRouteLinkWithId(routerPaths.ModerationUser, id),
        };
      }
    );
  };
  render() {
    const { getColumns, getData } = this;
    return (
      <div className="accounts-moderation-table-wrapper">
        <Table
          // notFound={renderNotfound()}
          borderBottom
          columns={getColumns()}
          data={getData()}
          firtsFullWidth
          // showLoading
          // isLoading={loading}
        />
      </div>
    );
  }
}

export default injectIntl(AdminModerationAccounts);
