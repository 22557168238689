// @ts-nocheck
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ReactDOM from "react-dom";
import { Dispatch, select } from "../../store";
import { connect } from "react-redux";
import { IRootState } from "../../store";
import { InfoDialogContainer } from "../../containers";
import { tawkInit } from "../../utils";
import { IUser, IWhitelabel } from "../../types";
import { TAWK_API, userRoles } from "../../_constants";
import Loading from "../../modules/global/components/Loading";
// TODO: Children type

interface IProps {
  getUser: Function;
  getCompanyWhitelabel: Function;
  getDictionary: Function;
  children: JSX.Element[] | JSX.Element;
  isUserLoaded?: boolean;
  whitelabelData: IWhitelabel;
  hasCampaign: null | boolean;
  getWhitelabel: Function;
}

class WrappedMainContainer extends Component<IProps> {
  private fbContianer = React.createRef<HTMLDivElement>();

  componentDidMount() {
    const {
      props: { getDictionary, getUser, getWhitelabel },
      getUserSuccessCallback,
    } = this;
    getUser({ success: getUserSuccessCallback });
    getDictionary();
    getWhitelabel();
  }

  getUserSuccessCallback = (user: IUser) => {
    const {
      props: { getCompanyWhitelabel },
    } = this;
    if (user.role_key === userRoles.WL_OWNER) {
      return null;
    }

    getCompanyWhitelabel({
      id: user.whitelabel_id,
      success: (data: IWhitelabel) => {
        if (data.whitelabel_settings.fb_page_id) {
          const { fb_page_id, primary_color } = data.whitelabel_settings;
          const fbElement = ReactDOM.findDOMNode(this.fbContianer.current);
          fbElement.setAttribute("page_id", fb_page_id);
          fbElement.setAttribute("theme_color", `#${primary_color}`);
          FB.init({
            xfbml: true,
            version: "v6.0",
          });
        } else {
          tawkInit(TAWK_API, () => {
            // window.Tawk_API.setAttributes(
            //   {
            //     name: `${user.first_name} ${user.last_name}`,
            //     email: user.email,
            //     "created-at": user.created_at,
            //   },
            //   function() {}
            // );
          });
        }
      },
    });
  };

  render() {
    const {
      props: { children, isUserLoaded, hasCampaign, whitelabelData },
      fbContianer,
    } = this;

    if (!isUserLoaded || (hasCampaign === null && isUserLoaded !== true) || !whitelabelData) {
      return <Loading />;
    }
    return (
      <div className="dsp">
        <div
          ref={fbContianer}
          className="fb-customerchat"
          attribution="install_email"
        />
        <Helmet>
          <title>{whitelabelData?.name}</title>
        </Helmet>
        <InfoDialogContainer>{children}</InfoDialogContainer>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  isUserLoaded: select.authentication.isUserLoaded(state),
  whitelabelData: select.admin.whitelabelDataSelector(state),
  hasCampaign: select.campaign.hasCampaignSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getDictionary: dispatch.dictionary.getDictionary,
  getUser: dispatch.authentication.getUser,
  getCompanyWhitelabel: dispatch.space.getCompanyWhitelabel,
  getWhitelabel: dispatch.admin.getWhitelabel,
});

export const MainContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedMainContainer);
