import React from "react";
import { Field } from "redux-form";
import { injectIntl } from "react-intl";
import {
  ValidatableInputField,
  ContentWrapper,
  MaiRow,
  Switcher,
  SliderField,
  MaiSelectInclExclFieldV2,
} from "@intent-ai/mandal-npm-lib-v2";
import { campaignMessages } from "../../../../messages";
import "./style.scss";

function AudienceFormBasicInfo({
  intl,
  currentData,
  ageGroupToData,
  formChange,
  change,
  dictGender,
  handleCampaignGaugeFieldsChange,
  dictLanguages,
}: any) {
  const { formatMessage } = intl;

  return (
    <div className="campaign-form-basic-info audience-basic-info">
      <ContentWrapper title={formatMessage(campaignMessages.basicInfo)}>
        <Field
          name="name"
          component={ValidatableInputField}
          withLabel
          label={"Audience Name"}
          topLabel
        />
        <MaiRow mp0>
          <MaiRow.Col size={6}>
            <Field
              name="targeting.gender"
              component={Switcher}
              switchType="inline"
              options={dictGender}
              label={formatMessage(campaignMessages.gender)}
              withLabel
              topLabel
              handelChange={handleCampaignGaugeFieldsChange}
            />
          </MaiRow.Col>
          <MaiRow.Col size={6}>
            <SliderField
              fromName="targeting.fromAge"
              toName="targeting.toAge"
              selectData={ageGroupToData}
              placeholder={"Select Range"}
              min={18}
              max={65}
              formChange={change}
              label={formatMessage(campaignMessages.age)}
              currentData={currentData}
              withLabel
              topLabel
              maxLabel="65+"
              handelOnChange={handleCampaignGaugeFieldsChange}
            />
          </MaiRow.Col>
        </MaiRow>
        <MaiRow mp0>
          <MaiSelectInclExclFieldV2
            name="targeting.languages"
            selectData={dictLanguages}
            placeholder={formatMessage(campaignMessages.selectLanguages)}
            formChange={formChange}
            currentData={currentData}
            label={formatMessage(campaignMessages.languages)}
            handelOnChange={handleCampaignGaugeFieldsChange}
            topLabel
            withLabel
            excludeText={formatMessage(campaignMessages.excludeLanguages)}
          />
        </MaiRow>
      </ContentWrapper>
    </div>
  );
}

export default injectIntl(AudienceFormBasicInfo);
