import React from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { MaiRow, Button } from "@intent-ai/mandal-npm-lib-v2";
import { Modal } from "../../../containers";
import { PaymentsWithdrawHistoryModalTable } from "../../../components";
import { IModalProps } from "../../../types";
import { Dispatch, IRootState, select } from "../../../store";
import { connect } from "react-redux";
import "./styles.scss";

interface IProps extends IModalProps {
  intl: IntlShape;
  getWhiteLabelWithdrawHistory: Function;
  whiteLabelWithdrawHistory: boolean;
}

const WrappedPaymentsWithdrawHistoryModal = ({
  closeModal,
  whiteLabelWithdrawHistory,
}: IProps) => {
  return (
    <div className="payments-history-modal">
      <Modal.Header>
        <MaiRow className="align-items-center">
          <MaiRow.Col size={3}>
            <FormattedMessage
              id="Payments.withdrawHistory"
              defaultMessage="Withdraw History"
            />
          </MaiRow.Col>
          <MaiRow.Col size={3} />
          <MaiRow.Col
            size={3}
            customClassName="payments-modal-date-section-container"
          ></MaiRow.Col>
          <MaiRow.Col
            size={3}
            customClassName="payments-modal-date-section-container"
          ></MaiRow.Col>
        </MaiRow>
      </Modal.Header>
      <div style={{ flex: "1 " }}>
        <PaymentsWithdrawHistoryModalTable
          modalHistoryData={whiteLabelWithdrawHistory}
        />
      </div>
      <Modal.Footer>
        <Button
          onClick={closeModal}
          i18nId="ButtonLabel.cancel"
          type="border"
        />
      </Modal.Footer>
    </div>
  );
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getWhiteLabelWithdrawHistory: dispatch.admin.getWhiteLabelWithdrawHistory,
});

const mapStateToProps = (state: IRootState) => ({
  whiteLabelWithdrawHistory: select.admin.whiteLabelWithdrawHistorySelector(
    state
  ),
});
export const PaymentsWithdrawHistoryModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WrappedPaymentsWithdrawHistoryModal));
