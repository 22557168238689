import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IntSelect, numberToStringWithCommas } from "../../../global";
import React, { MouseEventHandler, useEffect, useMemo, useState } from "react";
import { style } from "./style";
import { ReportLightDTO, TrafficReportState } from "../../types";
import { ReportService } from "../../services";
import { StatusCode } from "../../../global/types";
import { Control, SetFieldValue, useWatch } from "react-hook-form";
import { TRAFFIC_SOURCE_SORTING_OPTIONS } from "../../utils";
import { TrafficSourceSortingOption } from "../../types/traffic";

type Props = {
  control: Control<any>;
  selectedTab: 'impressions' | 'clicks';
  setValue: SetFieldValue<any>;
  reset: MouseEventHandler<HTMLButtonElement>;
}

function TrafficSourceReport({ control, selectedTab, setValue, reset }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'reporting.traffic' });

  const [trafficData, setTrafficData] = useState<TrafficReportState>({
    data: null,
    isLoading: true,
  });

  const [sortingOption, setSortingOption] = useState(TrafficSourceSortingOption.HIGH_TO_LOW);

  const formValues = useWatch({
    control,
  });

  function getTrafficData(filter: ReportLightDTO) {
    setTrafficData({ data: null, isLoading: true });
    ReportService.getPublishersReport(filter)
      .then(response => {
        if (response.type?.code == StatusCode.OK) { // eslint-disable-line
          setTrafficData({
            data: response.data,
            isLoading: false,
          });
        }
      })
  }

  useEffect(() => {
    if (formValues.campaignId) {
      getTrafficData({
        ...ReportService.buildReportLightDTO(formValues),
        top_publishers_highest_first: sortingOption === TrafficSourceSortingOption.HIGH_TO_LOW,
      });
    }
  }, [formValues, sortingOption]);

  const trafficSources = useMemo(() => {
    if (!trafficData.data) {
      return [];
    }

    if (trafficData.data[selectedTab].others || trafficData.data[selectedTab].publishers?.length) {
      const sources = [];
      sources.push(...(trafficData.data[selectedTab].publishers || []));
      sources.push({ name: 'Others', value: trafficData.data[selectedTab].others });
      return sources;
    }

    return [];
  }, [trafficData.data, selectedTab]);

  const showChart = !trafficData.isLoading && !!trafficSources.length;

  const total = trafficSources.reduce((accum, curr) => curr.value + accum, 0);

  function handleSortingOptionChange(event: any) {
    const value = event.target.value;
    setSortingOption(value as TrafficSourceSortingOption);
  }

  function handleTrafficSourceChange(name: string) {
    if (name !== 'Others') {
      setValue('publisher', name);
    }
  }

  return (
    <>
      <IntSelect
        options={TRAFFIC_SOURCE_SORTING_OPTIONS}
        value={sortingOption}
        onChange={handleSortingOptionChange}
        sx={style.select}
      />
      {showChart ? (
        <Box sx={style.metrics}>
          {trafficSources.map(({ name, value }) => (
            <Box key={name} sx={style.source} onClick={() => handleTrafficSourceChange(name)}>
              <Typography sx={style.sourceName}>{name}</Typography>
              <Box sx={style.sourceMetrics}>
                <Box sx={style.sourceBar(value / total * 100)} />
                <Typography sx={style.sourceValue}>{numberToStringWithCommas(value)}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box sx={style.noDataBox}>
          {trafficData.isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography sx={style.noDataAvailable}>{t('noData')}</Typography>
              <Button variant='outlined' sx={style.resetFilters} onClick={reset}>{t('resetFilters')}</Button>
            </>
          )}
        </Box>
      )}
    </>
  );
}

TrafficSourceReport.displayName = 'TrafficSourceReport';

export default TrafficSourceReport;