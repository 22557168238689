/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 1/9/2024
 * All Rights Reserved.
 */
import { IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from "@mui/material";
import { AudienceDTO } from "../../types/audience";
import { MouseEvent, useState } from "react";
import { MoreVertIcon } from "../../../global";
import { style } from './style';
import { useTranslation } from "react-i18next";
import { AudienceService } from "../../services";

type Props = {
  audience: AudienceDTO;
  refreshList: () => void;
}

function AudienceListRow({ audience, refreshList }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { t } = useTranslation('translation', { keyPrefix: 'audience' });

  const open = Boolean(anchorEl);

  function handleMenuOpen(event: MouseEvent<HTMLElement>) {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  async function onDelete() {
    await AudienceService.deleteAudience(audience.id);
    refreshList();
  }

  return (
    <TableRow>
      <TableCell>
        <Typography sx={style.simpleText}>
          {audience.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography sx={style.simpleText}>
          {audience.rowCount}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography sx={style.simpleText}>
          {audience.date}
        </Typography>
      </TableCell>
      <TableCell sx={style.iconButton}>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleMenuOpen}
        >
          <MoreVertIcon sx={style.moreVertIcon}/>
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
        >
          <MenuItem dense onClick={onDelete}>
            {t('delete')}
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}

export default AudienceListRow;
