import React, { Component } from "react";
import {
  IntDrawer,
  ValidatableInputField,
} from "@intent-ai/mandal-npm-lib-v2";
import { FetchUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { injectIntl, IntlShape } from "react-intl";
import { AdminDefaultView, AdminSearchView } from "../../../components";
import { AdminSidebar } from "../../../containers";
import { adminMessages } from "../../../messages";
import { connect } from "react-redux";
import { IRootState, Dispatch, select } from "../../../store";
import { IAdminSearchResult } from "../../../types";
import { withRouter, RouteComponentProps } from "react-router-dom";
import "./style.scss";
import { userRoles } from "../../../_constants";
import { NEW_ROUTES } from "../../../modules";
import Loading from "../../../modules/global/components/Loading";
import { routerPaths } from "../../../config/routes/paths";

interface IProps {
  intl: IntlShape;
  open: boolean;
  closeAdminPanel: Function;
  getAdminSearchResult: Function;
  setAdminNewSpace: Function;
  setAdminResultsLoading: Function;
  adminSearchResult: IAdminSearchResult;
  isAdminSearchResultsLoading: boolean;
  userRole: number;
  whiteLabelName: string;
}

interface IState {
  query: string;
}
const { debounce } = FetchUtils;
class AdminDrawer extends Component<IProps & RouteComponentProps, IState> {
  state = {
    query: "",
  };
  componentDidMount() {
    const { fetchData } = this;
    fetchData();
  }

  fetchData = () => {
    const {
      props: { getAdminSearchResult },
      state: { query },
    } = this;
    getAdminSearchResult({ data: { name: query } });
  };

  debounceEvent = debounce(() => {
    const {
      props: { getAdminSearchResult },
      state: { query },
    } = this;
    return getAdminSearchResult({ data: { name: query } });
  }, 250);

  onQueryChange = (e: any) => {
    const {
      props: { setAdminResultsLoading },
      debounceEvent,
    } = this;
    const value = e.target.value;
    this.setState(
      () => ({ query: value }),
      () => {
        setAdminResultsLoading(true);
        debounceEvent();
      }
    );
  };

  onAdminChange = (id: string) => () => {
    const {
      props: {
        setAdminNewSpace,
        closeAdminPanel,
        history: { replace, push },
      },
    } = this;
    setAdminNewSpace({
      id,
      success: () => {
        closeAdminPanel();
        push({ pathname: "/empty" });
        setTimeout(() => {
          replace({ pathname: routerPaths.Campaigns });
        });
      },
    });
  };

  onCampaignChange = (campaignId: string, spaceId: string) => () => {
    const {
      props: {
        history: { replace, push },
        setAdminNewSpace,
      },
    } = this;

    push({ pathname: "/empty" });
    setTimeout(() => {
      setAdminNewSpace({ id: spaceId }).then((res: any) => {
        replace({
          pathname: NEW_ROUTES.campaign.edit.onEdit(campaignId),
        });
      });
    });
  };

  render() {
    const {
      props: {
        intl: { formatMessage },
        open,
        closeAdminPanel,
        adminSearchResult,
        isAdminSearchResultsLoading,
        userRole,
        whiteLabelName,
      },
      state: { query },
      onQueryChange,
      onAdminChange,
      onCampaignChange,
    } = this;

    if (!open) {
      return null;
    }

    const input = {
      value: query,
      onChange: onQueryChange,
    };

    return (
      <div>
        <IntDrawer open={open} placement="top" onClose={closeAdminPanel}>
          <div className="admin-drawer">
            <div className="admin-drawer-content">
              <div>
                <ValidatableInputField
                  placeholder={formatMessage(
                    adminMessages.adminSearchPlaceholder,
                    { whiteLabelName: whiteLabelName || "Intent.ai" }
                  )}
                  input={input}
                  autoFocus
                />
              </div>
              {isAdminSearchResultsLoading ? (
                <Loading />
              ) : query ? (
                <AdminSearchView
                  campaigns={adminSearchResult.campaigns}
                  accounts={adminSearchResult.accounts}
                  query={query}
                  allResult={adminSearchResult.all}
                  onAdminChange={onAdminChange}
                  onCampaignChange={onCampaignChange}
                />
              ) : (
                <AdminDefaultView
                  campaigns={adminSearchResult.campaigns}
                  accounts={adminSearchResult.accounts}
                  onAdminChange={onAdminChange}
                  onCampaignChange={onCampaignChange}
                />
              )}
            </div>
            { userRole !== userRoles.WL_MODERATOR ? (
              <AdminSidebar
                target="_blank"
                moderationCount={
                  adminSearchResult.campaigns_in_moderation_count +
                  adminSearchResult.registrants_in_moderation_count
                }
              />
            ) : null}
          </div>
        </IntDrawer>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  whiteLabelName: select.admin.whiteLabelNameSelector(state),
  adminSearchResult: select.admin.adminSearchResultSelector(state),
  isAdminSearchResultsLoading: select.admin.isAdminSearchResultsLoadingSelector(
    state
  ),
  userRole: select.authentication.selectCurrentUserRoleId(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAdminSearchResult: dispatch.admin.getAdminSearchResult,
  setAdminNewSpace: dispatch.admin.setAdminNewSpace,
  setAdminResultsLoading: dispatch.admin.setAdminResultsLoading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(AdminDrawer)));
