import { Report } from "../../modules/report/components";
import { Profile, Settings } from "../../modules/profile/components";
import {
  AdminAccounts, AdminPublishers,
  AdminTransactions,
  // AudienceNotFound,
  Billing, ConfirmRegistration,
  Customization,
  // EditAudience,
  Income, LegalEntityUser,
  Moderation, ModerationUser,
  // NewAudience,
  NotFound,
  ResetPassword, TokenValidation, Transactions,
} from "../../screens";
import AudienceList from "../../modules/audience/components/AudienceList";
import { CampaignForm, CampaignList } from "../../modules/campaign/components";
import { ForgotPassword, Login, Signup } from "../../modules/auth/components";
import { IsAccountant, IsUser, paths, WithAuthenticated, WithNotAuthenticated } from "./paths";
import { AdminRoutes, AuthRoutes } from "./components";
import Events from "../../modules/events";



export const authRouterConfig: IRoutConfig = {
  routes: [
    {
      middlewares: [IsUser, WithAuthenticated, AuthRoutes],
      path: "/reports",
      component: Report,
    },
    {
      middlewares: [WithAuthenticated, AuthRoutes],
      path: paths.profile,
      component: Profile,
    },
    {
      middlewares: [WithAuthenticated, AuthRoutes],
      path: paths.profileAdmin,
      component: Profile,
    },
    {
      middlewares: [IsUser, WithAuthenticated, AuthRoutes],
      path: paths.settings,
      component: Settings,
    },
    {
      middlewares: [IsUser, WithAuthenticated, AuthRoutes],
      path: paths.billing,
      component: Billing,
    },
    {
      middlewares: [IsUser, WithAuthenticated, AuthRoutes],
      path: paths.events,
      component: Events,
    },
    {
      middlewares: [IsUser, WithAuthenticated, AuthRoutes],
      path: paths.audience,
      component: AudienceList,
      children: [
        // {
        //   path: paths.create,
        //   component: NewAudience,
        // },
        // {
        //   path: paths.edit + paths.id,
        //   component: EditAudience,
        // },
      ],
    },
    // {
    //   middlewares: [IsUser, WithAuthenticated, AuthRoutes],
    //   path: paths.audienceUnderConstruction,
    //   component: AudienceNotFound,
    // },
    {
      middlewares: [IsUser, WithAuthenticated, AuthRoutes],
      path: paths.campaigns,
      component: CampaignList,
      children: [
        {
          path: paths.edit + paths.id,
          component: CampaignForm,
        },
        {
          path: paths.create,
          component: CampaignForm,
        },
      ],
    },
  ],
};

export const adminRouterConfig = {
  routes: [
    {
      path: paths.admin,
      middlewares: [IsUser, WithAuthenticated, AdminRoutes],
      children: [
        {
          path: paths.customization,
          component: Customization,
        },
        {
          path: paths.income,
          component: Income,
        },
        {
          path: paths.moderation,
          component: Moderation,
        },
        {
          path: paths.transactions,
          component: AdminTransactions,
        },
        {
          path: paths.accounts,
          component: AdminAccounts,
        },
        {
          path: paths.publishers,
          component: AdminPublishers,
        },
        {
          path: paths.legalEntity + paths.user + paths.id,
          component: LegalEntityUser,
        },
        {
          path: paths.moderation + paths.user + paths.id,
          component: ModerationUser,
        },
      ],
    },
  ],
};

export const transactionsRouterConfig = {
  routes: [
    {
      middlewares: [IsAccountant, WithAuthenticated, AuthRoutes],
      path: paths.transactions,
      component: Transactions,
    },
  ],
};

export const routerConfig = {
  routes: [
    {
      path: paths.auth,
      middlewares: [WithNotAuthenticated],
      children: [
        {
          path: paths.login,
          component: Login,
        },
        {
          path: paths.tokenValidation,
          component: TokenValidation,
        },
        {
          path: paths.confirmRegistration,
          component: ConfirmRegistration,
        },
        {
          path: paths.signup,
          component: Signup,
        },
        {
          path: paths.forgotPassword,
          component: ForgotPassword,
        },
        {
          path: paths.resetPassword,
          component: ResetPassword,
        },
      ],
    },
  ],
};

export const notFoundConfig = WithAuthenticated(NotFound);
export const defaultPath = paths.campaigns;