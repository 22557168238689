import React from "react";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Font,
  Defs,
  LinearGradient,
  Stop,
  Svg,
  Rect,
} from "@react-pdf/renderer";
import { Image } from "@react-pdf/renderer";
import blue_logo from "../../assets/images/blue_logo.png";
import fontItalic from "../../assets/fonts/SourceSansPro-Italic.ttf";
import bold from "../../assets/fonts/Gilroy-ExtraBold.otf";
import light from "../../assets/fonts/Gilroy-Light.otf";
import { styles } from "./pdfStyes";
import { formatDate } from "../../modules";


Font.register({
  family: "Helvetica-Bold",
  fonts: [
    {
      format: "truetype",
      src: light,
    },
    {
      format: "truetype",
      src: bold,
      fontWeight: "bold",
    },
    {
      format: "truetype",
      src: fontItalic,
      fontStyle: "italic",
    },
  ],
});

const stylesPdf: any = StyleSheet.create(styles);

const InvoicePDF = ({ invoice }: any) => {
  return (
    <Document>
      <Page size="A4" style={stylesPdf.body}>
        <Header invoice={invoice} />
        <PaymentSection invoice={invoice} />
        <Body invoice={invoice} />
        <InvoiceFooter invoice={invoice} />
      </Page>
    </Document>
  );
};

const Header = ({ invoice }: any) => {
  return (
    <View style={stylesPdf.headerContainer}>
      <View style={stylesPdf.headerLeftBox}>
        <Text style={stylesPdf.headerOwner}>
          Tax Invoice for {invoice?.user_name}
        </Text>
        <Text style={stylesPdf.headerAccountId}>
          Account ID: {invoice.user_id}
        </Text>
      </View>
      <View style={stylesPdf.headerRightBox}>
        <Image src={blue_logo} style={stylesPdf.logo} />
      </View>
    </View>
  );
};

const PaymentSection = ({ invoice: { payment_transaction, ...rest } }: any) => {
  return (
    <View style={stylesPdf.paymentSection}>
      <View style={stylesPdf.paymentSectionLeftBox}>
        <Text style={stylesPdf.paid}>Paid</Text>
        <Text
          style={{
            ...stylesPdf.paymentSectionCurrency,
            fontFamily: "Helvetica-Bold",
            fontWeight: "bold",
          }}
        >
          {`$${rest.total} USD`}
        </Text>
        <Text style={stylesPdf.description}>
          Remaining ad costs at the end of the month.
        </Text>
        <View style={stylesPdf.dateContainer}>
          <Text style={stylesPdf.invoicePayment}>Invoice/Payment Date</Text>
          <Text style={stylesPdf.date}>
            {formatDate(payment_transaction.transaction_date, 'MMM DD, YYYY HH:mm')}
          </Text>
        </View>
      </View>
      {rest.status === "NEW" ? null : (
        <View style={stylesPdf.paymentSectionRightBox}>
          <View style={stylesPdf.paymentSectionRightBoxInner}>
            <Text style={stylesPdf.bold}>Payment method</Text>
            <Text style={stylesPdf.rightBoxDescription}>
              {payment_transaction.credit_card_type
                ? `${
                    payment_transaction.credit_card_type
                  } · ${payment_transaction.card_number.substring(
                    payment_transaction.card_number.length - 4,
                    payment_transaction.card_number.length
                  )} `
                : ""}
            </Text>
            <Text style={stylesPdf.rightBoxDescription}>
              Invoice ID: {payment_transaction.invoice_id ?? ""}
            </Text>
          </View>
          <View style={stylesPdf.paymentSectionRightBoxInner}>
            <Text style={stylesPdf.bold}>Transaction ID</Text>
            <Text style={stylesPdf.rightBoxDescription}>
              {payment_transaction.transaction_id ?? ""}
            </Text>
          </View>
          <View style={stylesPdf.paymentSectionRightBoxInner}>
            <Text style={stylesPdf.bold}>Product type</Text>
            <Text style={stylesPdf.rightBoxDescription}>Intent Ads</Text>
          </View>
        </View>
      )}
    </View>
  );
};

const Body = ({ invoice }: any) => {
  return (
    <View style={stylesPdf.bodyContainer}>
      <View
        style={{
          borderBottom: "1px solid #EAEEF3",
          marginTop: 22,
          paddingBottom: 8,
          fontSize: 12,
        }}
      >
        <Text>Campaigns</Text>
      </View>
      <BodyItem invoice={invoice} />
    </View>
  );
};
const BodyItem = ({ invoice }: any) => {
  return (
    <View>
      {invoice?.line_items?.map((item: any) => {
        return (
          <View
            key={item.campaign_id}
            style={{
              borderBottom: "1px solid #EAEEF3",
              marginTop: 11,
            }}
          >
            <View
              style={{
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <View>
                <Text
                  style={{
                    fontSize: 8,
                    textTransform: "uppercase",
                  }}
                >
                  Job: {item.campaign_title}
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    color: "#272C36",
                    marginTop: 4,
                  }}
                >
                  {`${formatDate(
                    invoice.charge_from_date,'MMM DD, YYYY HH:mm'
                  )} to ${formatDate(invoice.charge_to_date,'MMM DD, YYYY HH:mm')}`}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    fontSize: 8,
                    fontFamily: "Helvetica-Bold",
                    fontWeight: "bold",
                  }}
                >
                  {`$${item.amount}`}
                </Text>
              </View>
            </View>
            <View
              style={{
                borderTop: "1px solid #EAEEF3",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: 50,
                marginTop: 10,
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <Text
                style={{
                  fontSize: 7,
                  textTransform: "uppercase",
                  color: "#272C36",
                }}
              >
                Job: {item.campaign_title}
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  color: "#272C36",
                }}
              >
                {item.impression_count} Impressions
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  color: "#272C36",
                }}
              >
                {`$${item.amount}`}
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

const InvoiceFooter = ({ invoice }: any) => {
  return (
    <View
      fixed
      style={{
        // backgroundColor: "#3A77EB",
        // paddingTop: 11,
        // paddingBottom: 11,
        // paddingLeft: 16,
        // paddingRight: 16,
        // marginTop: 100,
        borderRadius: 8,
        position: "absolute",
        bottom: 30,
        left: 40,
      }}
    >
      <Svg viewBox="0 0 495 30" width="100%" height="30px">
        <Defs>
          {/*@ts-ignore*/}
          <LinearGradient id="gradient">
            <Stop
              offset="5%"
              stopColor="#3A77EB
"
            />
            <Stop
              offset="95%"
              stopColor="#6CA1F2
"
            />
          </LinearGradient>
        </Defs>

        <Rect
          x="0"
          y="0"
          rx={8}
          ry={8}
          width={495}
          height={30}
          fill="url('#gradient')"
        />
      </Svg>
      <Text
        style={{
          fontSize: 8,
          fontFamily: "Helvetica-Bold",
          fontWeight: "bold",
          color: "#fff",
          position: "absolute",
          left: 21,
          top: 10,
        }}
      >
        intent.ai
      </Text>
      <Text
        style={{
          fontSize: 7,
          color: "#fff",
          position: "absolute",
          right: 21,
          top: 11,
        }}
      >
        4 Grand Canal Square, Grand Canal Harbour, VAT ID: 02865916
      </Text>
    </View>
  );
};
export default InvoicePDF;
