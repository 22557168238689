import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import "./style.scss";
import clsx from "clsx";
import { ArrowIcon } from "../../../modules";
import { routerPaths } from "../../../config/routes/paths";

interface IProps {
  target?: string;
  moderationCount?: number;
  withToggle?: boolean;
  setSideBar?: any;
}

export default function AdminSidebar({
  target,
  moderationCount,

}: IProps) {
  const [isActive] = React.useState(false);

  return (
    <div className={clsx("admin-drawer-links", { active: isActive })}>
      <h3 className="admin-drawer-links-title">
        <FormattedMessage id="Admin.adminLinks" defaultMessage="Admin Links" />
      </h3>
      <ul className="admin-drawer-links-list">
        <li>
          <Link to={routerPaths.Customization} target={target}>
            <FormattedMessage
              id="Admin.adminLinksCustomizationSettings"
              defaultMessage="Customization"
            />
          </Link>
          <ArrowIcon className='admin-icon' />
        </li>
        <li>
          <Link to={routerPaths.Income} target={target}>
            <FormattedMessage
              id="Admin.adminLinksIncome"
              defaultMessage="Income"
            />
          </Link>
          <ArrowIcon className='admin-icon' />
        </li>
        <li>
          <Link to={routerPaths.AdminAccounts} target={target}>
            <FormattedMessage
              id="Admin.adminLinksAccounts"
              defaultMessage="Accounts"
            />
          </Link>
          <ArrowIcon className='admin-icon' />
        </li>
        <li>
          <Link to={routerPaths.AdminTransactions} target={target}>
            <FormattedMessage
              id="Admin.adminLinksTransactions"
              defaultMessage="Transactions"
            />
          </Link>
          <ArrowIcon className='admin-icon' />
        </li>
        <li>
          <Link to={routerPaths.Moderation} target={target}>
            <div className="admin-sidebar-moderation-link">
              <FormattedMessage
                id="Admin.adminLinksModeration"
                defaultMessage="Moderation"
              />
              {moderationCount !== 0 && moderationCount && (
                <div className="admin-sidebar-moderation-link-count">
                  {moderationCount}
                </div>
              )}
            </div>
          </Link>
          <ArrowIcon className='admin-icon' />
        </li>
        <li>
          <Link to={routerPaths.AdminPublishers} target={target}>
            <div className="admin-sidebar-moderation-link">
              <FormattedMessage
                id="Admin.adminLinksPublishers"
                defaultMessage="Publishers"
              />
            </div>
          </Link>
          <ArrowIcon className='admin-icon' />
        </li>
        {/* <li>
          <Link to={routerPaths.LegalEntity} target={target}>
            <div className="admin-sidebar-moderation-link">
              <FormattedMessage
                id="Admin.adminLinksLegalEntity"
                defaultMessage="Legal Entity"
              />
            </div>
          </Link>
          <IntIcon type="arrow_right" />
        </li> */}
        {/* <li className="disabled">
          <FormattedMessage
            id="Admin.adminLinksUserManagement"
            defaultMessage="Staff Management"
          />
          <IntIcon type="arrow_right" />
        </li> */}
      </ul>
    </div>
  );
}
