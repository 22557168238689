import React from "react";
import { Flex } from "@intent-ai/mandal-npm-lib-v2";
import { creditCardTypes } from "../../../_constants";
import "./style.scss";

interface IProps {
  credit_card_type_key: number;
  expiration_month: string;
  expiration_year: string;
  masked_number: string;
}

export function ModalPaymentCard({
  credit_card_type_key,
  expiration_month,
  expiration_year,
  masked_number,
}: IProps) {
  return (
    <Flex customClassName="payment-card-container" alignItems="center">
      <Flex customClassName="payment-card-details">
        <div className="payment-card-image">
          <img
            src={creditCardTypes[credit_card_type_key].image}
            alt="Credit card"
          />
        </div>
        <div className="payment-card-info">
          <p className="payment-card-info-number">
            {creditCardTypes[credit_card_type_key].name} {masked_number}
          </p>
          <p className="payment-card-info-date">
            {expiration_month}/{expiration_year}
          </p>
        </div>
      </Flex>
    </Flex>
  );
}
