import { FetchUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { POST, PUT } from "../_constants";
import {
  IAdminSearch,
  IWhitelabel,
  IModerationAction,
  IBaseFilters,
} from "../types";
import { ApiService } from "../modules/global/services";

const { createRequest } = FetchUtils;
const EMPTY_OBJECT = {};
const HEADER_PLAIN_TEXT = {
  "Content-Type": "text/plain",
};

export const getAdminSearchResultRequest = (data: IAdminSearch) =>
  createRequest("/whitelabel/search", POST, EMPTY_OBJECT, data);

export const setAdminNewSpaceRequest = (id: string) =>
  createRequest(`/user/account/switch/${id}`, POST, EMPTY_OBJECT, {});

export const getWhitelabelCustomizationDataRequest = (id: string) =>
  createRequest(`/whitelabel/${id}`);

export const setWhitelabelCustomizationDataRequest = (data: IWhitelabel) =>
  createRequest(`/whitelabel`, PUT, EMPTY_OBJECT, data);

export const getWhiteLabelWithdrawHistoryRequest = (data = EMPTY_OBJECT) =>
  createRequest("/cash-out/history", POST, EMPTY_OBJECT, data);

export const getWithdrawBalanceRequest = () =>
  createRequest(`/cash-out/withdraw/balance`);

export const getIncomeOverviewRequest = (data = EMPTY_OBJECT) =>
  createRequest(`/reports/whitelabel/income/history`, POST, EMPTY_OBJECT, data);

export const sendWithdrawAmountRequest = (amount = 0) =>
  createRequest(`/cash-out/${amount}`);

export const approveCampaignRequest = (data = EMPTY_OBJECT) =>
  createRequest(`/whitelabel/admin/campaign/approve`, POST, EMPTY_OBJECT, data);

export const rejectCampaignRequest = (data = EMPTY_OBJECT) =>
  createRequest(`/whitelabel/admin/campaign/reject`, POST, EMPTY_OBJECT, data);

export const getModerationRegistrationsRequest = (data = EMPTY_OBJECT) =>
  createRequest(`/whitelabel/admin/registrations`, POST, EMPTY_OBJECT, data);

export const getModerationLegalUsersRequest = (data = EMPTY_OBJECT) =>
  createRequest(`/whitelabel/admin/legal/users`, POST, EMPTY_OBJECT, data);

export const setModerationLegalUsersApproveRequest = (
  data: IModerationAction
) => createRequest(`/whitelabel/admin/user/approve`, POST, EMPTY_OBJECT, data);

export const setModerationLegalUsersRejectRequest = (data: IModerationAction) =>
  createRequest(`/whitelabel/admin/user/reject`, POST, EMPTY_OBJECT, data);

export const getModerationRegistrationRequest = (id: string) =>
  createRequest(`/whitelabel/admin/user/${id}`);

export const setModerationRegistrationApproveRequest = (data = EMPTY_OBJECT) =>
  createRequest(
    `/whitelabel/admin/registration/approve`,
    POST,
    EMPTY_OBJECT,
    data
  );

export const resendConfirmationEmailRequest = (id: string) =>
  createRequest(
    `/whitelabel/user/confirmation/email/resend`,
    POST,
    EMPTY_OBJECT,
    id,
    HEADER_PLAIN_TEXT
  );

export const getModerationCampaignRequest = (data = EMPTY_OBJECT) =>
  createRequest(`/whitelabel/admin/campaigns`, POST, EMPTY_OBJECT, data);

export const getCompanyWhitelabelRequest = (companyId: string) =>
  createRequest(`/whitelabel/${companyId}`);

export const getCompanyWhitelabelDataRequest = () =>
  createRequest("/whitelabel/info");

export const getAdminAccountsListRequest = (filters: IBaseFilters) =>
  createRequest(`/whitelabel/admin/accounts/list`, POST, EMPTY_OBJECT, filters);

export const getAdminPublisherListRequest = (filters: IBaseFilters) =>
  createRequest(
    `/whitelabel/admin/publishers/list`,
    POST,
    EMPTY_OBJECT,
    filters
  );

export const getAdminAccountsExcelReportRequest = (filters: IBaseFilters) =>
  createRequest(
    `/whitelabel/admin/accounts/excel`,
    POST,
    EMPTY_OBJECT,
    filters
  );

export const getAdminAccountsTotalsRequest = () =>
  createRequest(
    `/whitelabel/admin/accounts/totals`,
    POST,
    EMPTY_OBJECT,
    EMPTY_OBJECT
  );

export const adminNewSpaceRequest = (id: string) =>
 ApiService.post(`${window.REACT_APP_BASE_URL}/v1/user/account/switch/${id}`, {});
