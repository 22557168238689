import { MenuItem } from "../../types";
import { style } from "./style";
import { Box, Collapse, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { ArrowDown, InfoRoundedIcon } from "../../../global";

type Props = {
  menuItem: Omit<MenuItem, 'advanced'>;
  // menuItem: MenuItem;
  tab: string;
  handleExpandedChange?: (expanded: boolean) => void;
  onTabChange: (tab: string) => void;
}

function CampaignFormMenuItem({ menuItem, tab, onTabChange, handleExpandedChange}: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.form' });

  useEffect(() => {
    const hasError = (menuItem.children || []).some(({ hasError }) => hasError);
    if (hasError) {
      handleExpandedChange && handleExpandedChange(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItem.children]);

  useEffect(() => {
    if((menuItem.children || []).some(({ value }) => value === tab)){
      handleExpandedChange && handleExpandedChange(true);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, menuItem]);

  const hasChildren = Array.isArray(menuItem.children) && menuItem.children.length > 0;

  return (
    <>
      <Box
        sx={[
          style.tabWrapper,
          tab === menuItem.value && style.selectedTab,
          !!menuItem.expanded && hasChildren && style.childSelected,
          hasChildren && style.tabWithChildren
        ]}
        onClick={hasChildren ? () => handleExpandedChange && handleExpandedChange(!menuItem.expanded) :
          () => {
            handleExpandedChange && handleExpandedChange(false)
            onTabChange(menuItem.value)
        }}
      >
        <Typography sx={style.tab}>
          {t(menuItem.label)}
          {menuItem.hasError && (
            <InfoRoundedIcon sx={style.infoRoundedIcon}/>
          )}
        </Typography>
        {hasChildren && (
          <ArrowDown
            viewBox='0 0 48 48'
            sx={style.arrowIcon(!!menuItem.expanded)}
          />
        )}
      </Box>
      {hasChildren && (
        <Collapse in={menuItem.expanded} timeout="auto" unmountOnExit>
          <Box sx={style.collapsed}>
            {(menuItem.children as Array<MenuItem>).map(item => (
              <CampaignFormMenuItem menuItem={item} tab={tab} onTabChange={onTabChange} key={item.id} />
            ))}
          </Box>
        </Collapse>
      )}
    </>
  )
}

CampaignFormMenuItem.displayName = 'CampaignFormMenuItem';

export default CampaignFormMenuItem;
