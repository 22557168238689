import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import { Modal } from "../../../containers";
import { Button } from "@intent-ai/mandal-npm-lib-v2";
import "./style.scss";

interface IProps {
  intl: IntlShape;
  closeModal: Function;
  modalData: any;
}

const ArchiveWarningModal = ({
  closeModal,
  modalData: { title = "", description = "", ButtonLabel = "", action },
}: IProps) => {
  const handleArchiveButtonClick = async () => {
    await action();
    closeModal();
  };

  return (
    <div className="archive-warning-modal-wrapper">
      <Modal.Header>
        <p>{title}</p>
      </Modal.Header>
      <div className="archive-warning-modal-container">
        <p className="archive-warning-modal-description">{description}</p>
      </div>
      <Modal.Footer>
        <Button i18nId={ButtonLabel} onClick={handleArchiveButtonClick} />
        <Button
          type="border"
          i18nId="ButtonLabel.cancel"
          onClick={closeModal}
        />
      </Modal.Footer>
    </div>
  );
};
export default injectIntl(ArchiveWarningModal);
