import React, { Component } from "react";
import { injectIntl } from "react-intl";
import {
  RangeInput,
  AudienceSizeIndicator,
  NumberAnimatedLoading,
} from "../../components";
import { Tooltip } from "@intent-ai/mandal-npm-lib-v2";

import "./style.scss";
import { CAMPAIGN_FORM } from "../../_constants";


function nFormatter(num: any) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(0).replace(/\.0$/, "") + "b";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(0).replace(/\.0$/, "") + "m";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(0).replace(/\.0$/, "") + "k";
  }
  return num;
}
class AudienceSize extends Component<any> {
  render() {
    const {
      props: {
        audienceEstimatedSizeIsLoading,
        currentData,
        audienceSizeData,
        formChange,
        handleCampaignGaugeFieldsChange,
        isAudience,
      },
    } = this;
    const estimateImpressions = audienceSizeData?.estimate * 960 ?? 0;

    //TODO remove
    const isNG = window.location.host.includes('ng-app');
    const isTN = window.location.host.includes('tn-dsp');

    return (
      <div className="audience-size-container">
        {isAudience && (
          <div
            className="audience-size-title"
            style={{
              padding: "1rem 0 0 0",
            }}
          >
            <h1>AUDIENCE SIZE</h1>
          </div>
        )}
        <div className="audience-estimated-size">
          <p className="audience-estimated-size-count">
            {audienceEstimatedSizeIsLoading ? (
              <div
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  marginTop: "5px",
                  lineHeight: "inherit",
                  height: "inherit",
                }}
              >
                <NumberAnimatedLoading />
              </div>
            ) : currentData.targeting_on ? isTN ? '4m' : nFormatter(audienceSizeData.estimate) : (
              isNG ? '43.7M' : isTN ? '12.5M' : "2.9M"
            )}
          </p>

          <Tooltip tooltip="To estimate the audience size we use Age, Gender and Interests chosen in the Targeting settings" />
        </div>
        <div className="impressions-month-container">
          <h1
            className="impressions-month-heading"
            style={{
              marginTop: audienceEstimatedSizeIsLoading ? "31px" : "20px",
            }}
          >
            impressions / month
          </h1>
          <p className="impressions-month">
            {audienceEstimatedSizeIsLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "12px",
                  marginLeft: "20px",
                }}
              >
                <NumberAnimatedLoading />
              </div>
            ) : currentData.targeting_on ? isTN ? '10B' : nFormatter(estimateImpressions) : (
              isNG ? '110B' : isTN ? '30B' : "2.2B"
            )}
          </p>
        </div>
        {currentData.targeting_on && (
          <>
            {" "}
            <p className="audience-size-description">ESTIMATED AUDIence size</p>
            <div className="audience-size-indicator-container">
              <AudienceSizeIndicator estimate={audienceSizeData.estimate} />
            </div>
            <p className="audience-size-lookalikes">Lookalikes</p>
            <div className="audience-size-range-container">
              <RangeInput
                currentData={currentData}
                onRangeChange={(e: number) => {
                  formChange(CAMPAIGN_FORM, "similarity", e);
                  handleCampaignGaugeFieldsChange();
                }}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default injectIntl(AudienceSize);
