import React from "react";
import { Table } from "@intent-ai/mandal-npm-lib-v2";
import { injectIntl, defineMessages, IntlShape } from "react-intl";
import { formatDate, Loading, numberToStringWithCommas } from "../../../modules/global";


const messages = defineMessages({
  tableHeadTransactionDate: {
    id: "Payments.tableHeadTransactionDate",
    defineMessages: "Transaction Date",
  },
  tableHeadBalanceBeforeWithdraw: {
    id: "Payments.tableHeadBalanceBeforeWithdraw",
    defineMessages: "Balance Before Withdraw",
  },
  tableHeadBalanceAfterWithdraw: {
    id: "Payments.tableHeadBalanceAfterWithdraw",
    defineMessages: "Balance After Withdraw",
  },
  tableHeadAmountWithdrawOut: {
    id: "Payments.tableHeadAmountWithdrawOut",
    defineMessages: "Amount Withdraw Out",
  },
});

interface IProps {
  intl: IntlShape;
  modalHistoryData: any;
}

const WrappedPaymentsWithdrawHistoryModalTable = ({
  intl: { formatMessage },
  modalHistoryData,
}: IProps) => {
  if (!modalHistoryData) {
    return <Loading />;
  }

  const getColumns = [
    {
      title: formatMessage(messages.tableHeadTransactionDate),
      dataIndex: "created_at",
    },
    {
      title: formatMessage(messages.tableHeadBalanceBeforeWithdraw),
      dataIndex: "before_amount",
    },
    {
      title: formatMessage(messages.tableHeadBalanceAfterWithdraw),
      dataIndex: "after_amount",
    },
    {
      title: formatMessage(messages.tableHeadAmountWithdrawOut),
      dataIndex: "amount",
    },
  ];

  const getData = () =>
    modalHistoryData.map(({ created_at, amount, before_amount }: any) => {
      return {
        created_at: (
          <span className="mai-bold">{formatDate(created_at,'YYYY-MM-DD')}</span>
        ),
        before_amount: `$ ${numberToStringWithCommas(before_amount)}`,
        after_amount: `$ ${numberToStringWithCommas(before_amount - amount)}`,
        amount: (
          <span className="payments-withdraw-modal-table-amount">
            $ {numberToStringWithCommas(amount)}
          </span>
        ),
      };
    });

  return (
    <div>
      <Table columns={getColumns} data={getData()} h100 />
    </div>
  );
};

export const PaymentsWithdrawHistoryModalTable = injectIntl(
  WrappedPaymentsWithdrawHistoryModalTable
);
