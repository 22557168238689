import React, { Component } from "react";
import { Modal } from "../../../containers";
import { IModalProps, IDictionaryField } from "../../../types";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { connect } from "react-redux";
import { IStore, select, Dispatch } from "../../../store";
import "./style.scss";
import { MaiSelect, Button, Flex, NotificationApi } from "@intent-ai/mandal-npm-lib-v2";
import { campaignMessages } from "../../../messages";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { remove_icon } from "../../../assets";
import { routerPaths } from "../../../config/routes/paths";

interface IProps extends IModalProps {
  getDictionaryValues: Function;
  dictIabCategories: IDictionaryField[];
  approveCampaign: Function;
  intl: IntlShape;
}

interface IState {
  value: number[];
}

class ApproveCampaignModal extends Component<
  IProps & RouteComponentProps,
  IState
> {
  state = {
    value: [],
  };

  categoryChange = (value: number[]) => this.setState(() => ({ value }));

  handleApproveCampaign = () => {
    const {
      props: {
        approveCampaign,
        modalData,
        intl: { formatMessage },
        closeModal,
        history: { push },
      },
      state: { value },
    } = this;
    approveCampaign({
      data: {
        campaign_id: modalData.campaignId,
        moderation_start_at: new Date(),
        category_keys: value,
      },
      fail: () =>
        NotificationApi.error(formatMessage(campaignMessages.globalError)),
      success: () => {
        NotificationApi.success(
          formatMessage(campaignMessages.campaignApproved)
        );
        push(routerPaths.Campaigns);
      },
    });
    closeModal();
  };

  render() {
    const {
      state: { value },
      props: { dictIabCategories, closeModal },
      categoryChange,
      handleApproveCampaign,
    } = this;
    const input = {
      value,
      onChange: categoryChange,
    };
    return (
      <div className="in-moderation-modal">
        <img
          src={remove_icon}
          alt="remove-icon"
          className="approve-modal-close-icon"
          onClick={() => closeModal()}
        />
        <Modal.Header>
          <FormattedMessage
            id="Admin.chooseCampaignCategory"
            defaultMessage="Choose Campaign Category"
          />
        </Modal.Header>
        <div className="admin-approve-campaign">
          <p>
            <FormattedMessage
              id="Admin.chooseCampaignCategoryDescription"
              defaultMessage="Some websites allow/disallow certain ad categories on their websites. This category will help us identify the campaigns which need to be included or excluded from certain web pages. "
            />
          </p>
          <div className="admin-approve-campaign-select-box">
            <MaiSelect input={input} selectData={dictIabCategories} />
          </div>
          <Flex justifyContent="center">
            <Button
              i18nId="ButtonLabel.confirm"
              onClick={handleApproveCampaign}
            />
          </Flex>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  dictIabCategories: select.dictionary.selectDictionaryField(
    state,
    "iab_categories"
  ),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getDictionaryValues: dispatch.dictionary.getDictionaryValues,
  approveCampaign: dispatch.admin.approveCampaign,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(ApproveCampaignModal)));
