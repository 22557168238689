import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  FormattedMessage,
  injectIntl,
  defineMessages,
  IntlShape,
} from "react-intl";
import { Button, ValidatableInputField, Flex } from "@intent-ai/mandal-npm-lib-v2";

import {
  APIResponseFail,
  APIResponseSuccess,
} from "@intent-ai/mandal-npm-lib-v2/dist/actions";
import { Modal } from "../../../containers";
import { Dispatch } from "../../../store";
import "./styles.scss";
import { numberToStringWithCommas } from "../../../modules";


const messages = defineMessages({
  withdrawFail: {
    id: "Payments.WithdrawFailure",
    defaultMessage: "Withdraw Failure",
  },
  withdrawFailDescription: {
    id: "Payments.WithdrawFailureDescription",
    defaultMessage:
      "Something went wrong during withdrawal process, please try again later.",
  },
  withdrawSuccess: {
    id: "Payments.WithdrawSuccessful",
    defaultMessage:
      "Something went wrong during withdrawal process, please try again later.",
  },
  withdrawSuccessDescription: {
    id: "Payments.WithdrawSuccessfulDescription",
    defaultMessage:
      "Withdrawal processed successfully, we will contant you shortly.",
  },
});

interface IProps {
  modalData: any;
  sendWithdrawAmount: Function;
  APIResponseSuccess: Function;
  APIResponseFail: Function;
  intl: IntlShape;
  closeModal: Function;
}

interface IState {
  value: string;
  error: boolean;
}

export class WrappedWithdrawModal extends Component<IProps, IState> {
  state = {
    value: "",
    error: false,
  };

  onChange = ({ target: { value } }: any) => {
    const {
      props: {
        modalData: { withdrawBalance },
      },
    } = this;
    this.setState(() => ({ value }));
    if (Number(value) <= Number(withdrawBalance) && Number(value) > 50000) {
      this.setState(() => ({ error: false }));
    } else {
      this.setState(() => ({ error: true }));
    }
  };

  onSubmit = (e: any) => {
    e.preventDefault();
    const {
      props: { sendWithdrawAmount },
      state: { value: amount },
      onWithdrawFail: fail,
      onWithdrawSuccess: success,
    } = this;
    sendWithdrawAmount({ amount, fail, success });
  };

  onWithdrawFail = () => {
    const {
      props: {
        intl: { formatMessage },
        APIResponseFail,
      },
    } = this;
    APIResponseFail(
      formatMessage(messages.withdrawFail),
      formatMessage(messages.withdrawFailDescription)
    );
  };

  onWithdrawSuccess = () => {
    const {
      props: {
        intl: { formatMessage },
        APIResponseSuccess,
        closeModal,
      },
    } = this;

    APIResponseSuccess(
      formatMessage(messages.withdrawSuccess),
      formatMessage(messages.withdrawSuccessDescription),
      () => closeModal()
    );
  };

  render() {
    const {
      props: {
        closeModal,
        modalData: { withdrawBalance },
      },
      state: { value, error },
      onChange,
      onSubmit,
    } = this;

    const meta = {
      touched: error,
      error: {
        id: "Payments.withdrawError",
        defaultMessage:
          "Amount must be more 50000 amd and less then your current balance",
      },
    };
    return (
      <form onSubmit={onSubmit}>
        <div className="payments-cash-out-modal">
          <Modal.Header>
            <FormattedMessage
              id="Payments.withdrawFunds"
              defaultMessage="Withdraw FUNDS"
            />
          </Modal.Header>
          <div className="payments-cash-out-modal-container">
            <p className="payments-cash-out-modal-available-balance">
              <FormattedMessage
                id="Payments.withdrawFundsAvailable"
                defaultMessage="Available to Withdraw"
              />
            </p>
            <p className="payments-cash-out-modal-available-ammount">
              $ {numberToStringWithCommas(withdrawBalance, 2)}
            </p>
            <ValidatableInputField
              currentValue={value}
              onChange={onChange}
              type="number"
              meta={meta}
            />
            <FormattedMessage
              id="withdrawDescription"
              defaultMessage="Please indicate the exact amount to be withdraw out."
            />
          </div>
          <Modal.Footer>
            <Flex style={{ width: "100%" }} justifyContent="space-between">
              <Button
                type="border"
                i18nId="ButtonLabel.cancel"
                onClick={closeModal}
              />
              <Button
                buttonType="submit"
                disabled={error}
                i18nId="Payments.withdraw"
              />
            </Flex>
          </Modal.Footer>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendWithdrawAmount: dispatch.admin.sendWithdrawAmount,
  APIResponseSuccess: bindActionCreators(APIResponseSuccess, dispatch),
  APIResponseFail: bindActionCreators(APIResponseFail, dispatch),
});

export const WithdrawModal = connect(
  null,
  mapDispatchToProps
)(injectIntl(WrappedWithdrawModal));
