import { createModel } from "@rematch/core";
import { FetchUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import {
  getCurrentSpaceBalanceRequest,
  getCurrentSpaceUserDataRequest,
  getCurrentSpaceUserAccountDataRequest,
  getCompanyWhitelabelRequest,
} from "../api";
import { IUser, IUserAccount, IWhitelabel } from "../types";

const { makeRequest } = FetchUtils;

export interface ISpaceState {
  currentBalance: number;
  user: null | IUser;
  account: IUserAccount;
  whitelabelData: null | IWhitelabel;
}
export const space = createModel({
  name: "space",
  state: {
    currentBalance: 0,
    user: null,
    account: {},
    whitelabelData: null,
  } as ISpaceState,
  reducers: {
    setCurrentBalance: (state, currentBalance) => ({
      ...state,
      currentBalance,
    }),
    setCurrentUserData: (state, user) => ({
      ...state,
      user,
    }),
    setCurrentUserAccountData: (state, account) => ({
      ...state,
      account,
    }),
    nullifyUserAndAccountData: (state, account) => ({
      ...state,
      account: {},
      user: null,
    }),
    setWhitelabelData: (state, whitelabelData) => ({
      ...state,
      whitelabelData,
    }),
  },
  selectors: state => ({
    currentBalanceSelector() {
      return state((state: ISpaceState) => state.currentBalance);
    },
    currentUserDataSelector() {
      return state((state: ISpaceState) => state.user);
    },
    currentUserAccountSelector() {
      return state((state: ISpaceState) => state.account);
    },
    whitelabelCustomizeDataSelector() {
      return state(
        (state: ISpaceState) => state.whitelabelData?.whitelabel_settings
      );
    },
    whitelabelCreatedDateSelector() {
      return state((state: ISpaceState) => state.whitelabelData?.created_at);
    },
  }),

  effects: dispatch => ({
    async getCurrentSpaceBalance({ id, success, fail }) {
      await makeRequest(getCurrentSpaceBalanceRequest(id), {
        success: (currentBalance: number) => {
          dispatch.space.setCurrentBalance(currentBalance);
          if (success) {
            success(currentBalance);
          }
        },
        fail,
      });
    },
    async getCurrentSpaceUserData({ id, success, fail }) {
      await makeRequest(getCurrentSpaceUserDataRequest(id), {
        success: (user: IUser) => {
          dispatch.space.setCurrentUserData(user);
          if (success) {
            success(user);
          }
        },
        fail,
      });
    },
    async getCurrentSpaceUserAccountData({ id, success, fail }) {
      await makeRequest(getCurrentSpaceUserAccountDataRequest(id), {
        success: (account: IUserAccount) => {
          dispatch.space.setCurrentUserAccountData(account);
          if (success) {
            success(account);
          }
        },
        fail,
      });
    },
    async getCompanyWhitelabel({ id, success, fail }) {
      await makeRequest(getCompanyWhitelabelRequest(id), {
        success: (whitelabelData: IWhitelabel) => {
          dispatch.space.setWhitelabelData(whitelabelData);
          if (success) {
            success(whitelabelData);
          }
        },
        fail,
      });
    },
  }),
});
