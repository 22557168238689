import { Box, Button, Divider, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { style } from './style';
import React from "react";
import { useTranslation } from "react-i18next";
import { IntFieldController } from "../../../global";
import { yupResolver } from "@hookform/resolvers/yup";
import { getProfileSecurityFormSchema, PROFILE_PASSWORD_FORM_DEFAULT_VALUES } from "../../utils";
import { ProfileService } from "../../services";
import { PasswordChangeFormDTO } from "../../types";
import { NotificationService } from "../../../global/services";

function ProfileSecurityForm() {
  const {
    handleSubmit,
    control,
    formState,
    reset,
  } = useForm({
    resolver: yupResolver(getProfileSecurityFormSchema()),
    mode: 'onChange',
    defaultValues: PROFILE_PASSWORD_FORM_DEFAULT_VALUES,
  });

  const { t } = useTranslation();

  async function onSubmit(data: PasswordChangeFormDTO) {
    const response = await ProfileService.updateUserPassword(data as PasswordChangeFormDTO);
    if (response.type?.code == 0) { // eslint-disable-line
      NotificationService.handleSuccess(t('profile.securityForm.formSuccess'));
      reset();
    }
  }

  const hasError = !!Object.keys(formState.errors).length;

  return (
    <Box component='form' sx={style.form} onSubmit={handleSubmit(onSubmit)}>
      <Typography sx={style.formTitle}>{t('profile.securityForm.title')}</Typography>
      <Divider />
      <Box>
        <Box sx={style.field}>
          <Typography sx={style.fieldLabel}>{t('profile.securityForm.currentPassword')}</Typography>
          <IntFieldController
            name='currentPassword'
            control={control}
            placeholder={t<string>('profile.securityForm.currentPassword')}
            sx={style.fieldInput}
            type='password'
          />
        </Box>
        <Divider />
        <Box sx={style.field}>
          <Typography sx={style.fieldLabel}>{t('profile.securityForm.newPassword')}</Typography>
          <IntFieldController
            name='newPassword'
            control={control}
            placeholder={t<string>('profile.securityForm.newPassword')}
            sx={style.fieldInput}
            type='password'
          />
        </Box>
        <Divider />
        <Box sx={style.field}>
          <Typography sx={style.fieldLabel}>{t('profile.securityForm.repeatNewPassword')}</Typography>
          <IntFieldController
            name='repeatNewPassword'
            control={control}
            placeholder={t<string>('profile.securityForm.repeatNewPassword')}
            sx={style.fieldInput}
            type='password'
          />
        </Box>
        <Divider />
      </Box>
      <Box sx={style.submitForm}>
        <Button
          disabled={!formState.isDirty|| formState.isSubmitting || hasError}
          sx={style.submitButton}
          type='submit'
        >{t('profile.securityForm.submitTitle')}</Button>
      </Box>
    </Box>
  );
}

ProfileSecurityForm.displayName = 'ProfileSecurityForm';

export default ProfileSecurityForm;