import { ApiService } from "../../global/services";
import { ForgotPasswordDTO } from "../../auth/types";

class UserService {
  static ENDPOINT = `${window.REACT_APP_BASE_URL}/v1/user`;

  static getUserById(id: string) {
    return ApiService.get(`${UserService.ENDPOINT}/${id}`);
  }

  static resetPassword(data: ForgotPasswordDTO) {
    return ApiService.post(`${UserService.ENDPOINT}/reset-password/init`, data.email, {
      'Content-Type': 'text/plain'
    }, false, false);
  }
}

export default UserService;
