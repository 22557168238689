import { createModel } from "@rematch/core";
import { FetchUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { ICampaign } from "../types";
import {
  publishAudienceRequest,
  getAudienceListRequest,
  updateAudienceRequest,
  getAudienceRequest,
  audienceResourceSearchRequest,
} from "../api";
import { IStore, select, IRootState } from "../store";

const { makeRequest } = FetchUtils;
const audienceInitialValues = {
  interactive: false,
  budget_type_key: 2,
  frequencyCap: false,
  setScheduling: false,
  device_type_key: 1,
  scheduling: {},
  targeting: {
    gender: 1,
    // locations: {
    //   include: [],
    //   exclude: [],
    // },
    // langugaes: {
    //   include: [],
    //   exclude: [],
    // },
    apps: {
      include: [],
      exclude: [],
      includeAny: [],
    },
    websites: {
      include: [],
      exclude: [],
      includeAny: [],
    },
    interests: {
      include: [],
      exclude: [],
      includeAny: [],
    },
    // behaviors: {
    //   include: [],
    //   exclude: [],
    // },
    languages: {
      include: [],
      exclude: [],
    },
    fromAge: 18,
    toAge: 65,
    location_option_key: "1",
  },
  excluded_categories: [],
  custom_audience: [],
  placement: {
    device_type_key: 1,
    min_os_version: 1,
    max_os_version: 5,
  },
  frequency_cap: {
    1: "",
    2: "",
    3: "",
  },
  visuals: {
    adaptive: {},
  },
  allow_adaptive: true,
  optimization_type_key: 1,
  // budget: 0,
};
export interface IAudienceState {
  audienceList: any;
  audienceListIsLoading: boolean;
  audienceResource: any;
  audienceResourceLoading: boolean;
}

export const audience = createModel({
  name: "audience",
  state: {
    audienceList: [],
    audienceListIsLoading: false,
    audienceResource: [],
    audienceResourceLoading: false,
  } as IAudienceState,
  reducers: {
    setAudienceResource: (state, audienceResource): IAudienceState => ({
      ...state,
      audienceResource,
    }),
    setAudienceListIsLoading: (
      state,
      audienceListIsLoading
    ): IAudienceState => ({
      ...state,
      audienceListIsLoading,
    }),
    setAudienceResourceLoading: (
      state,
      audienceResourceLoading
    ): IAudienceState => ({
      ...state,
      audienceResourceLoading,
    }),
    setAudienceListData: (state, audienceList) => ({
      ...state,
      audienceList,
    }),
    nullifyAudienceResource: (state): IAudienceState => ({
      ...state,
      audienceResource: [],
    }),
  },
  selectors: (state) => ({
    audienceListIsLoadingSelector() {
      return state((state: IAudienceState) => state.audienceListIsLoading);
    },
    audienceResourceSelector() {
      return state((state: IAudienceState) => state.audienceResource);
    },
    audienceResourceLoadingSelector() {
      return state((state: IAudienceState) => state.audienceResourceLoading);
    },
    audienceListSelector() {
      return state((state: IAudienceState) => state.audienceList);
    },
    initialValuesSelector() {
      return state(() => audienceInitialValues);
    },
  }),
  effects: (dispatch) => ({
    // async getTransactions(data: IBaseFilters) {
    //   dispatch.transactions.setTransactionsHistoryIsLoading(true);
    //   await makeRequest(getTransactionsRequest(data), {
    //     success: (transactionsListData: any[]) => {
    //       dispatch.transactions.setTransactionsListData(transactionsListData);
    //       dispatch.transactions.setTransactionsHistoryIsLoading(false);
    //     },
    //     fail: () => {
    //       dispatch.transactions.setTransactionsHistoryIsLoading(false);
    //     },
    //   });
    // },
    // async getTransactionById(id: string) {
    //   await makeRequest(getTransactionsByIdRequest(id), {
    //     success: (transaction: any) => {
    //       dispatch.transactions.setTransaction(transaction);
    //     },
    //     fail: () => {},
    //   });
    // },
    async audienceResourceSearch({id, query, success, fail }) {
      dispatch.audience.setAudienceResourceLoading(true);
      await makeRequest(audienceResourceSearchRequest(query,id), {
        success: (result: any) => {
          dispatch.audience.setAudienceResource(result);
          if (success) {
            success(result);
          }
          dispatch.audience.setAudienceResourceLoading(false);
        },
        fail: (data: any) => {
          dispatch.audience.setAudienceResourceLoading(false);
          if (fail) fail(data);
        },
      });
    },
    async getAudience(id: string, store: IStore) {
      dispatch.campaign.cmpaignIsLoadingStart();
      const spaceId = select.authentication.selectCurrentUserSpaceId(store);
      await makeRequest(getAudienceRequest(id), {
        success: (data: ICampaign) => {
          if (data.space_id !== spaceId) {
            dispatch.admin.setAdminNewSpace({ id: data.space_id });
          }
          dispatch.campaign.setCampaign(data);
        },
      });
    },
    async publishAudience({ id, success, fail }: any) {
      dispatch.campaign.setPublishCampaignsLoaded(true);

      await makeRequest(publishAudienceRequest(id), {
        success: (data: any) => {
          dispatch.campaign.setPublishCampaignsLoaded(false);
          if (success) success(data);
        },
        fail: (data: any) => {
          dispatch.campaign.setPublishCampaignsLoaded(false);
          if (fail) fail(data);
        },
      });
    },
    async updateAudience({ audience, success, fail, isPublish }: any, store) {
      if (!isPublish) {
        dispatch.campaign.setSaveCampaignsLoaded(true);
      } else {
        dispatch.campaign.setPublishCampaignsLoaded(true);
      }
      const spaceId = select.authentication.selectCurrentUserSpaceId(store);
      await makeRequest(
        updateAudienceRequest({
          space_id: spaceId,
          ...audience,
          manual_cpm: true,
        }),
        {
          success: (data: ICampaign) => {
            success(data);
            dispatch.campaign.setSaveCampaignsLoaded(false);
            dispatch.campaign.setHasCampaign(true);
          },
          fail: (data: any) => {
            if (fail) {
              fail(data);
            }
            dispatch.campaign.setPublishCampaignsLoaded(false);
            dispatch.campaign.setSaveCampaignsLoaded(false);
          },
        }
      );
    },
    async getAudienceList(data: any, store: IStore | IRootState) {
      const currentSpaceId = store.authentication.space.id;
      dispatch.audience.setAudienceListIsLoading(true);
      await makeRequest(
        getAudienceListRequest({ ...data, space_id: currentSpaceId }),
        {
          success: (audience: any) => {
            dispatch.audience.setAudienceListData(audience);
            dispatch.audience.setAudienceListIsLoading(false);
          },
          fail: () => {
            dispatch.audience.setAudienceListIsLoading(false);
          },
        }
      );
    },
  }),
});
