import React, { Component, useContext, useState } from "react";
import { connect } from "react-redux";
import { injectIntl, IntlShape, FormattedMessage } from "react-intl";
import { IRootState, Dispatch, select } from "../../../../store";
import {
  IAdminAccountsTotals,
  IPaginationData,
  IAdminAccountsUser,
  IModalProps,
  IDictionaryField,
} from "../../../../types";
import {
  MaiFilters,
  MaiFilterField,
  MaiFiltersDate,
  ValidatableInputField,
  MaiFiltersContext,
  MaiSelect,
} from "@intent-ai/mandal-npm-lib-v2";
import { adminMessages } from "../../../../messages";
import { AdminAccountsList, Modal } from "../../../../containers";
import { WithModal } from "../../../../providers";
import "./style.scss";
import { getAdminAccountsExcelReportRequest } from "../../../../api";
import { Button, Dialog, DialogContent } from "@mui/material";
import { style } from './style';
import { AddAccountForm } from "../../../../modules/admin/modules/account/components";
import { useTranslation } from "react-i18next";
import AdminAccountsTotals from "../../../../modules/admin/modules/account/components/adminAccountTotals";
import { formatDate, stringToDate } from "../../../../modules/global/utils/date-utils";


const renderOnParentContainer = (node: HTMLElement) => node.parentNode;

interface IProps extends IModalProps {
  intl: IntlShape;
  getAdminAccountsTotals: Function;
  getAdminAccountsList: Function;
  adminAccountsTotals: IAdminAccountsTotals;
  whitelabelCreatedDate: Date;
  adminAccountsUsers: IPaginationData<IAdminAccountsUser[]>;
  adminAccountsUsersIsLoading: boolean;
  adminAccountsTotalsIsLoading: boolean;
  userStatus: IDictionaryField[];
  getDictionaryValues: Function;
}

const DownloadExcel = () => {
  const filters: any = useContext(MaiFiltersContext);
  const formattedFilters = { ...filters.filters, ...filters.filters.date };

  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const downloadExcelReport = () => {
    const dateFormat = 'MMM DD, YYYY';

    getAdminAccountsExcelReportRequest(formattedFilters)
      .then((response: any) => response.blob())
      .then((blob: any) => {
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = `reports-${formatDate(
          formattedFilters.from_date, dateFormat
        )}-${formatDate(formattedFilters.to_date, dateFormat)}.xlsx`;
        link.click();
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <div className="accounts-container-graph-download-excel">
      <Button variant='contained' onClick={handleOpen} sx={style.addNewUser}>{t('admin.account.create.title')}</Button>
      <div onClick={downloadExcelReport}>
        <FormattedMessage
          id="DashboardChartSection.downloadExcel"
          defaultMessage="Download Excel"
        />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <AddAccountForm onCancel={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

class AdminAccountsContainer extends Component<IProps> {
  componentDidMount() {
    const {
      props: { getAdminAccountsTotals, getDictionaryValues },
    } = this;
    getAdminAccountsTotals();
    getDictionaryValues(["accounts_filter_status"]);
  }

  onRowClick = (account: IAdminAccountsUser) => {
    const {
      props: { openModal, modalTypes },
    } = this;
    openModal({
      modalType: modalTypes.adminAccountsModal,
      modalData: {
        account: account,
      },
    });
  };
  onIconClick = (account: IAdminAccountsUser) => {
    const {
      props: { openModal, modalTypes },
    } = this;
    openModal({
      modalType: modalTypes.entityEditModal,
      modalData: {
        account,
      },
    });
  };

  render() {
    const {
      props: {
        intl: { formatMessage },
        getAdminAccountsList,
        whitelabelCreatedDate,
        adminAccountsUsers,
        adminAccountsUsersIsLoading,
        adminAccountsTotals,
        adminAccountsTotalsIsLoading,
        modalType,
        toggle,
        isOpen,
        closeModal,
        modalTypes,
        modalData,
        openModal,
        userStatus,
      },
      onRowClick,
      onIconClick,
    } = this;
    const pageCount = Math.ceil(
      adminAccountsUsers?.total_size / adminAccountsUsers?.page_size
    );

    return (
      <>
        <div>
          <div>
            <AdminAccountsTotals
              totals={adminAccountsTotals}
              loading={adminAccountsTotalsIsLoading}
            />
          </div>
          <MaiFilters
            pageCount={pageCount}
            showPagination
            onReady={getAdminAccountsList}
            onChange={getAdminAccountsList}
            initialValues={{
              status_filter_key: 1,
            }}
          >
            <MaiFilters.Container>
              <MaiFilterField
                name="name"
                component={ValidatableInputField}
                placeholder={formatMessage(
                  adminMessages.accountsSearchPlaceholder
                )}
              />
              <MaiFilterField
                name="status_filter_key"
                component={MaiSelect}
                container={renderOnParentContainer}
                selectData={userStatus}
                multiple={false}
                showSearch={false}
              />
              <MaiFilterField
                name="date"
                component={MaiFiltersDate}
                allTimeFromDate={stringToDate(whitelabelCreatedDate)}
                fullWidth
                showLifetime
              />
            </MaiFilters.Container>
            <DownloadExcel />
            <AdminAccountsList
              adminAccountsUsers={adminAccountsUsers?.data}
              loading={adminAccountsUsersIsLoading}
              onRowClick={onRowClick}
              onIconClick={onIconClick}
            />
          </MaiFilters>
        </div>
        {isOpen && (
          <Modal
            modalData={modalData}
            modalType={modalType}
            toggle={toggle}
            isOpen={isOpen}
            openModal={openModal}
            closeModal={closeModal}
            modalTypes={modalTypes}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  adminAccountsTotals: select.admin.adminAccountsTotalsSelector(state),
  adminAccountsUsers: select.admin.adminAccountsUsersSelector(state),
  adminAccountsUsersIsLoading: select.admin.adminAccountsUsersIsLoadingSelector(
    state
  ),
  adminAccountsTotalsIsLoading: select.admin.adminAccountsTotalsIsLoadingSelector(
    state
  ),
  whitelabelCreatedDate: select.admin.whitelabelCreationDateSelector(state),
  userStatus: select.dictionary.selectDictionaryField(
    state,
    "accounts_filter_status"
  ),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAdminAccountsTotals: dispatch.admin.getAdminAccountsTotals,
  getAdminAccountsList: dispatch.admin.getAdminAccountsList,
  getDictionaryValues: dispatch.dictionary.getDictionaryValues,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WithModal(AdminAccountsContainer)));
