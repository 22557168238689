import React, { Component } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { IAdminPublisher } from "../../../types";
import { adminMessages } from "../../../messages";
import { Table } from "@intent-ai/mandal-npm-lib-v2";
import { TableNotFound } from "../../../components";

interface IProps {
  intl: IntlShape;
  publisherList: IAdminPublisher[];
  loading: boolean;
}

class AdminPublisherList extends Component<IProps> {
  renderNotfound = () => {
    return (
      <TableNotFound
        titleId="Transactions.transactionsNotFound"
        titleDefaultMessage="No Transactions has been made!"
        descriptionId="Transactions.transactionsNotFoundDescription"
        descriptionDefaultMessage="Try adjusting filters or choosing different date range."
      />
    );
  };

  getColumns = () => {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;
    return [
      {
        title: formatMessage(adminMessages.publishersInventory),
        dataIndex: "publishersInventory",
      },
      {
        title: formatMessage(adminMessages.publishersName),
        dataIndex: "publishersName",
      },
    ];
  };

  getData = () => {
    const {
      props: { publisherList },
    } = this;
    return publisherList
      ? publisherList.map(({ full_name, inventory_name }) => {
          return {
            inventory_name,
            full_name,
          };
        })
      : [];
  };

  render() {
    const {
      props: { loading },
      getColumns,
      getData,
    } = this;
    return (
      <div>
        <Table
          notFound={this.renderNotfound()}
          borderBottom
          columns={getColumns()}
          data={getData()}
          firtsFullWidth
          showLoading
          isLoading={loading}
        />
      </div>
    );
  }
}

export default injectIntl(AdminPublisherList);
