import { createModel } from "@rematch/core";
import { FetchUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { IBaseFilters, ITransaction, IGenericList } from "../types";
import { getTransactionsRequest, getTransactionsByIdRequest } from "../api";

const { makeRequest } = FetchUtils;

export interface ITransactionsState {
  transactionsHistoryIsLoading: boolean;
  transactionsListData: {} | IGenericList<ITransaction>;
  tranascation: any;
}

export const transactions = createModel({
  name: "transactions",
  state: {
    transactionsListData: {},
    transactionsHistoryIsLoading: true,
    tranascation: {},
  } as ITransactionsState,
  reducers: {
    setTransactionsHistoryIsLoading: (state, transactionsHistoryIsLoading) => ({
      ...state,
      transactionsHistoryIsLoading,
    }),
    setTransactionsListData: (state, transactionsListData) => ({
      ...state,
      transactionsListData,
    }),
    setTransaction: (state, transaction) => ({
      ...state,
      transaction,
    }),
  },
  selectors: state => ({
    transactionsHistoryIsLoadingSelector() {
      return state(
        (state: ITransactionsState) => state.transactionsHistoryIsLoading
      );
    },
    transactionsListDataSelector() {
      return state((state: ITransactionsState) => state.transactionsListData);
    },
    transactionSelector() {
      return state((state: ITransactionsState) => state.tranascation);
    },
  }),
  effects: dispatch => ({
    async getTransactions(data: IBaseFilters) {
      dispatch.transactions.setTransactionsHistoryIsLoading(true);
      await makeRequest(getTransactionsRequest(data), {
        success: (transactionsListData: any[]) => {
          dispatch.transactions.setTransactionsListData(transactionsListData);
          dispatch.transactions.setTransactionsHistoryIsLoading(false);
        },
        fail: () => {
          dispatch.transactions.setTransactionsHistoryIsLoading(false);
        },
      });
    },
    async getTransactionById(id: string) {
      await makeRequest(getTransactionsByIdRequest(id), {
        success: (transaction: any) => {
          dispatch.transactions.setTransaction(transaction);
        },
        fail: () => {},
      });
    },
  }),
});
