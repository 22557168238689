import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, IntlShape } from "react-intl";
import { IModalProps } from "../../../types";
import { bindActionCreators } from "redux";
import { Modal } from "../../../containers";
import { FormattedMessage } from "react-intl";
import { adminMessages } from "../../../messages";
import { Tooltip, ValidatableInputField, Button } from "@intent-ai/mandal-npm-lib-v2";
import "./style.scss";
import {
  APIResponseSuccess,
  APIResponseFail,
} from "@intent-ai/mandal-npm-lib-v2/dist/actions";
import { IRootState, Dispatch } from "../../../store";
const armenianLanguageExp = /([\u0530-\u058F\s]*)$/g;

interface IProps {
  intl: IntlShape;
  APIResponseFail: Function;
  APIResponseSuccess: Function;
  updateUserLegalEntity: Function;
  getAdminAccountsList: Function;
}
interface IState {
  entityValue: string;
  entityError: boolean;
}

interface IProps extends IModalProps {}
class EntityEditModal extends Component<IProps, IState> {
  state = {
    entityValue: "",
    entityError: false,
  };
  componentDidMount() {
    const {
      props: {
        modalData: { account },
      },
    } = this;
    if (account?.entity_name) {
      this.setState({ entityValue: account?.entity_name });
    }
  }

  onEntityChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const entityExp = value.match(armenianLanguageExp);
    this.setState(() => ({ entityValue: value }));
    if (entityExp && !!entityExp[0]) {
      this.setState(() => ({ entityError: false }));
    } else {
      this.setState(() => ({ entityError: true }));
    }
  };
  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const {
      props: {
        updateUserLegalEntity,
        modalData: { account },
      },
      state: { entityValue: entity_name },
      postFail: fail,
      postSuccess: success,
    } = this;
    updateUserLegalEntity({
      data: { legal_entity_name: entity_name, user_id: account?.user_id },
      success,
      fail,
    });
  };

  postFail = () => {
    const {
      props: {
        intl: { formatMessage },
        closeModal,
        APIResponseFail,
      },
    } = this;
    closeModal();
    APIResponseFail(
      formatMessage(adminMessages.failHeader),
      formatMessage(adminMessages.failDescription)
    );
  };

  postSuccess = () => {
    const {
      props: {
        intl: { formatMessage },
        closeModal,
        APIResponseSuccess,
        modalData: { account },
        getAdminAccountsList,
      },
    } = this;
    closeModal();
    APIResponseSuccess(
      formatMessage(adminMessages.successHeader),
      formatMessage(adminMessages.successDescription, {
        fullName: account?.full_name,
      }),
      () => {}
    );
    getAdminAccountsList();
  };

  render() {
    const {
      props: {
        intl: { formatMessage },
        closeModal,
        // modalData: { account },
      },
      state: { entityError, entityValue },
      onEntityChange,
      onSubmit,
    } = this;
    const entityMeta = {
      touched: entityError,
      error: {
        id: "Payments.entityWarning",
        defaultMessage:
          "Please, input the legal name of your Company in Armenian",
      },
    };
    return (
      <div className="entity-name-change-modal-wrapper">
        <form onSubmit={onSubmit}>
          <Modal.Header>
            <FormattedMessage
              id="Admin.editEntityName"
              defaultMessage="Edit Entity Name"
            />
          </Modal.Header>
          <div className="entity-name-change-modal-container">
            <p className="entity-name-change-modal-description">
              <FormattedMessage
                id="Admin.editEntityDescription"
                defaultMessage="Entity name is the legal registered name of the company. It must be typed in Armenian."
              />
            </p>
            <p className="exact-entity_name-text">
              <FormattedMessage
                id="Payments.entityLabel"
                defaultMessage="Input entity name in Armenian"
              />
              <Tooltip tooltip={formatMessage(adminMessages.entityTooltip)} />
            </p>
            <ValidatableInputField
              currentValue={entityValue}
              onChange={onEntityChange}
              type="text"
              meta={entityMeta}
            />
          </div>
          <Modal.Footer>
            <Button
              type="border"
              i18nId="ButtonLabel.cancel"
              onClick={closeModal}
            />
            <Button
              buttonType="submit"
              disabled={entityValue === "" ? true : entityError}
              i18nId="ButtonLabel.save"
              // loading={isEntityLoading}
            />
          </Modal.Footer>
        </form>
      </div>
    );
  }
}
const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  APIResponseSuccess: bindActionCreators(APIResponseSuccess, dispatch),
  APIResponseFail: bindActionCreators(APIResponseFail, dispatch),
  updateUserLegalEntity: dispatch.authentication.updateUserLegalEntity,
  getAdminAccountsList: dispatch.admin.getAdminAccountsList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EntityEditModal));
