import React, { ChangeEvent, Component } from "react";
import { injectIntl } from "react-intl";
import "./style.scss";
import clsx from "clsx";

const RANGE_MAX_VALUE = 3;
const RANGE_MIN_VALUE = 0;

const rangeInputValues = {
  yellow: 2,
  green: 1,
  red: 3,
};
class RangeInput extends Component<any> {
  state = {
    value: 0,
  };
  render() {
    const { value } = this.state;
    const { onRangeChange, currentData } = this.props;
    const dataValue = currentData.similarity ?? value;
    return (
      <div className="range-container">
        <input
          value={+currentData.similarity ?? value}
          type="range"
          min={RANGE_MIN_VALUE}
          max={RANGE_MAX_VALUE}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            this.setState({ value: e.target.value });
            onRangeChange(this.state.value);
          }}
        />
        <div className="ticks">
          <p
            className={clsx("tick", {
              "active-1": dataValue >= rangeInputValues.green,
            })}
          >
            <span className="tick-text">None</span>
          </p>
          <p
            className={clsx("tick", {
              "active-2": dataValue >= rangeInputValues.yellow,
            })}
          >
            <span className="tick-text"></span>
          </p>
          <p
            className={clsx("tick", {
              "active-3": dataValue >= rangeInputValues.red,
            })}
          >
            <span className="tick-text">Similars</span>
          </p>
        </div>
      </div>
    );
  }
}

export default injectIntl(RangeInput);
