import React from "react";
import { Redirect, Switch, BrowserRouter, Route } from "react-router-dom";

import { RouteBuilder } from "./config/routes/route-builder";


import store from "./store";
import {
  adminRouterConfig,
  authRouterConfig, defaultPath, notFoundConfig,
  routerConfig,
  transactionsRouterConfig,
} from "./config/routes/route-config";
import { useSelector } from "react-redux";
import { getUserRole } from "./modules/store";
import { userRoles } from "./_constants";

const redirectToNotfound = () => <Redirect to={defaultPath} />;

export const Routes = () => {

  const userRole = useSelector(getUserRole);

  return (
    <BrowserRouter>
      <Switch>
        {RouteBuilder(routerConfig, store)}
        {RouteBuilder(transactionsRouterConfig, store)}
        {userRole === userRoles.WL_OWNER && RouteBuilder(adminRouterConfig, store)}
        {RouteBuilder(authRouterConfig, store)}
        <Route exact path="/notfound" component={notFoundConfig} />
        <Route render={redirectToNotfound} />
      </Switch>
    </BrowserRouter>
  );
};
