import React, { Component } from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { IAdminSearchResultCampaign, IAdminSearchResultAccount } from "../../../types";
import { Tabs } from "@intent-ai/mandal-npm-lib-v2";
import "./style.scss";
import { adminMessages } from "../../../messages";
import { AdminCampaign } from "../AdminCampaign";
import { AdminAccount } from "../AdminAccount";

interface IProps {
  intl: IntlShape;
  campaigns: IAdminSearchResultCampaign[];
  accounts: IAdminSearchResultAccount[];
  query: string;
  allResult: any;
  onAdminChange: Function;
  onCampaignChange: Function;
}

class AdminSearchView extends Component<IProps> {
  render() {
    const {
      props: {
        intl: { formatMessage },
        query,
        allResult,
        campaigns,
        accounts,
        onAdminChange,
        onCampaignChange,
      },
    } = this;

    if (allResult.length === 0) {
      return (
        <div className="admin-search-view">
          <p className="admin-search-view-no-result-found">
            <FormattedMessage
              id="Admin.adminSearchResultsNotFound"
              defaultMessage="No results found"
            />
          </p>
        </div>
      );
    }
    return (
      <div className="admin-search-view">
        <div>
          <p className="admin-search-view-query">
            <FormattedMessage
              id="Admin.adminSearchResults"
              defaultMessage="Search results for {query}"
              values={{ query }}
            />
          </p>
        </div>
        <Tabs>
          <Tabs.TabPaneFillContainer fill>
            <Tabs.TabPane
              tab={
                formatMessage(adminMessages.adminSearchAll) +
                ` (${allResult.length})`
              }
              tabKey="1"
            />
            <Tabs.TabPane
              tab={
                formatMessage(adminMessages.adminSearchAccounts) +
                ` (${accounts.length})`
              }
              tabKey="2"
            />
            <Tabs.TabPane
              tab={
                formatMessage(adminMessages.adminSearchCampaigns) +
                ` (${campaigns.length})`
              }
              tabKey="3"
            />
          </Tabs.TabPaneFillContainer>
          <div className="admin-search-view-content">
            <Tabs.Container tabKey="1">
              <ul className="admin-search-list">
                {allResult.map((item: any) => {
                  if (item.type_key === 1) {
                    return (
                      <AdminCampaign
                        key={item.campaign_id}
                        campaign={item}
                        campaignDetailShow
                        onCampaignChange={onCampaignChange}
                      />
                    );
                  } else if (item.type_key === 2) {
                    return (
                      <AdminAccount
                        account={item}
                        key={item.space_id}
                        onAdminChange={onAdminChange}
                      />
                    );
                  }
                  return null;
                })}
              </ul>
            </Tabs.Container>
            <Tabs.Container tabKey="2">
              <ul className="admin-search-list">
                {accounts.length !== 0 ? (
                  accounts.map(account => (
                    <AdminAccount
                      account={account}
                      key={account.space_id}
                      onAdminChange={onAdminChange}
                    />
                  ))
                ) : (
                  <div className="text-center mt-1">
                    <FormattedMessage
                      id="Admin.adminAccountsNotFound1"
                      defaultMessage="No Accounts Found"
                    />
                  </div>
                )}
              </ul>
            </Tabs.Container>
            <Tabs.Container tabKey="3">
              <ul className="admin-search-list">
                {campaigns.length !== 0 ? (
                  campaigns.map(campaign => (
                    <AdminCampaign
                      key={campaign.campaign_id}
                      campaign={campaign}
                      campaignDetailShow
                      onCampaignChange={onCampaignChange}
                    />
                  ))
                ) : (
                  <div className="text-center mt-1">
                    <FormattedMessage
                      id="Admin.adminCampaignsNotFound1"
                      defaultMessage="No Campaigns Found"
                    />
                  </div>
                )}
              </ul>
            </Tabs.Container>
          </div>
        </Tabs>
      </div>
    );
  }
}

export default injectIntl(AdminSearchView);
