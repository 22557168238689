import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage, IntlShape } from "react-intl";
import { PaymentsWithdraw } from "../../../containers";
import { Modal } from "../../../containers";
import { Table, ContentWrapper } from "@intent-ai/mandal-npm-lib-v2";
import messages from "./paymentsMessages";
import { Dispatch, select, IRootState } from "../../../store";
import { WithModal } from "../../../providers";
import "./styles.scss";
import { IModalProps } from "../../../types";
import { bindActionCreators } from "redux";
import { APIResponseFail } from "@intent-ai/mandal-npm-lib-v2/dist/actions";
import { numberToStringWithCommas } from "../../../modules";


interface IProps extends IModalProps {
  intl: IntlShape;
  incomeHistoryData: any;
  withdrawBalance: number;
  APIResponseFail: Function;
  getWhiteLabelWithdrawHistory: Function;
  whiteLabelWithdrawHistory: boolean;
}

class WrappedIncomeOverview extends Component<IProps> {
  componentDidMount() {
    const { getWhiteLabelWithdrawHistory } = this.props;
    getWhiteLabelWithdrawHistory({
      data: {
        from_date: null,
        to_date: null,
      },
    });
  }
  getColumns = () => {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;
    return [
      {
        title: formatMessage(messages.tableHeadSpace),
        dataIndex: "info",
      },
      {
        title: formatMessage(messages.tableHeadNumberOfCampaigns),
        dataIndex: "campaign_count",
      },

      {
        title: "%",
        dataIndex: "revenue_percentage",
      },
      {
        title: formatMessage(messages.tableHeadAllTimeIncome),
        dataIndex: "all_time_income",
      },
      {
        title: formatMessage(messages.tableHeadIncomeThisMonth),
        dataIndex: "income_this_month",
      },
    ];
  };

  getData = () => {
    const {
      props: {
        incomeHistoryData: {
          all_time_income_total,
          campaign_count_total,
          this_mount_income_total,
          whitelabel_space_incomes,
        },
      },
    } = this;

    let data =
      whitelabel_space_incomes &&
      whitelabel_space_incomes.data.map(
        ({
          all_time_income,
          campaign_count,
          picture_id,
          revenue_percentage,
          space_name,
          this_mount_income,
        }: any) => ({
          info: space_name,
          campaign_count,
          revenue_percentage: `${revenue_percentage}%`,
          all_time_income: `$ ${numberToStringWithCommas(all_time_income, 2)}`,
          this_mount_income: `$ ${numberToStringWithCommas(this_mount_income, 2)}`,
        })
      );
    data.unshift({
      info: (
        <strong className="payments-income-overview-total">
          <FormattedMessage
            id="Payments.tableHeadIncomeTotal"
            defaultMessage="Total"
          />
        </strong>
      ),
      campaign_count_total: <strong>{campaign_count_total}</strong>,
      revenue_percentage: "",
      all_time_income: (
        <strong>$ {numberToStringWithCommas(all_time_income_total, 2)}</strong>
      ),
      this_mount_income: (
        <strong>$ {numberToStringWithCommas(this_mount_income_total, 2)}</strong>
      ),
    });
    return data;
  };

  openWithDrawModal = () => {
    const {
      props: { openModal, modalTypes, withdrawBalance },
    } = this;
    openModal({
      modalType: modalTypes.withdrawModal,
      modalData: {
        withdrawBalance,
      },
    });
  };

  openWithdrawHistoryModal = () => {
    const {
      props: {
        whiteLabelWithdrawHistory,
        openModal,
        modalTypes,
        APIResponseFail,
        intl: { formatMessage },
      },
    } = this;

    if (!whiteLabelWithdrawHistory) {
      APIResponseFail(
        formatMessage(messages.noHistory),
        formatMessage(messages.noWithdraws)
      );
    } else {
      openModal({
        modalType: modalTypes.paymentsWithdrawHistoryModal,
        modalData: [],
      });
    }
  };

  openPaymentsIncomeHistoryModal = () => {
    const {
      props: { openModal, modalTypes },
    } = this;
    openModal({
      modalType: modalTypes.paymentsIncomeHistoryModal,
      modalData: [],
    });
  };

  render() {
    const {
      props: {
        isOpen,
        toggle,
        modalType,
        modalTypes,
        openModal,
        modalData,
        closeModal,
        withdrawBalance,
      },
      getColumns,
      getData,
      openWithDrawModal,
      openWithdrawHistoryModal,
    } = this;
    return (
      <>
        <div className="income-overview">
          <PaymentsWithdraw
            balance={withdrawBalance}
            withdrawFromBalanceHandler={openWithDrawModal}
            withdrawHistoryHandler={openWithdrawHistoryModal}
          />
          <ContentWrapper>
            <ContentWrapper.Header>
              <FormattedMessage
                id="Payments.sectionTitleIncomeOverview"
                defaultMessage="Income Overview"
              />
            </ContentWrapper.Header>

            <Table data={getData()} columns={getColumns()} />
          </ContentWrapper>
        </div>
        {isOpen && (
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            modalType={modalType}
            modalTypes={modalTypes}
            openModal={openModal}
            modalData={modalData}
            closeModal={closeModal}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state: IRootState) => ({
  whiteLabelWithdrawHistory:
    select.admin.whiteLabelWithdrawHistorySelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  APIResponseFail: bindActionCreators(APIResponseFail, dispatch),
  getWhiteLabelWithdrawHistory: dispatch.admin.getWhiteLabelWithdrawHistory,
});

export const IncomeOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WithModal(WrappedIncomeOverview)));
