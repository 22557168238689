export const CUSTOM_CAMPAIGN = 1;
export const APPLICATION_PROMOTION = 2;
export const FULLSCREEN_CAMPAIGN = 3;
export const RESPONSIVE_CAMPAIGN = 4;
export const HTML_CAMPAIGN = 5;

export const CAMPAIGN_TYPES = {
  BANNER: 1,
  CATFISH: 3,
  INTERACTIVE: 4,
  FULLSCREEN: 2,
  IN_PAGE_VIDEO: 5,
};
