import { Theme } from "@mui/material";

export const style = {
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'background.paper',
    borderRadius: 3,
    boxShadow: '0 12px 8px -10px rgba(0, 0, 0, 0.12)',
    width: 640,
  },
  header: (theme: Theme) => ({
    padding: theme.spacing(2, 3, 2, 10),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  footer: (theme: Theme) => ({
    padding: theme.spacing(5, 7),
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  title: {
    fontSize: 20,
    lineHeight: 1.4,
    color: '#292f41',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    position: 'relative',
  },
  label: {
    color: '#292f41',
    textTransform: 'uppercase',
    fontSize: 14,
    letterSpacing: '.8px',
    lineHeight: 1.14,
  },
  form: (theme: Theme) => ({
    padding: theme.spacing(7, 10),
    display: 'flex',
    flexDirection: 'column',
    height: 264,
    gap: 3,
  }),
  uploadButton: {
    width: 250,
    borderRadius: 4,
  },
  uploadFile: {
    height: 76,
    width: '100%',
  },
  iconButton: {
    padding: 0,
  },
  button: {
    width: 140,
    height: 32,
    borderRadius: 5,
  },
  cancel: {
    color :'rgba(41, 47, 65, 0.5)',
    width: 95,
  }
};
