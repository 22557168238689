import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, ContentWrapper } from "@intent-ai/mandal-npm-lib-v2";
import "./styles.scss";
import clsx from "clsx";
import { Loading, numberToStringWithCommas } from "../../../modules/global";


interface IProps {
  balance: number;
  withdrawHistoryHandler: Function;
  withdrawFromBalanceHandler: Function;
}

export function PaymentsWithdraw({
  balance,
  withdrawHistoryHandler,
  withdrawFromBalanceHandler,
}: IProps) {
  if (balance === null) {
    return <Loading />;
  }
  const haveMoney = Number(balance) < 50000;
  return (
    <ContentWrapper>
      <ContentWrapper.Header>
        <p className="payments-withdraw-info-text">
          <FormattedMessage
            id="Payments.availableToWithdraw"
            defaultMessage="Available to withdraw"
          />
        </p>
      </ContentWrapper.Header>
      <div className="payments-withdraw">
        <div className="payments-withdraw-info">
          <p
            className={clsx("payments-withdraw-info-balance", {
              "zero-balance": haveMoney,
            })}
          >
            $ {numberToStringWithCommas(balance, 2)}
            {haveMoney && (
              <span className="payments-withdraw-info-no-balance">
                <FormattedMessage
                  id="Payments.noFunds"
                  defaultMessage="Your funds are below the minimum amount"
                />
              </span>
            )}
          </p>
        </div>
        <div className="payments-withdraw-actions">
          <Button
            onClick={withdrawHistoryHandler}
            type="border"
            i18nId="Payments.withdrawHistory"
          />
          <Button
            disabled={haveMoney}
            onClick={withdrawFromBalanceHandler}
            i18nId="Payments.withdrawFromBalance"
          />
        </div>
      </div>
    </ContentWrapper>
  );
}
