export const DICT_KEYS = {
  dict_age_group: "age_group",
  dict_gender: "gender",
  dict_device_types: "device_types",
  dict_ios_versions: "ios_versions",
  dict_android_versions: "android_versions",
  dict_budget: "budget",
  dict_frequency_cap: "frequency_cap",
  dict_interests: "interest",
  dict_preferences: "preference",
  dict_languages: "languages",
  dict_week_days: "week_days",
  dict_campaign_status: "campaign_status",
  dict_role: "role",
  dict_account_status: "account",
  dict_campaign_ads_tagging: "campaign_ads_tagging",
  dict_creative: "creative",
  dict_locations: "locations",
  dict_location_options: "location_options",
  dict_app_store_types: "app_store_types",
  dict_behavior: "behavior",
  dict_token_type: "token_type",
  dict_creative_element_types: "creative_element_types",
  dict_iab_categories: "iab_categories",
};

export const BUDGET_TYPES = {
  total: 1,
  daily: 2,
  unlimited: 3,
};

export const BUDGET_TYPES_KEY_VALUE_MAP = {
  1: "Total",
  2: "Daily",
  3: "Unlimited",
};

export const dict_types = {
  tree: "tree",
  list: "list",
};

export const deviceTypes = {
  all: 1,
  desktop: 2,
  mobile: 3,
  ios: 4,
  android: 5,
  mac: 6,
  windows: 7,
  otherDesktop: 8,
  otherMobile: 9,
};

export const adTypes: any = {
  adaptive: 2,
  rich_media: 3,
  banner: 4,
};
