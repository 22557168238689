export type ReportTotal = {
  bids: number;
  campaign_cpm: number;
  clicks: number;
  cpm: number;
  cvr: number;
  cpa: number;
  impressions: number;
  conversions: number;
  reach: number;
  spent: number;
  win_rate: number;
  conversions_unique_bid: number;
}

export type ReportLightDTO = {
  from_date: Date;
  to_date: Date;
  id: string;
  device_type_key: number;
  visual_size_key: number | null;
  top_publishers_highest_first?: boolean;
  publisher: string;
  dimension?: string;
}

export type ReportDashboardRequestDTO = {
  from_date: Date | string;
  to_date: Date | string;
  id: string;
  device_type_key: number;
  visual_size_key: number | null;
  granularity_type_key: number;
  publisher?: string;
}

// export type DashboardData = {
//   cpm: number;
//   reach: number;
//   bids: number;
//   name: string;
//   clicks: number;
//   impressions: number;
// }
export type DashboardData = {   session_engaged: number;   cpm: number;   conversions: number;   reach: number;   spent: number;   bids: number;   name: string;   clicks: number;   bounce_rate: number;   impressions: number;   conversion_value: number;   win_rate: number; }

export enum Granularity {
  PER_DAYS = 2,
  PER_HOURS = 1,
}

export enum ReportTotalTab {
  SPENDING = 'spending',
  IMPRESSIONS = 'impressions',
  REACH = 'reach',
  CLICKS = 'clicks',
  BIDS = 'bids',
  CONVERSIONS = 'conversions',
  COMPARE = 'compare'
}

export type ReportFormDTO = {
  date: {
    from: Date;
    to: Date;
  }
  campaignId: string;
  deviceTypeKey: number;
  visualSizeKey: number | null;
  granularityType?: number;
  publisher: string;
}