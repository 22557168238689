import { createModel } from "@rematch/core";

export interface IDialogState {
  open: boolean;
  title: any;
  description: any;
  onConfirm: Function;
  data: any;
  closeModlaText: string;
  confirmText: string;
  closeDialogCallback: Function | null;
}

const initialState = {
  open: false,
  title: null,
  description: null,
  onConfirm: () => {},
  data: {},
  closeModlaText: "ButtonLabel.cancel",
  confirmText: "ButtonLabel.ok",
  closeDialogCallback: null,
};

export const dialog = createModel({
  name: "dialog",
  state: initialState as IDialogState,
  reducers: {
    setDialogData: (state, data) => ({
      ...state,
      ...data,
    }),
    closeDialog: () => initialState,
  },
  selectors: state => ({
    dialogStateSelector() {
      return state((state: IDialogState) => state);
    },
  }),
  effects: dispatch => ({
    async openDialog({ data }) {
      dispatch.dialog.setDialogData(data);
    },
  }),
});
