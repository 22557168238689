import React, { Component } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { connect } from "react-redux";
import { Dispatch, select, IRootState } from "../../../store";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { transactionsMessages } from "../../../messages";
import {
  MaiFilters,
  MaiFilterField,
  MaiFiltersDate,
  ValidatableInputField,
} from "@intent-ai/mandal-npm-lib-v2";
import { TransactionsList, Modal } from "../../../containers";
import { ITransaction, IGenericList, IModalProps } from "../../../types";
import { WithModal } from "../../../providers";
import { stringToDate } from "../../../modules/global/utils/date-utils";


interface IProps extends IModalProps {
  intl: IntlShape;
  getTransactions: Function;
  transactionsHistoryIsLoading: boolean;
  whitelabelCreatedDate: Date;
  transactionsListData: IGenericList<ITransaction>;
}

class WrappedTransactions extends Component<IProps & RouteComponentProps> {
  onRowClick = (transaction: ITransaction) => {
    const {
      props: { openModal, modalTypes },
    } = this;
    openModal({
      modalType: modalTypes.transactionModal,
      modalData: {
        transaction: transaction,
      },
    });
  };

  render() {
    const {
      props: {
        intl: { formatMessage },
        getTransactions,
        transactionsHistoryIsLoading,
        whitelabelCreatedDate,
        transactionsListData,
        modalType,
        toggle,
        isOpen,
        closeModal,
        modalTypes,
        modalData,
        openModal,
      },
      onRowClick,
    } = this;

    const pageCount = Math.ceil(
      transactionsListData?.total_size / transactionsListData.page_size
    );

    return (
      <>
        <div className="campaign-list">
          <MaiFilters
            pageCount={pageCount}
            showPagination
            // onChange={fetchData}
            onReady={getTransactions}
            onChange={getTransactions}
          >
            <MaiFilters.Container>
              <MaiFilterField
                name="name"
                component={ValidatableInputField}
                placeholder={formatMessage(
                  transactionsMessages.searchPlaceholder
                )}
              />
              <MaiFilterField
                name="date"
                component={MaiFiltersDate}
                allTimeFromDate={stringToDate(whitelabelCreatedDate)}
                fullWidth
                showLifetime
              />
            </MaiFilters.Container>
            <TransactionsList
              transactionsHistory={transactionsListData?.data}
              loading={transactionsHistoryIsLoading}
              onRowClick={onRowClick}
            />
          </MaiFilters>
        </div>
        {isOpen && (
          <Modal
            modalData={modalData}
            modalType={modalType}
            toggle={toggle}
            isOpen={isOpen}
            openModal={openModal}
            closeModal={closeModal}
            modalTypes={modalTypes}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  transactionsHistoryIsLoading: select.transactions.transactionsHistoryIsLoadingSelector(
    state
  ),
  transactionsListData: select.transactions.transactionsListDataSelector(state),
  whitelabelCreatedDate: select.admin.whitelabelCreationDateSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getTransactions: dispatch.transactions.getTransactions,
});

const Transactions = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(WithModal(WrappedTransactions)))
);

Transactions.displayName = "Transactions";

export default Transactions;
