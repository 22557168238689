import React, { Component } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { TransactionsContainer } from "../../containers";
import { AdminHeader } from "../../components";
import { transactionsMessages } from "../../messages";

interface IProps {
  intl: IntlShape;
}

class WrappedAdminTransactions extends Component<IProps> {
  render() {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;

    return (
      <div>
        <AdminHeader title={formatMessage(transactionsMessages.title)}>
          <TransactionsContainer />
        </AdminHeader>
      </div>
    );
  }
}

const AdminTransactions = injectIntl(WrappedAdminTransactions);

AdminTransactions.displayName = "AdminTransactions";

export default AdminTransactions;
