import { Theme } from '@mui/material';

export const style = {
  reports: (theme: Theme) => ({
    padding: theme.spacing(4),
    background: '#fff',
    boxShadow: '0 12px 8px -10px rgba(0, 0, 0, 0.12)',
    borderRadius: theme.spacing(1),
    margin: '-24px -86px 0 -86px',
  }),
  metrics: (theme: Theme) => ({
    display: 'flex',
    gap: theme.spacing(4),
    flexWrap: 'wrap',
  }),
  tab: {
    width: 'calc(25% - 12px)',
    height: 150,
    minWidth: 160,
  },
  tabIndicator: (theme: Theme) => ({
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    height: 10,
  }),
  footer: (theme: Theme) => ({
    display: 'flex',
    paddingTop: theme.spacing(4),
    flexWrap: 'wrap',
    gap: theme.spacing(4),
    margin: '0 -86px',
  }),
  exportCSV: {
    marginLeft: 'auto',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: '#3a77eb',
    cursor: 'pointer',
    marginRight: 2,
  },
};