import React from "react";
import {
  DRAFT,
  AUDIENCE_STATUSES,
} from "../../../_constants";
import { FormattedMessage } from "react-intl";
import "./style.scss";
import clsx from "clsx";
import { formatDate, StatusIndicator } from "../../../modules";

interface IProps {
  status_key?: number;
  favorite?: boolean;
  name?: string;
  audience_start_at?: Date;
}

export const AudienceListName = ({
  status_key = 0,
  favorite,
  name,
  audience_start_at,
}: IProps) => {
  const isDraft = status_key === DRAFT;
  return (
    <div>
      <div className="audience-list-item-title">
        <div className="status-tooltip">
          <StatusIndicator status={status_key} isAudience />
          <span className="status-tooltiptext">
            <FormattedMessage
              id={`AudienceStatus.${AUDIENCE_STATUSES[status_key]}`}
            />
          </span>
        </div>
        <div className="audience-list-item-intro">
          <div
            className={clsx("audience-list-item-name", {
              gray: isDraft,
            })}
          >
            <div className={`audience-name status-${status_key}`}>
              <p className="name">{name}</p>
              <p className="status">
                <span
                  className={`audience-list-item-status-text status-${status_key}`}
                >
                  <FormattedMessage
                    id={`AudienceStatus.${AUDIENCE_STATUSES[status_key]}`}
                  />
                  {/* {(status_key === RUNNING ||
                    status_key === WAITING_TO_START) && ( */}
                  <span> {formatDate(audience_start_at,'MMM DD, YYYY HH:mm')}</span>
                  {/* //   )} */}
                </span>
              </p>
            </div>
            {!favorite ? (
              <div
                className="campaign-list-star"
                style={{ backgroundImage: "unset", width: "auto" }}
              />
            ): null}
          </div>
        </div>
      </div>
    </div>
  );
};
