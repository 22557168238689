import { ApiService } from "../../../../global/services";
import { Account, AccountSource } from "../types";
import { WHITELABEL_TO_WHITELABEL_ID } from "../uitls";

class AccountService {
  static ENDPOINT = `${window.REACT_APP_BASE_URL}/v1/whitelabel/admin`;

  static mapToAccountFields(accountFields: Account) {
    return {
      first_name: accountFields.firstName,
      last_name: accountFields.lastName,
      company_name: accountFields.companyName,
      // eslint-disable-next-line
      source: accountFields.source === AccountSource.OTHER ? accountFields.other : accountFields.source,
      // TODO to be removed after backend change, meaning: PHYSICAL
      user_type_key: 1,
      rewarded_ads_cpm_rate: +accountFields.rewarded_ads_cpm_rate,
      email: accountFields.email,
      phone: accountFields.phone,
      whitelabel_id: WHITELABEL_TO_WHITELABEL_ID[accountFields.whitelabelId],
      niche: accountFields.niche,
      position: accountFields.position,
      // TODO to be removed
      agree_with_tos: true,
      type: accountFields.type,
    };
  }

  static addAccount(accountFields: Account) {
    const body = AccountService.mapToAccountFields(accountFields);
    return ApiService.post(`${AccountService.ENDPOINT}/account/add`, body);
  }

  static rejectCampaign(campaignId: string, reason: string) {
    return ApiService.post(`${AccountService.ENDPOINT}/campaign/reject`, {
      campaign_id: campaignId,
      comment: reason,
      moderation_start_at: new Date().toISOString(),
    })
  }

  static approveCampaign(campaignId: string, categories: string[], exchangeIds: string[]) {
    return ApiService.post(`${AccountService.ENDPOINT}/campaign/approve`, {
      campaign_id: campaignId,
      category_keys: categories,
      exchange_id: exchangeIds,
      moderation_start_at: new Date().toISOString(),
    })
  }
}

export default AccountService;
