import React, { Component } from "react";
import { IRoutePaths, IModalProps } from "../../../types";
import { injectIntl, IntlShape } from "react-intl";
import { audienceMessages } from "../../../messages";
import { Table } from "@intent-ai/mandal-npm-lib-v2";
import { WithModal } from "../../../providers";
import {
  TableNotFound,
} from "../../../components";

import { Link } from "react-router-dom";
import { Modal, AudienceListName, AudienceUploadInfo } from "../../../containers";
import { formatDate } from "../../../modules";

interface IProps extends IModalProps {
  audienceListData: any;
  intl: IntlShape;
  loading: boolean;
  routerPaths: IRoutePaths;
  archiveCampaign: Function;
}
// const campaignTypesText: any = {
//   1: campaignMessages.banner,
//   3: campaignMessages.catfish,
//   4: campaignMessages.interactive,
//   2: campaignMessages.fullScreen,
//   5: campaignMessages.inPageVideo,
// };

class CampaignsList extends Component<IProps> {
  renderNotfound = () => {
    // const {
    //   props: {
    //     routerPaths
    //   },
    // } = this;
    return (
      <TableNotFound
        titleId="CampaignList.noAudienceTitle"
        titleDefaultMessage="No Audience"
        descriptionId="CampaignList.noCampaignsDescription"
        descriptionDefaultMessage="We were not able to find results for your search.\n\nTo carry on searching, remember to check the spelling and be less specific in your wording.\n\nDo not get upset.\n\nYou can always create a new campaign and start promoting your brand!"
        buttonLabelId="ButtonLabel.createAudience"
        link={"/audience/create"}
      />
    );
  };

  getColumns = () => {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;
    return [
      {
        title: formatMessage(audienceMessages.audienceName),
        dataIndex: "name",
      },
      {
        title: formatMessage(audienceMessages.estimatedSize),
        dataIndex: "estimated_size",
      },
      {
        title: formatMessage(audienceMessages.uploadedInfo),
        dataIndex: "uploaded_info",
      },
      {
        title: formatMessage(audienceMessages.dateCreated),
        dataIndex: "date_created",
      },
    ];
  };

  getData = () => {
    const {
      props: {
        audienceListData,
      },
    } = this;

    if (!Array.isArray(audienceListData)) return [];
    if (audienceListData.length === 0) return [];
    return audienceListData.map(
      ({ name, gauge, created_at, status_key, last_exported_at, id }: any) => {
        return {
          name: (
            <Link to={`/audience/edit/${id}`}>
              <AudienceListName
                name={name}
                // favorite={favorite}
                status_key={status_key}
                audience_start_at={created_at}
              />
            </Link>
          ),
          estimated_size: (
            <Link to={`/audience/edit/${id}`}>{gauge || "-"}</Link>
          ),
          uploaded_info: (
            <Link to={`/audience/edit/${id}`}>
              {/* {"Facebook"} */}
              <AudienceUploadInfo
                title={"Facebook"}
                isActive={Boolean(last_exported_at)}
              />
            </Link>
          ),
          date_created: (
            <Link to={`/audience/edit/${id}`}>
              {formatDate(created_at,'MMM DD, YYYY HH:mm')}
            </Link>
          ),
        };
      }
    );
  };

  render() {
    const {
      props: {
        loading,
        modalData,
        modalType,
        toggle,
        isOpen,
        openModal,
        closeModal,
        modalTypes,
        //audienceListData,
      },
      getColumns,
      getData,
      renderNotfound,
    } = this;
    return (
      <div className="audience-list-table-wrapper">
        <Table
          notFound={renderNotfound()}
          borderBottom
          columns={getColumns()}
          data={getData()}
          firtsFullWidth
          showLoading
          isLoading={loading}
        />
        {isOpen && (
          <Modal
            modalData={modalData}
            modalType={modalType}
            toggle={toggle}
            isOpen={isOpen}
            openModal={openModal}
            closeModal={closeModal}
            modalTypes={modalTypes}
          />
        )}
      </div>
    );
  }
}

export default injectIntl(WithModal(CampaignsList));
