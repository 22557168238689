import React, { Component } from "react";
import { connect } from "react-redux";
import { IStore, select, Dispatch } from "../../store";
import { IDialogState } from "../../models/dialog.model";
import { Modal } from "@intent-ai/mandal-npm-lib-v2/dist/providers";

interface IProps {
  dialogState: IDialogState;
  children: JSX.Element | JSX.Element[];
  closeDialog: Function;
}

class InfoDialogContainer extends Component<IProps> {
  render() {
    const {
      props: { children, dialogState, closeDialog },
    } = this;
    return (
      <div>
        {dialogState.open && (
          <Modal
            modalType="warningModal"
            isOpen={dialogState.open}
            closeModal={() => {
              closeDialog();
              if (dialogState.closeDialogCallback) {
                dialogState.closeDialogCallback();
              }
            }}
            handleConfirmNavigationClick={() => {
              dialogState.onConfirm();
              closeDialog();
            }}
            title={dialogState.title}
            description={dialogState.description}
            data={dialogState.data}
            closeModlaText={dialogState.closeModlaText}
            confirmText={dialogState.confirmText}
            buttonReverse
          />
        )}
        {children}
      </div>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  dialogState: select.dialog.dialogStateSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeDialog: dispatch.dialog.closeDialog,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoDialogContainer);
