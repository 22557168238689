/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 1/12/2024
 * All Rights Reserved.
 */
import { abbreviateNumber, CrossIcon, IntAutocomplete, IntFieldController, ProfileIcon } from "../../../global";
import { Box, debounce, Typography } from "@mui/material";
import { style } from "./style";
import { useFormContext, useWatch } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { AudienceService } from "../../../audience/services";
import { AudienceResponseDTO } from "../../../audience/types/audience";
import { useSelector } from "react-redux";
import { getUserIdSelector } from "../../../store";

type Props = {
  name: string;
  placeholder: string;
}

function CustomAudienceList({ name, placeholder }: Props) {
  const [value, setValue] = useState('');

  const { control, setValue: setFieldValue } = useFormContext();

  const audiences = useWatch({
    name,
    control,
  });

  const [audienceList, setAudienceList] = useState<{ isLoading: boolean; data: Array<any>; }>({
    isLoading: false,
    data: [],
  });

  const userId = useSelector(getUserIdSelector)

  const searchAudience = useCallback(debounce((searchTerm: string) => { // eslint-disable-line react-hooks/exhaustive-deps
    setAudienceList({
      isLoading: true,
      data: [],
    });
    AudienceService.getAudienceList(userId, {
      name: searchTerm,
    }).then((response) => {
      if (Array.isArray(response.data?.data)) {
        setAudienceList({
          isLoading: false,
          data: response.data.data
          .map((item: AudienceResponseDTO) => ({ ...item, value: item.id })),
        })
      }
    });
  }, 300), [userId]);

  useEffect(() => {
    searchAudience(value);
  }, [value, userId]); // eslint-disable-line react-hooks/exhaustive-deps

  function removeAudienceFromList(id: string) {
    setFieldValue(name, audiences.filter((app: AudienceResponseDTO) => app.id !== id));
  }

  return (
    <>
      {!!audiences.length && (
        <Box sx={style.audienceList}>
          {audiences.map((audience: AudienceResponseDTO) => (
            <Box sx={style.audience} key={audience.id}>
              <Typography sx={style.audienceName}>{audience.name}</Typography>
              <ProfileIcon style={style.profileIcon}/>
              <Typography sx={style.estimatedSize}>{abbreviateNumber(audience.row_count)}</Typography>
              <CrossIcon style={style.crossIcon} onClick={() => removeAudienceFromList(audience.id)}/>
            </Box>
          ))}
        </Box>
      )}
      <IntFieldController
        control={control}
        name={name}
        Component={IntAutocomplete}
        renderTags={() => null}
        placeholder={placeholder}
        onInputChange={(e, newValue, reason) => {
          if (reason === 'input') {
            setValue(newValue);
          }}
        }
        options={audienceList.data}
        inputValue={value}
        getOptionLabel={(option) => option.name}
        multiple
        clearIcon={null}
        disableCloseOnSelect
        sx={style.autocomplete}
        disableClearable
      />
    </>
  );
}

CustomAudienceList.displayName = 'CustomAudienceList';

export default CustomAudienceList;