import { DashboardData, Granularity } from "../../types";
import { LineChart, Line, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { formatDate } from "../../../global";
import { Box, MenuItem, Select, Tab, Tabs, Typography } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { style } from './style';
import { useTranslation } from "react-i18next";

type Props = {
  data: Array<DashboardData>;
  currency: string | undefined;
  dateFormat: string;
  firstMetric: string;
  secondMetric: string;
  setFirstMetric: (m: string) => void;
  setSecondMetric: (m: string) => void;
  granularityType: Granularity;
  handleGranularityTypeChange:(event: SyntheticEvent, newTab: Granularity) => void;
}


const moneyFields = ['spent', 'cpm'];
const metrics = ['spent', 'clicks', 'conversions', 'cpm', 'reach', 'bids', 'impressions'];
const legendCircle = (color: any) => (<Box sx={{...style.circle, backgroundColor: color}}/>);

function CompareLineChart({ data, currency = '$',firstMetric, secondMetric, setFirstMetric, setSecondMetric, dateFormat, granularityType, handleGranularityTypeChange }: Props) {

  const { t } = useTranslation('translation', { keyPrefix: 'reporting.chart' });

  const [firstMetricList, setFirstMetricList] = useState(metrics.filter((m) => m !== secondMetric));
  const [secondMetricList, setSecondMetricList] = useState(metrics.filter((m) => m !== firstMetric));

  const onFirstChange = (e: any) => {
    setFirstMetric(e.target.value);
    setSecondMetricList(metrics.filter((m) => m !== e.target.value));
  }

  const onSecondChange = (e: any) => {
    setSecondMetric(e.target.value);
    setFirstMetricList(metrics.filter((m) => m !== e.target.value))
  }

  const getMetricsToShow = (label: any) => {
   return data.filter(item => item.name === label)[0] || {};
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {

      const selectedObj = getMetricsToShow(label);
      const filteredMetrics = metrics.filter(m => m !== payload[0].name && m !== payload[1].name);

      return (
        <Box sx={{backgroundColor: '#ffffffe6', border: '1px solid #ccc', borderRadius: '4px',  padding: '8px'}}>
          <Typography sx={{fontWeight: 600}} >{formatDate(label, dateFormat)}</Typography>
          <Typography sx={{color: payload[0].color, textTransform: 'capitalize'}}>{`${payload[0].name} : ${payload[0].value}`}</Typography>
          <Typography sx={{color: payload[1].color, textTransform: 'capitalize'}}>{`${payload[1].name} : ${payload[1].value}`}</Typography>
          { filteredMetrics.map((name: any) => {
            return (<Typography key={name}>{`${name} : ${selectedObj[name as keyof typeof selectedObj]}`}</Typography>)
          })}
        </Box>
      );
    }

    return null;
  };


  return (
    <>
      <Box sx={{display: 'flex', justifyContent: 'space-between', padding: '0 42px', marginTop: '24px'}}>
        <Box>
          <Select
            sx={{width: '200px', height: '27px', marginRight: '16px'}}
            value={firstMetric}
            IconComponent={ () => legendCircle('#8884d8')}
            onChange={onFirstChange}
          >
            {firstMetricList.map(item => (<MenuItem key={item} value={item}>{item}</MenuItem>))}
          </Select>

          <Select
            sx={{width: '200px', height: '27px', marginRight: '16px'}}
            value={secondMetric}
            IconComponent={ () => legendCircle('#82ca9d')}
            onChange={onSecondChange}
          >
            {secondMetricList.map(item => (<MenuItem key={item} value={item}>{item}</MenuItem>))}
          </Select>
        </Box>
        <Box>
          <Tabs value={granularityType} onChange={handleGranularityTypeChange} sx={style.tabs}>
            <Tab value={Granularity.PER_HOURS} label={t('granularity.hours')} sx={style.tab}></Tab>
            <Tab value={Granularity.PER_DAYS} label={t('granularity.days')} sx={style.tab}></Tab>
          </Tabs>
        </Box>
      </Box>

      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tickFormatter={date => formatDate(date, dateFormat) as string} fontSize={14} />
          <YAxis tickFormatter={data => `${moneyFields.includes(firstMetric) ? currency : ""} ${data}`} yAxisId="left" stroke="#8884d8" />
          <YAxis tickFormatter={data => `${moneyFields.includes(secondMetric) ? currency : ""} ${data}`} yAxisId="right" stroke="#82ca9d" orientation="right" />
          <Tooltip content={<CustomTooltip />} />
          <Line yAxisId="left" type="monotone" dataKey={firstMetric} stroke="#8884d8" activeDot={{ r: 5 }} />
          <Line yAxisId="right" type="monotone" dataKey={secondMetric} stroke="#82ca9d" activeDot={{ r: 5 }} />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

CompareLineChart.displayName = 'CompareLineChart';

export default CompareLineChart;