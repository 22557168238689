import { TopProgressBar } from "../utils";

export const TopProgressMiddleware = (store: any) => (next: any) => (
  action: any
) => {
  if (store.getState().loading.global) {
    TopProgressBar.start();
  }
  if (!store.getState().loading.global) {
    TopProgressBar.done();
  }
  next(action);
};
