import React, { Component } from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { Button, Flex, ValidatableInputField } from "@intent-ai/mandal-npm-lib-v2";
import { Modal } from "../../../containers";
import { ITransaction } from "../../../types";
import { transactionsMessages } from "../../../messages";
import "./style.scss";

interface IProps {
  modalData: { transaction: ITransaction };
  intl: IntlShape;
  closeModal: Function;
}

export class TransactionModal extends Component<IProps> {
  render() {
    const {
      props: {
        intl: { formatMessage },
        closeModal,
        modalData: { transaction },
      },
    } = this;

    return (
      <div>
        <div className="transaction-modal">
          <Modal.Header>
            <FormattedMessage
              id="Transactions.accountInformation"
              defaultMessage="Account Information"
            />
          </Modal.Header>
          <div>
            <ValidatableInputField
              currentValue={transaction.space_name}
              label={formatMessage(transactionsMessages.contactPerson)}
              withLabel
              disabled
              baseline
            />
            <ValidatableInputField
              currentValue={transaction.entity_name}
              label={formatMessage(transactionsMessages.entity)}
              withLabel
              disabled
              baseline
            />
            <ValidatableInputField
              currentValue={
                transaction.company_name
                  ? transaction.company_name
                  : formatMessage(transactionsMessages.individual)
              }
              label={formatMessage(transactionsMessages.companyName)}
              withLabel
              disabled
              baseline
            />
            <ValidatableInputField
              currentValue={transaction.email}
              label={formatMessage(transactionsMessages.email)}
              withLabel
              disabled
              baseline
            />
            <ValidatableInputField
              currentValue={transaction.phone}
              label={formatMessage(transactionsMessages.phone)}
              withLabel
              disabled
              baseline
            />
          </div>
          <Modal.Footer>
            <Flex style={{ width: "100%" }} justifyContent="center">
              <Button
                type="border"
                i18nId="ButtonLabel.cancel"
                onClick={closeModal}
              />
            </Flex>
          </Modal.Footer>
        </div>
      </div>
    );
  }
}

export default injectIntl(TransactionModal);
