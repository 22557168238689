export const style = {
  moreVertIcon: {
    color: '#6CA1F2'
  },
  simpleText: {
    fontSize: 20,
    color: '#292f41',
    lineHeight: '28px',
    fontWeight: 600,
  },
  iconButton: {
    width: 30,
  }
}
