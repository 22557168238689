import React, { Component } from "react";
import { reduxForm, Field, InjectedFormProps } from "redux-form";
import { injectIntl, IntlShape } from "react-intl";
import { ValidatableInputField, Button } from "@intent-ai/mandal-npm-lib-v2";
import { ValidateUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { RESET_PASSWORD_FORM } from "../../../../_constants";
import messages from "./messages";
import "./styles.css";
import { IResetPassword } from "../../../../types";

const {
  validate,
  methods: { isPassword, matches },
} = ValidateUtils;

interface IProps {
  intl: IntlShape;
}

interface InjectedProps extends InjectedFormProps<IResetPassword, IProps> {}
interface ComponentProps extends IProps, InjectedProps {}

class WrappedResetPasswordForm extends Component<ComponentProps> {
  static validationConfig = {
    password: [
      {
        method: matches.to("repeatPassword"),
        message: messages.passwordNoMatch,
      },
      {
        method: matches.notTo("firstName lastName"),
        message: messages.passwordPersonal,
      },
      { method: isPassword, message: messages.passwordInsecure },
    ],
    repeatPassword: [
      {
        method: matches.to("password"),
        message: messages.passwordNoMatch,
      },
      {
        method: matches.notTo("firstName lastName"),
        message: messages.passwordPersonal,
      },
      {
        method: isPassword,
        message: messages.passwordInsecure,
      },
    ],
  };

  render() {
    const {
      handleSubmit,
      intl: { formatMessage },
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className="reset-password-form">
        <Field
          name="password"
          type="password"
          component={ValidatableInputField}
          label={formatMessage(messages.enterPassword)}
          topLabel
          withLabel
        />
        <Field
          name="repeatPassword"
          type="password"
          component={ValidatableInputField}
          label={formatMessage(messages.repeatPassword)}
          topLabel
          withLabel
        />
        <Button i18nId="Auth.savePassword" buttonType="submit" />
      </form>
    );
  }
}

export const ResetPasswordForm = injectIntl(
  reduxForm<IResetPassword, IProps>({
    form: RESET_PASSWORD_FORM,
    validate: validate(WrappedResetPasswordForm.validationConfig),
  })(WrappedResetPasswordForm)
);
