import React, { Component } from "react";
import { REACT_APP_BASE_URL, CAMPAIGN_FORM } from "../../../_constants";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { ObjectUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import { FileUpload } from "@intent-ai/mandal-npm-lib-v2";
import { trash_icon } from "../../../assets";
import "./style.scss";
import { ICampaign, IVisualsImage } from "../../../types";
import { campaignMessages } from "../../../messages";
import clsx from "clsx";

const { getFieldInObject } = ObjectUtils;

interface IProps {
  smallSize: boolean;
  currentData: ICampaign;
  width: number;
  height: number;
  changeFieldValue: Function;
  imageKey: number;
  intl: IntlShape;
}

interface IState {
  error: boolean;
}

class WrappedCampaignResponsiveVisualForm extends Component<IProps, IState> {
  state = {
    error: false,
  };

  setErrorTrue = () => this.setState(() => ({ error: true }));

  resetError = () => this.setState(() => ({ error: false }));

  handleSuccessUpload = (
    id: any,
    fileWidth: string,
    fileHeight: string,
    type_key: number
  ) => {
    const {
      props: { changeFieldValue, currentData, width, height, imageKey },
      resetError,
    } = this;
    resetError();
    const prevImages = getFieldInObject(
      currentData,
      "visuals.adaptive.banners"
    );
    const newElement = {
      file_id: id,
      width,
      height,
      deleted: false,
      key: imageKey,
      type_key,
    };
    if (prevImages) {
      changeFieldValue(CAMPAIGN_FORM, "visuals.adaptive.banners", [
        ...prevImages,
        newElement,
      ]);
      return;
    }
    changeFieldValue(CAMPAIGN_FORM, "visuals.adaptive.banners", [newElement]);
  };

  handleFailUpload = (e: any) => {
    if (e.type.code === 1996) {
      this.setErrorTrue();
    }
  };

  getImage = () => {
    const {
      props: { currentData, width, height },
    } = this;
    const images = getFieldInObject(currentData, "visuals.adaptive.banners");
    if (images) {
      const image = images.find(
        ({ width: validWidth, height: validHeight }: IVisualsImage) => {
          if (width === validWidth && height === validHeight) {
            return true;
          }
          return false;
        }
      );
      if (image) {
        return image.file_id;
      }
      return "";
    }
    return "";
  };

  deleteImage = (filedId: string) => () => {
    //TODO: Remove dublciate
    const {
      props: { currentData, changeFieldValue },
    } = this;
    const images = getFieldInObject(currentData, "visuals.adaptive.banners");
    const newImage = images.filter(({ file_id }: IVisualsImage) => {
      return file_id !== filedId;
    });
    changeFieldValue(CAMPAIGN_FORM, "visuals.adaptive.banners", newImage);
  };

  render() {
    const {
      props: {
        intl: { formatMessage },
        width,
        height,
      },
      state: { error },
      handleFailUpload,
      handleSuccessUpload,
      getImage,
      deleteImage,
    } = this;
    const image = getImage();
    return (
      <div>
        <div
          className="visual-image-field-container"
          style={{ maxWidth: width }}
        >
          <div
            className={clsx("visual-image-field", {
              "show-image": image,
            })}
          >
            { image ? (
              <div className="adaptive-visual-image">
                <img
                  src={`${REACT_APP_BASE_URL}/v1/file/${image}`}
                  alt="Visual"
                />
                <div
                  onClick={deleteImage(image)}
                  className="image-section-remove-container"
                >
                  <img src={trash_icon} alt="Remove icon" />
                </div>
              </div>
              ) : (
              <FileUpload
                type="area"
                label={formatMessage(campaignMessages.uploadImage)}
                handleFailUpload={handleFailUpload}
                handleSuccessUpload={handleSuccessUpload}
                fielAccept=".jpg, .png, .jpeg"
                width={width}
                height={height}
                isImage
              />
              )
            }
          </div>
          <p className="visual-image-field-label">
            {width} x {height}
          </p>
        </div>
        {!!error && (
          <div className="visual-image-field-error">
            <FormattedMessage
              id="CampaignForm.imagesSizeInvalid"
              defaultMessage="FILE DOESN'T HAVE SUPPORTED RESOLUTION"
            />
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(WrappedCampaignResponsiveVisualForm);
