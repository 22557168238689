export const userRoles = {
  SYSTEM_ADMIN: 1,
  WL_OWNER: 10,
  ACCOUNTANT: 11,
  WL_MODERATOR: 12,
  USER_OWNER: 20,
};

export const USER_TYPES = {
  company: 1,
  individual: 2,
};

export const USER_STATUSES = {
  NEED_VERIFICATION: 1,
  APPROVED: 2,
  ACTIVE: 5,
};

export const USER_TYPE_KEY = {
  LEGAL: 1,
  PHYSICAL: 2,
};
