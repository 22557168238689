import { Box, Button, Divider, Typography } from "@mui/material";
import { style } from './style';
import { useTranslation } from "react-i18next";
import { IntFieldController, IntSwitch } from "../../../global";
import { useForm } from "react-hook-form";
import React from "react";
import { IUserAccount } from "../../../../types";
import { PROFILE_NOTIFICATIONS_FORM_DEFAULT_VALUES } from "../../utils";
import { ProfileNotificationForm } from "../../types";
import { ProfileService } from "../../services";
import { StatusCode } from "../../../global/types";
import { NotificationService } from "../../../global/services";

type Props = {
  userAccount: IUserAccount;
}

function ProfileNotifications({ userAccount }: Props) {
  const { t } = useTranslation();


  const defaultValues = ProfileService.mapToNotificationsForm(userAccount);

  const {
    control,
    formState,
    handleSubmit,
    reset,
  } = useForm<ProfileNotificationForm>({
    defaultValues: defaultValues || PROFILE_NOTIFICATIONS_FORM_DEFAULT_VALUES,
  });

  async function onSubmit(values: ProfileNotificationForm) {
    const data = ProfileService.mapToNotificationsDTO(values);
    const response = await ProfileService.updateUserNotification({
      ...userAccount,
      settings: { ...data },
    });
    if (response.type.code == StatusCode.OK) { // eslint-disable-line
      NotificationService.handleSuccess(t('profile.notifications.savedSuccessfully'))
      const formValues = ProfileService.mapToNotificationsForm(response.data) as ProfileNotificationForm;
      reset(formValues);
    }
  }

  return (
    <Box sx={style.form} component='form' onSubmit={handleSubmit(onSubmit)}>
      <Typography sx={style.formTitle}>{t('profile.notifications.title')}</Typography>
      <Box sx={style.header}>
        <Typography sx={style.headerField}>{t('profile.notifications.eventType')}</Typography>
        <Typography sx={style.headerField}>{t('profile.notifications.emailNotifications')}</Typography>
      </Box>
      <Divider />
      <Box>
        <Box sx={style.field}>
          <Typography sx={style.fieldLabel}>{t('profile.notifications.campaignRejected')}</Typography>
          <IntFieldController control={control} name='campaignRejected' Component={IntSwitch} />
        </Box>
        <Divider />
        <Box sx={style.field}>
          <Typography sx={style.fieldLabel}>{t('profile.notifications.outOfBudget')}</Typography>
          <IntFieldController control={control} name='outOfBudget' Component={IntSwitch} />
        </Box>
        <Divider />
        <Box sx={style.field}>
          <Typography sx={style.fieldLabel}>{t('profile.notifications.closeToThreshold')}</Typography>
          <IntFieldController control={control} name='closeToThreshold' Component={IntSwitch} />
        </Box>
        <Divider />
        <Box sx={style.field}>
          <Typography sx={style.fieldLabel}>{t('profile.notifications.spaceOutOfBudget')}</Typography>
          <IntFieldController control={control} name='spaceOutOfBudget' Component={IntSwitch} />
        </Box>
        <Divider />
        <Box sx={style.field}>
          <Typography sx={style.fieldLabel}>{t('profile.notifications.campaignApproved')}</Typography>
          <IntFieldController control={control} name='campaignApproved' Component={IntSwitch} />
        </Box>
        <Divider />
        <Box sx={style.field}>
          <Typography sx={style.fieldLabel}>{t('profile.notifications.endDateReached')}</Typography>
          <IntFieldController control={control} name='endDateReached' Component={IntSwitch} />
        </Box>
      </Box>
      <Divider />
      <Box sx={style.submitForm}>
        <Button
          disabled={!formState.isDirty || formState.isSubmitting || !formState.isValid}
          sx={style.submitButton}
          type='submit'
        >{t('profile.legalForm.submitTitle')}</Button>
      </Box>
    </Box>
  );
}

ProfileNotifications.displayName = 'ProfileNotifications';

export default ProfileNotifications;