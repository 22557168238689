import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { style } from './style';
import React, { MouseEventHandler, useEffect, useMemo, useState } from "react";
import { Control, SetFieldValue, useWatch } from "react-hook-form";
import { DeviceType, PlatformReportState, ReportLightDTO } from "../../types";
import { ReportService } from "../../services";
import { StatusCode } from "../../../global/types";
import { Cell, Pie, PieChart, Tooltip as ChartTooltip } from "recharts";
import { renderCustomizedLabel, customTooltip } from './customized-components';

type Props = {
  control: Control<any>;
  selectedTab: 'impressions' | 'clicks';
  setValue: SetFieldValue<any>;
  reset: MouseEventHandler<HTMLButtonElement>;
}

const INNER_CIRCLE_COLORS = ["#F2A658", "#518AEE"];

const OUTER_CIRCLE_COLORS = ["#EF902E", "#92B5F4"];

function PlatformReport({ control, selectedTab, setValue, reset }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'reporting' });

  const [platformReport, setPlatformReport] = useState<PlatformReportState>({
    data: null,
    isLoading: true,
  });

  const formValues = useWatch({
    control,
  });

  function getPlatformReport(filter: ReportLightDTO) {
    setPlatformReport({ data: null, isLoading: true });
    ReportService.getPlatformReport(filter)
      .then(response => {
        if (response?.type?.code == StatusCode.OK) { // eslint-disable-line
          setPlatformReport({
            data: response.data,
            isLoading: false,
          });
        }
      });
  }

  useEffect(() => {
    if (formValues.campaignId) {
      getPlatformReport(ReportService.buildReportLightDTO(formValues));
    }
  }, [formValues]);

  const { innerData, outerData, listItems } = useMemo(() => {
    if (!platformReport.data) {
      return {
        innerData: [],
        outerData: [],
        listItems: [],
      };
    }

    const desktopTotal = platformReport.data[selectedTab]?.desktop?.total || 0;
    const mobileTotal = platformReport.data[selectedTab]?.mobile?.total || 0;
    const desktopOthersTotal = platformReport.data[selectedTab]?.desktop?.others || 0;
    const androidTotal = platformReport.data[selectedTab]?.mobile?.values?.android || 0;
    const mobileOthersTotal = platformReport.data[selectedTab]?.mobile.others || 0;

    if (desktopTotal || mobileTotal || desktopOthersTotal || androidTotal || mobileOthersTotal) {
      const innerData = [
        { name: t('desktop'), value: desktopTotal },
        { name: t('mobile'), value: mobileTotal },
      ];

      const outerData = [
        { name: t('desktopOthers'), value: desktopOthersTotal },
        { name: t('android'), value: androidTotal },
      ];

      return {
        innerData,
        outerData,
        listItems: [
          { name: t('desktop'), value: desktopTotal, fill: '#F2A658' },
          { name: t('mobile'), value: mobileTotal, fill: '#4F88EE' },
          { name: t('desktopOthers'), value: desktopOthersTotal, fill: '#EF902E' },
          { name: t('android'), value: androidTotal, fill: '#95B8F4' },
          { name: t('mobileOthers'), value: mobileOthersTotal, fill: '#7AA7F2' }
        ].filter(platform => platform.value),
      };
    }
    return {
      innerData: [],
      outerData: [],
      listItems: [],
    };
  }, [platformReport.data, selectedTab, t]);

  const showChart = !platformReport.isLoading && !!(innerData.length || outerData.length);

  function handleChartItemClick({ name }: { name: string }) {
    if (name === 'Desktop') {
      setValue('deviceTypeKey', DeviceType.DESKTOP);
    } else if (name === 'Desktop Others') {
      setValue('deviceTypeKey', DeviceType.DESKTOP_OTHERS);
    } else if (name === 'Mobile') {
      setValue('deviceTypeKey', DeviceType.MOBILE);
    } else if (name === 'Android') {
      setValue('deviceTypeKey', DeviceType.ANDROID);
    } else if (name === 'Mobile Others') {
      setValue('deviceTypeKey', DeviceType.MOBILE_OTHERS)
    }
  }

  return (
    <>
      <Box sx={style.chart}>
        {showChart ? (
          <PieChart width={320} height={340}>
            <Pie
              data={innerData}
              label={renderCustomizedLabel(handleChartItemClick)}
              onClick={handleChartItemClick}
              labelLine={false}
              dataKey='value'
              stroke='none'
              outerRadius={95}
            >
              {innerData.map((d, index) => (
                <Cell key={`cell-${index}`} fill={INNER_CIRCLE_COLORS[index]}/>
              ))}
            </Pie>
            <Pie
              data={outerData}
              dataKey='value'
              innerRadius={120}
              outerRadius={160}
              stroke='none'
              onClick={handleChartItemClick}
            >
              {outerData.map((d, index) => (
                <Cell key={`cell-${index}`} fill={OUTER_CIRCLE_COLORS[index]} />
              ))}
            </Pie>
            <ChartTooltip content={customTooltip(selectedTab)} />
          </PieChart>
        ) : (
          <Box sx={style.noDataBox}>
            {platformReport.isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Typography sx={style.noDataAvailable}>{t('noData')}</Typography>
                <Button variant='outlined' sx={style.resetFilters} onClick={reset}>{t('resetFilters')}</Button>
              </>
            )}
          </Box>
        )}
      </Box>
      {!!listItems.length && (
        <List sx={style.platformList}>
          {listItems.map(({ name, value, fill }) => (
            <ListItem key={name} sx={style.listItem} onClick={() => handleChartItemClick({ name })}>
              <svg
                width="16"
                height="16"
                version="1.1"
                fill={fill}
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="16" height="16" />
              </svg>
              <ListItemText>{name} <strong>({value})</strong></ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </>
  )
}

PlatformReport.displayName = 'PlatformReport';

export default PlatformReport;