import { IRootState } from "../../../../store";

function getUserRole(state: IRootState) {
  return state?.authentication?.user?.role_key;
}

function getUserSpaceSelector(state: IRootState) {
  return state?.authentication?.space;
}

function getUserIdSelector(state: IRootState) {
  return state?.authentication?.space.id;
}

export {
  getUserSpaceSelector,
  getUserRole,
  getUserIdSelector,
}