import React from "react";
import { Flex } from "@intent-ai/mandal-npm-lib-v2";
import { PAYMENT_STATUS_COLORS } from "../../../_constants";
import "./style.scss";
import { getSpaceImage } from "../../../modules";

interface IProps {
  imageId: string;
  name: string;
  status: number;
}

export function PaymentSpaceAndImage({ imageId, name, status }: IProps) {
  return (
    <Flex>
      <div className="payment-space-image-container">
        <img
          className="payment-space-image"
          src={getSpaceImage(imageId)}
          alt="Curretn space"
        />
        <div
          className="payment-space-status-color"
          style={{
            backgroundColor: `${PAYMENT_STATUS_COLORS[status]}`,
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <p className="payment-space-info">{name}</p>
        {/* <FormattedMessage id={`Payments.${PAYMENT_STATUSES[status]}`} /> */}
      </div>
    </Flex>
  );
}
