/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 9/13/2023
 * All Rights Reserved.
 */
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { style } from './style';
import { AudioPlayer, PlusIcon, VoiceAdsUploadIcon } from "../../../global";
import React, { ChangeEvent, ReactNode } from "react";
import { UploadService } from "../../../upload/services";
import { useFormContext, useWatch } from "react-hook-form";
import { CampaignFormDTO } from "../../types";
import { StatusCode } from "../../../global/types";
import { validateAudioFile } from "./validation";
import { NotificationService } from "../../../global/services";

type Props = {
  footer: ReactNode;
}

function CampaignTracks({ footer }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.audioAds' });
  const { setValue, control } = useFormContext<CampaignFormDTO>();

  const audio = useWatch({
    control,
    name: 'audioAds',
  });

  async function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    if (file) {
      const {isValid, errorKey} = await validateAudioFile(file);
      if(isValid) {
        const response = await UploadService.uploadAudioFile(file);
        if (response.type?.code == StatusCode.OK) { // eslint-disable-line
          if (response.data?.id) {
            setValue('id', response.data.id);
          }
          setValue('audioAds', [...(audio || []), {
            fileId: response.data.file_id,
            name: file.name,
            url: response.data.file_url,
            size: file.size / 1000000, //MB
            extension: file.name.split('.')?.[1],
          }]);
        }
      } else {
        NotificationService.handleError(t(errorKey), 4000);
      }
    }
  }

  function handleRemove(id: string) {
    setValue('audioAds', audio.filter(({ fileId }) => fileId !== id));
  }

  return (
    <Box sx={style.voiceAds}>
      <Typography sx={style.title}>{t('title')}</Typography>
      <Divider />
      <Typography sx={style.description}>{t('uploadFile')}</Typography>
      {!audio.length && (
        <Box sx={style.voiceUploader}>
          <VoiceAdsUploadIcon viewBox='0 0 120 120' sx={style.voiceAdsUploadIcon}/>
          <Typography sx={style.addVoiceFile}>{t('addVoiceFile')}</Typography>
          <Box sx={style.uploader}>
            <IconButton sx={style.plusIconButton}>
              <PlusIcon />
            </IconButton>
            <Box
              component='input'
              type='file'
              accept='.mp3'
              onChange={handleChange}
              sx={style.hiddenInput}
            />
          </Box>
        </Box>
      )}
      {!!audio.length && (<Box sx={style.list}>
        {audio.map(file => (
          <AudioPlayer file={file} key={file.name} onRemove={handleRemove} />
        ))}
        {audio.length < 5 && (
          <Box sx={style.uploader}>
            <IconButton sx={style.plusIconButton}>
              <PlusIcon />
            </IconButton>
            <Box
              component='input'
              type='file'
              accept='.mp3'
              onChange={handleChange}
              sx={style.hiddenInput}
            />
          </Box>
        )}
      </Box>)}
      {footer}
    </Box>
  );
}

CampaignTracks.displayName = 'CampaignTracks';

export default CampaignTracks;