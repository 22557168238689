import React from "react";
import { AdminSidebar } from "../../../containers";
import { useSelector } from "react-redux";
import { select } from "../../../store";
import "./style.scss";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { IntentLogo } from "../../../modules";
import { routerPaths } from "../../../config/routes/paths";
import Footer from "../AdminFooter";

interface IProps {
  title: string;
  logo?: string;
  children: JSX.Element | JSX.Element[];
}

const AdminHeader = ({ title, logo, children }: IProps) => {
  const termOfServiceUrl = useSelector(select.admin.termsOfServiceLinkSelector);
  const [isActive, setActive] = React.useState(false);

  return (
    <>
      <div className="admin-container">
        <div className="admin-header">
          <div className="admin-header-logo">
            <IntentLogo />
          </div>
          <p className="title">{title}</p>
          <Link to={routerPaths.Campaigns} className="go-back-container">
            <p>
              <FormattedMessage
                id="Admin.GoToPlatform"
                defaultMessage="Go to Platform"
              />
            </p>
          </Link>
        </div>
        <div className="admin-content">
          <div className="admin-content-inner">
            <AdminSidebar withToggle setSideBar={setActive} />
            <div
              className={clsx("admin-table-wrapper", {
                active: isActive,
              })}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
      <div className="admin-footer-wrapper">
        <Footer termOfServiceUrl={termOfServiceUrl} />
      </div>
    </>
  );
};

export default AdminHeader;
