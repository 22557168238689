import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { match } from "react-router-dom";
import { History } from "history";
import { AuthFormWrapper } from "@intent-ai/mandal-npm-lib-v2";
import { AuthWrapper } from "../../components";
import { ResetPasswordForm } from "../../containers";
import { IResetPassword, IResponse } from "../../types";
import { Dispatch } from "../../store";
import { RT_TOKEN_INVALID } from "../../_constants";
import { SubmissionError } from "redux-form";
import "./style.scss";
import { routerPaths } from "../../config/routes/paths";
import { NotificationService } from "../../modules/global/services";

interface IMatchParams {
  key: string;
}

interface IProps {
  finishForgotPassword: Function;
  match: match<IMatchParams>;
  history: History;
}

class WrappedResetPassword extends Component<IProps> {
  handleFormSubmit = ({ password: newPassword }: IResetPassword) => {
    const {
      props: {
        finishForgotPassword,
        match: {
          params: { key },
        },
      },
      handleResponseErrors,
      handleFormSubmitSuccess: success,
    } = this;
    const data = { newPassword, key };
    return new Promise((resolve, reject) => {
      const fail = handleResponseErrors(reject);
      finishForgotPassword({ data, success, fail });
    });
  };

  handleResponseErrors = (reject: Function) => (resp: IResponse<any>) => {
    if (resp.type.code === RT_TOKEN_INVALID) {
      reject(
        new SubmissionError({
          repeatPassword: {
            id: "Auth.tokenInvalid",
            defaultMessage: "Token expired",
          },
        })
      );
    }
  };

  handleFormSubmitSuccess = () => {
    const { history } = this.props;
    setTimeout(() => NotificationService.handleSuccess( 'Password changed. Login into your account now.'), 0);
    history.push(routerPaths.Login);
  };

  render() {
    const { handleFormSubmit } = this;
    return (
      <AuthWrapper>
        <AuthFormWrapper>
          <div className="signup-title mb-3 reset-password-title">
            <FormattedMessage
              id="Auth.resetPassword"
              defaultMessage="Reset Password"
            />
          </div>
          <ResetPasswordForm onSubmit={handleFormSubmit} />
        </AuthFormWrapper>
      </AuthWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  finishForgotPassword: dispatch.authentication.finishForgotPassword,
});

export const ResetPassword = connect(
  null,
  mapDispatchToProps
)(WrappedResetPassword);

ResetPassword.displayName = "ResetPassword";
