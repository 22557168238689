import React, { Component } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { AuthTitleWrapper } from "../../components";
import { transactionsMessages } from "../../messages";
import { TransactionsContainer } from "../../containers";

interface IProps {
  intl: IntlShape;
}

class WrappedTransactions extends Component<IProps> {
  render() {
    const {
      props: {
        intl: { formatMessage },
      },
    } = this;

    return (
      <AuthTitleWrapper
        iconName="billing"
        title={formatMessage(transactionsMessages.title)}
      >
        <TransactionsContainer />
      </AuthTitleWrapper>
    );
  }
}

const Transactions = injectIntl(WrappedTransactions);

Transactions.displayName = "Transactions";

export default Transactions;
