import { FetchUtils } from "@intent-ai/mandal-npm-lib-v2/dist/utils";

const { createRequest } = FetchUtils;

export const getCurrentSpaceBalanceRequest = (id: string) =>
  createRequest(`/space/balance/${id}`);

export const getCurrentSpaceRequest = (id: string) =>
  createRequest(`/space/${id}`);

export const getCurrentSpaceUserDataRequest = (id: string) =>
  createRequest(`/user/${id}`);

export const getCurrentSpaceUserAccountDataRequest = (id: string) =>
  createRequest(`/user/account/${id}`);
