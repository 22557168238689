import React, { useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { campaignMessages } from "../../messages";
import { AuthTitleWrapper } from "../../components";
import { CardType } from "@intent-ai/mandal-npm-lib-v2";
import "./style.scss";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CAMPAIGN_TYPES } from "../../_constants";
import { banner_card, banner_fullscreen, catfish_card } from "../../assets";
import { Dispatch } from "../../store";
import { routerPaths } from "../../config/routes/paths";

const CampaignType: React.FC<RouteComponentProps> = ({
  location,
  history: { push },
}) => {
  const dispatch: Dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const searchParams = new URLSearchParams(location.search);
  const campaignId = searchParams.get("campaignId");

  useEffect(() => {
    if (campaignId) {
      // @ts-ignore
      dispatch.campaign.getCampaign({ id: campaignId });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTypeClick = (type: number) => () => {
    if (campaignId) {
      dispatch.campaign.createSimilarCampaign({
        // @ts-ignore
        type,
        success: () => {
          push(`${routerPaths.CampaignForm}?type=${type}`);
        },
      });
      return;
    }
    push(`${routerPaths.CampaignForm}?type=${type}`);
  };
  return (
    <AuthTitleWrapper
      title={formatMessage(campaignMessages.createNewCampaign)}
      iconName="campaign"
    >
      <div className="campaign-type">
        <h2 className="campaign-type-title">
          <FormattedMessage
            id="Campaign.selectCreativeType"
            defaultMessage="Select a creative type"
          />
        </h2>
        <div className="campaign-type-list">
          <CardType
            name={formatMessage(campaignMessages.banner)}
            description={formatMessage(campaignMessages.bannerDescription)}
            buttonLabel="ButtonLabel.create"
            onClick={handleTypeClick(CAMPAIGN_TYPES.BANNER)}
            image={banner_card}
          />
          <CardType
            name={formatMessage(campaignMessages.catfish)}
            description={formatMessage(campaignMessages.catfishDescription)}
            buttonLabel="ButtonLabel.create"
            onClick={handleTypeClick(CAMPAIGN_TYPES.CATFISH)}
            image={catfish_card}
          />
          <CardType
            name={formatMessage(campaignMessages.interactive)}
            description={formatMessage(campaignMessages.interactiveDescription)}
            buttonLabel="ButtonLabel.create"
            onClick={handleTypeClick(CAMPAIGN_TYPES.INTERACTIVE)}
            image={banner_card}
          />
          <CardType
            name={formatMessage(campaignMessages.fullScreen)}
            description={formatMessage(campaignMessages.fullScreenDescription)}
            buttonLabel="ButtonLabel.create"
            onClick={handleTypeClick(CAMPAIGN_TYPES.FULLSCREEN)}
            image={banner_fullscreen}
          />
          {/* <CardType
            name={formatMessage(campaignMessages.inPageVideo)}
            description={formatMessage(campaignMessages.inPageVideoDescription)}
            buttonLabel="ButtonLabel.create"
            onClick={handleTypeClick(CAMPAIGN_TYPES.IN_PAGE_VIDEO)}
            image={banner_fullscreen}
          /> */}
        </div>
      </div>
    </AuthTitleWrapper>
  );
};

CampaignType.displayName = "CampaignType";

export default CampaignType;
