import { Box, Button, Divider, Typography } from "@mui/material";
import { style } from './style';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { IntFieldController, NEW_ROUTES } from "../../../global";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FORGOT_PASSWORD_FORM_DEFAULT_VALUES, getForgotPasswordFormValidation } from "../../utils";
import { ForgotPasswordDTO } from "../../types";
import { UserService } from "../../../user/services";
import { StatusCode } from "../../../global/types";
import { useState } from "react";

function ForgotPassword() {
  const { t } = useTranslation('translation', { keyPrefix: 'auth.forgotPassword' });

  const [emailSent, setEmailSent] = useState(false);

  const { control, handleSubmit, formState: { isSubmitting } } = useForm({
    resolver: yupResolver(getForgotPasswordFormValidation()),
    defaultValues: FORGOT_PASSWORD_FORM_DEFAULT_VALUES,
  });

  const history = useHistory();

  function onLoginClick() {
    history.replace(NEW_ROUTES.auth.login.fullPath);
  }

  function onCreateAccountClick() {
    history.replace(NEW_ROUTES.auth.register.fullPath);
  }

  async function onSubmit(data: ForgotPasswordDTO) {
    const response = await UserService.resetPassword(data);
    // eslint-disable-next-line
    if (response.type.code == StatusCode.OK) {
      setEmailSent(true);
    }
  }

  return (
    <Box sx={style.view}>
      <Box sx={style.form} component='form' onSubmit={handleSubmit(onSubmit)}>
        {emailSent ? (
          <Typography sx={style.emailSent}>{t('emailSent')}</Typography>
        ) : <>
          <Typography sx={style.forgotPassword}>{t('title')}</Typography>
          <Box sx={style.field}>
            <Typography sx={style.fieldLabel}>{t('email')}</Typography>
            <IntFieldController
              control={control}
              name='email'
              sx={style.fieldInput}
              placeholder={t<string>('email')}
            />
          </Box>
          <Button sx={style.submitButton} type='submit' disabled={isSubmitting}>
            {t('reset')}
          </Button>
        </>}
        <Divider />
        <Button sx={style.backToLogin} onClick={onLoginClick}>
          {t('backToLogin')}
        </Button>
      </Box>
      <Typography sx={style.createAccount} onClick={onCreateAccountClick}>{t('createAccount')}</Typography>
    </Box>
  );
}

ForgotPassword.displayName = 'ForgotPassword';

export default ForgotPassword;
