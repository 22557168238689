import React, { Component } from "react";
import { injectIntl, IntlShape, defineMessages } from "react-intl";
import { IRootState, select, Dispatch } from "../../store";
import { connect } from "react-redux";
import { AdminHeader } from "../../components";
import { AdminModerationAccounts, AdminModerationCampaigns } from "../../containers";
import {
  IPaginationData,
  IUser,
  ICampaign,
  IDictionaryField,
} from "../../types";
import { ContentWrapper, MaiFilters, Tabs } from "@intent-ai/mandal-npm-lib-v2";
import "./style.scss";
import { IN_MODERATION, PENDING } from "../../_constants";
import { adminMessages } from "../../messages";
import { AdminLegalEntity } from "../../containers";

const messages = defineMessages({
  title: {
    id: "Moderation.title",
    defaultMessage: "Moderation",
  },
});

interface IProps {
  intl: IntlShape;
  getModerationRegistrations: Function;
  getModerationCampaign: Function;
  moderationUsers: IPaginationData<IUser[]>;
  moderationCampaign: IPaginationData<ICampaign[]>;
  setAdminNewSpace: Function;
  getDictionaryValues: Function;
  dictUserStatus: IDictionaryField[];
}

class WrappedModeration extends Component<IProps> {
  componentDidMount() {
    const {
      props: { getDictionaryValues },
    } = this;
    getDictionaryValues(["user_status"]);
  }

  render() {
    const {
      props: {
        intl: { formatMessage },
        getModerationRegistrations,
        getModerationCampaign,
        moderationUsers,
        moderationCampaign,
        setAdminNewSpace,
        dictUserStatus,
      },
    } = this;
    return (
      <AdminHeader title={formatMessage(messages.title)}>
        <div>
          <Tabs defaultActiveKey="2">
            <Tabs.TabPane
              tab={formatMessage(adminMessages.adminSearchCampaigns)}
              tabKey="1"
            />
            <Tabs.TabPane
              tab={formatMessage(adminMessages.adminSearchAccounts)}
              tabKey="2"
            />
            <Tabs.TabPane
              tab={formatMessage(adminMessages.adminLinksLegalEntity)}
              tabKey="3"
            />

            <Tabs.Container tabKey="1">
              <div className="admin-moderation-tab">
                <MaiFilters
                  showSearch
                  title=""
                  onReady={getModerationCampaign}
                  onChange={getModerationCampaign}
                  showPagination
                  pageCount={Math.ceil(moderationCampaign?.total_size / 10)}
                  additionalData={{ status_filter: [IN_MODERATION] }}
                >
                  <div>
                    <ContentWrapper>
                      <AdminModerationCampaigns
                        moderationCampaigns={moderationCampaign?.data}
                        setAdminNewSpace={setAdminNewSpace}
                      />
                    </ContentWrapper>
                  </div>
                </MaiFilters>
              </div>
            </Tabs.Container>
            <Tabs.Container tabKey="2">
              <div className="admin-moderation-tab">
                <MaiFilters
                  onReady={getModerationRegistrations}
                  onChange={getModerationRegistrations}
                  additionalData={{ status_filter: [IN_MODERATION, PENDING] }}
                  pageCount={Math.ceil(moderationUsers?.total_size / 10)}
                  showSearch
                  showPagination
                >
                  <ContentWrapper>
                    <div>
                      <AdminModerationAccounts
                        moderationUsers={moderationUsers?.data}
                        dictUserStatus={dictUserStatus}
                      />
                    </div>
                  </ContentWrapper>
                </MaiFilters>
              </div>
            </Tabs.Container>
            <Tabs.Container tabKey="3">
              <AdminLegalEntity />
            </Tabs.Container>
          </Tabs>
        </div>
      </AdminHeader>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  moderationUsers: select.admin.moderationUsersSelector(state),
  moderationCampaign: select.admin.moderationCampaignSelector(state),
  dictUserStatus: select.dictionary.selectDictionaryField(state, "user_status"),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getModerationRegistrations: dispatch.admin.getModerationRegistrations,
  getModerationCampaign: dispatch.admin.getModerationCampaign,
  setAdminNewSpace: dispatch.admin.setAdminNewSpace,
  getDictionaryValues: dispatch.dictionary.getDictionaryValues,
});

const Moderation = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WrappedModeration));

Moderation.displayName = "Moderation";

export default Moderation;
